import axios from "axios";
import {
  getToken,
  getRefreshToken,
  saveToken,
  saveExpToken,
  deleteExpJwToken,
} from "../Utils";
import { initializeAxiosMockAdapter, isMockEnabled } from "../mock/mock";

class Service {
  constructor() {
    let service = axios.create({
      //baseURL: process.env.REACT_APP_API_BASE_URL,
      //baseURL: "http://3.109.148.47:8001",
      //baseURL:"http://averav2.nesh.live:8001",
      // baseURL: "http://localhost:8001",
      // baseURL: "http://3.109.148.47:8002", //for QA
      //baseURL: "http://3.109.166.16:8001", //for production
      // baseURL: "http://localhost:8001",
      baseURL: "https://api.nesh.live", //for production url
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (isMockEnabled()) {
      initializeAxiosMockAdapter(service);
    }
    service.interceptors.request.use((config) => {
      getToken() &&
        (config.headers["Authorization"] =
          "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")));
      //getRefreshToken()
      localStorage.getItem("isRefreshToken") !== null &&
        (config.headers["isRefreshToken"] =
          window.localStorage.getItem("jwToken") === null ? false : true);
      localStorage.getItem("isRefreshToken") !== null &&
        (config.headers["refreshTokenKey"] = getRefreshToken());
      return config;
    });

    service.interceptors.response.use(
      (res) => {
        return res;
      },
      async (err) => {
        const originalConfig = err.config;
        if (originalConfig.url !== "/authenticate" && err.response) {
          if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;
            localStorage.setItem("isRefreshToken", true);
            try {
              const rs = await service.get("/refreshToken");
              const { accessToken, refreshToken } = rs.data;
              deleteExpJwToken();
              saveToken(accessToken, refreshToken);
              return service(originalConfig);
            } catch (_error) {
              //deleteToken();
              //deleteRefreshToken();
              saveExpToken();
              return Promise.reject(_error);
            }
          }
        }
        return Promise.reject(err);
      }
    );
    this.service = service;
  }

  get(path, params = {}) {
    return this.service.get(path, { params });
  }

  post(path, payload, params = {}, headers = {}) {
    return this.service.request({
      method: "POST",
      url: path,
      responseType: "json",
      data: payload,
      params,
      headers,
    });
  }
}

export default new Service();