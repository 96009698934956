import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { CardHeader, CardContent, Chip } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import VDRDropDown from "./VDRDropDown";
import { ButtonAlign } from "../shared/button/ButtonAlign";
import { Button } from "@mui/material";
import logServices from "../../services/logService";
import {
  StyledCardShadow,
  Exportoptions,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import { useVehicleDataMontraMutation } from "../../store/slice/ReportSlice/ReportSlice";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";







const ManualCanDataReportMontra = () => {

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;


  const [responseData, setResponseData] = useState([]);
  const text = "Note:This Report gets the details of battery in a Fleet based on date selection.";
  // useEffect(async () => {
  //   await callbackLoadFn();


  //   var request = {};
  //   request.message = "Reports > VehicleData";
  //   request.action = "View";
  //   request.featureName = "VehicleData";
  //   request.customerName = "All";
  //   request.fleetName = "All";
  //   request.result = "Success";
  //   request.access = "Portal";
  //   request.severity = "INFO";
  //   request.orgId = globalOrgId;
  //   request.userId = globaluserId;
  //   request.orgName = globalOrgName;
  //   request.userName= globalUserName;


  //   const responseData = await logServices.saveAuditLogDetails(request);




  // }, []);

  // const callbackLoadFn = async (
  //     selResellerId,
  //     selRegionId,
  //     selDealerId,
  //     selCustomerId,
  //     selFleetId,
  //     selVehicleId,
  //     selimei
  // ) => {
  //   loadrequest = assignedval;
  //   loadrequest.regionId = selRegionId;
  //   loadrequest.resellerId = selResellerId;
  //   //loadrequest.resellerId = 5;
  //   loadrequest.dealerId = selDealerId;
  //   loadrequest.customerId = selCustomerId;
  //   loadrequest.orgId = selFleetId; // fleet
  //   loadrequest.vehicleId = selVehicleId;
  //   const fromDate =
  //     format(addDays(new Date(), -1), "yyyy-MM-dd") + " 00:00:00";
  //   const toDate = format(addDays(new Date(), -1), "yyyy-MM-dd") + " 23:59:59";
  //   loadrequest.startDate = fromDate;
  //   loadrequest.endDate = toDate;
  //   loadrequest.orderDir = "";
  //   loadrequest.search = "";
  //   loadrequest.start = newPage;
  //   loadrequest.length = rowsPerPage;
  //   loadrequest.status = "9";


  //   // loadrequest = assignedval;
  //   // loadrequest.regionId = 1;
  //   // loadrequest.resellerId = 110224;
  //   // //loadrequest.resellerId = 5;
  //   // loadrequest.dealerId = 110379;
  //   // loadrequest.customerId = 110384;
  //   // loadrequest.orgId = 110385; // fleet
  //   // loadrequest.vehicleId = 26791;
  //   // const fromDate =
  //   //   format(addDays(new Date(), -1), "yyyy-MM-dd") + " 00:00:00";
  //   // const toDate = format(addDays(new Date(), -1), "yyyy-MM-dd") + " 23:59:59";
  //   // loadrequest.startDate = "2023-09-24 00:00:00";
  //   // loadrequest.endDate = "2023-09-24 23:59:59";
  //   // loadrequest.orderDir = "desc";
  //   // loadrequest.search = "0";
  //   // loadrequest.start = 0;
  //   // loadrequest.length = 50;
  //   // loadrequest.status = "9";

    
   

  //   setAssignedval(loadrequest);
   
  //   if(loadrequest.vehicleId!=null && loadrequest.vehicleId!=-1 && loadrequest.vehicleId!=0){
  //   const responseData = (await ReportServices.ManualCanDataReportMontra(loadrequest))
  //     .data;
  //   setResponseData([]);
  //   // console.log(JSON.parse(JSON.stringify(responseData.data)));
  // const result=JSON.parse(responseData.data);
  //   setResponseData(result.content);
  //   setTotalElements(5);
  //   setTotalPages(0);
  //   setTotalElements(result.totalElements);
  //   setTotalPages(result.totalPages);
  //   }else {
  //     setLoading("Success");
  //     setTotalElements(0);
  //     setTotalPages(0)
  //   }
  // };

  const [auditLog]= useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId:orgId,
    userId:userId, //User ID
    orgName:orgName, //Org Name
    userName:userName,
  }

  useEffect(async () => {
    auditPayload.message = 'Reports > VehicleData';
    auditPayload.action = 'View';
    auditPayload.featureName = 'VehicleData';
    auditPayload.customerName = 'All';
    auditPayload.fleetName = "All";
    auditPayload.result = 'Success';
    auditPayload.access = 'Portal';
    auditPayload.severity = 'INFO';
    auditLog(auditPayload);
  
  }, []);
  
  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "createdDate",
      },
      {
        Header: "Device Received Time",
        accessor: "deviceReceivedTime",
      },
      {
        Header: "Vehicle Number",
        accessor: "vehicleno",
        width:180
      },
      {
        Header: "Vehicle Status",
        accessor: "gpsMoveStatus",
        width:120,
        Cell:({row}) => {
          // console.log('row', row.original.strgpsMoveStatus);
          if(row.original.gpsMoveStatus !== '-' ){
              return <Chip label={row.original.gpsMoveStatus} size="small" color={row.original.gpsMoveStatus === 'Moving' ? 'success' : row.original.gpsMoveStatus === 'Charging' ? 'warning' : 'error'}/>
          }else{
              return row.original.gpsMoveStatus
          }
        }
      },
      {
        Header: "GPS Location",
        accessor: "location",
        width:350,
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async(e) => {
            if(refAddres.current.innerText === ''){
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (await ReportServices.getGeocodeAddress(request)).data;
              refAddres.current.innerText = responseData;
              if(responseData !== ''){
                refLink.current.style.display = 'none'
                refAddres.current.style.display = 'block'
                toggleAddressBtn.current.style.display = 'block'
                getAddressBtn.current.style.display = 'none'
                toggleAddressBtn.current.innerText='LatLng'
              }
            }
          }
        
          //Address toggle
          const ToggleAdderss = () => {
            if( refAddres.current.style.display === 'block' ){
              refAddres.current.style.display = 'none'
              refLink.current.style.display = 'block'
              toggleAddressBtn.current.innerText='Address'
            }else{
              refAddres.current.style.display = 'block'
              refLink.current.style.display = 'none'
              toggleAddressBtn.current.innerText='LatLng'
            }
          }
          return(
            <Box sx={{display:'flex', alignItems:'center'}} >
            <Box className='addresslink' id={row.original.location} sx={{display:'flex', alignItems:'center'}}>
                <Button onClick={(e) => getAddress(e)} value={row.original.location} ref={getAddressBtn}>Address</Button>
                <Button onClick={(e) => ToggleAdderss(e)} ref={toggleAddressBtn} sx={{display:'none'}}>LatLng</Button>
                <a href={`https://www.google.com/maps/search/?api=1&query=${row.original.location}`} target="_blank" className="latlink"  ref={refLink} id={row.original.location}>{row.original.location}</a>
                <a href={`https://www.google.com/maps/search/?api=1&query=${row.original.location}`} target="_blank" className="address" ref={refAddres} id={row.original.location} key={row.original.location}></a>
            </Box>
        </Box>
          )
        },
      },

      {
        Header: "Odometer (km)",
        accessor: "canOdometer",
        width:120,
      },
      {
        Header: "Speed (km/h)",
        accessor: "canVehicleSpeed",
        width:120,
      },
      {
        Header: "Battery Soc(%)",
        accessor: "batterySoc",
      },
      {
        Header: "Battery Current(A)",
        accessor: "batteryCurrent",
      },
      {
        Header: " Battery Energy (kWh)",
        accessor: "energyAtTime",
      },
     // {
      //  Header: "DC Bus Current (A) ",
     //   accessor: "dcCurrentBusInputCurrent",
    //  },
       {
         Header: "MCU Input Current (A)",
         accessor: "dcCurrentBusInputCurrent",
       },
      {
        Header: "MotorPower (kW)",
        accessor: "motorPower",
      },
      {
        Header: "MotorTorque (Nm)",
        accessor: "motorTorque",
      },
      {
        Header: "MotorSpeed (RPM)",
        accessor: "motorSpeedKmhr", 
      },

      {
        Header: "DCDC Input Voltage (V)",
        accessor: "dcdcInputVoltage", 
      },
      {
        Header: "DCDC Input Current (A)",
        accessor: "dcdcInputCurrent",
      },
      {
        Header: "Min Cell Voltage (V)",
        accessor: "minCellVoltage",
      },
      {
        Header: "Max Cell Voltage (V)",
        accessor: "maxCellVoltage",
      },
      {
        Header: "Battery Min Temp (℃)",
        accessor: "minTemp",
      },
      {
        Header: "Battery Max Temp (℃)",
        accessor: "maxTemp",
      },
      {
        Header: "Motor Temperature (℃)",
        accessor: "motorTemperature",
      },
      {
        Header: " Battery Primary Parameters ",

        columns: [
          {
            Header: "Battery Voltage (V)",
            accessor: "batteryVoltage",
          },
          {
            Header: " Battery SOH (%)",
            accessor: "batterySoh",
          },
          {
            Header: "Battery Warning Level",
            accessor: "batteryWarningLevel",
          },
          {
            Header: "Battery Status",
            accessor: "batteryStatus",
          },
          // {
          //   Header: "Battery Vital Info",
          //   accessor: "batteryLife",
          // },
          {
            Header: "Cooling Control",
            accessor: "coolingControl",
          },
          // {
          //   Header: "Positive Relay",
          //   accessor: "positiveRelay",
          // },
        ]},
        // {
        //     Header: "Battery Cooling Unit",
    
        //     columns: [
        //       {
        //         Header: "Negative Relay",
        //         accessor: "negativeRelay",
        //       },
        //     ]
        // },
        {
            Header: "PDU & BMS",
    
            columns: [
              // {
              //   Header: "PreCharge Relay Status",
              //   accessor: "pRechargeRelay",
              // },
              // {
              //   Header: "Vehicle Charging State Relay",
              //   accessor: "slowChargeRelay",
              // },
              // {
              //   Header: "Board Charging State Relay",
              //   accessor: "boardChargingUnitRelay",
              // },
              // {
              //   Header: "Heating Control",
              //   accessor: "heatingControl",
              // },
              {
                Header: "ACC Signal",
                accessor: "accSignal",
              },
              {
                Header: "Charger Connected Confirmation",
                accessor: "chargerConnectionConfirmation",
                
              }, {
                Header: "Board Charging Unit Connection",
                accessor: "boardChargingUnit",
                
              }, {
                Header: "Charger Status",
                accessor: "chargerIsOnline",
               
              }, {
                Header: "State of Charge",
                accessor: "stateOfCharge",
               
              },
              // , {
              //   Header: "Positive Bus Resistance (Ohms)",
              //   accessor: "positiveBusResistance",
               
              // },
              // {
              //   Header: "Negative Bus Resistance (Ohms)",
              //   accessor: "negativeBusResistance",
               
              // },
              {
                Header: "Max Allowable Charging Current (A)",
                accessor: "maxAllowChargeCurrent",
               
              }, {
                Header: "Max Allowable Discharging Current(A)",
                accessor: "maxAllowDischargeCurrent",
               
              }, {
                Header: "Max Permissible Charging Power (kW)",
                accessor: "maxAllowChargePower",
               
              }, {
                Header: "Max Allowable Discharge Power (kW)",
                accessor: "maxAllowDischargePower",
               
              }, {
                Header: "Max Voltage-Subsystem Number",
                accessor: "maxBatteryVoltage", 
               
              }, {
                Header: "Max Voltage-Cell Number",
                accessor: "maxVoltageCell", 
               
              }, {
                Header: "Min Voltage-Subsystem Number",
                accessor: "minBatteryVoltage", 
               
              }, {
                Header: "Min Voltage - Cell Number",
                accessor: "minVoltageCell", 
               
              }, {
                Header: "Max Temp-Subsystem Number",
                accessor: "maxTempSystemNumber", 
               
              }, {
                Header: "Max Temp-Probe Number",
                accessor: "maxTempProbeNumber", 
               
              }, {
                Header: "Min Temp-Subsystem Number",
                accessor: "minTempSystemNumber", 
               
              }, {
                Header: "Min Temp-Probe Number",
                accessor: "minTempProbeNumber", 
               
              }, {
                Header: "Insulation Resistance (Ohm)",
                accessor: "insulationResistance", 
               
              }, 
              // {
              //   Header: "Highest Alarm Level",
              //   accessor: "highAlarm", 
               
              // }, 
              // {
              //   Header: "Rechargeable Energy Storage Device Failure (count)",
              //   accessor: "rechargeStorageFailure", 
               
              // }, 
              {
                Header: " BMS Alarm 1",
                accessor: "level1BmsFaultCode", 
               
              }, {
                Header: " BMS Alarm 2",
                accessor: "-", 
               
              }, 
              // {
              //   Header: "BMS Fault - Level 3",
              //   accessor: "level3BmsFaultCode", 
               
              // }, 
              {
                Header: "General Alarm Flag 1",
                accessor: "generalAlarmFlag", 
               
              },
              
              {
                Header: "General Alarm Flag 2",
                accessor: "generalAlarmFlag2", 
               
              },{
                Header: "Charging",
                accessor: "charging", 
               
              }
              // {
              //   Header: "Single Battery Charge",
              //   accessor: "singleBatteryCharge", 
               
              // },
              //  {
              //   Header: "Accumulated Charged Electrical Quantity",
              //   accessor: "accumulateCharge", 
               
              // },
              //  {
              //   Header: "Rechargeable Energy Storage Subsystem (count)",
              //   accessor: "noOfEnergyStorage", 
               
              // }
              // ,{
              //   Header: "Rechargeable Energy Storage Subsystem Numbers",
              //   accessor: "noOfRechargeSystem", 
               
              // }
              // ,
              // {
              //   Header: "Energy Storage Device Voltage (V)",
              //   accessor: "esDeviceVolt", 
               
              // }
              ,{
                Header: "Rechargeable Energy Storage Device Current (A)",
                accessor: "esDeviceCurrent", 
               
              }
              // ,{
              //   Header: "Rechargeable Energy Storage Subsystem Length Coding",
              //   accessor: "esCoding", 
               
              // }
              // ,{
              //   Header: "Rechargeable Energy Storage Subsystem Encoding",
              //   accessor: "esEncoding", 
               
              // },{
              //   Header: "Rechargeable Energy Storage Subsystem Numbers",
              //   accessor: "noRechargeableEs", 
               
              // }
              ,{
                Header: "Rechargeable Energy Storage Temperature Probe (count)",
                accessor: "noRechargeableEsProbe", 
               
              }
              // {
              //   Header: "Number of Single Cells",
              //   accessor: "totalCells", 
               
              // }
              // ,{
              //   Header: "Retention",
              //   accessor: "retention", 
               
              // }
              // ,{
              //   Header: "BMS Fault Alarm",
              //   accessor: "bmsFaultAlarm", 
               
              // }
            //  ,{
           //     Header: "Battery Cell Voltage",
           //     accessor: "", 
               
           //   },{
            //    Header: "Battery Cell Temperature",
             //   accessor: "", 
               
             // }
              // ,{
              //   Header: "SOC (%)",
              //   accessor: "soc", 
               
              // }
              ,{
                Header: "Hall disconnection status",
                accessor: "hallDisConnection", 
               
              },{
                Header: "Battery abnormality protection status",
                accessor: "batteryAbmProtectionStatus", 
               
              },{
                Header: "Break cell voltage status",
                accessor: "breakcellVoltStatus", 
               
              },{
                Header: "Break cell temp status",
                accessor: "breakcellTempStatus", 
                
              },{
                Header: "Pre charge failure status",
                accessor: "prechargeFailureStatus", 
                
              },{
                Header: "Relay failure status",
                accessor: "relayFailureStatus", 
                
              },{
                Header: "BSU Balancing troubleshooting status",
                accessor: "busBalancingStatus", 
                
              },
              // {
              //   Header: "DI1 Status",
              //   accessor: "di1Status", 
                
              // },{
              //   Header: "DI2 Status",
              //   accessor: "di2Status", 
                
              // }
              // ,{
              //   Header: "DI3 Status",
              //   accessor: "di3Status", 
                
              // }
              // ,{
              //   Header: "DI4 Status",
              //   accessor: "di4Status", 
                
              // }
              // ,{
              //   Header: "DI5 Status",
              //   accessor: "di5Status", 
                
              // }
              // ,{
              //   Header: "DI6 Status",
              //   accessor: "di6Status", 
                
              // }
              // ,{
              //   Header: "DI7 Status",
              //   accessor: "di7Status", 
                
              // }
              // {
              //   Header: "DI8 Status",
              //   accessor: "di8Status", 
                
              // },
              {
                Header: "Relay1 state - Common relay(Aux relay)",
                accessor: "relay1Status", 
                
              },{
                Header: "Relay2 state - Negative relay",
                accessor: "relay2Status", 
                
              }
              ,{
                Header: "Relay3 state - Precharge relay",
                accessor: "relay3Status", 
                
              }
              ,{
                Header: "Relay4 state - Charger 2 positive",
                accessor: "relay4Status", 
                
              }
              ,{
                Header: "Relay5 state - Charge 1 positive",
                accessor: "relay5Status", 
                
              }
              ,{
                Header: "Relay6 state - BCS wake up",
                accessor: "relay6Status", 
                
              }
              ,{
                Header: "Relay7 state - Total positve",
                accessor: "relay7Status", 
                
              }
              ,{
                Header: "Relay8 state - Reserve",
                accessor: "relay8Status", 
                
              }
              //,{
              //   Header: "PDU Power Command",
              //   accessor: "pduPowerCommands", 
                
              // },{
              //   Header: "PDU DC/DC power control",
              //   accessor: "pduDcdcPowerCommands", 
                
              // },{
              //   Header: "PDU Oil pump control",
              //   accessor: "pduOilpumpControl", 
                
              // },{
              //   Header: "PDU Air pump power control",
              //   accessor: "pduAirpumpControl", 
                
              // },{
              //   Header: "PDU air conditioning",
              //   accessor: "pduAirConditioning", 
                
              // },{
              //   Header: "PDU battery heating power control",
              //   accessor: "pduBatteryHeatingControl", 
                
              // },{
              //   Header: "PDU electric defrost control",
              //   accessor: "pduElectricDefroastControl", 
                
              // },{
              //   Header: "PDU low pressure notice",
              //   accessor: "pduLowPressureNotice", 
                
              // }
            ]
            },
              {
                Header: "Motor",
        
                columns: [
                  {
                Header: "Actual Motor Rotation Direction",
                accessor: "actualMotorRotationDirection", 
                width:350,
              },
              // {
              //   Header: "Max Torque- Allowed (Nm)",
              //   accessor: "maximumOutputTorqueOfTheMotor", 
                
              // },
              // {
              //   Header: "Max Power- Allowed (kW)",
              //   accessor: "maximumPowerOutputOfMotorTorque", 
                
              // } ,
              {
                Header: "MCU Fault Level",
                accessor: "mcuFaultLevel", 
                
              } ,{
                Header: "MCU Fault Code",
                accessor: "mcuFaultCode", 
                
              } ,{
                Header: "Motor Controller Temperature (℃)",
                accessor: "motorControllerTemperature", 
                
              } 
              
            ]},
            {
              Header: "Motor Controller",
      
              columns: [
            //     {
            //   Header: "MCU Current Mode",
            //   accessor: "mcuCurrentMode", 
              
            // },
            {
              Header: "DC Bus Current (A)",
              accessor: "DcCurrent", 
              
            },
            {
              Header: "DC Bus Input Voltage (V)",
              accessor: "DcVoltageInputVoltageBus", 
              
            } 
            // {
            //   Header: "Motor Drive failure (count)",
            //   accessor: "totalNumberOfMotorDriveFailure", 
              
            // } 
            // ,{
            //   Header: "MCU Fault Code",
            //   accessor: "mcuFaultCode", 
              
            // } ,{
            //   Header: "Motor Controller Temperature (℃)",
            //   accessor: "motorControllerTemperature", 
              
            // } 
            ,{
              Header: " MCU Output Power (kW)",
              accessor: "mcuOutputPower", 
              
            } ,{
              Header: "OPU Electric Power",
              accessor: "opuElectricPower", 
              
            }]
          },  {
            Header: "Vehicle Control Unit Parameters",
    
            columns: [
            {
              Header: "Air Pump Compressor",
              accessor: "airPumpCompressor", 
              
            } ,{
              Header: "DCDC DC / DC Status",
              accessor: "dcdcdcdcStatus", 
              
            } ,{
              Header: "BMS Contactor (Main)",
              accessor: "bmsContactorMain", 
              
            } ,{
              Header: "VCU SelfCheck",
              accessor: "vcuSelfCheck", 
              
            }  ,{
              Header: "SysInterlock",
              accessor: "sysInterLock", 
              
            } ,{
              Header: "Vehicle Ready",
              accessor: "vehicleready", 
              
            } ,{
              Header: "VCU Status",
              accessor: "vcuStatus", 
              
            } ,{
              Header: "TCU Gearbox ",
              accessor: "tcuGearbox", 
              
            } ,{
              Header: "VCU CAN status",
              accessor: "vcuCanStatus", 
              
            } ,{
              Header: "Driver Seat",
              accessor: "driverSeat", 
              
            }
            //  ,{
            //   Header: "Gear Shifts",
            //   accessor: "gearShifts", 
              
            // }
             ,{
              Header: "VCU Fault Code",
              accessor: "vcuFaultCode", 
              
            } ,{
              Header: "Vehicle Driving Mode",
              accessor: "vehicleDrivingMode", 
              
            } 
            ,{
              Header: "Shift Gear Mode",
              accessor: "shiftGearMode", 
              
            }
             ,{
              Header: "Dynamic EcoMode",
              accessor: "dynamicEcoMode", 
              
            } ,{
              Header: "Trailer Mode",
              accessor: "trailerMode", 
            } ,{
              Header: "Limp Mode",
              accessor: "limpMode", 
            } ,{
              Header: "Energy Back Mode",
              accessor: "energyBackMode", 
            } ,{
              Header: "Transmission Current Gear",
              accessor: "transmissionCurrentGear", 
            },{
              Header: "Accelerator Pedal Percent (%)",
              accessor: "acceleratorPedalPercent", 
            },{
              Header: "Accelerator Pedal Valid",
              accessor: "acceleratorPedalValid", 
            },{
              Header: "VCU aux loop control",
              accessor: "vcuAuxLoopControl", 
            } ,{
              Header: "VCU Emergency Stop",
              accessor: "vcuEmergencyStop", 
            } ,
            
           // {
             // Header: "VCU Speed",
            //  accessor: "vcuVehicleSpeed", 
            //} ,
            // {
            //   Header: "Rolling Counter",
            //   accessor: "rollingCounter", 
            // },
            // {
            //   Header: "OPU Electrical power enable flag",
            //   accessor: "opuElectricPowerEnable", 
            // }  ,{
            //   Header: "OPU Electrical power enable flag",
            //   accessor: "opuControlElectricPowerEnable", 
            // } ,
            // {
            //   Header: "OPU Electrical power reset flag",
            //   accessor: "opuElectricResetEnable", 
            // }  ,
            {
              Header: "CAN communication speed",
              accessor: "canCommunicationSpeed", 
            }  ,{
              Header: "VCU CAN Comm failure",
              accessor: "canCommunicationFailure", 
            } ,{
              Header: "PTO Status",
              accessor: "ptoStatus", 
            },
            // {
            //   Header: "RollingCounter Life Value",
            //   accessor: "rollingCounterLifeValue",             
            // },
            {
              Header: "VCU Software version",
              accessor: "vcuSoftwareVersion", 
              
            },{
              Header: "Emergency Switch Signal",
              accessor: "emergencySwitchSignal", 
              
            } ,{
              Header: "Oil pump power status",
              accessor: "oilPumpPowerStatus", 
              
            } ,{
              Header: "Air pump power status",
              accessor: "airPumpPowerStatus", 
              
            },{
              Header: "DCDC status ",
              accessor: "vcuDcdcStatus",
              
            } ,
            // {
            //   Header: "Air Conditioning status",
            //   accessor: "airConditioningStatus",
              
            // } ,
            {
              Header: "Fan status",
              accessor: "fanStatus",
              
            } ,{
              Header: "Water pump status",
              accessor: "waterPumpStatus",
              
            },{
              Header: "Heat status",
              accessor: "heatStatus",
              
            } 
            // ,{
            //   Header: "Defrost status",
            //   accessor: "defrostStatus",
              
            // }
            ,{
              Header: "Low Battery Voltage (V)",
              accessor: "lowBatteryVoltage",
            },{
              Header: "MiddleDoor door status",
              accessor: "middleDoorStatus",
            }, {
              Header: "Front door status",
              accessor: "frontDoorStatus",
            } , {
              Header: "Rear hatch status",
              accessor: "realHatchStatus",
            }, {
              Header: "ON Range Signal",
              accessor: "onRangeSignal",
            }, {
              Header: "Start speed signal",
              accessor: "startspeedSignal",
            },
            {
              Header: "Brake Fault Alarm",
              accessor: "brakeFaultAlarm",
            } ,
            {
              Header: "Handbrake state",
              accessor: "handBrakeStart",
            } ,
            {
              Header: "Stop State",
              accessor: "stopState",
            } ,
            {
              Header: "Vehicle Right steer state",
              accessor: "vehicleRightSteerState",
            }
           ]},
           {
            Header: "Gear Box Parameters",
    
            columns: [
              
             {
              Header: "Gear Shift",
              accessor: "gearShifts", 
            }

            ,{
              Header: "Gear Box Problem",
              accessor: "gearBoxProblem", 
            }  
            // ,{
            //   Header: "Gear Shift Mode",
            //   accessor: "shiftGearMode", 
            // }
            ,{
              Header: "Gear Key State",
              accessor: "gearKeyState", 
            } 
            ,{
              Header: "Gear State",
              accessor: "gearState", 
            } 
            ,{
                Header: "Gear Holding Request",
                accessor: "gearHoldingRequest", 
              } 
              ,{
                Header: "Gear Target",
                accessor: "gearTarget", 
              }      
              ,{
                Header: "Current Gear",
                accessor: "currentGear", 
              } ]}
              ,{
                Header: "DCDC Parameters",
        
                columns: [
                  {
                Header: "DCDC Output Current (A)",
                accessor: "dcdcOutputCurrent", 
              } ,{
                Header: "DCDC Controller Temperature (℃)",
                accessor: "dcdcControllerTemp", 
              }
              ,{
                Header: "DCDC Output Low Volt (V)",
                accessor: "dcdcOutputLowVolt", 
              }
              ,{
                Header: "DCDC Communication State Value",
                accessor: "dcdcComStateValue", 
              },{
                Header: "DCDC Fault code",
                accessor: "dcdcFault", 
              },{
                Header: "DCDC State",
                accessor: "dcdcState", 
              }
            ]}
              , {
                Header: "Steering & Brake Unit Parameters",
        
                columns: [{

                Header: "DCDC Precharge State",
                accessor: "dcdcPrechargeState", 
              } ,{
                Header: "Brake Pedal (%)",
                accessor: "breakPedalDegree", 
              },{
                Header: "Brake Signal",
                accessor: "footBrakeSignal", 
              },{
                Header: "Handbrake Signal",
                accessor: "handBrakeSignal", 
              },{
                Header: "Steering Pump Status",
                accessor: "oilPumpEnable", 
              }
              // ,{
              //   Header: "Brake Alarm",
              //   accessor: "brakeFaultAlarm", 
              // },
              // {
              //   Header: "Handbrake State",
              //   accessor: "handBrakeStart", 
              // }
              // ,
              // {
              //   Header: "Steer Right Turn state",
              //   accessor: "vehicleRightSteerState", 
              // }
              ,{
                Header: "Brake Pedal Status",
                accessor: "brakePedalStatus", 
              },{
                Header: "Front Brake Pressure  (Kpa)",
                accessor: "frontBrakeCylinderPressureKpa", 
              }
             
          ]},
          {
            Header: "S&B Controller (Power Electronic Unit (PEU))",
    
            columns: [
              
          {
            Header: "Rear Brake Pressure  (Kpa)",
            accessor: "rearBrakeCylinderPressureKpa", 
          },
          {
            Header: "Before Accumulator Pressure Value",
            accessor: "beforeAccumulatorPressure", 
          },
          {
            Header: "After Accumulator Pressure Value",
            accessor: "afterAccumulatorPressure", 
          },
          {
            Header: "Low Motor Speed",
            accessor: "lowMotorSpeed", 
          },
          {
            Header: "High Motor Speed",
            accessor: "highMotorSpeed", 
          },
          {
            Header: "S&B Voltage",
            accessor: "powerUnitVoltage", 
          },
          {
            Header: "S&B Controller Current",
            accessor: "powerUnitCurrentController", 
          },
          {
            Header: "Pump body Temp",
            accessor: "powerUnitPumpBodyTemperature", 
          },
          {
            Header: "Heartbeat",
            accessor: "powerUnitHeartBeat", 
          },
          {
            Header: "Feedback Controller State",
            accessor: "powerUnitBasicStateMotor", 
          },
          {
            Header: "Motor Controller State",
            accessor: "powerUnitFeedBackState", 
          },
          {
            Header: "S&B Fault Code",
            accessor: "peuFaultCode", 
          },
          {
            Header: "Internal Battery %",
            accessor: "internalBatteryPercentage", 
          }

          
        ]},            {
          Header: "Ignition",
          accessor: "ign",
        },
        {
          Header: "BMS instruction operation mode",
          accessor: "bmsInstrnOprtnMode",
        },{
          Header: "High voltage request",
          accessor: "highVoltageRequest",
        },{
          Header: "Charging Mode",
          accessor: "chargingMode",
        },{
          Header: "HVDC contactor",
          accessor: "hvdcContactor",
        },{
          Header: "Set temperature",
          accessor: "temperature",
        },{
          Header: "TMS actual operation mode",
          accessor: "tmsActualOprtnMode",
        },{
          Header: "Thermal management HVDC contactor status",
          accessor: "thermalHVDCStatus",
        },{
          Header: "Outlet water temp (TMS to Battery)",
          accessor: "waterTempOutlet",
        },{
          Header: "Inlet water temp (Battery to TMS)",
          accessor: "waterTempInlet",
        },{
          Header: "Compressor frequency",
          accessor: "compressorFrequency",
        },{
          Header: "Power request (Feedback)",
          accessor: "powerRequest",
        },{
          Header: "DTC level",
          accessor: "dtclevel",
        },{
          Header: "Acc pedal throttle position",
          accessor: "accPedalThrottlePosition",
        },
             
      ],
      []
    
        
  
    
  );
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [assignedval, setAssignedval] = useState({});
  const [searchval, setSearchval] = useState("");
  // const onSubmit = async (data) => {
  //   setLoading("");
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);
  //   loadrequest = assignedval;
  //   loadrequest.regionId = data.region;
  //   loadrequest.resellerId = data.reseller;
  //   //loadrequest.resellerId = 5;
  //   loadrequest.dealerId = data.dealer;
  //   loadrequest.customerId = data.customer;
  //   loadrequest.orgId = data.fleet; // fleet
  //   loadrequest.vehicleId = data.vehicle;
  //   loadrequest.start = newPage;
  //   loadrequest.length = rowsPerPage;
  //   loadrequest.startDate = data.from_date;
  //   loadrequest.endDate = data.to_date;
  //   loadrequest.orderDir = "desc";
  //   loadrequest.search = searchval;
  //   loadrequest.status = data.status;
    
    
  //   setAssignedval(loadrequest);
  //const responseData = (await ReportServices.ManualCanDataReportMontra(loadrequest))
  //     .data;
  //   setResponseData([]);
  //   const result=JSON.parse(responseData.data);
  //   setResponseData(result.content);
  //   //console.log(+"canOdometer");
  //   setLoading(responseData.resultText);
  //   setTotalElements(5);
  //   setTotalPages(0);
  //   setTotalElements(result.totalElements);
  //   setTotalPages(result.totalPages);
  // };
  
  // const fetchVehicleDataDetails = async (searchText, columnName) => {
  //   loadrequest = assignedval;
  //   loadrequest.search = searchText;
  //   if (columnName === undefined) {
  //     loadrequest.columnName = "MESSAGE";
  //   } else {
  //     loadrequest.columnName = columnName;
  //   }
  //   const responseData = (await ReportServices.ManualCanDataReportMontra(loadrequest))
  //     .data;
  //   setResponseData([]);
  //   const result=JSON.parse(responseData.data);
  //   setResponseData(result.content);
  //   setLoading(responseData.resultText);
  //   setTotalElements(result.totalElements);
  //   setTotalPages(result.totalPages);
  //   setTotalElements(5);
  //   setTotalPages(0);
  //   setTotalElements(result.totalElements);
  //   setTotalPages(result.totalPages);
  // };

  // const handleSearch = (searchText) => {
  //   fetchVehicleDataDetails(searchText);
  // };

  // const callbackChangePage = async (newPage, rowsPerPage) => {
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);
  //   loadrequest = assignedval;
  //   loadrequest.start = newPage;
  //   loadrequest.length = rowsPerPage;
  //   // setSelectedDropdownAry(request)
  //   setAssignedval(loadrequest);

  //   try {
  //     const responseData = (
  //       await ReportServices.ManualCanDataReportMontra(loadrequest)
  //     ).data;
  //     setResponseData([]);
  //     const result=JSON.parse(responseData.data);
  //     setResponseData(result.content);
  //     setLoading(responseData.resultText);
  //     setTotalElements(5);
  //     setTotalPages(0);
  //     setTotalElements(result.totalElements);
  //     setTotalPages(result.totalPages);
  //   } catch (error) {}
  // };
  


  const [vehicleDataMontra, {data:montraVehicleData, isLoading, isSuccess}] = useVehicleDataMontraMutation();
  const [payload, setPayload] = useState();
  
  let commonPayload = {
    'resellerId': resellerId,
  }

  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(50);
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.orderDir = 'desc';
    commonPayload.start = newPage;
    commonPayload.length = rowsPerPage;
    commonPayload.status = data.vehicleStatusId;
    commonPayload.search = '';
    setPayload(commonPayload);
    vehicleDataMontra(commonPayload);

    auditPayload.message = "Reports > Vehicle Data > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.statusName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Vehicle Data Report";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  }

  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
      payload.size = 0;
      payload.rowsPerPage = 50;
      setNewPage(0);
      setRowsPerPage(50);
    if(payload.search.length >= 3){
      vehicleDataMontra(payload);
    }else if(payload.search.length === 0){
      vehicleDataMontra(payload);
    }
  }


    const onChangePdf = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "ManualCanDataReport/vehicledataPDF.pdf?startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&orderDir=" +
        payload.orderDir +
        "&orgId=" +
        payload.orgId +
        "&status=" +
        payload.status +
        "&vehicleId=" +
        payload.vehicleId;
        window.open(url);

        auditPayload.message = "Reports > Vehicle Data > Export > PDF";
        auditPayload.action = "View";
        auditPayload.featureName = "VDR Report";
        auditPayload.customerName = "All";
        auditPayload.fleetName = "All";
        auditPayload.result = "Success";
        auditPayload.access = "Portal";
        auditPayload.severity = "INFO";
        auditLog(auditPayload);

    } catch (error) {
      console.warn('error', error);
    }
  };

  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "ManualCanDataReport/vehicleDataReportForMontracsv?startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&orderDir=" +
        payload.orderDir +
        "&orgId=" +
        payload.orgId +
        "&status=" +
        payload.status +
        "&vehicleId=" +
        payload.vehicleId;

      window.open(url);

      auditPayload.message = "Reports > Vehicle Data > Export > Excel";
      auditPayload.action = "View";
      auditPayload.featureName = "VDR Report";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
    } catch (error) {
      console.warn('error', error)
    }
  };

   //Page Change
   const pageChange = (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    payload.page = newPage;
    payload.size = rowsPerPage;
    payload.start=newPage;
    payload.length=rowsPerPage;
    vehicleDataMontra(payload);
  } 

  useEffect(() => {
    if(isSuccess){
      // setResponseData([]);

      if(montraVehicleData.data !== 'null'){
        const vrdData = JSON.parse(montraVehicleData.data);
        setResponseData(vrdData.content);
        setTotalElements(vrdData.totalElements);
        setTotalPages(vrdData.totalPages);
      }

      // if(montraVehicleData.data !== 'null'){
      //   setResponseData(montraVehicleData.data?.content);
      //   setTotalElements(montraVehicleData.data?.totalElements);
      //   setTotalPages(montraVehicleData.data?.totalPages);
      // }
    }
  },[isSuccess]);

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Reports" subDiv="Vehicle Data Report" includePopup={true}></Breadcrumb>
      <MouseOverPopover text={text} title={"Vehicle Data Report"} />
      <Box>
        {/* <VDRDropDown
          onSubmit={onSubmit}
          callbackLoadFn={callbackLoadFn}
          isStatus={true}
          displayDate={false}
          allowDays={0}
        /> */}
         <FilterDropdown getData={onSubmitForm} time={true} isVehicleData={true} isShowAll={false} allowDays = {1} showTime = {true} allowedMonth = {resellerId === 110224 ? 6 : 3} last6Month={true} getDataLoading={isLoading} />
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              payload={payload}
              exportWithAll={false}
              isLoading={isLoading}
              includeSearchBox={true}
              handleChange={handleSearch}
              onChangeXcl={onChangeXcl}
              onChangePdf={onChangePdf}
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "89vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              //callbck={handleSort}
              totalPages={totalPages}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};

export default ManualCanDataReportMontra;
