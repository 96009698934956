import Service from "./service";

const OrganizationServices = {
  getGridViewForOrg: function (resource) {
    //return Service.post('/gridviewfororg')
    //return Service.get('/gridviewfordevice')
    return Service.post("/organization/listOrganization", resource, null);
  },
  getOrganizationHistory: function (resource) {
    return Service.post(
      "/organization/listOrganizationHistory",
      resource,
      null
    );
  },
  saveOrgDetails: function (data) {
    //return Service.get('/gridviewfordevice')
    return Service.post("/organization/createOrganization", data, null);
  },
  deleteOrgById: (orgId) => {
    return Service.post("/organization/deleteOrganization", orgId, null);
  },
  editOrgById: (orgId) => {
    return Service.post("/organization/getOrganization", { orgId });
  },
  updateOrgDetails: function (data) {
    return Service.post("/organization/updateOrganization", data, null);
  },
  getOrganization: (orgId) => {
    return Service.post("organization/getOrganization", orgId);
  },
  updateOrganization: function (data) {
    return Service.post("/organization/updateOrganization", data, null);
  },
  getOrganizationListXLS: function (request) {
    return Service.get("/organization/organizationListXLS", { request });
  },
  getOrganizationListpdf: function (request) {
    return Service.get("/organization/organizationListPDF", { request });
  },
  chkOrgName: function (data) {
    return Service.post("/organization/chkOrgName", data);
  },

  chkOrgEmail: function (data) {
    return Service.post("/organization/chkOrgEmail", data);
  },
  chkUserName: function (data) {
    return Service.post("/organization/chkUserName", data);
  },
  loadCity: function (data) {
    return Service.post("/organization/loadCity", data);
  },
  loadState: function () {
    return Service.get("/organization/loadState", null);
  },
  // chkUserName: function (data) {
  //   return Service.post("/organization/chkUserName", data);
  // },
  loadCountry: function () {
    return Service.get("/organization/countryList", null);
  },
  loadTimeZone: function () {
    return Service.get("/organization/timeZoneList", null);
  },
  loadMobCode: function () {
    return Service.get("/organization/phoneCodeList", null);
  },
  loadStateByCountry: function (countryId) {
    return Service.get("/organization/loadStateByCounrty", countryId, null);
  },
};

export default OrganizationServices;
