import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { CardHeader, CardContent, Button } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import ReportNewDropDown from "../shared/ReportsDropDownNew";
import { ButtonAlign } from "../shared/button/ButtonAlign";
import logServices from "../../services/logService";

import {
  StyledCardShadow,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import {
  useAuditLogMutation,
  useOverSpeedMutation,
} from "../../store/slice/ApiSlice";
import { useVehicleDetailBatteryHealthMutation, useVehicleLatestDetailMutation } from "../../store/slice/ReportSlice/ReportSlice";

const text =
  "This report shows the latest vehicle detail.";

const VehicleDetailBatteryHealth = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [assignedval, setAssignedval] = useState([]);
  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  useEffect(async () => {
    auditPayload.message = "Reports > batteryDetail";
    auditPayload.action = "View";
    auditPayload.featureName = "batteryDetail";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

 
  const columns = React.useMemo(() => [
      // {
      //   Header: "Vehicle ID",
      //   accessor: "vehicleid",
      //   width:150,
      // },
      {
        Header: "Vehicle No",
        accessor: "vehicleno",
        width:120,
      },
      {
        Header: "Last Connected Time",
        accessor: "lastConnect",
        width:150,
      },
      {
        Header: "Vehicle Created Date",
        accessor: "createdDate",
        width:150,
      },
      {
        Header: "Odometer (km)",
        accessor: "odometer",
        width:100,
      },
      {
        Header: "SOC(%)",
        accessor: "stateofcharge",
        width:120,
      },
      {
        Header: "Energy Consumed (Wh)",
        accessor: "energyconsumed",
        width:120,
      },
      
      {
        Header: "GPS Location",
        accessor: "gpslocation",
        width:350,
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng =row.original.gpslocation;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.gpslocation}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.zoneName}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.gpslocation}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.gpslocation}
                >
                  {row.original.gpslocation}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.gpslocation}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.gpslocation}
                  key={row.original.gpslocation}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "Battery Voltage (V)",
        accessor: "batteryVoltage",
        width:120,
      },
      {
        Header: "Nominal Capacity (A)",
        accessor: "initialBatteryHealth",
        width:120,
      },
      {
        Header: "Residual Capacity (A)",
        accessor: "currentBatteryHealth",
        width:120,
      },
      {
        Header: "Battery Degradation (%)",
        accessor: "batteryDegradation",
        width:120,
      },

      
    ],
    []
  );

  let initialState1 = {};
  
  // Get Data from New Dropdown
  const [getgridView, { data: batteryDetail, isLoading, isSuccess }] =
    useVehicleDetailBatteryHealthMutation();
  let commonPayload = {};

  const onSubmitForm = (data) => {


    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.resellerId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.sortBy = "desc";
    commonPayload.start = newPage;
    commonPayload.length = rowsPerPage;
    commonPayload.sbatteryhealth = data.sbatteryhealth;
    
    //Service Call
    setPayload(commonPayload);
    getgridView(commonPayload);

    auditPayload.message = "Reports > BatteryDetail > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "BatteryDetail";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  //Export PDF & Excel
  const [payload, setPayload] = useState(null);
  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "VehicleLatestDetailReport/VehicleDetailBatteryXLS.csv?regionId=" +
        payload.regionId +
        "&resellerId=" +
        payload.resellerId +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&dealerId=" +
        payload.dealerId +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&customerId=" +
        payload.customerId;
      window.open(url);

      auditPayload.message = "Reports > BatteryDetail > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "BatteryDetail";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };
  
  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getgridView(payload);
    } else if (payload.search.length === 0) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getgridView(payload);
      
    }
  };

  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getgridView(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getgridView(payload);
    }
  };

  
  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (batteryDetail.data !== null) {
        setResponseData(batteryDetail.data.content);
        setTotalElements(batteryDetail.data.totalElements);
        setTotalPages(batteryDetail.data.totalPages);
      }
    }
  }, [isSuccess]);

  return (
    <StyledPageLayout>
     
      <MouseOverPopover text={text} title={"BatteryDetail"} />
      <Box>
        <FilterDropdown
          getData={onSubmitForm}
          status={false}
          time = {true}
          showCalendar={false}
          dehydration={true}
          getDataLoading={isLoading}

        />

        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              initialState={initialState1}
              payload={payload}
              exportWithAll={true}
              columns={columns}
              includeSearchBox={true}
              onChangeXcl={onChangeXcl}
              handleChange={handleSearch}
              includeExportButton={true}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              isLoading={isLoading}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};

export default VehicleDetailBatteryHealth;