import React, { useCallback, useEffect, useState } from "react";
import { Masonry } from "@mui/lab";
import {
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Box,
  useTheme,
  Stack,
  MenuItem,
  Chip,
  OutlinedInput,
  ListItem,
  ListItemButton,
  ListItemIcon,
  List,
  ListItemText,
  Paper,
  FormHelperText,
  InputBase,
  ListSubheader,
  Divider,
  FormControl,
  Checkbox,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import {
  StyledCardShadow,
  StyledFiterButton,
  StyledTableTitle,
  StyledFormControl,
  StyledFormControlLabel,
  StyledDropDown,
  StyledPageLayout,
  StyledCommonListItem,
  SoftButton,
  StyledTextField,
} from "../../theme/StyledEle";
import { useForm } from "react-hook-form";
import dropdownServices from "../../services/dropdownServices";
import helpDeskServices from "../../services/helpDeskServices";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { MdAssignmentAdd, MdBookmarkAdd, MdDeleteSweep, MdEditSquare, MdOutlineBookmarkAdd } from "react-icons/md";
import { FaExchangeAlt, FaUser } from "react-icons/fa";
import { useAsssignEscalationMutation, useCategorySettingsQuery, useEscalationGetTimeQuery, useGetAssignToMutation, useGetNewAssignedUserMutation, useGetNewUserListMutation, useGetSettingsMutation, useGetUserListSliceMutation, useMailToSendMutation, useSaveEscalationMutation } from "../../store/slice/Helpdesk/HelpDeskSlice";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useDispatch, useSelector } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import { RiCheckboxMultipleFill } from "react-icons/ri";
import { FaFilter } from "react-icons/fa";
import Escalation from "./Escalation";
import AdminFilter from "./AdminFilter";
import HelpdeskSettingOrgDropdown from "./HelpdeskSettingOrgDropdown";
import { useNavigate } from "react-router-dom";
import { listUserData } from "../../mock/listUserData";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      padding:0,
    },
  },
};

export default function CreateHelpdeskSettings() {
  const userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let userName = userData.userName;
  let orgName = userData.orgName;

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };

  const themes = useTheme();
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    watch,
  } = useForm({
    mode: "all",
    defaultValues:{
      assignedTo:'-1',
      orgType:'-1',
      category:'-1',
    }
  });

  const [selectedOrgType, setSelectedOrgType] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState([]);
  const [selectedField, setSelectedField] = React.useState([]);
  const [assignedList, setAssignedList] = React.useState([]);
  const [userList, setUserList] = React.useState([]);
  const [settingList, setSettingList] = React.useState([]);

  const [createTo, setCreateTo] = React.useState([]);
  const [createCc, setCreateCc] = React.useState([]);
  const [createBcc, setCreateBcc] = React.useState([]);

  const [statusTo, setStatusTo] = React.useState([]);
  const [statusCc, setStatusCc] = React.useState([]);
  const [statusBcc, setStatusBcc] = React.useState([]);

  const [updateTo, setUpdateTo] = React.useState([]);
  const [updateCc, setUpdateCc] = React.useState([]);
  const [updateBcc, setUpdateBcc] = React.useState([]);

  const [deleteTo, setDeleteTo] = React.useState([]);
  const [deleteCc, setDeleteCc] = React.useState([]);
  const [deleteBcc, setDeleteBcc] = React.useState([]);

  const [editTo, setEditTo] = React.useState([]);
  const {data:categoryListData} = useCategorySettingsQuery();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (event.target.name === "createTo") {
      setCreateTo(typeof value === "string" ? value.split(",") : value);
    } else if (event.target.name === "createCc") {
      setCreateCc(typeof value === "string" ? value.split(",") : value);
    } else if (event.target.name === "createBcc") {
      setCreateBcc(typeof value === "string" ? value.split(",") : value);
    } else if (event.target.name === "statusTo") {
      setStatusTo(typeof value === "string" ? value.split(",") : value);
    } else if (event.target.name === "statusCc") {
      setStatusCc(typeof value === "string" ? value.split(",") : value);
    } else if (event.target.name === "statusBcc") {
      setStatusBcc(typeof value === "string" ? value.split(",") : value);
    } else if (event.target.name === "updateTo") {
      setUpdateTo(typeof value === "string" ? value.split(",") : value);
    } else if (event.target.name === "updateCc") {
      setUpdateCc(typeof value === "string" ? value.split(",") : value);
    } else if (event.target.name === "updateBcc") {
      setUpdateBcc(typeof value === "string" ? value.split(",") : value);
    } else if (event.target.name === "deleteTo") {
      setDeleteTo(typeof value === "string" ? value.split(",") : value);
    } else if (event.target.name === "deleteCc") {
      setDeleteCc(typeof value === "string" ? value.split(",") : value);
    } else if (event.target.name === "deleteBcc") {
      setDeleteBcc(typeof value === "string" ? value.split(",") : value);
    } else if (event.target.name === "editTo") {
      setEditTo(typeof value === "string" ? value.split(",") : value);
    }
  };

  const getOrgType = async () => {
    const orgTypePayload = {
      value: "orgType",
      resellerId: userData != null ? userData.resellerId : "-1",
      dealerId: 0,
      searchText: "",
      roleId: userData != null ? userData.roleId : "-1",
    };
    const { data: orgTypeResponseNew } = await dropdownServices.getResellers(
      orgTypePayload
    );
    const { content: orgTypeResponse } = orgTypeResponseNew.data;
    setSelectedOrgType(orgTypeResponse);
  };


  const getCategory =async() => {
    
    
  };

  const getField = () => {
    setSelectedField([
      {
        key: "Assigned",
        value: "Assigned",
      },
      {
        key: "Status",
        value: "Status",
      },
      {
        key: "Reported By",
        value: "Reported By",
      },
      {
        key: "Severity",
        value: "Severity",
      },
      {
        key: "Target Date",
        value: "Target Date",
      },
    ]);
  };



  useEffect(() => {
    let obj=[];
    if(categoryListData!=undefined){
      categoryListData.forEach(e=>{
        obj.push({
          key:e.value,
          value:e.value
        });
      });
      obj.push({
        key: "KYC Approval",
        value: "KYC Approval",
      });
      obj.push({
        key: "KYC Rejection",
        value: "KYC Rejection",
      });
      setSelectedCategory(obj);
    }   
  }, [categoryListData]);




  //Payload
  const payloads = {
    'resellerId': resellerId
  }


  const [settings, setSettings] = useState(1);
  //Slice
  const [ getAssignTo ] = useGetAssignToMutation();
  const [getUserList, {data:userListData, isLoading:usserListLoading, isSuccess:userListSuccess}] = useGetUserListSliceMutation()
  const [getSettingsNew, {data:getSettingsData, isLoading:getSettingsLoading, isSuccess:getSettingsSuccess}] = useGetSettingsMutation();
  const [getEscalationSettings, {data:getEscalationData, isLoading:getEscalationLoading, isSuccess:getEscalationSuccess}] = useAsssignEscalationMutation();


  const getAssignToList = async() => {
      try{
        const list = await getAssignTo(payloads);
        setAssignedList(list.data);
        // if(list.data.length > 0){
        //   reset({'assignedTo':list.data[0].id})
        // }
      }
      catch {}
  }


  const getuserList = async () => {
    const userPayload = {
      resellerId: userData != null ? userData.resellerId : "-1",
      orgType: userData != null ? userData.userOrgType : "-1",
    };
    let userList = await getUserList(userPayload);
    // const { data: userResponseNew } = await helpDeskServices.getUserList(
    //   userPayload
    // );
    // const { userList: userResponse } = userResponseNew;
    //setUserList(userResponse);
  };

  // useEffect(() => {
  //   if(userList?.length > 0){
  //     getSetting(1); 
  //   }
  // },[userList])

  var toname=[];
  var ccname = [];
  var bccname = [];


    //convertIdToName
    const convertIdToName = (selected, list, type) => {
      selected.forEach((key, index) => {
        if(type === 'to'){
          const getName = list.filter(item => item.id === key)
          toname.push(getName[0].userName);
          
        }else if(type === 'cc'){
          const getName = list.filter(item => item.id === key)
          ccname.push(getName[0].userName);
        
        }else if(type === 'bcc'){
          const getName = list.filter(item => item.id === key)
          bccname.push(getName[0].userName);
      
        }
    })
    }



    const [isRepotor, setIsRepotor] = useState(0);
    const settingPayload = {
      resellerId: userData != null ? userData.resellerId : "-1",
      orgId: userData != null ? userData.orgId : "-1",
      orgType: watch("orgType"),
      //userData != null ? userData.userOrgType : "-1",
      action: settings === 1 ? "Create" : settings === 2 ? "Closed" : settings === 3 ? "Update" : settings === 4 ? "Delete" : 'Edit',
    };

//value === 1? "Create" : value === 2?"Closed":value === 3?"Update":value === 4?"Delete":"Edit",

 










  const getSetting = async (value) => {
    const settingPayload = {
      resellerId: userData != null ? userData.resellerId : "-1",
      orgId: userData != null ? userData.orgId : "-1",
      orgType: getValues("orgType"),
      //userData != null ? userData.userOrgType : "-1",
      catergory: value === 5? "Assigned" : getValues("category"),
      action:value === 1? "Create" : value === 2?"Closed":value === 3?"Update":value === 4?"Delete":"Edit",
    };
   
    const { data: settingResponseNew } = await helpDeskServices.getSettings(
      settingPayload
    );
    const { settingsList: settingResponse } = settingResponseNew;
    setSettingList(settingResponse);
        //reset({'assignedTo':settingResponse.assignedto});

        if(settingResponse.applicableTo !== null){
          var arryList = settingResponse.applicableTo.split(',');
          convertIdToName(arryList,userList,'to');
          if(value === 1){
            setCreateTo(toname);
         }else if(value === 2){
          setStatusTo(toname);
         }else if(value === 3){
          setUpdateTo(toname);
         }else if(value === 4){
          setDeleteTo(toname);
         }else if(value === 5){
          setEditTo(toname);
         }
        }

        if(settingResponse.applicableCc !== null){
          var arryList = settingResponse.applicableCc.split(',');
          convertIdToName(arryList,userList,'cc');
          if(value === 1){
            setCreateCc(ccname);
         }else if(value === 2){
          setStatusCc(ccname);
         }else if(value === 3){
          setUpdateCc(ccname);
         }else if(value === 4){
          setDeleteCc(ccname);
         }
        }

        if(settingResponse.applicableFollowers !== null){
          var arryList = settingResponse.applicableFollowers.split(',');
          convertIdToName(arryList,userList,'bcc');
          if(value === 1){
            setCreateBcc(bccname);
         }else if(value === 2){
          setStatusBcc(bccname);
         }else if(value === 3){
          setUpdateBcc(bccname);
         }else if(value === 4){
          setDeleteBcc(bccname);
         }
        }
        
  };


  const dispatch = useDispatch();
  const isSuccess = useSelector((state) => state.controls.alert);
  const [ sendMailTo, {isLoading} ] = useMailToSendMutation();

  //escalation
  const [saveEscalation, {data:escalationData, isLoading:escalationLoading, isSuccess:escalationSuccess}] = useSaveEscalationMutation();
  const {data:escalationTimeData} = useEscalationGetTimeQuery();
  
  //New slice apis
  const [getNewUserList, {data:newUserListData, isLoading:newUserLoading, isSuccess:newUserSuccess}] = useGetNewUserListMutation();
  const [newAssignedUser, {data:assignedUser}] = useGetNewAssignedUserMutation();
    //UserList

  useEffect(() => {
    if (isSuccess.isSuccess) {
      setTimeout(() => dispatch(snackService([null, null, null])), 6000);
    }
  }, [isSuccess.isSuccess]);


    //Styled ListItem

    const navigate = useNavigate();

    const handleSettingsTab = (value) => {
      setSettings(value);
      //getSetting(value);
      var tabName ="";
      if(value === 1){
         tabName = "Created";
         navigate('#created');
      }else if(value === 2){
        tabName = "Status Changed";
        navigate('#status_changed');
      }else if(value === 3){
        tabName = "Updated";
        navigate('#updated');
      }else if(value === 4){
        tabName = "Deleted";
        navigate('#deleted');
      }else if(value === 5){
        tabName = "Edit Ticket Settings";
        navigate('#edit_ticket');
      }else if(value === 6){
        tabName = "Escalation";
        navigate('#Escalation');
      }else if(value === 7){
        tabName = "Admin Filter";
        navigate('#Admin_Filter');
      }

      auditPayload.message = "Help Desk > HelpDesk Settings > "+tabName;
      auditPayload.action = "View";
      auditPayload.featureName = "Help Desk";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);
    }

  let to = [];
  let cc = [];
  let bcc = [];

  //Conver
  const convert = (selected, list, type) => {
    selected.forEach((key, index) => {
      if(type === 'to'){
        const getId = list.filter(item => item.userName === key)
        to.push(getId[0].id);
      }else if(type === 'cc'){
        const getId = list.filter(item => item.userName === key)
        cc.push(getId[0].id);
      }else if(type === 'bcc'){
        const getId = list.filter(item => item.userName === key)
        bcc.push(getId[0].id);
      }
  })
  }



  //Start

  //Step 1 // Go Audit log and Get Userlist

  useEffect(() => {
    getOrgType();
    getCategory();
    getField();
    getuserList();
    getAssignToList();
    getUserListNew();
    auditPayload.message = "Help Desk > HelpDesk Settings";
    auditPayload.action = "View";
    auditPayload.featureName = "Help Desk";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  }, []);


  //step 2 // set default category and Orgtype

    //Tab Changed
    const tabChanged = () => {
      setValue('category', selectedCategory[0].key);
      setValue('orgType', selectedOrgType[1].key);
      //getUserListNew();
    }

    useEffect(() => {
      if(selectedCategory.length > 0 && selectedOrgType.length > 0){
        tabChanged();
        getSelectedValues();
      }
    },[settings, selectedCategory, selectedOrgType])

    useEffect(() => {
      handleSettingsTab(1); // Set Page 
   
    },[])



  let helpSettingPayload = {
    "orgId": resellerId,

  }

  let userListPayload = {
    "resellerId":resellerId,
    "limit":2000,
    "searchText":""
  }

  let assignedUserPayload = {
      "resellerId":resellerId,
      'dealerId':0,
      'customerId':0,
      'action':settings === 1 ? "Create" : settings === 2? "Closed":settings === 3? "Update":settings === 4? "Delete" : "Edit" ,
  }




  useEffect(() => {
    if(watch('orgType') === '5' && watch('dealer') !== null && watch('dealer') !== undefined && newUserList !== null){
      settingPayload.customerId = 0;
      settingPayload.dealerId = watch('dealer');
      getSelectedValues();
    } 
  },[watch('orgType'), watch('dealer')])





  //Handle category
  const handleChangeCategory = async (value) => {
      if(value !== '-1'){
        //getSetting(value);
        assignedUserPayload.catergory = value;
        let assignUser = await newAssignedUser(assignedUserPayload)
      }
  }


 
  //Handle region
  const handleChangeRegion = () => {

  }

  //Handle Dealer
  const handleChangeDealer = () => {

  }

  //Handle Customer
  const handleChangeCustomer = () => {

  }






  const [newUserList, setNewUserList] = useState(null);
  const [assigUser, setAssigUser] = useState([]);
  const [createToUsers, setCreateToUsers] = useState([]);
  const [ccUsers, setCcUsers] = useState([]);
  const [bccUsers, setBccUsers] = useState([]);



  //Assign Onchange
  const assignOnChange = (value) => {
    setAssigUser(value);
  }


   //Create Onchange
   const createToChange = (value) => {
    setCreateToUsers(value);
  }

  //Followers Onchange
   const followersCcChange = (value) => {
    setCcUsers(value);
  }

  //Followers Onchange
  const followersBCcChange = (value) => {
    setBccUsers(value);
  }

  const [list, setList] = useState([]);
  

  //userlist  
  const convertToString = async () => {
    setList([]);
    newUserList?.length > 0 && newUserList?.forEach((key, index) => {
      let userObj = {
        name:key.userName,
        id:key.id
      }
      setList(oldList => [...oldList, userObj])
    })
  }

  useEffect(() => {
    if(newUserList !== undefined && newUserList !== null){
      convertToString();
    }
  },[newUserList])


  const getAssignedUser = useCallback((userId) => {
        //setAssigUser([]);
        const getAssignuser = list.find((item) => item.id === userId);
        setAssigUser(getAssignuser);
  },[settings, list])


  const getTousers = useCallback((users) => {
      const splitToUsers = users?.split(',');
      for(let i = 0; splitToUsers?.length > i; i++){
        const getusers = list.filter((item) => item.id === splitToUsers[i]);
        setCreateToUsers(oldUser => [...oldUser, getusers[0]])
  }},[settings, list])

  const getCcusers = useCallback((users) => {
    const splitToUsers = users?.split(',');
    for(let i = 0; splitToUsers?.length > i; i++){
      const getusers = list.filter((item) => item.id === splitToUsers[i]);
      setCcUsers(oldUser => [...oldUser, getusers[0]])
    }
  },[settings, list])

  const getBccusers = useCallback((users) => {
    const splitToUsers = users?.split(',');
    for(let i = 0; splitToUsers?.length > i; i++){
      const getusers = list.filter((item) => item.id === splitToUsers[i]);
      setBccUsers(oldUser => [...oldUser, getusers[0]])
    }
  },[settings, list])



  
  //Handle category
  const handleChangeDrodown = (name, value) => {
    if(name === 'category'){
        //handleChangeCategory(value);
        if(value !== '-1'){
          getSelectedValues();
        }
    }else if(name === 'orgType'){
      handleChangeOrgType(value);
      if(value === '2'){ // Only run when select organazation
        getSelectedValues();
      }
      
      //getSetting(value);
    }else if(name === 'region'){
      userListPayload.regionId = watch('region');
      userListPayload.dealerId = watch('dealer');
      if(watch('orgType') === '3'){
        userListPayload.customerId = watch('customer');
      }
      //getUserListNew();
    }else if(name === 'dealer'){
      userListPayload.regionId = watch('region');
      userListPayload.dealerId = watch('dealer');
      if(watch('orgType') === '3'){
        userListPayload.customerId = watch('customer');
      }
      //getUserListNew();
    }else if(name === 'customer'){
      userListPayload.regionId = watch('region');
      userListPayload.dealerId = watch('dealer');
      userListPayload.customerId = watch('customer');
      settingPayload.customerId = watch('customer');
      settingPayload.dealerId = watch('dealer')
      getUserListNew();
      getSelectedValues();
    }else if(name === 'field'){
      if(value !== '-1'){
        getSelectedValues();
      }
    }
  }   

   //Handle orgType
   const handleChangeOrgType = async (value) => {
    if(value === '2'){
        // get org users
        getUserListNew();
    }else if (value === '3'){
        if(watch('customer') !== null && watch('customer') !== undefined){
          userListPayload.regionId = watch('region');
          userListPayload.dealerId = watch('dealer');
          userListPayload.customerId = watch('customer');
          getUserListNew();
        };
    }
}







  //get Userlist
  const getUserListNew = async (type) => {
    const newUserList = await getNewUserList(userListPayload);
    if(type === 'assign'){
      setAssignedList(newUserList.data.userList);
    }else{
      setNewUserList(newUserList.data.userList);
    }
  }
  
  // get Escalation payload
  let escalationPayload = {};


  const getSelectedValues = async () => {
    settingPayload.catergory = settings !== 5 ? watch('category') : watch('field');
    if(watch('orgType') === '2'){
      settingPayload.dealerId = 0;
      settingPayload.customerId = 0;
      escalationPayload.orgId = resellerId;
      
    }else if(watch('orgType') === '5'){
      settingPayload.dealerId = watch('dealer');
      settingPayload.customerId = 0;
      escalationPayload.orgId = watch('dealer');
    }else if(watch('orgType') === '3'){
      settingPayload.dealerId = watch('dealer');
      settingPayload.customerId = watch('customer');
      escalationPayload.orgId = watch('customer');
    }

    // setAssigUser([]);
    // setCreateToUsers([]);
    // setCcUsers([]);
    // setBccUsers([]);

      const getSettingsNews = await getSettingsNew(settingPayload);
      escalationPayload.catergory = watch('category')
      const getEscalationNew = await getEscalationSettings(escalationPayload);
      //getAssignedUser(getSettingsNews.data.settingsList.assignedto, );
      //getTousers( getSettingsNews.data.settingsList.applicableTo);
      //getCcusers(getSettingsNews.data.settingsList.applicableCc);
      //getBccusers(getSettingsNews.data.settingsList.applicableFollowers);
  }

  //get userlist
  const getSettinguserList = () => {

  }


  // get default value
  const helpdeskSettingDefault = useCallback( async() => {

  },[])



    //change customer
    useEffect(() => {
      if(watch('orgType') === '3' && watch('customer') !== null && watch('customer') !== undefined && newUserList !== null){
        settingPayload.customerId = watch('customer');
        settingPayload.dealerId = watch('dealer');
        getSelectedValues();
      }
    },[watch('customer'), watch('orgType')])


    // Change escalation
    const getEscalation = useCallback((users, number) => {
      const splitToUsers = users?.split(',');
      for(let i = 0; splitToUsers?.length > i; i++){
        const getusers = list.filter((item) => item.id === splitToUsers[i]);
      
        if(number === '1'){
          setLevel1User(oldUser => [...oldUser, getusers[0]])
          //setLevel1User(getusers)
        }
        else if(number === '2'){
          setLevel2User(oldUser => [...oldUser, getusers[0]])
          //setLevel2User(getusers)
        }else if(number === '3'){
          setLevel3User(oldUser => [...oldUser, getusers[0]])
          //setLevel3User(getusers)
        }else if(number === '4'){
          setLevel4User(oldUser => [...oldUser, getusers[0]])
          //setLevel4User(getusers)
        }else if(number === '5'){
          setLevel5User(oldUser => [...oldUser, getusers[0]])
          //setLevel5User(getusers)
        }else if(number === '6'){
          setLevel6User(oldUser => [...oldUser, getusers[0]])
          //setLevel6User(getusers)
        }
        
      }
    },[settings, list])

    

  useEffect(() => {
    //setAssigUser([]);
    setCreateToUsers([]);
    setCcUsers([]);
    setBccUsers([]);
    setLevel1User([]);
    setLevel2User([]);
    setLevel3User([]);
    setLevel4User([]);
    setLevel5User([]);
    setLevel6User([]);
    if(list.length > 0 && getSettingsData !== undefined && getEscalationData !== undefined){
      getAssignedUser(getSettingsData.settingsList?.assignedto);
      getTousers(getSettingsData.settingsList?.applicableTo);
      getCcusers(getSettingsData.settingsList?.applicableCc);
      getBccusers(getSettingsData.settingsList?.applicableFollowers);
      setIsRepotor(getSettingsData.settingsList?.reporterEmail);


      //escalation
      getEscalation(getEscalationData.settingsList?.levelOneUser, '1');
      getEscalation(getEscalationData.settingsList?.levelTwoUser, '2');
      getEscalation(getEscalationData.settingsList?.levelThreeUser, '3');
      getEscalation(getEscalationData.settingsList?.levelFourUser, '4');
      getEscalation(getEscalationData.settingsList?.levelFiveUser, '5');
      getEscalation(getEscalationData.settingsList?.levelCriticalUser, '6');

      //Get Time
      setValue('escalation_remainder_l1', getEscalationData.settingsList?.levelOneRemainder !== null ?  getEscalationData.settingsList?.levelOneRemainder : '-1');
      setValue('escalation_remainder_l2', getEscalationData.settingsList?.levelTwoRemainder !== null ? getEscalationData.settingsList?.levelTwoRemainder : '-1');
      setValue('escalation_remainder_l3', getEscalationData.settingsList?.levelThreeRemainder !== null ? getEscalationData.settingsList?.levelThreeRemainder: '-1');
      setValue('escalation_remainder_l4', getEscalationData.settingsList?.levelFourRemainder !== null ? getEscalationData.settingsList?.levelFourRemainder : '-1');
      setValue('escalation_remainder_l5', getEscalationData.settingsList?.levelFiveRemainder !== null ? getEscalationData.settingsList?.levelFiveRemainder : '-1');
      setValue('escalation_remainder_cl1', getEscalationData.settingsList?.levelCriticalRemainder !== null ? getEscalationData.settingsList?.levelCriticalRemainder : '-1');
    }
  },[settings, list, getSettingsData, getEscalationData])


  //convert string
  const [stringTo, setStringTo] = useState([]);
  const [stringcc, setStringCc] = useState([]);
  const [stringbcc, setStringBcc] = useState([]);




  //Escalation State

  const [level1User, setLevel1User] = useState([]);
  const [level2User, setLevel2User] = useState([]);
  const [level3User, setLevel3User] = useState([]);
  const [level4User, setLevel4User] = useState([]);
  const [level5User, setLevel5User] = useState([]);
  const [level6User, setLevel6User] = useState([]);


  const userLevelHandleChange = (value, level) => {
    if(level === 1){
        setLevel1User(value);
    }else if(level === 2){
        setLevel2User(value);
    }else if(level === 3){
        setLevel3User(value);
    }else if(level === 4){
        setLevel4User(value);
    }else if(level === 5){
        setLevel5User(value);
    }else if(level === 6){
        setLevel6User(value);
    }
  }

  

  const onSubmit = async (data) => {
    helpSettingPayload.category =  data.category;
    helpSettingPayload.orgType =  data.orgType;
    helpSettingPayload.reporterEmail = isRepotor;
    helpSettingPayload.dealerId =  0;
    helpSettingPayload.customerId =  0;
    if(watch('orgType') === '2'){
      helpSettingPayload.resellerId = resellerId;
      if(settings === 6){
        helpSettingPayload.orgId = orgId;
      }
    }else if(watch('orgType') === '3'){
      helpSettingPayload.dealerId = watch('dealer');
      helpSettingPayload.customerId = watch('customer');
      if(settings === 6){
        helpSettingPayload.orgId = watch('customer');
      }
    }else if(watch('orgType') === '5'){
      helpSettingPayload.dealerId = watch('dealer');
      if(settings === 6){
        helpSettingPayload.orgId = watch('dealer');
      }
    }
    //Created
    if(settings === 1){
      helpSettingPayload.action = "Create";
      if(assigUser !== undefined && assigUser !== null ){
        helpSettingPayload.assignedto = assigUser.id;
      }else{
        dispatch(snackService(['Assign to user cant be empty', 1, true]));
      }
      
      newStringConvertion(createToUsers, 'to');
      newStringConvertion(ccUsers, 'cc');
      newStringConvertion(bccUsers, 'bcc');
    }else if(settings === 2){ //Changed
      helpSettingPayload.action = "Closed";
      newStringConvertion(createToUsers, 'to');
      newStringConvertion(ccUsers, 'cc');
      newStringConvertion(bccUsers, 'bcc');
    }else if(settings === 3){//Updted
      helpSettingPayload.action = "Update";
      newStringConvertion(createToUsers, 'to');
      newStringConvertion(ccUsers, 'cc');
      newStringConvertion(bccUsers, 'bcc');
    }else if(settings === 4){//Updted
      helpSettingPayload.action = "Delete";
      newStringConvertion(createToUsers, 'to');
      newStringConvertion(ccUsers, 'cc');
      newStringConvertion(bccUsers, 'bcc');
    }else if(settings === 5){//Edit
      newStringConvertion(createToUsers, 'to');
      helpSettingPayload.category =  data.field;
      helpSettingPayload.action = "Edit";
      helpSettingPayload.applicableFollowers = to.toString();
    }else if(settings === 6){
      newStringConvertion(level1User, 'l1');
      newStringConvertion(level2User, 'l2');
      newStringConvertion(level3User, 'l3');
      newStringConvertion(level4User, 'l4');
      newStringConvertion(level5User, 'l5');
      newStringConvertion(level6User, 'l6');
    }
    helpSettingPayload.applicableTo =  newTo.toString();
    helpSettingPayload.applicableCc = newcc.toString();
    helpSettingPayload.applicableFollowers = newbcc.toString();
    if(watch('category') !== '-1' && watch('orgType') !== '-1'){
      if(settings === 1){
        if(assigUser !== undefined && assigUser !== null){
          const response = await sendMailTo(helpSettingPayload);
          dispatch(snackService([response.data.resultMessage, response.data.resultCode, true]));
        }else{
          dispatch(snackService(['Assign to user cant be empty', 1, true]));
        }
      }else if(settings !== 1 && settings !== 6){
        const response = await sendMailTo(helpSettingPayload);
        dispatch(snackService([response.data.resultMessage, response.data.resultCode, true]));
      }else if(settings === 6){
        helpSettingPayload.levelOneRemainder = data.escalation_remainder_l1 === '-1' ? null : data.escalation_remainder_l1 
        helpSettingPayload.levelTwoRemainder = data.escalation_remainder_l2 === '-1' ? null : data.escalation_remainder_l2 
        helpSettingPayload.levelThreeRemainder = data.escalation_remainder_l3 === '-1' ? null : data.escalation_remainder_l3 
        helpSettingPayload.levelFourRemainder = data.escalation_remainder_l4 === '-1' ? null : data.escalation_remainder_l4 
        helpSettingPayload.levelFiveRemainder = data.escalation_remainder_l5 === '-1' ? null : data.escalation_remainder_l5 
        helpSettingPayload.levelCriticalRemainder = data.escalation_remainder_cl1 === '-1' ? null : data.escalation_remainder_cl1 

        helpSettingPayload.levelOneUser = level1User !== null && level1User !== undefined ? level1.toString() : ''
        helpSettingPayload.levelTwoUser = level2User !== null && level2User !== undefined ? level2.toString() : ''
        helpSettingPayload.levelThreeUser = level3User !== null && level3User !== undefined ? level3.toString() : ''
        helpSettingPayload.levelFourUser = level4User !== null && level4User !== undefined ? level4.toString() : ''
        helpSettingPayload.levelFiveUser = level5User !== null && level5User !== undefined ? level5.toString() : ''
        helpSettingPayload.levelCriticalUser = level6User !== null && level6User !== undefined ? level6.toString() : ''
        const saveEscalationRes = await saveEscalation(helpSettingPayload);
        if(saveEscalationRes?.data !== null && saveEscalationRes.data !== undefined){
          dispatch(snackService([saveEscalationRes.data.resultMessage, saveEscalationRes.data.resultCode, true]));
        }else{
          dispatch(snackService(['Updation Faild', 1, true]));
        }
      }
    }else if(watch('category') === '-1'){
      dispatch(snackService(['Please select category', 1, true]));
    }else if(watch('category') === '-1'){
      dispatch(snackService(['Please select organization type', 1, true]));
    }else if(watch('field') === '-1'){
      dispatch(snackService(['Please select filed', 1, true]));
    }else if(createToUsers.length === 0 && createToUsers !== undefined){
      dispatch(snackService(['Please select Search users', 1, true]));
    }

  };


  let newTo = [];
  let newcc = [];
  let newbcc = [];
  let level1 = [];
  let level2 = [];
  let level3 = [];
  let level4 = [];
  let level5 = [];
  let level6 = [];
  const newStringConvertion = (items, type) => {
    items.length > 0 && items.forEach((key, index) => {
        if(type === 'to'){
          newTo.push(key.id);
          setStringTo(oldValue => [...oldValue, key.id]);
        }else if(type === 'cc'){
          newcc.push(key.id);
          setStringCc(oldValue => [...oldValue, key.id])
        }else if(type === 'bcc'){
          newbcc.push(key.id);
          setStringBcc(oldValue => [...oldValue, key.id])
        }else if(type === 'l1'){
          level1.push(key.id);
          //setStringBcc(oldValue => [...oldValue, key.id])
        }else if(type === 'l2'){
          level2.push(key.id);
          //setStringBcc(oldValue => [...oldValue, key.id])
        }else if(type === 'l3'){
          level3.push(key.id);
          //setStringBcc(oldValue => [...oldValue, key.id])
        }else if(type === 'l4'){
          level4.push(key.id);
          //setStringBcc(oldValue => [...oldValue, key.id])
        }else if(type === 'l5'){
          level5.push(key.id);
          //setStringBcc(oldValue => [...oldValue, key.id])
        }else if(type === 'l6'){
          level6.push(key.id);
          //setStringBcc(oldValue => [...oldValue, key.id])
        }
    })
  }



  //Style Chip
  const Usertag = ({option, index}) => {
    return(
        <Chip
        variant="outlined"
        label={option.name}
        size="small"
        color="primary"
      />
    )
  }



  return (
    <StyledPageLayout>
        <Breadcrumb
          mainDiv="Helpdesk"
          subDiv="Helpdesk Settings"
        />
        <Grid container spacing={1}>
            <Grid item xl={2} lg={3}>
                <Paper elevation={0} sx={{px:1}}>
                <List>
                    <StyledCommonListItem dense={true} >
                        <ListItemButton selected={settings === 1} onClick={() => handleSettingsTab(1)}>
                            <ListItemIcon>
                                <MdAssignmentAdd />
                            </ListItemIcon>
                            <ListItemText>Created</ListItemText>
                        </ListItemButton>
                    </StyledCommonListItem>
                    <StyledCommonListItem dense={true}>
                        <ListItemButton selected={settings === 2} onClick={() => handleSettingsTab(2)} >
                            <ListItemIcon>
                                <FaExchangeAlt />
                            </ListItemIcon>
                            <ListItemText>Status Changed</ListItemText>
                        </ListItemButton>
                    </StyledCommonListItem>
                    <StyledCommonListItem dense={true}>
                        <ListItemButton selected={settings === 3} onClick={() => handleSettingsTab(3)} >
                            <ListItemIcon>
                                <MdEditSquare />
                            </ListItemIcon>
                            <ListItemText>Updated</ListItemText>
                        </ListItemButton>
                    </StyledCommonListItem>
                    <StyledCommonListItem dense={true}>
                        <ListItemButton selected={settings === 4} onClick={() => handleSettingsTab(4)} >
                            <ListItemIcon>
                                <MdDeleteSweep />
                            </ListItemIcon>
                            <ListItemText>Deleted</ListItemText>
                        </ListItemButton>
                    </StyledCommonListItem>
                    {/* <StyledCommonListItem dense={true}>
                        <ListItemButton selected={settings === 5} onClick={() => handleSettingsTab(5)} >
                            <ListItemIcon>
                                <MdDeleteSweep />
                            </ListItemIcon>
                            <ListItemText>Edit Ticket Settings</ListItemText>
                        </ListItemButton>
                    </StyledCommonListItem> */}
                    <StyledCommonListItem dense={true}>
                        <ListItemButton selected={settings === 6} onClick={() => handleSettingsTab(6)} >
                            <ListItemIcon>
                                <RiCheckboxMultipleFill />
                            </ListItemIcon>
                            <ListItemText>Escalation</ListItemText>
                        </ListItemButton>
                    </StyledCommonListItem>
                    {resellerId === 109882 && 
                    <StyledCommonListItem dense={true}>
                        <ListItemButton selected={settings === 7} onClick={() => handleSettingsTab(7)} >
                            <ListItemIcon>
                            <FaFilter />
                            </ListItemIcon>
                            <ListItemText>Admin Filter</ListItemText>
                        </ListItemButton>
                    </StyledCommonListItem>
                    }
                </List>
                </Paper>
            </Grid>
            <Grid item xl={9} lg={9}>
                {
                  settings === 1 && 
                  <form onSubmit={handleSubmit(onSubmit)}>
                  <StyledCardShadow>
                    <CardHeader
                      title={
                        <StyledTableTitle sx={{ display: "block" }}>
                          NOTIFY WHEN HELPDESK TICKET IS CREATED
                        </StyledTableTitle>
                      }
                      //subheader={<Typography variant="caption">Permissions</Typography>}
                    />
                    <CardContent sx={{ py: 0 }}>
                        <Grid container rowSpacing={2} columnSpacing={2}>
                          <HelpdeskSettingOrgDropdown categoryist={selectedCategory} register={register} setValue={setValue} reset={reset} watch={watch} resellerId={resellerId} selectedOrgType={selectedOrgType} handleChangeDrodown={handleChangeDrodown} userList={newUserList}/>
                          <Grid item lg={6}>
                            <StyledFormControl fullWidth>
                              <StyledFormControlLabel required>
                                Assigned To
                              </StyledFormControlLabel>

                                  <Autocomplete
                                  //disableCloseOnSelect
                                  //multiple
                                  //limitTags={2}
                                  //id="multiple-limit-tags"
                                  options={list.length > 0 && list}
                                  value={assigUser !== undefined ? assigUser : []}
                                  getOptionLabel={(option) => option?.name || ''}
                                  onChange={(event, value) => assignOnChange(value)}
                                  defaultValue={assigUser !== undefined ? assigUser : []}
                                  renderInput={(params) => (
                                    <StyledTextField {...params} placeholder="Assinged Users" size="small" fullWidth/>
                                  )}
                                  />
                             

                            </StyledFormControl>
                          </Grid>
                          <Grid item lg={6}>
                            <StyledFormControl fullWidth>
                              <StyledFormControlLabel>To </StyledFormControlLabel>
                                <Autocomplete
                                  multiple
                                  limitTags={1}
                                  disableCloseOnSelect
                                  id="multiple-limit-tags"
                                  options={list.length > 0 && list}
                                  value={createToUsers !== undefined ? createToUsers : []}
                                  getOptionLabel={(option) => option?.name}
                                  onChange={(event, value) => createToChange(value)}
                                  //defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Usertag index={index} option={option} {...getTagProps({ index })}/>
                                    ))
                                  }
                                  
                                  renderInput={(params) => (
                                    <StyledTextField {...params} placeholder="Search users" size="small" fullWidth/>
                                  )}
                                />

                              
                            </StyledFormControl>
                          </Grid>
                          <Grid item lg={6}>
                            <StyledFormControl fullWidth>
                              <StyledFormControlLabel>
                                Search users
                              </StyledFormControlLabel>

                                <Autocomplete
                                  multiple
                                  limitTags={1}
                                  disableCloseOnSelect
                                  id="multiple-limit-tags"
                                  options={list.length > 0 && list}
                                  value={ccUsers !== undefined ? ccUsers : []}
                                  getOptionLabel={(option) => option?.name}
                                  onChange={(event, value) => followersCcChange(value)}
                                  //defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Usertag index={index} option={option} {...getTagProps({ index })}/>
                                    ))
                                  }
                                  renderInput={(params) => (
                                    <StyledTextField {...params} placeholder="Search users" size="small" fullWidth/>
                                  )}
                                />

                             
                            </StyledFormControl>
                          </Grid>
                          <Grid item lg={6}>
                            <StyledFormControl fullWidth>
                              <StyledFormControlLabel>
                                Search users
                              </StyledFormControlLabel>

                              <Autocomplete
                                  multiple
                                  limitTags={1}
                                  disableCloseOnSelect
                                  id="multiple-limit-tags"
                                  options={list.length > 0 && list}
                                  value={bccUsers !== undefined ? bccUsers : []}
                                  getOptionLabel={(option) => option?.name}
                                  onChange={(event, value) => followersBCcChange(value)}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Usertag index={index} option={option} {...getTagProps({ index })}/>
                                    ))
                                  }
                                  //defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                                  renderInput={(params) => (
                                    <StyledTextField {...params} placeholder="Search users" size="small" fullWidth/>
                                  )}
                                />
                              
                            
                            </StyledFormControl>
                          </Grid>
                          <Grid item lg={4}>
                                <FormControlLabel  control={<Checkbox  size="small" checked={isRepotor === 0 ? false : true} onChange={(event) => setIsRepotor(event.target.checked === true ?  1 : 0)}/>} label={'Send copy to Ticket Creator'}/> 
                          </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                      <Stack
                        direction={"row"}
                        justifyContent={"flex-end"}
                        width="100%"
                      >
                        <SoftButton
                          size="small"
                          //type="button"
                          variant="contained"
                          className="success"
                          color="success"
                          disableElevation
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading ? <ScaleLoader height={12} color="#fff"/> : 'Save'}
                        </SoftButton>
                      </Stack>
                    </CardActions>
                  </StyledCardShadow>
                  </form>
                }
                {
                  settings === 2 && <form onSubmit={handleSubmit(onSubmit)}>
                  <StyledCardShadow>
                    <CardHeader
                      title={
                        <StyledTableTitle sx={{ display: "block" }}>
                          NOTIFY WHEN HELPDESK TICKET STATUS CHANGED
                        </StyledTableTitle>
                      }
                      //subheader={<Typography variant="caption">Permissions</Typography>}
                    />
                    <CardContent sx={{ py: 0 }}>
                      <div>
                        <Grid container rowSpacing={2} columnSpacing={2}>
                        <HelpdeskSettingOrgDropdown categoryist={selectedCategory} register={register} setValue={setValue} reset={reset} watch={watch} resellerId={resellerId} selectedOrgType={selectedOrgType} handleChangeDrodown={handleChangeDrodown}/>
                        
                          <Grid item lg={4}>
                            <StyledFormControl fullWidth>
                              <StyledFormControlLabel>To </StyledFormControlLabel>
                              <Autocomplete
                                  multiple
                                  limitTags={1}
                                  disableCloseOnSelect
                                  id="multiple-limit-tags"
                                  options={list.length > 0 && list}
                                  value={createToUsers !== undefined ? createToUsers : []}
                                  getOptionLabel={(option) => option?.name}
                                  onChange={(event, value) => createToChange(value)}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Usertag index={index} option={option} {...getTagProps({ index })}/>
                                    ))
                                  }
                                  //defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                                  renderInput={(params) => (
                                    <StyledTextField {...params} placeholder="Search users" size="small" fullWidth/>
                                  )}
                                />
                              
                            </StyledFormControl>
                          </Grid>
                          <Grid item lg={4}>
                            <StyledFormControl fullWidth>
                              <StyledFormControlLabel>
                                Search users
                              </StyledFormControlLabel>
                              <Autocomplete
                                  multiple
                                  limitTags={1}
                                  disableCloseOnSelect
                                  id="multiple-limit-tags"
                                  options={list.length > 0 && list}
                                  value={ccUsers !== undefined?ccUsers:[]}
                                  getOptionLabel={(option) => option?.name}
                                  onChange={(event, value) => followersCcChange(value)}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Usertag index={index} option={option} {...getTagProps({ index })}/>
                                    ))
                                  }
                                  //defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                                  renderInput={(params) => (
                                    <StyledTextField {...params} placeholder="Search users" size="small" fullWidth/>
                                  )}
                                />
                            </StyledFormControl>
                          </Grid>
                          <Grid item lg={4}>
                            <StyledFormControl fullWidth>
                              <StyledFormControlLabel>
                                Followers(Bcc)
                              </StyledFormControlLabel>
                              <Autocomplete
                                  multiple
                                  limitTags={1}
                                  disableCloseOnSelect
                                  id="multiple-limit-tags"
                                  options={list.length > 0 && list}
                                  value={bccUsers !== undefined?bccUsers:[]}
                                  getOptionLabel={(option) => option?.name}
                                  onChange={(event, value) => followersBCcChange(value)}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Usertag index={index} option={option} {...getTagProps({ index })}/>
                                    ))
                                  }
                                  //defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                                  renderInput={(params) => (
                                    <StyledTextField {...params} placeholder="Search users" size="small" fullWidth/>
                                  )}
                                />
                            </StyledFormControl>
                          </Grid>
                          <Grid item lg={4}>
                                  <FormControl control={<Checkbox />} />
                          </Grid>
                        </Grid>
                      </div>
                    </CardContent>
                    <CardActions>
                      <Stack
                        direction={"row"}
                        justifyContent={"flex-end"}
                        width="100%"
                      >
                        <SoftButton
                          size="small"
                          //type="button"
                          variant="contained"
                          className="success"
                          color="success"
                          disableElevation
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading ? <ScaleLoader height={12} color="#fff"/> : 'Save'}
                        </SoftButton>
                      </Stack>
                    </CardActions>
                  </StyledCardShadow>
                </form>
                }
                {
                  settings === 3 && <form onSubmit={handleSubmit(onSubmit)}>
                  <StyledCardShadow>
                    <CardHeader
                      title={
                        <StyledTableTitle sx={{ display: "block" }}>
                          NOTIFY WHEN HELPDESK TICKET IS UPDATED
                        </StyledTableTitle>
                      }
                      //subheader={<Typography variant="caption">Permissions</Typography>}
                    />
                    <CardContent sx={{ py: 0 }}>
                      <div>
                        <Grid container rowSpacing={2} columnSpacing={2}>
                        <HelpdeskSettingOrgDropdown categoryist={selectedCategory} register={register} setValue={setValue} reset={reset} watch={watch} resellerId={resellerId} selectedOrgType={selectedOrgType} handleChangeDrodown={handleChangeDrodown}/>
                          <Grid item lg={4}>
                            <StyledFormControl fullWidth>
                              <StyledFormControlLabel>To </StyledFormControlLabel>

                              <Autocomplete
                                  multiple
                                  limitTags={1}
                                  disableCloseOnSelect
                                  id="multiple-limit-tags"
                                  options={list.length > 0 && list}
                                  value={createToUsers !== undefined?createToUsers:[]}
                                  getOptionLabel={(option) => option?.name}
                                  onChange={(event, value) => createToChange(value)}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Usertag index={index} option={option} {...getTagProps({ index })}/>
                                    ))
                                  }
                                  //defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                                  renderInput={(params) => (
                                    <StyledTextField {...params} placeholder="Search users" size="small" fullWidth/>
                                  )}
                                />
                            </StyledFormControl>
                          </Grid>
                          <Grid item lg={4}>
                            <StyledFormControl fullWidth>
                              <StyledFormControlLabel>
                                Search users
                              </StyledFormControlLabel>
                              <Autocomplete
                                  multiple
                                  limitTags={1}
                                  disableCloseOnSelect
                                  id="multiple-limit-tags"
                                  options={list.length > 0 && list}
                                  value={ccUsers !== undefined?ccUsers:[]}
                                  getOptionLabel={(option) => option?.name}
                                  onChange={(event, value) => followersCcChange(value)}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Usertag index={index} option={option} {...getTagProps({ index })}/>
                                    ))
                                  }
                                  //defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                                  renderInput={(params) => (
                                    <StyledTextField {...params} placeholder="Search users" size="small" fullWidth/>
                                  )}
                                />
                            </StyledFormControl>
                          </Grid>
                          <Grid item lg={4}>
                            <StyledFormControl fullWidth>
                              <StyledFormControlLabel>
                                Followers(Bcc)
                              </StyledFormControlLabel>
                              <Autocomplete
                                  multiple
                                  limitTags={1}
                                  disableCloseOnSelect
                                  id="multiple-limit-tags"
                                  options={list.length > 0 && list}
                                  value={bccUsers !== undefined?bccUsers:[]} 
                                  getOptionLabel={(option) => option?.name}
                                  onChange={(event, value) => followersBCcChange(value)}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Usertag index={index} option={option} {...getTagProps({ index })}/>
                                    ))
                                  }
                                  //defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                                  renderInput={(params) => (
                                    <StyledTextField {...params} placeholder="Search users" size="small" fullWidth/>
                                  )}
                                />
                              {/* <StyledDropDown
                                multiple
                                displayEmpty
                                value={updateBcc}
                                name="updateBcc"
                                onChange={handleChange}
                                input={<OutlinedInput />}
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.length === 0 ? (
                                      <em>Select</em>
                                    ) : (
                                      selected.map((value) => {
                                        return (
                                          <Chip
                                            key={value}
                                            label={value}
                                            sx={{ height: "auto" }}
                                          />
                                        );
                                      })
                                    )}
                                  </Box>
                                )}
                                size="small"
                                MenuProps={MenuProps}
                              >
                                <ListSubheader sx={{background:'#fff', position:'sticky', top:'0px', padding:'8px 15px'}}>
                                      <StyledTextField 
                                      size="small" 
                                      fullWidth
                                      onChange={(event) => {
                                        if(event.target.value.length > 3 ){
                                            userListPayload.searchText = event.target.value;
                                            getUserListNew();
                                        }else if(event.target.value.length === 0){
                                            userListPayload.searchText = '';
                                            getUserListNew();
                                        }
                                      }}
                                      onKeyDown={(e) => e.stopPropagation()}
                                      autoFocus
                                      />
                                </ListSubheader>
                                <MenuItem disabled value="">
                                  <em>Select</em>
                                </MenuItem>
                                {newUserList !== null && newUserList?.length > 0 && 
                                  newUserList.map((name) => {
                                    return (
                                      <MenuItem key={name.id} value={name.userName}>
                                        {name.userName}
                                      </MenuItem>
                                    );
                                  })}
                              </StyledDropDown> */}

                            </StyledFormControl>
                          </Grid>
                        </Grid>
                      </div>
                    </CardContent>
                    <CardActions>
                      <Stack
                        direction={"row"}
                        justifyContent={"flex-end"}
                        width="100%"
                      >
                        <SoftButton
                          size="small"
                          //type="button"
                          variant="contained"
                          className="success"
                          color="success"
                          disableElevation
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading ? <ScaleLoader height={12} color="#fff"/> : 'Save'}
                        </SoftButton>
                      </Stack>
                    </CardActions>
                  </StyledCardShadow>
                </form>
                }
                {
                  settings === 4 && <form onSubmit={handleSubmit(onSubmit)}>
                  <StyledCardShadow>
                    <CardHeader
                      title={
                        <StyledTableTitle sx={{ display: "block" }}>
                          NOTIFY WHEN HELPDESK TICKET IS DELETED
                        </StyledTableTitle>
                      }
                      //subheader={<Typography variant="caption">Permissions</Typography>}
                    />
                    <CardContent sx={{ py: 0 }}>
                      <div>
                        <Grid container rowSpacing={2} columnSpacing={2}>
                        <HelpdeskSettingOrgDropdown categoryist={selectedCategory} register={register} setValue={setValue} reset={reset} watch={watch} resellerId={resellerId} selectedOrgType={selectedOrgType} handleChangeDrodown={handleChangeDrodown} getSetting={getSetting}/>
                          {/* <Grid item lg={12}>
                            <Grid container spacing={1}>
                              <Grid item lg={6}>
                                <StyledFormControl fullWidth>
                                  <StyledFormControlLabel>
                                    Category
                                  </StyledFormControlLabel>
                                  <StyledDropDown
                                    {...register("category", {})}
                                    onChange={(e) => {
                                      setValue("category", e.target.value);
                                      getSetting(settings);
                                    }}
                                    size="small"
                                    defaultValue={selectedCategory.length > 0 ?selectedCategory[0].key:""}
                                  >
                                    <MenuItem value="0">Select</MenuItem>
                                    {selectedCategory.length > 0 &&
                                      selectedCategory
                                        .filter(
                                          (value) =>
                                            value.key !== "KYC Approval" &&
                                            value.key !== "KYC Rejection"
                                        )
                                        .map((option, index) => {
                                          return (
                                            <MenuItem key={index} value={option.key}>
                                              {option.value}
                                            </MenuItem>
                                          );
                                        })}
                                  </StyledDropDown>
                                </StyledFormControl>
                              </Grid>
                              <Grid item lg={6}>
                                <StyledFormControl fullWidth>
                                  <StyledFormControlLabel>
                                    Organization Type
                                  </StyledFormControlLabel>
                                  <StyledDropDown
                                    {...register("orgType", {})}
                                    onChange={(e) => {
                                      setValue("orgType", e.target.value);
                                      getSetting(settings);
                                    }}
                                    size="small"
                                    defaultValue={"2"}
                                  >
                                    <MenuItem value="0">Select</MenuItem>
                                    {selectedOrgType.length > 0 &&
                                      selectedOrgType.map((option, index) => {
                                        return (
                                          <MenuItem key={index} value={option.key}>
                                            {option.value}
                                          </MenuItem>
                                        );
                                      })}
                                  </StyledDropDown>
                                </StyledFormControl>
                              </Grid>
                            </Grid>
                          </Grid> */}
                          <Grid item lg={4}>
                            <StyledFormControl fullWidth>
                              <StyledFormControlLabel>To </StyledFormControlLabel>
                              <Autocomplete
                                  multiple
                                  limitTags={1}
                                  disableCloseOnSelect
                                  id="multiple-limit-tags"
                                  options={list.length > 0 && list}
                                  value={createToUsers !== undefined?createToUsers:[]}
                                  getOptionLabel={(option) => option?.name}
                                  onChange={(event, value) => createToChange(value)}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Usertag index={index} option={option} {...getTagProps({ index })}/>
                                    ))
                                  }
                                  //defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                                  renderInput={(params) => (
                                    <StyledTextField {...params} placeholder="Search users" size="small" fullWidth/>
                                  )}
                                />
                              {/* <StyledDropDown
                                multiple
                                displayEmpty
                                value={deleteTo}
                                name="deleteTo"
                                onChange={handleChange}
                                input={<OutlinedInput />}
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.length === 0 ? (
                                      <em>Select</em>
                                    ) : (
                                      selected.map((value) => {
                                        return (
                                          <Chip
                                            key={value}
                                            label={value}
                                            sx={{ height: "auto" }}
                                          />
                                        );
                                      })
                                    )}
                                  </Box>
                                )}
                                size="small"
                                MenuProps={MenuProps}
                              >
                                <ListSubheader sx={{background:'#fff', position:'sticky', top:'0px', padding:'8px 15px'}}>
                                      <StyledTextField 
                                      size="small" 
                                      fullWidth
                                      onChange={(event) => {
                                        if(event.target.value.length > 3 ){
                                            userListPayload.searchText = event.target.value;
                                            getUserListNew();
                                        }else if(event.target.value.length === 0){
                                            userListPayload.searchText = '';
                                            getUserListNew();
                                        }
                                      }}
                                      onKeyDown={(e) => e.stopPropagation()}
                                      autoFocus
                                      />
                                </ListSubheader>
                                <MenuItem disabled value="">
                                  <em>Select</em>
                                </MenuItem>
                                {newUserList !== null && newUserList?.length > 0 && 
                                  newUserList.map((name) => {
                                    return (
                                      <MenuItem key={name.id} value={name.userName}>
                                        {name.userName}
                                      </MenuItem>
                                    );
                                  })}
                              </StyledDropDown> */}
                            </StyledFormControl>
                          </Grid>
                          <Grid item lg={4}>
                            <StyledFormControl fullWidth>
                              <StyledFormControlLabel>
                                Search users
                              </StyledFormControlLabel>
                              <Autocomplete
                                  multiple
                                  limitTags={1}
                                  disableCloseOnSelect
                                  id="multiple-limit-tags"
                                  options={list.length > 0 && list}
                                  value={ccUsers !== undefined?ccUsers:[]}
                                  getOptionLabel={(option) => option?.name}
                                  onChange={(event, value) => followersCcChange(value)}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Usertag index={index} option={option} {...getTagProps({ index })}/>
                                    ))
                                  }
                                  //defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                                  renderInput={(params) => (
                                    <StyledTextField {...params} placeholder="Search users" size="small" fullWidth/>
                                  )}
                                />
                              {/* <StyledDropDown
                                multiple
                                displayEmpty
                                value={deleteCc}
                                name="deleteCc"
                                onChange={handleChange}
                                input={<OutlinedInput />}
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.length === 0 ? (
                                      <em>Select</em>
                                    ) : (
                                      selected.map((value) => {
                                        return (
                                          <Chip
                                            key={value}
                                            label={value}
                                            sx={{ height: "auto" }}
                                          />
                                        );
                                      })
                                    )}
                                  </Box>
                                )}
                                size="small"
                                MenuProps={MenuProps}
                              >
                                <ListSubheader sx={{background:'#fff', position:'sticky', top:'0px', padding:'8px 15px'}}>
                                      <StyledTextField 
                                      size="small" 
                                      fullWidth
                                      onChange={(event) => {
                                        if(event.target.value.length > 3 ){
                                            userListPayload.searchText = event.target.value;
                                            getUserListNew();
                                        }else if(event.target.value.length === 0){
                                            userListPayload.searchText = '';
                                            getUserListNew();
                                        }
                                      }}
                                      onKeyDown={(e) => e.stopPropagation()}
                                      autoFocus
                                      />
                                </ListSubheader>
                                <MenuItem disabled value="">
                                  <em>Select</em>
                                </MenuItem>
                                {newUserList !== null && newUserList?.length > 0 && 
                                  newUserList.map((name) => {
                                    return (
                                      <MenuItem key={name.id} value={name.userName}>
                                        {name.userName}
                                      </MenuItem>
                                    );
                                  })}
                              </StyledDropDown> */}
                            </StyledFormControl>
                          </Grid>
                          <Grid item lg={4}>
                            <StyledFormControl fullWidth>
                              <StyledFormControlLabel>
                                Followers(Bcc)
                              </StyledFormControlLabel>
                              <Autocomplete
                                  multiple
                                  limitTags={1}
                                  disableCloseOnSelect
                                  id="multiple-limit-tags"
                                  options={list.length > 0 && list}
                                  value={bccUsers !== undefined?bccUsers:[]}
                                  getOptionLabel={(option) => option?.name}
                                  onChange={(event, value) => followersBCcChange(value)}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Usertag index={index} option={option} {...getTagProps({ index })}/>
                                    ))
                                  }
                                  //defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                                  renderInput={(params) => (
                                    <StyledTextField {...params} placeholder="Search users" size="small" fullWidth/>
                                  )}
                                />
                              {/* <StyledDropDown
                                multiple
                                displayEmpty
                                name="deleteBcc"
                                value={deleteBcc}
                                onChange={handleChange}
                                input={<OutlinedInput />}
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.length === 0 ? (
                                      <em>Select</em>
                                    ) : (
                                      selected.map((value) => {
                                        return (
                                          <Chip
                                            key={value}
                                            label={value}
                                            sx={{ height: "auto" }}
                                          />
                                        );
                                      })
                                    )}
                                  </Box>
                                )}
                                size="small"
                                MenuProps={MenuProps}
                              >
                               <ListSubheader sx={{background:'#fff', position:'sticky', top:'0px', padding:'8px 15px'}}>
                                      <StyledTextField 
                                      size="small" 
                                      fullWidth
                                      onChange={(event) => {
                                        if(event.target.value.length > 3 ){
                                            userListPayload.searchText = event.target.value;
                                            getUserListNew();
                                        }else if(event.target.value.length === 0){
                                            userListPayload.searchText = '';
                                            //getUserListNew();
                                        }
                                      }}
                                      onKeyDown={(e) => e.stopPropagation()}
                                      autoFocus
                                      />
                                </ListSubheader>
                                <MenuItem disabled value="">
                                  <em>Select</em>
                                </MenuItem>
                                {newUserList !== null && newUserList?.length > 0 && 
                                  newUserList.map((name) => {
                                    return (
                                      <MenuItem key={name.id} value={name.userName}>
                                        {name.userName}
                                      </MenuItem>
                                    );
                                  })}
                              </StyledDropDown> */}
                            </StyledFormControl>
                          </Grid>
                        </Grid>
                      </div>
                    </CardContent>
                    <CardActions>
                      <Stack
                        direction={"row"}
                        justifyContent={"flex-end"}
                        width="100%"
                      >
                        <SoftButton
                          size="small"
                          //type="button"
                          variant="contained"
                          className="success"
                          color="success"
                          disableElevation
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading ? <ScaleLoader height={12} color="#fff"/> : 'Save'}
                        </SoftButton>
                      </Stack>
                    </CardActions>
                  </StyledCardShadow>
                </form>
                }
                {
                  settings === 5 &&  <form onSubmit={handleSubmit(onSubmit)}>
                  <StyledCardShadow>
                    <CardHeader
                      title={
                        <StyledTableTitle sx={{ display: "block" }}>
                          NOTIFY WHEN HELPDESK TICKET IS EDITED
                        </StyledTableTitle>
                      }
                      //subheader={<Typography variant="caption">Permissions</Typography>}
                    />
                    <CardContent sx={{ py: 0 }}>
                      <div>
                        <Grid container rowSpacing={2}>
                        <HelpdeskSettingOrgDropdown categoryist={selectedCategory} register={register} setValue={setValue} reset={reset} watch={watch} resellerId={resellerId} selectedOrgType={selectedOrgType} selectedField={selectedField} handleChangeDrodown={handleChangeDrodown}/>
                          {/* <Grid item lg={12}>
                            <Grid container spacing={1}>
                              <Grid item lg={6}>
                                <StyledFormControl fullWidth>
                                  <StyledFormControlLabel>
                                    Field
                                  </StyledFormControlLabel>
                                  <StyledDropDown
                                    {...register("field", {})}
                                    onChange={(e) => {
                                      setValue("field", e.target.value);
                                    }}
                                    size="small"
                                    defaultValue={"Assigned"}
                                  >
                                    <MenuItem value="0">Select</MenuItem>
                                    {selectedField.length > 0 &&
                                      selectedField.map((option, index) => {
                                        return (
                                          <MenuItem key={index} value={option.key}>
                                            {option.value}
                                          </MenuItem>
                                        );
                                      })}
                                  </StyledDropDown>
                                </StyledFormControl>
                              </Grid>
                              <Grid item lg={6}>
                                <StyledFormControl fullWidth>
                                  <StyledFormControlLabel>
                                    Organization Type
                                  </StyledFormControlLabel>
                                  <StyledDropDown
                                    {...register("orgType", {})}
                                    onChange={(e) => {
                                      setValue("orgType", e.target.value);
                                    }}
                                    size="small"
                                    defaultValue={"2"}
                                  >
                                    <MenuItem value="0">Select</MenuItem>
                                    {selectedOrgType.length > 0 &&
                                      selectedOrgType.map((option, index) => {
                                        return (
                                          <MenuItem key={index} value={option.key}>
                                            {option.value}
                                          </MenuItem>
                                        );
                                      })}
                                  </StyledDropDown>
                                </StyledFormControl>
                              </Grid>
                            </Grid>
                          </Grid> */}
                          <Grid item lg={4}>
                            <StyledFormControl fullWidth>
                              <StyledFormControlLabel>To </StyledFormControlLabel>
                              <Autocomplete
                                  multiple
                                  limitTags={1}
                                  disableCloseOnSelect
                                  id="multiple-limit-tags"
                                  options={list.length > 0 && list}
                                  value={createToUsers !== undefined?createToUsers:[]}
                                  getOptionLabel={(option) => option?.name}
                                  onChange={(event, value) => createToChange(value)}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Usertag index={index} option={option} {...getTagProps({ index })}/>
                                    ))
                                  }
                                  //defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                                  renderInput={(params) => (
                                    <StyledTextField {...params} placeholder="Search users" size="small" fullWidth/>
                                  )}
                                />
                              {/* <StyledDropDown
                                multiple
                                displayEmpty
                                value={editTo}
                                name="editTo"
                                onChange={handleChange}
                                input={<OutlinedInput />}
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.length === 0 ? (
                                      <em>Select</em>
                                    ) : (
                                      selected.map((value) => {
                                        return (
                                          <Chip
                                            key={value}
                                            label={value}
                                            sx={{ height: "auto" }}
                                          />
                                        );
                                      })
                                    )}
                                  </Box>
                                )}
                                size="small"
                                MenuProps={MenuProps}
                              >
                               <ListSubheader sx={{background:'#fff', position:'sticky', top:'0px', padding:'8px 15px'}}>
                                      <StyledTextField 
                                      size="small" 
                                      fullWidth
                                      onChange={(event) => {
                                        if(event.target.value.length > 3 ){
                                            userListPayload.searchText = event.target.value;
                                            //getUserListNew();
                                        }else if(event.target.value.length === 0){
                                            userListPayload.searchText = '';
                                            //getUserListNew();
                                        }
                                      }}
                                      onKeyDown={(e) => e.stopPropagation()}
                                      autoFocus
                                      />
                                </ListSubheader>
                                <MenuItem disabled value="">
                                  <em>Select</em>
                                </MenuItem>
                                {newUserList !== null && newUserList?.length > 0 && 
                                  newUserList.map((name) => {
                                    return (
                                      <MenuItem key={name.id} value={name.userName}>
                                        {name.userName}
                                      </MenuItem>
                                    );
                                  })}
                              </StyledDropDown> */}
                            </StyledFormControl>
                          </Grid>
                        </Grid>
                      </div>
                    </CardContent>
                    <CardActions>
                      <Stack
                        direction={"row"}
                        justifyContent={"flex-end"}
                        width="100%"
                      >
                        <SoftButton
                          size="small"
                          //type="button"
                          variant="contained"
                          className="success"
                          color="success"
                          disableElevation
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading ? <ScaleLoader height={12} color="#fff"/> : 'Save'}
                        </SoftButton>
                      </Stack>
                    </CardActions>
                  </StyledCardShadow>
                </form>
                }
                {
                  settings === 6 && <Escalation orgType={selectedOrgType} userList={userList} categoryist={selectedCategory} register={register} setValue={setValue} reset={reset} watch={watch} resellerId={resellerId} selectedOrgType={selectedOrgType} selectedField={selectedField} handleChangeDrodown={handleChangeDrodown} list={list} userLevelHandleChange={userLevelHandleChange} level1={level1User} level2={level2User} level3={level3User} level4={level4User} level5={level5User} level6={level6User} handleSubmit={handleSubmit} onSubmit={onSubmit} escalationTimeData={escalationTimeData}/>
                }
                 {
                  settings === 7 && <AdminFilter />
                }
            </Grid>
        </Grid>
        {isSuccess.isSuccess && (
        <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
      )}


    </StyledPageLayout>

  );
}
