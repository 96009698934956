import { Autocomplete, Avatar, Box, CardMedia, Grid, Icon, IconButton, InputBase, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Stack, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState, useLayoutEffect } from 'react'
import { FileUploadBox, SoftButton, StyledFilterFormControl, StyledFormControl, StyledFormControlLabel, StyledTextField } from '../../../theme/StyledEle'
import { FormControl } from '@mui/material'
import { RiUploadCloud2Fill } from 'react-icons/ri'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useCategoryListQuery, useCreateTicketMutation, useGetVehicleListByOrgMutation, useGetVehicleListMutation } from '../../../store/slice/Helpdesk/HelpDeskSlice'
import { AiOutlineDelete } from 'react-icons/ai'
import { BsImageAlt } from 'react-icons/bs'
import { useGetDropdownListMutation, useGetVehicleDropdownListMutation } from '../../../store/slice/ApiSlice'

import { snackService } from '../../../store/slice/ControlsSlice'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import ScaleLoader from 'react-spinners/ScaleLoader'
import SingleDropdownHelpDesk from "../../NewDropdown/SingleDropdownHelpDesk";
import { useCallback } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import moment from "moment";
import NewDropdown from '../../../Utils/NewDropdown'
import ImageUpload from '../../../Utils/ImageUpload'

function CreateTicket(props) {

  const { getDrop, createLoading, createTicketNew } = props

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let orgType = userData.userOrgType;
  let userName = userData.userName;
  let value = 0;
  let userOrgType = userData?.userOrgType;
  //Create State
  const [editorValue, setEditorValue] = useState('');
  const [category, setCategory] = useState(resellerId === 110224 ? 'Vehicle Breakdown' : 'Service');
  const [type, setType] = useState(0);
  const [status, setStatus] = useState('Open');
  const [servity, setServity] = useState('Medium');
  const [title, setTitle] = useState(null);
  const [incidentDateTime, setIncidentDateTime] = useState(moment(new Date()).format("yyyy/MM/DD HH:mm"));
  const [incidentCloseDateTime, setIncidentCloseDateTime] = useState(moment(new Date()).format("yyyy/MM/DD HH:mm"));
  const [incidentLocation, setIncidentLocation] = useState("");
  const [driverNumber, setDriverNumber] = useState("");
  const [tempDate, setTempDate] = useState();
  
  const [typeList, setTypeList] = useState(null);
  //Hide State
  const [serviceShow, setServiceShow] = useState(false);
  const [issueShow, setIssueShow] = useState(false);

  //Image Upload array
  const [upload, setUpload] = useState([]);
  const [filedet, setFile] = useState([]);


  const [images, setImages] = useState([]);
  const fileHandleChange = (imageList, addUpdateIndex) => {
      setImages(imageList);
  }
  


  //get Vehicle List
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedVeh, setSelectedVeh] = useState(null);
  const [closeType, setCloseType] = useState(false);

  let vehicleNo = [];
  let vehicleId = [];
  let vehicleChassisNo = [];
  const { data:categoryListData, categorySuccess } = useCategoryListQuery();

  const [getVehicleLst, { data: vehiclesData }] = useGetVehicleListMutation();
  const [validate, setValidate] = useState(false);
  const [allOption, setAllOption] = useState(true);

  const [region, setRegion] = useState(0);
  const [dealer, setDealer] = useState(0);
  const [customer, setCustomer] = useState(0);
  const [fleet, setFleet] = useState(0);
  const [vehicle, setVehicle] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [selectVehicle, setSelectVehicle] = useState(null);
  const [search, setSearch] = useState(false);
  const [customerLists, setCustomerLists] = useState(null);
  const [regionList, setRegionList] = useState(null);
  const [dealerList, setDealerList] = useState(null);
  const [customerList, setCustomerList] = useState(null);
  const [fleetList, setFleetList] = useState(null);
  const [vehicleList, setVehicleList] = useState(null);
  
  
  const [ getRegionList, { data: regionData, isLoading: regionLoading, isSuccess: regionSuccess } ] = useGetDropdownListMutation();
  const [ getDealerList, { data: dealerdata, isLoading: dealerLoading, isSuccess: dealerSuccess }, ] = useGetDropdownListMutation();
  const [ getCustomerList, { data: customerdata, isLoading: customerLoading, isSuccess: customerSuccess, } ] = useGetDropdownListMutation();
  const [ getFleetList, { data: fleetdata, isLoading: fleetLoading, isSuccess: fleetSuccess } ] = useGetDropdownListMutation();
  const [ getVehicleList, { data: vehicledata, isLoading: vehicleLoading, isSuccess: vehicleSuccess }, ] = useGetVehicleDropdownListMutation();

  const [ getVehicleSearch, { data: searchVehicles, isLoading: searchVehicleLoading, isSuccess: searchVehicleSuccess, } ] = useGetVehicleDropdownListMutation();



  const handleChange = (event, regiterVal, all) => {
    if(regiterVal === 'region'){
        getDealer(event.target.value,all, null);
    }else if(regiterVal === 'dealer'){
        getCustomer(event.target.value,all,null);
    }else if(regiterVal === 'customer'){
        getFeet(event.target.value, all, null);
    }else if(regiterVal === 'fleet'){
        getVehicle(event.target.value, all, null);
    }else if(regiterVal === 'vehicle'){
        handleVehicle(event.target.value, all, null);
    }   
}



    //dropdown

    let CommonPayload = {
      resellerId: resellerId,
    };

  let vehilePayload = {
  value: "orgId",
  searchText: ""
  };

  //Region List Loaded
  let regionPayload = {
      value: "region",
      searchText: "",
  };

  const getRegion = async () => {
      let regionList = await getRegionList(regionPayload);
      if(regionList?.data !== undefined && regionList?.data?.data?.content?.length > 0 && regionList?.data?.data !== null){
          setRegionList(regionList?.data?.data?.content);
      }else{
          setRegionList(null);
          setRegion(404);
          setDealer(404);
          setCustomer(404);
          setFleet(404);
          setVehicle(404);
          setDealerList(null);
          setCustomerList(null);
          setFleetList(null);
          setVehicleList(null);
      }
  }


  const getDealer = async (regionId,all, selectVehicle) => {
          setRegion(regionId);
        
          CommonPayload.value = "dealer";
          CommonPayload.regionId = regionId;
          CommonPayload.dealerId = "0";
          CommonPayload.searchText = "";
      if(regionId !== '-1' && regionId !== 0){
          if(!all){
              const dealers = await getDealerList(CommonPayload);
              if(dealers.data !== undefined && dealers.data !== null){
                  if(dealers?.data !== undefined && dealers?.data?.data?.content?.length > 0 && dealers?.data?.data !== null){
                      setDealerList(dealers?.data?.data?.content);
                      if(selectVehicle === null){
                          getCustomer(dealers.data?.data?.content[0].key, false, selectVehicle);
                          
                      }else if(selectVehicle !== null){
                          getCustomer(selectVehicle[0].dealerId, false, selectVehicle);
                          setDealer(selectVehicle[0].dealerId);
                     }
                  }else{
                      setDealerList(null);
                      setDealer(404);
                      setCustomer(404);
                      setFleet(404);
                      setVehicle(404);
                      setCustomerList(null);
                      setFleetList(null);
                      setVehicleList(null);
                  }
                  
                  
              }
          }
      }else{
          setDealer(0);
          setCustomer(0);
          setFleet(0);
          setVehicle(0);
          setVehicleList(null);
          setFleetList(null);
          setCustomerList(null);
          setDealerList(null);
          setSelectVehicle(null);
          setSearch(false);
      }
  }

  const getCustomer = async (dealerId,all, selectVehicle) => {
      setDealer(dealerId);
      CommonPayload.value = "customer";
      CommonPayload.dealerId = dealerId;
      CommonPayload.searchText = "";
      if(dealerId !== '-1' && dealerId !== 0){
          if(!all){
              const customers = await getCustomerList(CommonPayload);
              if(customers.data !== undefined && customers.data !== null){
                  if(customers?.data !== undefined && customers?.data?.data?.content?.length > 0 && customers?.data?.data !== null){
                      setCustomerList(customers?.data?.data?.content);
                  }else{
                      setCustomerList(null);
                      setCustomer(404);
                      setFleet(404);
                      setVehicle(404);
                      setFleetList(null);
                      setVehicleList(null);
                  }
                  if(selectVehicle === null){
                      getFeet(customers.data?.data?.content[0].key, false, selectVehicle);
                  }else if(selectVehicle !== null){
                      getFeet(selectVehicle[0].customerId, false, selectVehicle);
                      setCustomer(selectVehicle[0].customerId);
                 }
                  
              }
          }
      }else{
          setCustomer(0);
          setFleet(0);
          setVehicle(0);
          setVehicleList(null);
          setFleet(null);
          setCustomerList(null);
          setSelectVehicle(null);
          setSearch(false);
      }
  }

  const getFeet = async (customerId, all, selectVehicle) => {
      setCustomer(customerId);
      CommonPayload.value = "fleet";
      CommonPayload.customerId = customerId;
      CommonPayload.searchText = "";
      CommonPayload.regionId = '0';
      CommonPayload.dealerId = "0";
      if(customerId !== '-1' && customerId !== 0){
          if(!all){
              const fleets = await getFleetList(CommonPayload);
              if(fleets.data !== undefined && fleets.data !== null){
                  if(fleets?.data !== undefined && fleets?.data?.data?.content?.length > 0 && fleets?.data?.data !== null){
                      setFleetList(fleets?.data?.data?.content);
                  }else{
                      setFleetList(null);
                      setFleet(404);
                      setVehicle(404);
                      setVehicleList(null);
                  }
                  if(selectVehicle === null){
                      getVehicle(fleets.data?.data?.content[0].key, false, selectVehicle);
                  }else if(selectVehicle !== null){
                      getVehicle(selectVehicle[0].fleetId, false, selectVehicle);
                      setFleet(selectVehicle[0].fleetId);
                 }
                 
              }
          }
      }else{
          setFleet(0);
          setVehicle(0);
          setVehicleList(null);
          setFleet(null);
          //setSelectVehicle(null);
          setSearch(false);
      }
  }

  const getVehicle = async (fleetId, all, selectVehicle) => {
      setFleet(fleetId);
      vehilePayload.paramValue = fleetId;
      if(fleetId !== '-1' && fleetId !== 0){
          if(!all){
              const vehicles = await getVehicleList(vehilePayload);
              if(vehicles.data !== undefined && vehicles.data !== null){
                  if(vehicles?.data !== undefined && vehicles?.data?.data?.content?.length > 0 && vehicles?.data?.data !== null){
                      setVehicleList(vehicles?.data?.data?.content);
                  }else{
                      setVehicleList(null);
                      setFleet(404);
                      setVehicle(404);
                  }
                  if(selectVehicle === null){
                      handleVehicle(vehicles.data?.data?.content[0].key, false, selectVehicle);
                  }
              }
          }
      }else{
          setVehicle(0);
          setSearch(false);
      }
  }

  const handleVehicle = (vehicleId, all, selectVehicle) => {
      setVehicle(vehicleId);
      if(search && vehicleId !== '-1' && vehicleList !== null){
          const selectvehicle = vehicleList.filter((vehicle, index) => vehicle.key === vehicleId);
          setSelectVehicle(selectvehicle);
          prepopulate(selectvehicle);
          
      }
  }

  //prepopulate
  const prepopulate = (selectvehicle) => {
      if(selectvehicle !== undefined && selectvehicle !== null && selectvehicle?.length > 0){
          setRegion(selectvehicle[0].regionId);
          setVehicle(selectvehicle[0].key);
          setDealer(selectvehicle[0].dealerId);
          getDealer(selectvehicle[0].regionId, false, selectvehicle);
          setSearchText('');
      }
  }
  

      //Onload
    useEffect(() => {
        getRegion();
    },[])

    
    //onSearch
    const onSearch = (event) => {
      setSearchText(event.target.value);
      vehicleSearch(event.target.value);
  }

  const vehicleSearch = async(searchText) => {
      let vehicleSeatchPayload = {
          resellerId: resellerId,
          searchText: searchText,
          page: "0",
          size: "10",
          orgType: userOrgType,
          dealerId: orgId,
          vehicleAllFilter: true,
          imeiSearch: "",
      };
      if(searchText !== '' && searchText?.length > 0){
          setSearch(true);
          let searchVehicle = await getVehicleSearch(vehicleSeatchPayload);
          if(searchVehicle?.data !== undefined && searchVehicle?.data?.data !== null && searchVehicle.data?.data?.content?.length > 0){
              setVehicleList(searchVehicle?.data?.data?.content);
          }else{
              setVehicleList(null);
          }
      }else{
          setSearch(false);
      }
  }



  //Drodowns
  let Getcustomer = {
    "resellerId": resellerId,
    "limit": 500,
    "searchText": "",
    'dealerId': 0
  }
  //Customer Login Payload
  let CustomerPayload = {
    resellerId: resellerId, // Set Login Customer ID
    value: "fleet",
    dealerId: "0",
    customerId: orgId,
    searchText: "",
  };

  let categoryPayload = {
    "isMobile": false
  };

  useEffect(async () => {
    setCustomer(0);
    setFleet(0);
    setVehicle(0);
    setVehicleList(null);
    setFleetList(null);
    if (category !== 0) {
      const typeData = categoryListData?.data?.type.filter((item) => item.title !== 'Pause' && item.title !== 'Resume' && item.category === 'Service' ? item.category === category : (item.category === 'Issue' || item.category === 'Feature Request' || item.category === 'Vehicle Breakdown' || item.category === 'Charger Breakdown' || item.category === 'General Complaint' || item.category === 'Scheduled Service' || item.category === 'Telematics Issue' || item.category === 'Accident') && item.category === category);
      setTypeList(typeData);
      setType(0);
    } else {
      setTypeList(null);
    }
    if (category === 'Service') {
      setServiceShow(true);
      setIssueShow(false);
      setSelectedVehicle([]);
      getVehicless();
    } else if (category === 'Issue') {
      setServiceShow(false);
      setIssueShow(true);
      setCustomerLists(null);
      Getcustomer.dealerId = 0;
      
      //Customer Login Payload
      let CustomerPayload = {
        resellerId: resellerId, // Set Login Customer ID
        value: "fleet",
        dealerId: "0",
        customerId: orgId,
        searchText: "",
      };
      if (orgType === 3) {
        CommonPayload.value = "fleet";
        CommonPayload.dealerId = "0";
        CommonPayload.customerId = orgId;
        CommonPayload.regionId = '0';
        CommonPayload.searchText = "";
        getFleetList(CommonPayload);
        if (allOption && selectedVehicle === null && orgType !== 3) {
          setFleet("-1");
          setVehicle("-1");
          setFleetList(null);
          setVehicleList(null);
        }
      } else {
        Getcustomer.value = 'customer';
        Getcustomer.customerId = value;
        const customerList = await getCustomerList(Getcustomer);
        setCustomerLists(customerList.data.data.content);
      }
      
    } else if (category === 'Vehicle Breakdown' || category === 'Charger Breakdown' || category === 'General Complaint' || category === 'Scheduled Service' || category === 'Telematics Issue' || category === 'Accident') {
      if(category==="Accident"){
        setCloseType(true);
      }else{
        setCloseType(false);
      }
      setServiceShow(false);
      setIssueShow(true);
      setCustomerLists(null);
      Getcustomer.dealerId = 0;
      //Customer Login Payload
      let CustomerPayload = {
        resellerId: resellerId, // Set Login Customer ID
        value: "fleet",
        dealerId: "0",
        customerId: orgId,
        searchText: "",
      };
      if (orgType === 3) {
      CommonPayload.value = "fleet";
        CommonPayload.dealerId = "0";
        CommonPayload.customerId = orgId;
        CommonPayload.regionId = '0';
        CommonPayload.searchText = "";
        getFleetList(CommonPayload);
        if (allOption && selectedVehicle === null && orgType !== 3) {
          setFleet("-1");
          setVehicle("-1");
          setFleetList(null);
          setVehicleList(null);
        }

      } else {
        Getcustomer.value = 'customer';
        Getcustomer.customerId = value;
      }

    } else {
      setIssueShow(false);
      setServiceShow(false);
    }
  }, [category,categoryListData])


  //Set default value here
  useEffect(() => {
    if (categorySuccess) {
      setStatus(categoryListData?.data?.status[0]?.value);
    }
  }, [categorySuccess])

  let Vehiclepayload = {
    "orgId": resellerId,
    "orgType": 2,
    "roleId": 6,
    "id": userId,
    "limit": 50,
    'searchText': '',
  }


  //service search
  const getSearchVehicleService = async (event, value) => {
    Vehiclepayload.searchText = value;
    const vehicles = await getVehicleLst(Vehiclepayload);
    if (vehicles?.data !== null) {
      setVehicleList(vehicles?.data?.data);
    } else {
      setVehicleList([]);
    }
  }


  //getVehicle for service
  const getVehicless = async () => {
    if (category === "Service") {
      const vehicles = await getVehicleLst(Vehiclepayload);
      if (vehicles?.data !== null) {
        setVehicleList(vehicles?.data?.data);
      } else {
        setVehicleList([]);
      }
    } else {
      setVehicleList(null);
      const vehicles = await getVehicleList(Vehiclepayload);
      if (vehicles?.data !== null) {
        setVehicleList(vehicles.data);
      } else {
        setVehicleList([]);
      }
    }
  }


  //Vehicle Convert
  const convertToString = async () => {
    selectedVehicle !== null && selectedVehicle.forEach((key, index) => {
      vehicleNo.push(key.vehicleNo);
      vehicleId.push(key.vehicleId);
      vehicleChassisNo.push(key.chassisNo);
    })
  }


  //Save Payload
  let createPayload = {
    orgId: orgId,
    id: userId,
    orgType: orgType,
    createdBy: userId,
    subOrgType: 0,
  }


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();


  //Save Ticket
  const handleSubmit = async (event) => {
    const { target } = event;
    let imgData = {}
    imgData = Object.fromEntries(new FormData(target));
    //Add
    createPayload.categoryval = category;
    createPayload.typeval = type;
    createPayload.statusval = status;
    createPayload.servityval = servity;
    createPayload.title = title;
    createPayload.file = {};
    createPayload.vehicleNo = "";
    createPayload.chassisNo = "";
    var checkValue=true;
    var checkValueSec=true;
    if (category === "Service") {
      convertToString();
      createPayload.vehicleNo = vehicleNo.toString();
      createPayload.chassisNo = vehicleChassisNo.toString();
      createPayload.vehicleId = category !== 'Issue' ? (vehicleId.toString() === "" ? ((category === 'Service' || category === 'Feature Request') ? vehicleId.toString() : vehicle) : vehicleId.toString()) : vehicle;
      createPayload.vehicleOrgId = createPayload.vehicleId;
    } else {

      let result = Array.isArray(selectedVeh !== null ? selectedVeh?.data : "");
      if (result === false) {
        if(createPayload.vehicleId!==undefined && createPayload.vehicleId!=="0" && createPayload.vehicleId!=="-1"){
          createPayload.vehicleNo = selectedVeh !== null ? selectedVeh?.data?.data[0]?.vehicleNo?.toString() : "";
          createPayload.chassisNo = selectedVeh !== null ? selectedVeh?.data?.data[0]?.chassisNo?.toString() : "";
        }
      } else {
        if(createPayload.vehicleId!==undefined && createPayload.vehicleId!=="0" && createPayload.vehicleId!=="-1"){
          createPayload.vehicleNo = selectedVeh !== null ? selectedVeh?.data[0]?.vehicleNo?.toString() : "";
          createPayload.chassisNo = selectedVeh !== null ? selectedVeh?.data[0]?.chassisNo?.toString() : "";
        }
      }
      createPayload.vehicleId = category !== 'Issue' ? (vehicleId.toString() === "" ? ((category === 'Service' || category === 'Feature Request') ? vehicleId.toString() : vehicle) : vehicleId.toString()) : vehicle;
      createPayload.vehicleId = createPayload.vehicleId === "-1" ? "0" : createPayload.vehicleId;
      createPayload.vehicleOrgId = parseInt(createPayload.vehicleId);      
    }

    if(orgType !== 9 && orgType !== 5 && orgType !== 3 && orgType !== 1){
      if(region==="-1" || dealer==="-1"){
        if(category==="Vehicle Breakdown" || category==="Charger Breakdown" || category==="General Complaint" || category==="Scheduled Service" || category==="Accident"){
          checkValueSec=false;
        }
      }      
    }

    if(category!=="Telematics Issue" ){
      if(createPayload.vehicleId===undefined || createPayload.vehicleId==="-1" || createPayload.vehicleId==="0"){
        if(category==="Vehicle Breakdown" || category==="Charger Breakdown" || category==="General Complaint" || category==="Scheduled Service" || category=== "Accident" ){
          checkValue=false;
        }        
      }      
    }
     if(checkValue===true){
       if(checkValueSec===true){
        createPayload.description = editorValue;
        createPayload.fleetOrgId = fleet === "-1" && fleet !== 404 ? 0 : parseInt(fleet);
        createPayload.customerOrgId = customer === "-1" && customer !== 404 ? 0 : parseInt(customer);
        createPayload.dealerOrgId = dealer === "-1" && dealer !== 404 ?  0 : parseInt(dealer);
        createPayload.regionId = region === "-1" && region !== 404 ? 0 : parseInt(region);
        createPayload.incidentDateTime = moment(incidentDateTime).format("yyyy-MM-DD HH:mm");
        createPayload.incidentLocation = incidentLocation;
        createPayload.driverNumber = driverNumber;
        let formData = new FormData();
        formData.append(`finaldata`, JSON.stringify(createPayload));
        if (images?.length > 0) {
          for (let i = 0; i < images?.length; i++) {
            formData.append(`files`, images[i].file);
          }
        } else {
          formData.append(`files`, "");
        } 
       
        const responseData = await createTicketNew(formData);
        if (responseData?.data?.resultCode === 0) {
          setUpload([]);
          dispatch(snackService([responseData.data?.resultMessage, responseData?.data?.resultCode, true]));
          navigate(location.pathname);
        } else {
          dispatch(snackService([responseData.data?.resultMessage, responseData.data?.resultCode, true]));
        }
       }else{
         dispatch(snackService(["Please select Region,Dealer before submit", 1, true]));
       }      
       }else{
         dispatch(snackService(["Please select Vehicle before submit", 1, true]));
      }    
  }


  const Validation = (event) => {
    event.preventDefault();
    if (title === null || title === '') {
      dispatch(snackService(['Title Required', 1, true]));
    } else if (editorValue === null || editorValue === '') {
      dispatch(snackService(['Description Required', 1, true]));
    } else {
      handleSubmit(event);
    }
  }

  //-----------------------------------------------------------------------------------------------------------------


  //resetTicket
  const resetTicket = () => {
    setEditorValue('');
    setCategory(resellerId === 110224 ? 'Vehicle Breakdown' : 'Service');
    setType(0);
    setStatus('Open');
    setServity('Medium');
    setTitle('');
    setSelectedVehicle(null);
    setVehicle("-1");
    setCustomer("-1");
    setFleet("-1");
    setDealer("-1");
    setRegion("-1");
    setTypeList(null);
    setUpload([]);
    //setSelectedVehicle([]);
  }

  const handleDateChange = (newValue) => {
    if(moment.utc(moment(newValue, "yyyy/MM/DD HH:mm"))<=moment.utc(moment(new Date(), "yyyy/MM/DD HH:mm"))){
      setTempDate(newValue); 
      var data=moment(newValue).format("yyyy/MM/DD HH:mm");
      setIncidentDateTime(data);
    }else{
      dispatch(snackService(['Incident time should be less then or equal to current time', 1, true]));
    }
  };
 
  const onKeyDown = (e) => {
        e.preventDefault();
    };
  return (
    <form component={'form'} onSubmit={Validation} enctype="multipart/form-data">
      <Box>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item lg={12}>
            <FormControl fullWidth>
              <StyledFormControlLabel required>Ticket Title</StyledFormControlLabel>
              <StyledTextField name={'title'} size='small' placeholder='Enter ticket name' value={title} fullWidth onChange={(event) => setTitle(event.target.value)} />
            </FormControl>
          </Grid>
          <Grid item lg={6}>
            <FormControl fullWidth>
              <StyledFormControlLabel required>Category</StyledFormControlLabel>
              <StyledTextField size='small' placeholder='Enter ticket name' fullWidth select value={category} onChange={(event) => setCategory(event.target.value)}>
                {
                  categoryListData !== undefined &&
                  categoryListData?.data?.category.map((item, index) => <MenuItem value={item.value}>{item.title}</MenuItem>)
                }
              </StyledTextField>
            </FormControl>
          </Grid>

          <Grid item lg={6}>
            <FormControl fullWidth style={{"display":closeType===true?"none":"block"}}>
              <StyledFormControlLabel>Type</StyledFormControlLabel>
              <StyledTextField size='small' placeholder='Enter ticket name' fullWidth select value={type} onChange={(event) => setType(event.target.value)}>
                <MenuItem value={0} sx={{ fontWeight: 300 }}><em>Select</em></MenuItem>
                {
                  typeList !== undefined && typeList !== null &&
                  typeList?.map((item, index) => <MenuItem value={item.value}>{item.title}</MenuItem>)
                }
              </StyledTextField>
            </FormControl>
          </Grid>
          <Grid item lg={6}>
            <FormControl fullWidth>
              <StyledFormControlLabel required>Status</StyledFormControlLabel>
              <StyledTextField size='small' placeholder='Enter ticket name' fullWidth select value={status} onChange={(event) => setStatus(event.target.value)} disabled>
                <MenuItem value={0} sx={{ fontWeight: 300 }}><em>Select</em></MenuItem>
                {
                  categoryListData !== undefined &&
                  categoryListData?.data?.status.map((item, index) => item.value === 'Open' && <MenuItem value={item.value}>{item.title}</MenuItem>)
                }
              </StyledTextField>
            </FormControl>
          </Grid>
          <Grid item lg={6}>
            <FormControl fullWidth>
              <StyledFormControlLabel required>Severity</StyledFormControlLabel>
              <StyledTextField size='small' placeholder='Enter ticket name' fullWidth select value={servity} onChange={(event) => setServity(event.target.value)}>
                {
                  categoryListData !== undefined &&
                  categoryListData?.data?.servity.map((item, index) => <MenuItem value={item.value}>{item.title}</MenuItem>)
                }
              </StyledTextField>
            </FormControl>
          </Grid>
          {/* Show Vehicle Start*/}
          {serviceShow && <Grid item lg={12}>
            <FormControl fullWidth>
              <StyledFormControlLabel>Vehicle</StyledFormControlLabel>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={vehicleList !== null ? vehicleList : []}
                getOptionLabel={(options) => options?.vehicleNo}
                // value={selectedVehicle}
                // onChange={ selectVehicle}
                value={selectedVehicle}
                onChange={(event, value) => setSelectedVehicle(value)}
                onInputChange={(event, value) => getSearchVehicleService(event, value)}
                renderInput={(params) => <TextField {...params} />}
                size='small'
                disableCloseOnSelect
                disableClearable
                multiple
              />
            </FormControl>
          </Grid>
          }
          {/* Show Vehicle Start*/}

          {/* Show Issue Start*/}
          {/* {
             issueShow && <Grid item lg={6}>
                <FormControl fullWidth>
                    <StyledFormControlLabel required>Region</StyledFormControlLabel>
                    <StyledTextField size='small' placeholder='Enter ticket name' fullWidth select value={customer} onChange={(event) => setCustomer(event.target.value)}>
                        <MenuItem value={0} sx={{fontWeight:300}}><em>Select</em></MenuItem>
                        {
                            categoryListData !== undefined &&
                            categoryListData.data.category.map((item, index) => <MenuItem value={item.value}>{item.title}</MenuItem>)
                        }
                    </StyledTextField>
                </FormControl>
            </Grid>
            } */}
          {/* {
             issueShow && <Grid item lg={6}>
                <FormControl fullWidth>
                    <StyledFormControlLabel required>Dealer</StyledFormControlLabel>
                    <StyledTextField size='small' placeholder='Enter ticket name' fullWidth select value={customer} onChange={(event) => setCustomer(event.target.value)}>
                        <MenuItem value={0} sx={{fontWeight:300}}><em>Select</em></MenuItem>
                        {
                            categoryListData !== undefined &&
                            categoryListData.data.category.map((item, index) => <MenuItem value={item.value}>{item.title}</MenuItem>)
                        }
                    </StyledTextField>
                </FormControl>
            </Grid>
            } */}
          <NewDropdown issueShow={issueShow} region={region} dealer={dealer} customer={customer} fleet={fleet} vehicle={vehicle} searchText={searchText} search={search} regionList={regionList} dealerList={dealerList} customerList={customerList} fleetList={fleetList} vehicleList={vehicleList} onSearch={onSearch} handleChange={handleChange}/>
          {/* {orgType !== 9 && orgType !== 5 && orgType !== 3 && orgType !== 1 && (
            <>
              {issueShow && <Grid item lg={6}>
                    <SingleDropdownHelpDesk
                      label={"Region"}
                      listItems={regionList}
                      handleChange={getDealer}
                      value={region}
                      isSearch={dropDownSearch}
                      showAll={allOption}
                      getInnerText={getInnerText}
                    />

              </Grid>
              }
            </>
          )}
          {orgType !== 9 && orgType !== 5 && orgType !== 3 && orgType !== 1 && (
            <>
              {issueShow && <Grid item lg={6}>
                <FormControl fullWidth>
                  <StyledFormControlLabel>

                    <SingleDropdownHelpDesk
                      label={"Dealer"}
                      listItems={dealerList}
                      handleChange={getCustomer}
                      value={dealer}
                      isSearch={dropDownSearch}
                      showAll={allOption}
                    />

                  </StyledFormControlLabel>
                </FormControl>
              </Grid>
              }
            </>
          )}
          {orgType !== 3 && orgType !== 1 && (
            <>
              {issueShow && <Grid item lg={6}>
                <FormControl fullWidth>
                  <StyledFormControlLabel>

                    <SingleDropdownHelpDesk
                      label={"Customer"}
                      listItems={customerList}
                      handleChange={getFleet}
                      value={customer}
                      isSearch={dropDownSearch}
                      showAll={allOption}
                    />

                  </StyledFormControlLabel>
                </FormControl>
              </Grid>
              }
            </>
          )}
          {orgType !== 1 && (
            <>
              {issueShow && <Grid item lg={6}>
                <FormControl fullWidth>
                  <StyledFormControlLabel>

                    <SingleDropdownHelpDesk
                      label={"Fleet"}
                      listItems={fleetList}
                      handleChange={getVehicle}
                      value={fleet}
                      isSearch={dropDownSearch}
                      showAll={orgType === 3 ? false : allOption}
                    />

                  </StyledFormControlLabel>
                </FormControl>
              </Grid>
              }
            </>
          )}
          {(issueShow) && <Grid item lg={6}>
            <FormControl fullWidth>
              <StyledFormControlLabel>
                {(
                  <SingleDropdownHelpDesk
                    label={"Vehicle"}
                    listItems={vehicleList}
                    onClick={() => setSearch(false)}
                    handleChange={selectVehicle}
                    value={selectedVehicle !== null ? selectedVehicle[0]?.key : vehicle}
                    isSearch={dropDownSearch}
                    searchText={searchText}
                    showAll={
                      location.pathname === "/geozone" && vehicleList !== null
                        ? true
                        : allOption
                    }
                  />
                )}


              </StyledFormControlLabel>
            </FormControl>
          </Grid>
          } */}
          {/* {
             issueShow && <Grid item lg={6}>
                <FormControl fullWidth>
                    <StyledFormControlLabel>Customer</StyledFormControlLabel>
                    <StyledTextField size='small' placeholder='Enter ticket name' fullWidth select value={customer} onChange={(event) => getDropdownLists(event.target.value, 'customer')}>
                        <MenuItem value={0} sx={{fontWeight:300}}><em>Select</em></MenuItem>
                        {
                            customerLists !== undefined &&
                            customerLists?.map((item, index) => <MenuItem value={item.key}>{item.value}</MenuItem>)
                        }
                    </StyledTextField>
                </FormControl>
            </Grid>
            }
            {
                issueShow &&
            <Grid item lg={6}>
                <FormControl fullWidth>
                    <StyledFormControlLabel>Fleet</StyledFormControlLabel>
                    <StyledTextField size='small' placeholder='Enter ticket name' fullWidth select value={fleet} onChange={(event) => getDropdownLists(event.target.value, 'fleet')}>
                        <MenuItem value={0} sx={{fontWeight:300}}><em>Select</em></MenuItem>
                        {
                            fleetList !== undefined &&
                            fleetList?.map((item, index) => <MenuItem value={item.key}>{item.value}</MenuItem>)
                        }
                    </StyledTextField>
                </FormControl>
            </Grid>
            }
            {
                issueShow &&
            <Grid item lg={6}>
                <FormControl fullWidth>
                    <StyledFormControlLabel>Vehicle</StyledFormControlLabel>
                    <StyledTextField size='small' placeholder='Enter ticket name' fullWidth select value={vehicle} onChange={(event) => setVehicle(event.target.value)}>
                            <MenuItem value={0} sx={{fontWeight:300}}><em>Select</em></MenuItem>
                            {
                                vehicleList !== undefined &&
                                vehicleList?.map((item, index) => <MenuItem value={item.vehicleId}>{item.title}</MenuItem>)
                            }
                       
                    </StyledTextField>
                </FormControl>
            </Grid>
            } */}
          {/* Show Issue Start*/}
          {(category !== "Service" && category !== "Issue" && category !== "Feature Request") && 
          <>
          <Grid item lg={6}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required >
                  Incident Time 
                  </StyledFormControlLabel>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      inputFormat="dd/MM/yyyy hh:mm a"
                      value={tempDate}
                      onChange={handleDateChange}                     
                        renderInput={(params) => (
                         <StyledTextField
                           fullWidth
                           size="small"
                           onKeyDown={onKeyDown}
                           {...params}
                           readonly
                         />
                       )}                   
                       />
                  </LocalizationProvider>
                </StyledFormControl>
              </Grid>
          </>
            }
          {(category !== "Service" && category !== "Issue" && category !== "Feature Request") && 
          <>
          <Grid item lg={6}>
            <FormControl fullWidth>
              <StyledFormControlLabel>Incident Location</StyledFormControlLabel>
              <StyledTextField name={'title'} size='small' placeholder='Enter Incident Location' value={incidentLocation} fullWidth onChange={(event) => setIncidentLocation(event.target.value)} />
            </FormControl>
          </Grid>
          </>
          }  
          {(category !== "Service" && category !== "Issue" && category !== "Feature Request") && 
          <>
          <Grid item lg={6}>
            <FormControl fullWidth>
              <StyledFormControlLabel>Driver Number</StyledFormControlLabel>
              <StyledTextField name={'title'} size='small' placeholder='Enter Driver Number' value={driverNumber} inputProps={{ maxLength: 15 }} fullWidth onChange={(event) => setDriverNumber(event.target.value)} />
            </FormControl>
          </Grid>
          </>
          }
          {/* Description */}
          <Grid item lg={12}>
            <StyledFormControlLabel required>Description</StyledFormControlLabel>
            <CKEditor
              editor={ClassicEditor}
              data={editorValue}
              onChange={(event, editor) => {
                const editorDator = editor.getData();
                setEditorValue(editorDator);
              }}
            />
          </Grid>

          {/* Start Image Upload */}
          <Grid item lg={12}>
            <ImageUpload images={images} fileHandleChange={fileHandleChange}/>
          </Grid>
          {

          }
        </Grid>
        <Stack direction={'row'} spacing={2} sx={{ mt: 2, width: "100%" }} justifyContent={'center'}>
          <SoftButton variant='contained' color='success' className='success' type='submit' disabled={createLoading} sx={{ cursor: createLoading ? 'wait' : 'pointer' }}>
            {
              createLoading ? <ScaleLoader height={12} color='#fff' /> : 'Save'
            }
          </SoftButton>
          <SoftButton variant='contained' color='error' className='error' onClick={resetTicket}>Reset</SoftButton>
        </Stack>
      </Box>
    </form>
  )
}

export default CreateTicket