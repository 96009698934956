import React, { useEffect, useState } from "react";
import { useLayoutEffect } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { StyledFiterButton, StyledPaperShadow } from "../../../theme/StyledEle";
import SingleDropdown from "../../NewDropdown/SingleDropdown";
import DatepickerDropdown from "../../AdminDropdown/DatepickerDropdown";
import { useGetDropdownListMutation, useGetVehicleDropdownListMutation, useGetAuditUserListMutation } from "../../../store/slice/ApiSlice";
import { useGetDymicAlertsMutation } from "../../../store/slice/AlertSlice/AlertSlice";
import { cellLogTypeData, dealerLogSearchByData, orgTypeData, userLogAccessData } from "./MockData";
import { kycLogProcessingStatusData } from "../../AdminDropdown/MockData";


function AlertDropdown(props) {
  const {
    getData,
    isShowAll,
    allowedDays=7,
    allowedMonth,
    createDateValue="1",
    createDateStatus
  } = props;
  //user data form local storage
  const userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let RoleId = userData.roleId;
  let userOrgType = userData.userOrgType;
  let userId = userData.id;

  let userPayload = {
    resellerId: resellerId,
    searchText: "",
  };

  //Is show all
  const [allOption, setAllOption] = useState(isShowAll);
  const menuList = JSON.parse(localStorage.getItem("menuList"));



  // All service -------------------------------------------------------------------
  const [
    getRegionList,
    { data: regionData, isLoading: regionLoading, isSuccess: regionSuccess },
  ] = useGetDropdownListMutation();
  const [
    getDealerList,
    { data: dealerdata, isLoading: dealerLoading, isSuccess: dealerSuccess },
  ] = useGetDropdownListMutation();
  const [
    getCustomerList,
    {
      data: customerdata,
      isLoading: customerLoading,
      isSuccess: customerSuccess,
    },
  ] = useGetDropdownListMutation();
  const [
    getFleetList,
    { data: fleetdata, isLoading: fleetLoading, isSuccess: fleetSuccess },
  ] = useGetDropdownListMutation();
  const [
    getVehicleList,
    { data: vehicledata, isLoading: vehicleLoading, isSuccess: vehicleSuccess },
  ] = useGetVehicleDropdownListMutation();

  const [
    getVehicleSearch,
    {
      data: searchVehicles,
      isLoading: searchVehicleLoading,
      isSuccess: searchVehicleSuccess,
    },
  ] = useGetVehicleDropdownListMutation();


  const [getAuditUserList, { data: auditUserList, isSuccess: auditUserListSuccess }] =
  useGetAuditUserListMutation(); // Audit Log

  //get Dynaminc Alerts
  const [ getDynamicAlerts, {data:AlertsData, isLoading:AlertsListLoading, isSuccess:AlertsListSuccess} ] = useGetDymicAlertsMutation();



  // const [getVehicleSearch, {data:searchVehicles, isLoading:searchVehicleLoading, isSuccess:searchVehicleSuccess}] = useGetVehicleDropdownListMutation();

  //All States ---------------------------------------------------------------------
  const [region, setRegion] = useState(null);
  const [dealer, setDealer] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [fleet, setFleet] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [alertType, setAlertType] = useState('All');
  const [searchBy, setSearchBy] = useState('All');
  const [cellType, setCellType] = useState('All');

  // All State API Responce List
  const [regionList, setRegionList] = useState(null);
  const [dealerList, setDealerList] = useState(null);
  const [customerList, setCustomerList] = useState(null);
  const [fleetList, setFleetList] = useState(null);
  const [vehicleList, setVehicleList] = useState(null);
  const [alertList, setAlertList] = useState(null);


  const [regionName, setRegionName] = useState("All");
  const [dealerName, setDealerName] = useState("All");
  const [customerName, setCustomerName] = useState("All");
  const [fleetName, setFleetName] = useState("All");
  const [vehicleName, setVehicleName] = useState("All");
  const [alertTypeName, setAlertTypeName] = useState("All");

  const [orgType, setOrgType] = useState("2");
  let [orgTypeList, setOrgTypeList] = useState({ content: orgTypeData });
  const [orgTypeShow, setOrgTypeShow] = useState(false);
  const [userListId, setUserListId] = useState("-1"); // Audit Log
  const [orgTypeName, setOrgTypeName] = useState("Organization");
  const [userName, setUserName] = useState("All");
  const [mainMenuShow, setMainMenuShow] = useState(false); //Audit Log
  const [subMenuShow, setSubMenuShow] = useState(false); //Audit Log
  const [accessShow, setAccessShow] = useState(false); // Audit Log 
  const [mainMenuData, setMainMenuData] = useState({ content: null });
  const [subMenuData, setSubMenuData] = useState({ content: null });
  const [accessData, setAccessData] = useState({ content: null });
  const [mainMenu, setMainMenu] = useState("-1"); // Audit Log
  const [subMenu, setSubMenu] = useState("-1"); // Audit Log
  const [access, setAccess] = useState("All"); // Audit Log / User Log /
  const [status, setStatus] = useState('All');
  const [mainMenuName, setMainMenuName] = useState('All');
  const [subMenuName, setSubMenuName] = useState('All');
  const [accessName, setAccessName] = useState('All');


  //If search and get vehicle
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  //Onload set
  const [load, setLoad] = useState(false);

  //Date Pickers
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD 00:00:00"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD hh:mm:ss"));
  const [activeLabel, setActiveLabel] = useState("today");
  const [label, setLabel] = useState("Today");
  const GetDate = (fromDate, toDate, name) => {
    setFromDate(fromDate);
    setToDate(toDate);
    setLabel(name);
  };

  const [searchText, setSearchText] = useState({ value: null, name: null });
  const [search, setSearch] = useState(false);

  //Login Implemented
  //Based Login Implemented
  const alertDropdown = () => {
    switch (userOrgType) {
      case 2:
        getRegionList(regionPayload);
        break;
      case 5:
        //getRegionList(regionPayload);
        getCustomer(orgId);
      case 3:
        getFleet(orgId);
        setCustomer(orgId);
        break;
      case 1:
          getVehicle(orgId);
          setFleet(orgId);
      break;
    }
  };


//List menu
const getMenuList = () => {
  let mainMenu = [];
  for (let i = 0; menuList.length > i; i++) {
    let menu = {
      value: menuList[i].title,
      key: menuList[i].title,
    };
    mainMenu.push(menu);
  }
  setMainMenuData({ content: mainMenu });
};


//handleMainMenu
const handleMainMenu = (mainMenuId) => {
  setMainMenu(mainMenuId);
  setMainMenuName(mainMenuId === '-1'?"All":mainMenuId);
  if (mainMenuId !== "-1") {
    const subMenu = menuList.filter((item) => item.title === mainMenuId);
    if (subMenu[0].sub.length > 1) {
      setSubMenu("-1");
      let subMenuList = [];
      for (let i = 0; subMenu[0].sub.length > i; i++) {
        let menu = {
          value: subMenu[0].sub[i].title,
          key: subMenu[0].sub[i].title,
        };
        subMenuList.push(menu);
      }
      setSubMenuData({ content: subMenuList });
    } else {
      setSubMenu("-1");
      setSubMenuData({ content: null });
    }
  } else {
    setSubMenu("-1");
    setSubMenuData({ content: null });
  }
};

  //handleSubmenu
  const handleSubmenu = (subMenuId) => {
    setSubMenu(subMenuId);
    setSubMenuName(subMenuId === '-1'?"All":subMenuId);
  };

  //handleAccess
  const handleAccess = (accessId) => {
    setAccess(accessId);
    setAccessName(accessId === '-1'?"All":accessId);
  };

  useEffect(() => {
    alertDropdown();
  }, []);

  useEffect(() => {
    userPayload.orgId = resellerId;
    getAuditUserList(userPayload);
    getMenuList();
  },[])

 

  //Payloads ---------------------------------------------------------------------
  //Common Payloads
  let CommonPayload = {
    resellerId: resellerId,
  };

  //Region List Loaded
  let regionPayload = {
    value: "region",
    searchText: "",
  };

  let roleListPayload = {
    value: "role",
    dealerId: 0,
    searchText: "",
    orgType: orgType,
  };

  //Load Region ------------------------------------------------------------------
  useEffect(() => {
    if (allOption) {
      setRegion("-1");
      AlertCall(resellerId);
    }
  }, []);


  //Alert Data
  const AlertCall = async (fleetId) => {
    try{
      const alertData = await getDynamicAlerts({'orgId': fleetId});
      setAlertList(alertData.data)
    } 
    catch {}
  }


  useEffect(() => {
    if(regionSuccess){
        setRegionList(regionData?.data);
        if(!allOption && selectedVehicle === null){
            getDealer(5);
        }else if(search && selectedVehicle !== null){
            getDealer(selectedVehicle[0].regionId);
        }
    }
    if(allOption && selectedVehicle === null){
        setRegion('-1');
    }
},[regionSuccess])



   //handle Org type 
   const handleOrgType = (orgTypeId) => {
    setOrgType(orgTypeId);
   
    if (orgTypeId === "2") {
      userPayload.orgId = orgId;
      getAuditUserList(userPayload);
    }else if(orgTypeId === "1"){
      userPayload.orgId = fleet;
      getAuditUserList(userPayload);
    }else if(orgTypeId === "3"){
      userPayload.orgId = customer;
      getAuditUserList(userPayload);
    }else if(orgTypeId === "4"){
      userPayload.orgId = fleet;
      getAuditUserList(userPayload);
    }else if(orgTypeId === "5"){
      userPayload.orgId = dealer;
      getAuditUserList(userPayload);
    }
    

  };

  const handleUserList = (userListId) => {
    setUserListId(userListId);

    if(userListId === "-1"){
      if (orgType === "2") {
        userPayload.orgId = orgId;
        getAuditUserList(userPayload);
      }else if(orgType === "1"){
        userPayload.orgId = fleet;
        getAuditUserList(userPayload);
      }else if(orgType === "3"){
        userPayload.orgId = customer;
        getAuditUserList(userPayload);
      }else if(orgType === "4"){
        userPayload.orgId = fleet;
        getAuditUserList(userPayload);
      }else if(orgType === "5"){
        userPayload.orgId = dealer;
        getAuditUserList(userPayload);
      }
    }
  };

//getDealer
const getDealer = (regionId) => {
    setRegion(regionId);
   
    if(regionId !== '-1'){
        CommonPayload.value = 'dealer';
        CommonPayload.regionId = regionId;
        CommonPayload.dealerId = '0';
        CommonPayload.searchText = '';
        getDealerList(CommonPayload);
        if(allOption && selectedVehicle === null){
            setDealer('-1');
            setCustomer('-1');
            setFleet('-1');
            setVehicle('-1');
            setDealerList(null);
            setCustomerList(null);
            setFleetList(null);
            setVehicleList(null);
        }
    }else{
       setDealer('-1');
       setCustomer('-1');
       setFleet('-1');
       setVehicle('-1');
       setDealerList(null);
       setCustomerList(null);
       setFleetList(null);
       setVehicleList(null);
       setSelectedVehicle(null);
       AlertCall(resellerId);
    }
}
useLayoutEffect(() => {
    if(dealerSuccess){
        setDealerList(dealerdata?.data);
        if(!allOption && selectedVehicle === null){
          getCustomer(dealerdata?.data.content[0]?.key);
        }else if(search && selectedVehicle !== null ){
          getCustomer(selectedVehicle[0]?.dealerId);
        }
    }
    if(allOption && selectedVehicle === null){
        setDealer('-1');
    }
},[dealerSuccess])

//getCustomer
const getCustomer = (dealerId) => {
    setDealer(dealerId);
    if(dealerId !== '-1'){
        CommonPayload.value = 'customer';
        CommonPayload.dealerId = dealerId;
        CommonPayload.searchText = '';
        getCustomerList(CommonPayload);
        if(orgType ==="5"){
          userPayload.orgId=dealerId;
          getAuditUserList(userPayload);
        }
        if(allOption && selectedVehicle === null){
            setCustomer('-1');
            setFleet('-1');
            setVehicle('-1');
            setCustomerList(null);
            setFleetList(null);
            setVehicleList(null);
        }
    }else{
       setCustomer('-1');
       setFleet('-1');
       setVehicle('-1');
       setCustomerList(null);
       setFleetList(null);
       setVehicleList(null);
       setSelectedVehicle(null);
       AlertCall(resellerId);
    }
   
}
useLayoutEffect(() => {
    if(customerSuccess){
      if (userOrgType === 3) {
        let customerName = customerdata?.data.content.filter(
          (id) => parseInt(id.key) === orgId
        );
        setCustomerList({ content: customerName });
        setCustomer(customerName[0].key);
        getFleet(customerName[0].key);
      } else {
        setCustomerList(customerdata?.data);
      }

        if(!allOption && selectedVehicle === null && userOrgType !== 3){
            getFleet(customerdata?.data?.content[0]?.key);
        }else if(search && selectedVehicle !== null ){
            getFleet(selectedVehicle[0]?.customerId);
        }
        }
        if(allOption && selectedVehicle === null){
            setCustomer('-1');  
        }
},[customerSuccess])


//getFleet
const getFleet = (customerId) => {
    setCustomer(customerId);
    if(customerId !== '-1'){
        CommonPayload.value = 'fleet';
        CommonPayload.dealerId = '0';
        CommonPayload.customerId = customerId;
        CommonPayload.searchText = '';
        getFleetList(CommonPayload);
        if(orgType ==="3"){
          userPayload.orgId=customerId;
          getAuditUserList(userPayload);
        }
        if(allOption && selectedVehicle === null ){
            setFleet('-1');
            setVehicle('-1');
            setFleetList(null);
            setVehicleList(null);
        }
    }else{
        setFleet('-1');
        setVehicle('-1');
        setFleetList(null);
        setVehicleList(null);
        setSelectedVehicle(null);
        AlertCall(resellerId);
    }
   
}

useLayoutEffect(() => {
    if(fleetSuccess){
        setFleetList(fleetdata?.data);
        if(!allOption && selectedVehicle === null){
            getVehicle(fleetdata?.data?.content[0]?.key)
            AlertCall(fleetdata?.data?.content[0]?.key);
        }else if(search && selectedVehicle !== null ){
            getVehicle(selectedVehicle[0]?.fleetId);
        }
        if(userOrgType === 3){
            getVehicle(fleetdata?.data?.content[0]?.key);
        }}
    if(allOption && selectedVehicle === null){
        setFleet('-1');
    }
},[fleetSuccess]);



let vehilePayload = {
  value: "orgId",
  searchText: ""
};

//Get Vehicle
const getVehicle = (fleetId) => {
    setFleet(fleetId);
    AlertCall(fleetId);
    if(fleetId !== '-1'){
      vehilePayload.paramValue = fleetId;
      if(search){
        vehilePayload.headerValue = vehicle;
        vehilePayload.param = 'search';
      }
      getVehicleList(vehilePayload);

      if(orgType ==="1"){
        userPayload.orgId=fleetId;
        getAuditUserList(userPayload);
      }


    }else{
        setVehicle('-1');
        setVehicleList(null);
        setSelectedVehicle(null);
    }
}
useLayoutEffect(() => {
    if(vehicleSuccess){
        setVehicleList(vehicledata?.data);
      if(search){
            setSearch(false);
        }
        if(!allOption && selectedVehicle === null){
            selectVehicle(vehicledata?.data?.content[0]?.key);
            
        }
        if(fleet !== undefined && (userOrgType === 3 || userOrgType === 5) && fleet !== null){
            setLoad(true);
        }
    }
    if(allOption && selectedVehicle === null){
        setVehicle('-1');
        setLoad(true);
    }
    if(userOrgType !== 3){
        setLoad(true);
    }
},[vehicleSuccess]);

  useEffect(() => {
    if(region !== null && !allOption && vehicle !== null && pathname !== "dealer"){
      setLoad(true);
    }else if(region === '-1' && pathname !== "dealer"){
      setLoad(true);
    }
  },[vehicle])


//Select Vehicle
const selectVehicle = (vehicleId, data) => {
    //setSelectedVehicle(null);
    setVehicle(vehicleId);
    if(search && vehicleId !== '-1' && vehicleId !== undefined ){
        const SelctedVehicleData = searchVehicles?.data?.content.filter((item) => item.key === vehicleId);
        if(!allOption && search){
            setRegion(SelctedVehicleData[0]?.regionId);
            getDealer(SelctedVehicleData[0]?.regionId);
        }
        if(allOption && search){
            setRegion(SelctedVehicleData[0]?.regionId);
            getDealer(SelctedVehicleData[0]?.regionId);
            setVehicle(SelctedVehicleData[0]?.key)
        }
        setSelectedVehicle(SelctedVehicleData);
        //getVehicle(SelctedVehicleData[0].fleetId);
        setSearchText({value:null});
    }
    if(vehicleId === '-1' && selectedVehicle !== null){
        setSelectedVehicle(null);
        getVehicle(fleet);
        setVehicle('-1');
    }
   
}


useEffect(() => {
    if(!search){
        setSelectedVehicle(null);
    }
},[search]);



const getInnerText = (value, label) =>{
  if(label === "Region"){
    setRegionName(value);
  }else if(label === "Dealer"){
    setDealerName(value);
  }else if(label === "Customer"){
    setCustomerName(value);
  } else if(label === "Fleet"){
    setFleetName(value);
  }else if(label === "Vehicle"){
    setVehicleName(value);
  }else if(label === "Alert Type"){
    setAlertTypeName(value);
  }else if(label === "User List"){
    setUserName(value);
  }else if(label === "Organization Type"){
    setOrgTypeName(value);
  }
}


  //Onsubmit Data
  const onSubmit = () => {
    let formdata = {
      regionId: region,
      dealerId: userOrgType === 5 ? orgId : dealer,
      customerId: userOrgType === 3 ? orgId : customer,
      fleetId: fleet,
      vehicleId: vehicle,
      fromDate:fromDate,
      toDate: label === 'Today' ? moment().format('yyyy-MM-DD HH:mm:ss') : toDate,
      alertType:alertType,
      searchBy:searchBy,
      cellType:cellType,
      regionName:regionName,
      dealerName:dealerName,
      customerName:customerName,
      fleetName:fleetName,
      vehicleName:vehicleName,
      alertTypeName:alertTypeName,
      selectedPeriodName:label!==undefined?label:"",
      accessId: access,
      mainMenuId: mainMenu ,
      subMenuId: subMenu ,
      selectedOrgType: orgType,
      selectedUserName:userName,
      statusId:status,
      orgTypeName:orgTypeName,
      mainMenuName:mainMenuName,
      subMenuName:subMenuName,
      accessName:accessName,

    };
    getData(formdata);
  };
  
  useEffect(() => {
    if (load) {
      onSubmit();
    }
  }, [load]);

  useEffect(() => {
    if(pathname !== 'dealer'){
      onSubmit();
    }
  },[vehicle])


  //Search--------------------------------------------------------------------------
  // vehicleSearch
  const vehicleSearch = (searchText) => {
    let vehicleSeatchPayload = {
      resellerId: resellerId,
      searchText: searchText,
      page: "0",
      size: "10",
      orgType: userOrgType,
      dealerId: orgId,
      vehicleAllFilter: true,
      imeiSearch: "",
    };
    if (searchText !== '') {
      getVehicleSearch(vehicleSeatchPayload);
    } else {
      setSelectedVehicle(null);
      setVehicleList(vehicledata?.data);
    }
  };
  useLayoutEffect(() => {
    if (searchVehicleSuccess) {
      if (searchVehicles.data !== null) {
        setVehicleList(searchVehicles.data);
      }
    }
  }, [searchVehicleSuccess]);

  const regionSearch = (searchText) => {
    CommonPayload.value = "region";
    CommonPayload.searchText = searchText;
    CommonPayload.dealerId = "-1";
    getRegionList(CommonPayload);
  };
  const dealerSearch = (searchText) => {
    CommonPayload.value = "dealer";
    CommonPayload.searchText = searchText;
    CommonPayload.regionId = region;
    if (region !== "-1") {
      getDealerList(CommonPayload);
    }
  };
  const customerSearch = (searchText) => {
    CommonPayload.value = "customer";
    CommonPayload.searchText = searchText;
    CommonPayload.dealerId = dealer;
    if (dealer !== "-1") {
      getCustomerList(CommonPayload);
    }
  };
  const FleetSearch = (searchText) => {
    CommonPayload.value = "fleet";
    CommonPayload.dealerId = "0";
    CommonPayload.searchText = searchText;
    CommonPayload.customerId = customer;
    if (customer !== "-1") {
      getFleetList(CommonPayload);
    }
  };

  const UserListSearch = (searchText) => {
    if (orgType === "2") {
      userPayload.orgId = orgId;
    }else if(orgType === "1"){
      userPayload.orgId = fleet;
    }else if(orgType === "3"){
      userPayload.orgId = customer;
    }else if(orgType === "4"){
      userPayload.orgId = fleet;
    }else if(orgType === "5"){
      userPayload.orgId = dealer;
    }
    userPayload.searchText=searchText;
    getAuditUserList(userPayload);
  };

  //search
  const dropDownSearch = (searchText, name) => {
    if (name === "Vehicle") {
      setSearchText({ value: searchText });
      setSearchText({ name: name });
      setSearch(true);
      vehicleSearch(searchText);
    } else if (name === "Region") {
      regionSearch(searchText);
    } else if (name === "Dealer") {
      dealerSearch(searchText);
    } else if (name === "Customer") {
      customerSearch(searchText);
    } else if (name === "Fleet") {
      FleetSearch(searchText);
    }else if (name === "User List") {
      UserListSearch(searchText);
    }
  };



  const location = useLocation();
  let pathname = location.pathname.split('/')[1];

  
  useEffect(() => {
    if(pathname === 'viewAlerts'){
      setAlertTypeShow(true);
      setDateShow(true);
    }
  },[])



  const [regionShow, setRegionShow] = useState(false);
  const [dealerShow, setDealerShow] = useState(false);
  const [customerShow, setCustomerShow] = useState(false);
  const [fleetShow, setFleetShow] = useState(false);
  const [vehicleShow, setVehicleShow] = useState(false);
  const [alertTypeShow, setAlertTypeShow] = useState(false);
  const [dateShow, setDateShow] = useState(false);
  const [searchByShow,  setSearchByShow] = useState(false);
  const [cellLogTypeShow,  setCellLogTypeShow] = useState(false);
  const [userListShow, setUserListShow] = useState(false); // Audit Log
  const [statusShow, setStatusShow] = useState(false);
  

  // useEffect(() => {
  //   if(vehicle !== '-1'){
  //       setActiveLabel('today');
  //   }else{
  //       setActiveLabel('yesterday');
  //   }
  // },[vehicle])

  //onload
  useEffect(() => {
    if (userOrgType === 1) {
      if (pathname === "viewAlerts" ) {
        setRegionShow(false);
        setDealerShow(false);
        setCustomerShow(false);
        setFleetShow(false);
        setVehicleShow(true);
        setAlertTypeShow(true);
        setDateShow(true);
      }else if(pathname === "alerts"){
        setRegionShow(false);
        setDealerShow(false);
        setCustomerShow(false);
        setFleetShow(false);
        setVehicleShow(true);
        setAlertTypeShow(false);
        setDateShow(false);
      }else if(pathname === "CellLog"){
        setRegionShow(false);
        setDealerShow(false);
        setCustomerShow(false);
        setFleetShow(false);
        setVehicleShow(true);
        setAlertTypeShow(false);
        setCellLogTypeShow(true);
        setDateShow(true);
      }
      else if(pathname === "dealer"){
        setRegionShow(false);
        setDealerShow(false);
        setCustomerShow(false);
        setFleetShow(false);
        setVehicleShow(true);
        setAlertTypeShow(true);
        setSearchByShow(true);
        location.hash === '#processing'?setStatusShow(true):setStatusShow(false);
      
        
      }
    } else if (userOrgType === 2) {
      if (pathname === "viewAlerts" ) {
        setRegionShow(true);
        setDealerShow(true);
        setCustomerShow(true);
        setFleetShow(true);
        setVehicleShow(true);
        setAlertTypeShow(true);
        setDateShow(true);
      }else if(pathname === "targettedDistanceSetting"){
        setRegionShow(true);
        setDealerShow(true);
        setCustomerShow(true);
        setFleetShow(true);
        setVehicleShow(true);
      }else if(pathname === "alerts"){
        setRegionShow(true);
        setDealerShow(true);
        setCustomerShow(true);
        setFleetShow(true);
        setVehicleShow(true);
        setAlertTypeShow(false);
        setDateShow(false);
      }else if(pathname === "CellLog"){
        setRegionShow(true);
        setDealerShow(true);
        setCustomerShow(true);
        setFleetShow(true);
        setVehicleShow(true);
        setCellLogTypeShow(true);
        setAlertTypeShow(false);
        setDateShow(true);
      }      else if(pathname === "dealer"){
        setRegionShow(false);
        setDealerShow(false);
        setCustomerShow(false);
        setFleetShow(false);
        setVehicleShow(false);
        setAlertTypeShow(false);
        setSearchByShow(true);
        location.hash === '#processing'?setStatusShow(true):setStatusShow(false);
       
      } else if(pathname === "AuditLog"){
        setOrgTypeShow(true)
        setRegionShow(true);
        setDealerShow(true);
        setCustomerShow(true);
        setFleetShow(true);
        setUserListShow(true);
        setMainMenuShow(true);
        setSubMenuShow(true);
        setAccessShow(true);
        setAccessData({ content: userLogAccessData });
        setDateShow(true);   
      }
    }else if (userOrgType === 5){
      if (pathname === "viewAlerts") {
        setCustomerShow(true);
        setFleetShow(true);
        setVehicleShow(true);
        setAlertTypeShow(true);
        setDateShow(true);
      }else if(pathname === "alerts"){
        setCustomerShow(true);
        setFleetShow(true);
        setVehicleShow(true);
      }else if(pathname === "CellLog"){
        setCustomerShow(true);
        setFleetShow(true);
        setVehicleShow(true);
        setAlertTypeShow(false);
        setCellLogTypeShow(true);
        setDateShow(true);
      }      else if(pathname === "dealer"){
        setRegionShow(false);
        setDealerShow(false);
        setCustomerShow(false);
        setFleetShow(false);
        setVehicleShow(false);
        setAlertTypeShow(false);
        setSearchByShow(true);
        location.hash === '#processing'?setStatusShow(true):setStatusShow(false);
        
      }
    }else if (userOrgType === 3){
      if (pathname === "viewAlerts") {
        setFleetShow(true);
        setVehicleShow(true);
        setAlertTypeShow(true);
        setDateShow(true);
      }else if(pathname === "alerts"){
        setFleetShow(true);
        setVehicleShow(true);
      }else if(pathname === "CellLog"){
        setFleetShow(true);
        setVehicleShow(true);
        setAlertTypeShow(false);
        setCellLogTypeShow(true);
        setDateShow(true);
      }      else if(pathname === "dealer"){
        setRegionShow(false);
        setDealerShow(false);
        setCustomerShow(false);
        setFleetShow(false);
        setVehicleShow(false);
        setAlertTypeShow(false);
        setSearchByShow(true);
        location.hash === '#processing'?setStatusShow(true):setStatusShow(false);
        
      }
    }

    
  }, [location.hash]);


  return (
    <StyledPaperShadow
      elevation={0}
      sx={{ display: "inline-block", width: "100%" }}
    >
      {orgTypeShow && (
        <SingleDropdown
          label={"Organization Type"}
          listItems={orgTypeList}
          handleChange={handleOrgType}
          value={orgType}
          isSearch={false}
          showAll={false}
          getInnerText={getInnerText}
          // hideOrg={hideOrg}
        />
      )}
      {
        regionShow &&
        <SingleDropdown
          label={"Region"}
          listItems={regionList}
          handleChange={getDealer}
          value={region}
          isSearch={dropDownSearch}
          showAll={allOption}
          getInnerText={getInnerText}
          disabled={pathname==="AuditLog" && (orgType ==="2" || orgType === "4") ?true:false}
        />
      }
      
      {
        dealerShow &&
        <SingleDropdown
          label={"Dealer"}
          listItems={dealerList}
          handleChange={getCustomer}
          value={dealer}
          isSearch={dropDownSearch}
          showAll={allOption}
          getInnerText={getInnerText}
          disabled={pathname==="AuditLog" && (orgType ==="2" || orgType === "4") ?true:false}
        />
      }
        
      {
        customerShow &&
        <SingleDropdown
          label={"Customer"}
          listItems={customerList}
          handleChange={getFleet}
          value={customer}
          isSearch={dropDownSearch}
          showAll={allOption}
          getInnerText={getInnerText}
          disabled={pathname==="AuditLog" && (orgType ==="2" || orgType === "5" || orgType === "4") ?true:false}
        />
      }
      
      {
        fleetShow && 
        <SingleDropdown
          label={"Fleet"}
          listItems={fleetList}
          handleChange={getVehicle}
          value={fleet}
          isSearch={dropDownSearch}
          showAll={allOption}
          getInnerText={getInnerText}
          disabled={pathname==="AuditLog" && (orgType ==="2" || orgType === "3" || orgType === "5" || orgType ==="4") ?true:false}
        />
      }
      
      {
        vehicleShow && 
        <SingleDropdown
          label={"Vehicle"}
          onClick={() => setSearch(false)}
          listItems={vehicleList}
          handleChange={selectVehicle}
          value={vehicle}
          isSearch={dropDownSearch}
          showAll={allOption}
          getInnerText={getInnerText}
        />
      }

{
        alertTypeShow && 
        <SingleDropdown
          label={"Alert Type"}
          listItems={alertList}
          handleChange={(alertTypeiD) => setAlertType(alertTypeiD)}
          value={alertType}
          isSearch={false}
          showAll={true}
          customAllValue={'All'}
          getInnerText={getInnerText}
        />
      }

{
            searchByShow && 
            <SingleDropdown
                label={'Search By'}
                listItems={{content:dealerLogSearchByData}}
                handleChange={(searchById) => {
                    setSearchBy(searchById);
                    if(searchById === 'Date'){
                      setDateShow(true);
                    }else{
                      setDateShow(false);
                    }
                }}
                value={searchBy}
                isSearch={false}
                searchText={false}
                showAll={true}
                customAllValue={'All'}
            />
        }
        {
        cellLogTypeShow && 
        <SingleDropdown
          label={"Type"}
          listItems={{content:cellLogTypeData}}
          handleChange={(typeiD) => setCellType(typeiD)}
          value={cellType}
          isSearch={false}
          showAll={true}
          customAllValue={'All'}
        />
      }
      
      {
        dateShow && 
        <DatepickerDropdown getDate={GetDate} activeLabel={activeLabel} showAll={false} time={false} showToday={ true } allowDays={allowedDays}  allowedMonth={allowedMonth} createDateStatus={true}
        createDateValue={createDateValue}/>
        // <BootsrapDateRange getTimeAndDate={getTimeAndDate} allowedDays={allowedDays} allowedMonth={allowedMonth}/>
      }
        

        {userListShow && (
        <SingleDropdown
          label={"User List"}
          listItems={{ content: auditUserList }}
          handleChange={handleUserList}
          value={userListId}
          isSearch={dropDownSearch}
          showAll={true}
          getInnerText={getInnerText}
        />
      )}

{mainMenuShow && (
        <SingleDropdown
          label={"Main Menu"}
          listItems={mainMenuData}
          handleChange={handleMainMenu}
          value={mainMenu}
          isSearch={false}
          showAll={true}
          getInnerText={getInnerText}
        />
      )}

      {subMenuShow && (
        <SingleDropdown
          label={"Submenu"}
          listItems={subMenuData}
          handleChange={handleSubmenu}
          value={subMenu}
          isSearch={false}
          showAll={true}
          getInnerText={getInnerText}
        />
      )}

      {accessShow && (
        <SingleDropdown
          label={"Access"}
          listItems={accessData}
          handleChange={handleAccess}
          value={access}
          isSearch={false}
          showAll={true}
          customAllValue={"All"}
          getInnerText={getInnerText}
        />
      )}

{
            statusShow && 
            <SingleDropdown
            label={'Status'}
            listItems={{content:  kycLogProcessingStatusData  }}
            handleChange={(StatusId) => setStatus(StatusId)}
            value={status}
            isSearch={false}
            searchText={false}
            showAll={true}
            customAllValue={'All'}
            />
        }
      
      <StyledFiterButton
        type="button"
        variant="contained"
        size="small"
        color="success"
        onClick={onSubmit}
        sx={{ marginTop: "25px" }}
      >
        Submit
      </StyledFiterButton>
    </StyledPaperShadow>
  );
}

export default AlertDropdown;
