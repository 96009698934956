import { Avatar, Box, CardMedia, Grid, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FileUploadBox, SoftButton, StyledPaperShadow } from '../../../theme/StyledEle'
import { RiUploadCloud2Fill } from 'react-icons/ri'
import { AiOutlineDelete } from 'react-icons/ai';
import { BsImageAlt } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { snackService } from '../../../store/slice/ControlsSlice';
import StyledSnackBar from '../../../Utils/StyledSnackBar';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { HiOutlineDownload } from 'react-icons/hi';
import { saveAs } from 'file-saver';
import ImageUpload from '../../../Utils/ImageUpload';

function EditTicketUpload(props) {
    const { data, uploadFile, ticketDetail, uploadFileLoading, getSingleTicket,deleteImage,deleteImageLoading  } = props;
    let theme = useTheme();

    let userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData.resellerId;
    let orgId = userData.orgId;
    let userId = userData.id;
    let orgName = userData.orgName;
    let orgType = userData.userOrgType;
    let userName = userData.userName;


    const [upload, setUpload] = useState([]);

    const [images, setImages] = useState([]);
    const fileHandleChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    }

    let createPayload = {
        ticketno:ticketDetail.ticketno,
        id:ticketDetail.id,
        createdBy:userId,
    }
    const dispatch = useDispatch();

    const uploadFileSubmission = async (event) => {
        event.preventDefault();
        const { target } = event;
        let imgData = {}
        imgData =  Object.fromEntries(new FormData(target));

        let formData = new FormData();
        formData.append(`finaldata`, JSON.stringify(createPayload));
        if (images.length > 0) {
            for (let i = 0; i < images.length; i++) {
              formData.append(`files`, images[i].file);
            }
          } else {
            formData.append(`files`, "");
          }
        const uploadImge = await uploadFile(formData);
        if(uploadImge.data.resultCode === 0){
            setImages([]);
            dispatch(snackService([uploadImge.data.resultMessage, uploadImge.data.resultCode, true]));
            getSingleTicket();
        }
    }


  const fileSaver = (name, type) => {
    let file = 'https://' + name;
    saveAs(file, `${name}.${type}`);
  }

  //Delete uploaded attachment
  let deletePayload = {
    id:ticketDetail.id,
    createdBy:userId,
}
  const deleteUploadedImage = async(imagename) => {
    deletePayload.docName = imagename ;
    const deleteImageData = await deleteImage(deletePayload);
    if(deleteImageData.data.resultCode === 0){
      setImages([]);
      dispatch(snackService([deleteImageData.data.resultMessage, deleteImageData.data.resultCode, true]));
      getSingleTicket();
  }else{
    dispatch(snackService([deleteImageData.data.resultMessage, deleteImageData.data.resultCode, true]));
  }
};


  return (
        <Box>
            <Grid container spacing={1}> 
                <Grid item lg={12} md={12} sm={12}>
                    <StyledPaperShadow sx={{flexDirection:'column'}} onSubmit={uploadFileSubmission} component={'form'} enctype="multipart/form-data">
                    <ImageUpload images={images} fileHandleChange={fileHandleChange}/>

                    {
                        images.length > 0 &&
                        <Stack direction={'row'}>
                        <SoftButton variant='contained' className='success' size='small' type='submit'>
                          {
                            uploadFileLoading ? <ScaleLoader color='#fff' height={12}/> : 'Upload'
                          }
                        </SoftButton>
                        </Stack>
                    }
                    
                  
                </StyledPaperShadow>
                </Grid>
                <Grid item lg={12} md={12} sm={12}>
                    <Typography sx={{px:2}}>Attachements</Typography>
                        <Grid container spacing={1} sx={{mt:.5, px:2}}>
                            {
                                data !== null && data !== undefined && data?.map((item, index) => {
                                  let docType = item.docOriginalName?.split('.').pop();
                                  return(
                                    <Grid item xl={2} lg={3} md={4} sm={6}>
                                        <Paper elevation={0} sx={{p:1, position:'relative',border:`.5px solid ${theme.palette.divider}`, overflow:'hidden', '&:hover .img-name':{bottom:'0px', cursor:'pointer'}}}>
                                        <IconButton 
                                        //component='a' 
                                        //href={`https://${item.docName}`}
                                        onClick={() => fileSaver(item.docName, docType)}
                                        size='small' 
                                        sx={{color:'#fff', background:theme.palette.info.main, textDecoration:'none', position:'absolute', top:'5px'}}

                                        ><HiOutlineDownload  /></IconButton>
                                        <IconButton 
                                        //component='a' 
                                        //href={`https://${item.docName}`}
                                        onClick={() => deleteUploadedImage(item.docOriginalName)}
                                        size='small' 
                                        sx={{color:'#fff', background:theme.palette.error.main, textDecoration:'none', position:'absolute',right: '5px',top:'5px'}}

                                        ><AiOutlineDelete /></IconButton>
                                            <CardMedia 
                                                src={
                                                  docType === 'xls' ? 'https://cdn-icons-png.flaticon.com/512/9798/9798110.png' : 
                                                  docType === 'pdf' ? 'https://cdn-icons-png.flaticon.com/512/4726/4726010.png' : 
                                                  docType === 'docx' || docType === 'doc' ? 'https://cdn-icons-png.flaticon.com/512/4725/4725970.png' : 
                                                  docType === 'jpg' || docType === 'png' ? `https://${item.docName}` : 'https://cdn-icons-png.flaticon.com/512/4904/4904233.png' }

                                                component={'img'}
                                                sx={{height:'80px', width:'100%', objectFit:'contain'}}
                                            />
                                            <Typography className='img-name'  sx={{background:theme.palette.success.main, color:theme.palette.success.contrastText, position:'absolute', width:'100%', height:'35px', bottom:'-50px', left:'0', display:'flex', alignItems:'center', justifyContent:'center', transition:'.5s', px:1, fontSize:'12px'}} variant='body2'>{item.docOriginalName}</Typography>
                                        </Paper>
                                    </Grid>
                                  )
                                }
                                    
                                )
                            }

                        </Grid>
                </Grid>
            </Grid>
        </Box>
  )
}

export default EditTicketUpload