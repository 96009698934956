import React, { useState, useMemo, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid, Button, Typography, Link, CardHeader, CardContent, Stack, CardMedia, Chip, Collapse } from "@mui/material";
import { Paper } from "@mui/material";
import { DataTable } from "../shared/data_table/DataTable";
import { styled } from "@mui/material/styles";
import DeviceData from "../../xls/device/DeviceTemplateNew.xls";
import DeviceMoveToReseller from "../../xls/device/DeviceMoveTemplate.xls";
import DeviceSimMap from "../../xls/device/DeviceSimMapNew.xls";
import DraggableDialog from "../shared/DailogDrag";
import deviceServices from "../../services/deviceOnBoardService";
import logServices from "../../services/logService";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";

import { FormControl, FormHelperText, Select, MenuItem } from "@mui/material";
import { FileUploadBox, StyledCardShadow, StyledFilterDropDown, StyledFilterFormControl, StyledFormControlLabel, StyledPaperShadow, StyledTableTitle } from "../../theme/StyledEle";
import uploadcsv from '../../assets/images/uploadcsv.png'
import { useDeviceOnBoardValidateMutation, useDeviceValidateMutation } from "../../store/slice/DeviceSlice/DeviceSlice";
import {  ActionMockDataSystem, ActionMockDataVendor } from "../device/Constans";
import SingleDropdown from "../NewDropdown/SingleDropdown";
import StyledSnackBar from "../../Utils/StyledSnackBar";
function DeviceUpload({ onCancel }) {
  const [file, setFile] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [dataTable, setDataTable] = useState(false);
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [open, setOpen] = useState(false);
  const [option,setOption] = useState(0);
  const [template,setTemplate] = useState(4);
  const [commercialPlan, setCommercialPlan] = useState(0);
  const [testPlan, setTestPlan] = useState(0);
  const [cols, setCols] = useState(null);
  const [validate, setValidate] = useState(false);
 
  const globalJson2 =
  localStorage.getItem("userData") !== null
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  var globaldisplayview = "";
  var globalOrgId = 1;
  var globaluserId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalResellerId = 2;
  var globalOrgType = 2;
  var globalOrgName = "";
  var globalUserName = "";

  if (
  "null" === localStorage.getItem("userData") ||
  null === localStorage.getItem("userData")
  ) {
  } else {
  globalJson =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : 0;
  globalOrgId = globalJson.orgId;
  globalOrgType = globalJson.userOrgType;
  globaluserId = globalJson.id;
  globalRoleId = globalJson.roleId;
  globalResellerId = globalJson.resellerId;
  globaldisplayview = globalJson.displayView;
  globalUserName = globalJson.userName;
  globalOrgName = globalJson.orgName;
  }

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: globalOrgId,
    userId: globaluserId, 
    orgName: globalOrgName, 
    userName: globalUserName,
  };

  //Validate Excel
  const [validateXls, {data:validateData, isLoading:validateLoading, isSuccess:validateSuccess}] = useDeviceValidateMutation();


  //Device Status
  const deviceData = useMemo(() => [
    {
      Header: "Device IMEI",
      accessor: "imei"
    },
    {
      Header: "Sim",
      accessor:"sim"
    }
  ]);

 

  //Device Sim Mapping
  const deviceSimMap = useMemo(() => [
    {
      Header: "Device IMEI",
      accessor: "imei"
    },
    {
      Header: "Status",
      accessor:"status"
    }
  ]);

  const deviceMoveToReseller = useMemo(() => [
    {
      Header: "Device IMEI",
      accessor: "imei"
    },
    {
      Header: "Reseller Status",
      accessor:"reseller"
    }
  ]);



  //SetCols
  const setColumns = (columnId) => {
    if(columnId === 1){ // Device And Sim Mapping
      setCols(deviceSimMap);
    }
    else if(columnId === 2){ // Device Data Upload
      setCols(deviceData);
    }
    else if(columnId === 3){ // Device Move to Reseller Upload
      setCols(deviceMoveToReseller);
    }
  }


  const handleSelectChange =(actionId) => {
    setOption(actionId);
    setTemplate(actionId);
    setColumns(actionId);
    setFile([]);
    setIsFilePicked(false);
    setResponseData([]);
    setValidate(false);
    setDataTable(false);

    if(actionId === 0 ){
      auditPayload.message = "Admin > Device > Import Device Details > Action > Select";
    }else if(actionId === 1){
      auditPayload.message = "Admin > Device > Import Device Details > Action > Device Sim Map";
    }else if(actionId === 2){
      auditPayload.message = "Admin > Device > Import Device Details > Action > Device Upload";
    }else if(actionId === 3){
      auditPayload.message = "Admin > Device > Import Device Details > Action > Device Move to Reseller";
    } 

    auditPayload.action = "Select";
    auditPayload.featureName = "Device";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = globalUserName;

    auditLog(auditPayload);
  };


  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = { 
     
      'planCode':commercialPlan,
      'testPlan':testPlan,
    };

    const formData = new FormData();
    formData.append("file", file[0]);
    formData.append("params", JSON.stringify(payload));

    const response = await deviceServices.uploadDevice(formData);


    var obj1 = {}; 
    obj1.dialogText =
    response.data.result === "success"
        ? "Uploaded Successfully"
        : response.data.result;

    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(
      response.data.result === "success"
      ? "Success"
      : "Failed"      
    );
    setOpen(true);
    if (response.status === 200) {
          setTimeout(() => {setOpen(false)}, 3000);
          setTimeout(() => onCancel(), 6000);
        }

        auditPayload.message = "Admin > Device > Import Device Details > Upload";
        auditPayload.action = "Upload";
        auditPayload.featureName = "Device";
        auditPayload.customerName = "All";
        auditPayload.fleetName = "All";
        auditPayload.result = "Success";
        auditPayload.access = "Portal";
        auditPayload.severity = "INFO";
        auditPayload.userName = globalUserName;
    
        auditLog(auditPayload);
  };
  const handleSbmtClose = () => {};
  const handleClearCls = () => {
    setOpen(false);
  };


  //const [ deviceValidateOnBoard, {data:deviceOnBoardValidate, isLoading:deviceOnBoardValidate} ] = useDeviceOnBoardValidateMutation();

  const handleValidate = async (e) => {
    e.preventDefault();
    const payload = { 
      'file': file[0],
      'planCode':commercialPlan,
      'testPlan':testPlan,
    };
    //validateXls(payload);
          const responseData = await deviceServices.validateDevice(payload);
      if (
        responseData.data !== "invalidtemplate" &&
        responseData.data !== "excesscount" &&
        Object.keys(responseData.data.result).length > 0 &&   
        responseData.data.result !== undefined
      ) {
        setResponseData(responseData.data.result);
        setDataTable(true);
        if (responseData.data.isValidateSuccess === true) {
          setValidate(true);
        } else {
          setValidate(false);
        }
      } else {
        setResponseData([]);
      }
  
      var obj1 = {};
  
      obj1.dialogText =
        responseData.data === "excesscount"
          ? "Please upload valid count"
          : responseData.data === "invalidtemplate"
          ? "Please Upload Valid Template"
          : "Validated Successfully";
  
      setDataStatus(true);
      setDialogContent(obj1);
      setDataStatusConfirm(
        responseData.data !== "excesscount" &&
          responseData.data !== "invalidtemplate"
          ? "Success"
          : "Failed"
      );
      setOpen(true);
  
      if (responseData.status === 200) {
        setTimeout(() => {
          setOpen(false);
        }, 2000);
      } 
    
      auditPayload.message = "Admin > Device > Import Device Details > Validate";
      auditPayload.action = "Validate";
      auditPayload.featureName = "Device";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = globalUserName;
  
      auditLog(auditPayload);
   
  }
  const [isFilePicked, setIsFilePicked] = useState(false);
  const FileRef = useRef();

  const handleOnChange = (e) => {
    setFile(null);
    setFile([e.target.files[0]]);
    setIsFilePicked(true);
    
  };


  const handleCommercialChange =(cplanId) => {
    setCommercialPlan(cplanId);
  };
 
  const handleTestChange =(testPlanId) => {
    setTestPlan(testPlanId);
  };

  const handleTemplateClick = (value) =>{

    if(value === 1 ){
      auditPayload.message = "Admin > Device > Import Device Details > Device Sim Map > Download Device Sim Map";
    }else if(value === 2){
      auditPayload.message = "Admin > Device > Import Device Details > Device upload > Download Device upload";
    }else if(value === 3){
      auditPayload.message = "Admin > Device > Import Device Details > Device Move To Reseller > Download Move To Reseller";
    }

    auditPayload.action = "Download";
    auditPayload.featureName = "Device";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = globalUserName;

    auditLog(auditPayload);
  }
  
  function temp() {
    switch(template){
      case 1:
        return <Button href={DeviceSimMap} variant="contained" size="small" component={Link} onClick={() => handleTemplateClick(template)}>Download Device Sim Map</Button>
        break;
      case 2:
        return <Button href={DeviceData} variant="contained" size="small" component={Link} onClick={() => handleTemplateClick(template)}>Download Device upload </Button>
        break;
      case 3:
        return <Button href={DeviceMoveToReseller} variant="contained" size="small" component={Link} onClick={() => handleTemplateClick(template)}>Download Move to Reseller</Button>
        break;
    }
  }
    
  useEffect(() => {
    temp();
  }, [template])

    

  useEffect(async () => {

      auditPayload.message = "Admin > Device > Import Device Details";
      auditPayload.action = "Select";
      auditPayload.featureName = "Device";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = globalUserName;
  
      auditLog(auditPayload);



  }, []);

  return (
    <>
      <StyledPaperShadow>
      <Stack direction='row'>
                <SingleDropdown
                    label={"Action"}
                    listItems={{content:globalOrgType===8?ActionMockDataSystem:ActionMockDataVendor}}
                    handleChange={handleSelectChange}
                    value={option}  
                    isSearch={false}
                    showAll={false}
                  />
            </Stack>
      </StyledPaperShadow>
      <Collapse in={option !== 0 ? true : false}>
      <StyledCardShadow sx={{my:2}}>
        <CardHeader
          title={<StyledTableTitle>Upload List</StyledTableTitle>}
          action={temp()}
        />
        <form onSubmit={handleSubmit}>
          <CardContent sx={{py:0}}>
              <FileUploadBox>
                  <input
                    type="file"
                    accept="application/vnd.ms-excel"
                    onChange={handleOnChange}
                    className="fileupload"
                    ref={FileRef}
                  />
                  <CardMedia
                    component='img'
                    src={uploadcsv}
                    sx={{width:'60px', height:'60px', my:2, objectFit:'contain'}}
                  />
                  <Typography sx={{mt:2}} variant="h6" color='primary'>Select a XLS file to upload</Typography>
                  {isFilePicked && <Chip sx={{ml:1}}    label={FileRef?.current !== undefined && FileRef.current?.files[0]?.name} color="warning"/>}
                </FileUploadBox>
              <Stack direction='row' sx={{mt:2}} spacing={2} justifyContent='center'>
                  { file?.length > 0 && <Button variant="contained" onClick={handleValidate}> Validate </Button> }
                  { validate && <Button variant="contained" color="success" type="submit"> Upload </Button> }
              </Stack>
          </CardContent>
        </form>
      </StyledCardShadow>
      </Collapse>
      <Box>
        {dataTable === true && file?.length > 0 && (
          <StyledCardShadow>
              <CardContent  sx={{ maxWidth: "50vw" }}>
                    <DataTable
                  includeSearchBox={false}
                  columns={cols}
                  data={responseData}
                  includePagination={false}
                  includeExportButton={false}
                 
                />
              </CardContent>
          </StyledCardShadow>
        )}
      </Box>

      {open && (
        <StyledSnackBar
          open={open}
          message={dialogContent.dialogText}
          status={dataStatusConfirm}
        />
      )}
    </>
  );
}

export default DeviceUpload;
