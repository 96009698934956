import { InfoWindow, Marker } from '@react-google-maps/api';
import React, { createRef, useEffect, useRef, useState } from 'react'
import { MovingBike, OfflineBike, IdleBike, StopBike, ChargingBike,  MovingAuto, OfflineAuto, IdleAuto, StopAuto, ChargingAuto, MovingTruck, OfflineTruck, IdleTruck, StopTruck, ChargingTruck, ChargingGifIcon, StopCar, ChargingCar, MovingCar, OfflineCar, MovingScvm, OfflineScvm, IdleScvm, StopScvm, ChargingScvm, MovingTractorm, OfflineTractorm, IdleTractorm, StopTractorm, ChargingTractorm, StopRick, ChargingRick, MovingRick, OfflineRick, MovingMapBus, OfflineMapBus, IdleMapBus, StopMapBus, ChargingMapBus, AllBuses  } from '../Constans';
import { Avatar, Box, Button, Chip, Fab, Grid, Icon, Skeleton, Stack, Typography, alpha, useTheme } from '@mui/material';
import { InfoBoxStyled } from '../../theme/StyledEle';
import { StyledInfoWindow } from './Treeview/TreeviewStyledEle';
import { MdEnergySavingsLeaf, MdSignalWifiBad } from 'react-icons/md';
import { theme } from 'antd';
import reportServices from '../../services/reportServices';
import { FaParking, FaRoad } from 'react-icons/fa';
import { FaPlugCircleBolt } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { useVehicleimageMutation} from '../../store/slice/ApiSlice'


function MarkerRow(props) {
    const {data, text,imgData, vehicleType} = props;
    const theme = useTheme();

    const markerRef = data !== null && data?.vehicles.map(() => createRef());
    const infoWindowRef = useRef();
    //open marker window based on vehicle clicked
    let [setlectMarker, setSelectMarker] = useState(null);
    let [address, setAddress] = useState(null);
    //Get MarkerData
    const getMarkerData = (data) => {
      setSelectMarker(data.current);
    }
    //get localstorage data
    const userData = JSON.parse(localStorage.getItem('userData'));
    let resellerId = userData.resellerId;
    //Vehicle Trails
    const vehicleTrails = useSelector((state) => state.controls.vehicleTrails);

    //get vehicle
    let VehicleType = userData.vehicleType;
    let [vehicle, setVehicle] = useState(null);
    const getVehicleType = () => {
        switch(VehicleType){
          case 'TwoWheeler':
            setVehicle(2);
          break;
          case 'ThreeWheeler':
            setVehicle(3);
          break;
          case 'Truck':
            setVehicle(4);
          break;
          case 'Car':
            setVehicle(5);
          break;
          case 'Scv':
            setVehicle(6);
          break;
          case 'Tractor':
            setVehicle(7);
          break;
          case 'ERick':
            setVehicle(8);
          break;
          case 'Bus':
            setVehicle(9);
          break;
          default:
            setVehicle(2);
        }
    }

    //Initially get Vehicle Type
    // useEffect(() => {
    //   if(VehicleType){
    //     getVehicleType();
    //   }
    // },[])


    useEffect(() => {
      getVehicleType();
    },[vehicleType])

    //Infowindow Close Event
    const onClose = () => {
      setSelectMarker(null);
    }

    useEffect(() =>{
        if(vehicleTrails !== null){
          onClose();  
        }
    },[vehicleTrails])

    //Convert Address functuion - when click a marker
    useEffect(() => {
      if(setlectMarker !== null){
          setAddress(null);
          var requestN = {};
          requestN.latLng = setlectMarker?.props.position.lat + "," + setlectMarker?.props.position.lng;
          return reportServices.getGeocodeAddress(requestN)
            .then((resN) => {
              setAddress(resN.data);
          })
          .catch((err1) => {
          console.log(err1);
          });
      }
    },[setlectMarker])


      
    //common Style for all ele background
    const BackgroundStyle = {
      background: 
      setlectMarker?.props.markerData.vehicleStatus === 'Stop' || setlectMarker?.props.markerData.vehicleStatus === '0' ? alpha(theme.palette.error.main, .9) : 
      setlectMarker?.props.markerData.vehicleStatus === 'Charging' || setlectMarker?.props.markerData.vehicleStatus === '6'? alpha(theme.palette.warning.main, .9) : 
      setlectMarker?.props.markerData.vehicleStatus === 'Offline' || setlectMarker?.props.markerData.vehicleStatus === '4'? alpha(theme.palette.grey[700], .9) : setlectMarker?.props.markerData.vehicleStatus === 'Moving' || setlectMarker?.props.markerData.vehicleStatus === '1'? alpha(theme.palette.success.main, .9) : alpha(theme.palette.grey[700], .9)
    }

    //data if changed hide info window
    useEffect(() => {
      onClose();
    },[data])


    const selectedVehicle = useSelector((state) => state.controls.selectedVehicle);

    //get hover vehicle
    const hoverVehicleID = useSelector((state) => state.controls.hoverVehicle);

    return (
        <>
        { 
        selectedVehicle === null &&
        data?.vehicles?.map((list, index) => 
         
        {
          let dataimg= 
          vehicle === 2 ? (list.vehicleStatus==="0" || list.vehicleStatus==="Stop") ? StopBike : (list.vehicleStatus==="6" || list.vehicleStatus==="Charging") ? ChargingBike : (list.vehicleStatus===1 || list.vehicleStatus==="Moving") ? MovingBike : (list.vehicleStatus==="4" || list.vehicleStatus==="Offline") ? OfflineBike : OfflineBike : 

          vehicle === 3 ? (list.vehicleStatus==="0" || list.vehicleStatus==="Stop") ? StopAuto : (list.vehicleStatus==="6" || list.vehicleStatus==="Charging") ? ChargingAuto : (list.vehicleStatus===1 || list.vehicleStatus==="Moving") ? MovingAuto : (list.vehicleStatus==="4" || list.vehicleStatus==="Offline") ? OfflineAuto : OfflineAuto :

          vehicle === 4 ? (list.vehicleStatus==="0" || list.vehicleStatus==="Stop") ? StopTruck : (list.vehicleStatus==="6" || list.vehicleStatus==="Charging") ? ChargingTruck : (list.vehicleStatus===1 || list.vehicleStatus==="Moving") ? MovingTruck : (list.vehicleStatus==="4" || list.vehicleStatus==="Offline") ? OfflineTruck : OfflineTruck :

          vehicle === 5 ? (list.vehicleStatus==="0" || list.vehicleStatus==="Stop") ? StopCar : (list.vehicleStatus==="6" || list.vehicleStatus==="Charging") ? ChargingCar : (list.vehicleStatus===1 || list.vehicleStatus==="Moving") ? MovingCar : (list.vehicleStatus==="4" || list.vehicleStatus==="Offline") ? OfflineCar : OfflineCar :

          vehicle === 6 ? (list.vehicleStatus==="0" || list.vehicleStatus==="Stop") ? StopScvm : (list.vehicleStatus==="6" || list.vehicleStatus==="Charging") ? ChargingScvm : (list.vehicleStatus===1 || list.vehicleStatus==="Moving") ? MovingScvm : (list.vehicleStatus==="4" || list.vehicleStatus==="Offline") ? OfflineScvm : OfflineScvm : 

          vehicle === 7 ? (list.vehicleStatus==="0" || list.vehicleStatus==="Stop") ? StopTractorm : (list.vehicleStatus==="6" || list.vehicleStatus==="Charging") ? ChargingTractorm : (list.vehicleStatus===1 || list.vehicleStatus==="Moving") ? MovingTractorm : (list.vehicleStatus==="4" || list.vehicleStatus==="Offline") ? OfflineTractorm : OfflineTractorm ? '' : '' :
          vehicle === 8 ? (list.vehicleStatus==="0" || list.vehicleStatus==="Stop") ? StopRick : (list.vehicleStatus==="6" || list.vehicleStatus==="Charging") ? ChargingRick : (list.vehicleStatus===1 || list.vehicleStatus==="Moving") ? MovingRick : (list.vehicleStatus==="4" || list.vehicleStatus==="Offline") ? OfflineRick : OfflineRick ? '' : '' :
          
          vehicle === 9 ? (list.vehicleStatus==="0" || list.vehicleStatus==="Stop") ? StopMapBus : (list.vehicleStatus==="6" || list.vehicleStatus==="Charging") ? ChargingMapBus : (list.vehicleStatus===1 || list.vehicleStatus==="Moving") ? MovingMapBus : (list.vehicleStatus==="4" || list.vehicleStatus==="Offline") ? OfflineMapBus : OfflineMapBus ? '' : ''

          : ''
         
          let MarkerImg = 
          ((list.vehicleStatus=== '1' || list.vehicleStatus==="Moving") && imgData?.moving !=="-" && imgData?.moving !== null )? `https://${imgData?.moving }`:
          ((list.vehicleStatus==="0" || list.vehicleStatus==="Stop")&& imgData?.stop !=="-" && imgData?.stop !== null )? `https://${imgData?.stop }`:
          ((list.vehicleStatus==="6" || list.vehicleStatus==="Charging")&& imgData?.charging !=="-" && imgData?.charging !== null )?`https://${imgData?.charging }`:
          ((list.vehicleStatus==="4" || list.vehicleStatus==="Offline")&& imgData?.offline !=="-" && imgData?.offline !== null )? `https://${imgData?.offline }`:
          dataimg
         
         

         
          return(
            <Marker 
            id={list.vehicleId}
            position={{lat:list.lat, lng:list.lng}} 
            ref={markerRef[index]}
            key={index}
            clickable
            markerData={list}
            
            
            //icons based on vehicle type
            // icon={
            // vehicle === 2 ? (list.vehicleStatus==="0" || list.vehicleStatus==="Stop") ? StopBike : (list.vehicleStatus==="6" || list.vehicleStatus==="Charging") ? ChargingBike : (list.vehicleStatus===1 || list.vehicleStatus==="Moving") ? MovingBike : (list.vehicleStatus==="4" || list.vehicleStatus==="Offline") ? OfflineBike : OfflineBike : 

            // vehicle === 3 ? (list.vehicleStatus==="0" || list.vehicleStatus==="Stop") ? StopAuto : (list.vehicleStatus==="6" || list.vehicleStatus==="Charging") ? ChargingAuto : (list.vehicleStatus===1 || list.vehicleStatus==="Moving") ? MovingAuto : (list.vehicleStatus==="4" || list.vehicleStatus==="Offline") ? OfflineAuto : OfflineAuto :

            // vehicle === 4 ? (list.vehicleStatus==="0" || list.vehicleStatus==="Stop") ? StopTruck : (list.vehicleStatus==="6" || list.vehicleStatus==="Charging") ? ChargingTruck : (list.vehicleStatus===1 || list.vehicleStatus==="Moving") ? MovingTruck : (list.vehicleStatus==="4" || list.vehicleStatus==="Offline") ? OfflineTruck : OfflineTruck :

            // vehicle === 5 ? (list.vehicleStatus==="0" || list.vehicleStatus==="Stop") ? StopCar : (list.vehicleStatus==="6" || list.vehicleStatus==="Charging") ? ChargingCar : (list.vehicleStatus===1 || list.vehicleStatus==="Moving") ? MovingCar : (list.vehicleStatus==="4" || list.vehicleStatus==="Offline") ? OfflineCar : OfflineCar :

            // vehicle === 6 ? (list.vehicleStatus==="0" || list.vehicleStatus==="Stop") ? StopScvm : (list.vehicleStatus==="6" || list.vehicleStatus==="Charging") ? ChargingScvm : (list.vehicleStatus===1 || list.vehicleStatus==="Moving") ? MovingScvm : (list.vehicleStatus==="4" || list.vehicleStatus==="Offline") ? OfflineScvm : OfflineScvm : 

            // vehicle === 7 ? (list.vehicleStatus==="0" || list.vehicleStatus==="Stop") ? StopTractorm : (list.vehicleStatus==="6" || list.vehicleStatus==="Charging") ? ChargingTractorm : (list.vehicleStatus===1 || list.vehicleStatus==="Moving") ? MovingTractorm : (list.vehicleStatus==="4" || list.vehicleStatus==="Offline") ? OfflineTractorm : OfflineTractorm ? '' : '' : ''
            // }
            icon={{
              url:MarkerImg,
              scaledSize: new window.google.maps.Size(45, 45), 
          }}
            onClick={() => getMarkerData(markerRef[index])}
            //animation={hoverVehicleID ? (list.vehicleId === parseInt(hoverVehicleID.vehicleId) ? '1' : '0') : '0'}
            //title={hoverVehicleID ? (list.vehicleId === parseInt(hoverVehicleID.vehicleId) ? list.vehicleStatus : '') : ''}
            //opacity={hoverVehicleID ? (list.vehicleId === parseInt(hoverVehicleID.vehicleId) ? 1 : 0.3) : 1}
            />
          )
        }
        )
        }
        
        {
          setlectMarker !== null && 
          <InfoWindow 
            position={setlectMarker?.props.position}
            onCloseClick={onClose}
            options={{pixelOffset: new window.google.maps.Size(0,-70)}}
            ref={infoWindowRef}
          >
              <StyledInfoWindow>
                  <Box 
                  className='infowindowHHeader'
                  sx={{...BackgroundStyle, width:'100%'}}
                  > 
                      <Stack spacing={2} direction={'row'} alignItems={'baseline'}>
                          <Box>
                            <Typography variant='caption'>Vehicle No </Typography>
                            <Typography variant='subtitle2'>{setlectMarker?.props.markerData.vehicleNo}</Typography>
                          </Box>
                          <Fab disableTouchRipple size='small' 
                          sx={BackgroundStyle} 
                          >
                             <Icon>
                             {
                              setlectMarker?.props.markerData.vehicleStatus === 'Stop' || setlectMarker?.props.markerData.vehicleStatus === '0' ? <FaParking />: 
                              setlectMarker?.props.markerData.vehicleStatus === 'Charging' || setlectMarker?.props.markerData.vehicleStatus === '6'? <FaPlugCircleBolt /> : 
                              setlectMarker?.props.markerData.vehicleStatus === 'Offline' || setlectMarker?.props.markerData.vehicleStatus === '4'? <MdSignalWifiBad /> : 
                              setlectMarker?.props.markerData.vehicleStatus === 'Moving' || setlectMarker?.props.markerData.vehicleStatus === '1'? <FaRoad /> : <FaParking />
                            }
                             </Icon>
                          </Fab>
                      </Stack>
                  </Box>
                  <Box sx={{padding:'5px 15px', width:'100%'}}>
                      <Stack direction={'row'} spacing={2} justifyContent={'space-between'} sx={{width:'100%'}}>
                          <Box sx={{width:'100%'}}> 
                              <Stack direction={'column'} spacing={.5}>
                                  <Box>
                                    <Typography variant='caption'>Date</Typography>
                                    <Typography variant='subtitle2'>{setlectMarker?.props.markerData.lastConnected}</Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant='caption'>Vehicle Status</Typography>
                                    <Typography variant='subtitle2'>{setlectMarker?.props.markerData.vehicleStatus}</Typography>
                                  </Box>
                                  <Box>
                                      <Stack direction={'row'} justifyContent={'space-between'}>
                                          <Box>
                                              <Typography variant='caption'>Odometer (km)</Typography>
                                              <Typography variant='subtitle2'>{setlectMarker?.props.markerData.odometer}</Typography>
                                          </Box>
                                          <Box>
                                              <Typography variant='caption'>SOC (%)</Typography>
                                              <Typography variant='subtitle2'>{setlectMarker?.props.markerData.soc}</Typography>
                                          </Box>
                                          <Box>
                                              <Typography variant='caption'>Speed (km/h)</Typography>
                                              <Typography variant='subtitle2'>{setlectMarker?.props.markerData?.gpsSpeed}</Typography>
                                          </Box>
                                      </Stack>
                                  </Box>
                                  <Box>
                                    <Typography variant='caption'>Address</Typography>
                                    {
                                      address !== null ? 
                                      <Typography variant='subtitle2'> {address} </Typography> :
                                      <Skeleton variant='subtitle2'/>
                                    }
                                  </Box>
                              </Stack>
                          </Box>
                      </Stack>
                  </Box>
              </StyledInfoWindow>
          </InfoWindow>
        }
      </>
    )
   
}

export default MarkerRow
