import React, { useEffect, useRef, useState } from "react";
import {
  TreeViewAccodionBox,
  TreeViewListItemBtn,
} from "../Treeview/TreeviewStyledEle";
import { HiMiniFolderMinus, HiMiniFolderPlus } from "react-icons/hi2";
import {
  Box,
  Collapse,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { BsGlobeCentralSouthAsia } from "react-icons/bs";
import Dealer from "./Dealer";
import {
  useGetRegionListMutation,
  useGetVehicleByResellerMutation,
  useGetVehicleCountMutation,
  useAuditLogMutation
} from "../../../store/slice/ApiSlice";
import { ScaleLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import {
  dropVehicles,
  isLoadingState,
  refresh,
  selectedType,
  selectedVehicleData,
  selectedVehicleTrailsData,
  treeViewRollId,
  vehicleCountSlice,
} from "../../../store/slice/ControlsSlice";

function Region(props) {
  let {
    regionItem,
    userData,
    index,
    buttonIndex,
    OpenRegoinId,
    openDealerId,
    openCustomerId,
    openfleetId,
  } = props;
  const theme = useTheme();
  const ResellerRef = useRef(regionItem);
  const [open, setOpen] = useState(false);

  //dispatch
  const dispatch = useDispatch();

  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };

  //Payload Start
  //Dealer Payload
  let getDealerPayload = {
    resellerId: userData.resellerId,
    regionId: ResellerRef.current.id,
    orgId: userData.resellerId,
    page: 0,
    size: 10,
    statusFlag: 10,
    value: "dealer",
    vehmodel: "All",
    search: "",
  };
  //getvehicle Count
  let commonCountPayload = {
    resellerId: userData.resellerId,
    
    
  };
  //Get vehicle
  let commonPayload = {
    resellerId: userData.resellerId,
    regionId: ResellerRef.current.id,
    dealerId: 0,
    customerId: 0,
    orgId: userData.resellerId,
    checkboxStatus: false,
    page: 0,
    size: 1000,
    region: 0,
    vehModel: "All",
    searchText: "",
    zoneId: 0,
  };
  //Payload End

  //API Slice
  const [getDealer, { data, isLoading, isSuccess }] =
    useGetRegionListMutation();
  //Vehicle Count & Vehicle
  const [
    getVehicleCount,
    {
      data: vehicleCountData,
      isLoading: countLoading,
      isSuccess: countSuccess,
    },
  ] = useGetVehicleCountMutation();
  const [
    getVehicleByReseller,
    {
      data: getVehicleData,
      isLoading: getVehicleLoading,
      isSuccess: getVehicleSuccess,
    },
  ] = useGetVehicleByResellerMutation();

  const selectedVehicle = useSelector(
    (state) => state.controls.selectedVehicle
  );

  let [dealerList, setDealerList] = useState(null);
  //Set Dealer Data List
  useEffect(() => {
    if (isSuccess) {
      setDealerList(data);
    }
  }, [isSuccess]);

  //set Vehicle Count
  useEffect(() => {
    if (countSuccess === true || getVehicleSuccess === true) {
      dispatch(vehicleCountSlice(vehicleCountData));
      dispatch(dropVehicles(getVehicleData));
      dispatch(isLoadingState(false));
    }
  }, [countSuccess, getVehicleSuccess]);

  //set Vehicle Count
  useEffect(() => {
    if (countSuccess === true && getVehicleSuccess === true) {
      dispatch(vehicleCountSlice(vehicleCountData));
      dispatch(dropVehicles(getVehicleData));
      dispatch(isLoadingState(false));
    }
  }, [countSuccess, getVehicleSuccess]);

  //collabseOpen
  const collabseOpen = () => {
    setOpen(!open);
    if (dealerList === null) {
      getDealer(getDealerPayload);
    }
    if (!open === true) {
      if(userData.resellerId !== 110224){
        commonCountPayload.selectId = ResellerRef.current.id; 
        commonCountPayload.selectType = "region";
        dispatch(treeViewRollId([ResellerRef.current.id, "region"]))
          getVehicleCount(commonCountPayload);
      }else{
        commonCountPayload.selectId = ""; 
        commonCountPayload.selectType = "";
        dispatch(treeViewRollId([null, null]))
        getVehicleCount(commonCountPayload);
      }
      getVehicleByReseller(commonPayload);
    }
  
      auditPayload.message = "Tracking > Map > Treeview > "+userData.orgName+" > "+regionItem.name;
      auditPayload.action = "Select";
      auditPayload.featureName = "Map";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      //auditLog(auditPayload);
  };
  //Onload Expand
  useEffect(() => {
    if (OpenRegoinId === regionItem.id && isSearchKeyword === null) {
      setOpen(true);
      getDealer(getDealerPayload);
    }
  }, []);

  const isSearchKeyword = useSelector(
    (state) => state.controls.treeview.searchKeyWord
  );
  const isLoadingStates = useSelector((state) => state.controls.isloading);
  useEffect(() => {
    const dealyDebounce = setTimeout(() => {
      if (
        regionItem !== null &&
        isLoadingStates === true &&
        isSearchKeyword !== null &&
        isSearchKeyword.length >= 3
      ) {
        if (index === 0) {
          setOpen(true);
          getDealerPayload.search = isSearchKeyword;
          getDealer(getDealerPayload);
        }
      }
    }, 1000);
    return () => clearTimeout(dealyDebounce);
  }, [isSearchKeyword]);

  //Click outsite btn get Count and Load marker
  const getCount = () => {
    //dispatch(selectedType(['regionId', ResellerRef.current.id]));
    if(userData.resellerId !== 110224){
      commonCountPayload.selectId = ResellerRef.current.id; 
      commonCountPayload.selectType = "region";
      dispatch(treeViewRollId([ResellerRef.current.id, "region"]))
        getVehicleCount(commonCountPayload);
    }else{
      commonCountPayload.selectId = ""; 
      commonCountPayload.selectType = "";
      dispatch(treeViewRollId(['', ""]))
      getVehicleCount(commonCountPayload);
    }
    dispatch(isLoadingState(true));
    getVehicleByReseller(commonPayload);
    if (selectedVehicle !== null) {
      dispatch(selectedVehicleData(null));
      dispatch(selectedVehicleTrailsData(null));
      dispatch(refresh([false, false]));
    }
  };

  return (
    <TreeViewAccodionBox
      rolles={"region"}
      key={index}
      isOpen={open}
      lineOffset={34}
    >
      <TreeViewListItemBtn
        primary={"region"}
        secondaryAction={
          <IconButton onClick={collabseOpen}>
            {open ? <HiMiniFolderMinus /> : <HiMiniFolderPlus />}
          </IconButton>
        }
        id={regionItem.id}
        ref={ResellerRef}
        data-region-name={regionItem.name}
        data-vehicle-count={regionItem.vehicleCount}
        data-role-name={"region"}
        onClick={getCount}
        buttonIndex={buttonIndex}
      >
        <ListItemIcon>
          <BsGlobeCentralSouthAsia />
        </ListItemIcon>
        <ListItemText
          primary={
            <Stack direction={"row"} spacing={0.5}>
              <Typography>{regionItem.name}</Typography>
              <Typography>{`(${regionItem.vehicleCount})`}</Typography>
            </Stack>
          }
        />
      </TreeViewListItemBtn>
      <Collapse in={open}>
        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", py: 1 }}>
            <ScaleLoader color={theme.palette.secondary.main} height={20} />
          </Box>
        ) : (
          <>
            {dealerList?.treeviewList.map((dealerItem, index) => (
              <Dealer
                dealers={dealerItem}
                key={index}
                index={index}
                userData={userData}
                buttonIndex={buttonIndex}
                openDealerId={openDealerId}
                openCustomerId={openCustomerId}
                openfleetId={openfleetId}
                regionName = {regionItem.name}
              />
            ))}
          </>
        )}
      </Collapse>
    </TreeViewAccodionBox>
  );
}

export default Region;
