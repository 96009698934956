import * as React from "react";
import { useState } from "react";
import MuiToggleButton from "@mui/material/ToggleButton";
import {
  StyledTab,
  StyledTabBox,
  StyledTabsGroup,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "../../theme/StyledEle";
import MonthlySummaryvehicleWiseReport from "../../components/Reports/MonthlySummaryVehicleWiseReport";
import MonthlySummaryDayWiseReport from "./MonthlySummaryDayWiseReport";
import { Box } from "@mui/system";
import {
  Typography,
  ToggleButtonGroup,
  Paper,
  Container,
  CardContent,
} from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import {
  StyledCardShadow,
  Exportoptions,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import MouseOverPopover from "../shared/PopOver";
import {
  useMonthlyDayMutation,
  useMonthlyVehicleMutation,
  useAuditLogMutation,
} from "../../store/slice/ApiSlice";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import { useRef } from "react";
import moment from "moment";

const text =
  "This Report gets the trip & battery details for Vehicle-wise or Day-wise. Energy Efficiency is calculated for vehicles that have traveled more than 10km per day.";

const MonthlySummaryReport = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let orgType = userData.userOrgType;

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId,
    orgName: orgName,
    userName: userName,
  };

  const [hideDetails, setHidedetails] = useState("vehicleWise");
  const [alignment, setAlignment] = React.useState("vehicleWise");
  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  const handleChange = (event) => {
    setHidedetails(event.target.value);
  };

  
  // Get Data from New Dropdown

  //Vehicle Wise
  const [
    getMonthlyVehicle,
    { data: monthlyVehicleWiseData, isLoading: vehicleWiseLoading, isSuccess },
  ] = useMonthlyVehicleMutation();
  const [getDayWise, { data: monthlyDayWiseData, isLoading:vehicleDaywiseLoading, }] = useMonthlyDayMutation();
  const [payload, setPayload] = useState(null);

  let commonPayload = {};
  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.resellerId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.sortBy = "desc";
    commonPayload.orderdir = "desc";
    commonPayload.page = 0;
    commonPayload.size = 25;
    //Service Call
    setPayload(commonPayload);
    if (alignment === "vehicleWise") {
      commonPayload.orderColumn = "AVG_RUN_TIME";
      getMonthlyVehicle(commonPayload);
      auditPayload.message = "Reports > Monthly Summary > Vehicle Wise > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedMonthName+" > "+"Submit";
    } else {
      //Day wise
      commonPayload.columnName = "DISTANCE_TRAVELED";
      getDayWise(commonPayload);
      auditPayload.message = "Reports > Monthly Summary > Day Wise > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedMonthName+" > "+"Submit";
    }
    dashboardGen(data);

    auditPayload.action = "Submit";
    auditPayload.featureName = "Overspeed";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    console.log("monthMsg::"+auditPayload.message);
    auditLog(auditPayload);
  };

  React.useEffect(() => {
    if (alignment === "vehicleWise") {
      if (payload !== null) {
        let newobj = {};
        newobj = payload;
        newobj.orderColumn = "AVG_RUN_TIME";
        getMonthlyVehicle(newobj);
      }
    } else {
      //Day wise
      if (payload !== null) {
        let newobj = {};
        newobj = payload;
        newobj.columnName = "ID";
        getDayWise(newobj);
      }
    }
  }, [alignment]);

  //Vegicle Wise data success;
  const [vehicleData, setVehicleData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const [responseData, setResponseData] = useState([]);

  const [dashboardUrl, setDashboardUrl] = useState(null); // Only for montra
  let iframeReload = useRef();
  const [value, setValue] = useState(0);
  //Montra Monthly Dashboard
  const dashboardGen = (formData) => {
    var regionId = "";
    var customerId = "";
    var dealerId = "";
    var fleetId = "";
    var vehicleId = "";
    if (formData !== null) {
      if (formData?.regionId !== "-1") {
        regionId = '"REGION_ID"=' + formData?.regionId + "and";
      } else {
        regionId = '"REGION_ID"!=5' + "and";
      }

      if (formData?.dealerId !== "-1") {
        dealerId = '"DEALER_ID"=' + formData?.dealerId + "and";
      } else {
        dealerId = "";
      }

      if (formData?.customerId !== "-1") {
        customerId = '"CUSTOMER_ID"=' + formData?.customerId + "and";
      } else {
        customerId = "";
      }

      if (formData?.fleetId !== "-1") {
        fleetId = '"vehicle_id"=' + formData?.fleetId + "and";
      } else {
        fleetId = "";
      }
      if (formData?.vehicleId !== "-1") {
        vehicleId = formData?.vehicleId;
      } else {
        vehicleId = "";
      }
    }
    //Set URL
    setDashboardUrl(
      "https://analytics.zoho.com/open-view/2399935000014921671?ZOHO_CRITERIA=" +
        regionId +
        dealerId +
        customerId +
        '"RESELLER_ID"=' +
        resellerId +
        ' AND "z_created_date" BETWEEN ' +
        `'${moment(formData?.fromDate).format("YYYY-MM-DD")}'` +
        " AND " +
        `'${moment(formData?.toDate).format("YYYY-MM-DD")}'` +
        ""
    );
  };

  const handleTapChanage = (event, newValue) => {
    setValue(newValue);
  };

  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText.trim();
    if (payload.search.length >= 3) {
      getMonthlyVehicle(payload);
    } else if (payload.search.length === 0) {
      getMonthlyVehicle(payload);
    }
  };

  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getMonthlyVehicle(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getMonthlyVehicle(payload);
    }
  };

  React.useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (monthlyVehicleWiseData?.data !== null) {
        setResponseData(monthlyVehicleWiseData?.data?.content);
        setTotalElements(monthlyVehicleWiseData?.data?.totalElements);
        setTotalPages(monthlyVehicleWiseData?.data?.totalPages);
      } else {
        setResponseData([]);
      }
    }
  }, [isSuccess]);



  // const [newPage, setNewPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(25);
  // const [totalElements, setTotalElements] = useState(5);
  // const [totalPages, setTotalPages] = useState(1);

  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Reports"
        subDiv="Monthly Summary Report"
        includePopup={true}
      ></Breadcrumb>
      <MouseOverPopover text={text} title={"Monthly Summary Report"} />

      {/* Dropdown */}

      <FilterDropdown
        getData={onSubmitForm}
        status={false}
        monthPicker={true}
        value={value}
        last6Month={true}
        getDataLoading={vehicleWiseLoading || vehicleDaywiseLoading}
      />
      <Box sx={{ m: 0 }}>
        {resellerId === 110224 && (
          <StyledTabBox>
            <StyledTabsGroup
              value={value}
              variant="scrollable"
              onChange={handleTapChanage}
              sx={{
                "& .MuiTabs-flexContainer": {
                  flexDirection: "row",
                },
              }}
            >
              <StyledTab label={"Monthly Summary Report"} key={0} />
              {
                orgType === 2 && <StyledTab label={"Monthly Analytics Report"} key={1} />
              }
            </StyledTabsGroup>
          </StyledTabBox>
        )}
        {value === 0 && (
          <StyledToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleAlignment}
            onClick={handleChange}
            size="small"
            sx={{ mt: 2 }}
          >
            <StyledToggleButton value={"vehicleWise"}>
              Vehicle Wise
            </StyledToggleButton>
            <StyledToggleButton value={"DayWise"}>Day Wise</StyledToggleButton>
          </StyledToggleButtonGroup>
        )}
      </Box>
      {value === 0 && (
        <>
          {hideDetails === "vehicleWise" && (
            <MonthlySummaryvehicleWiseReport
              data={responseData}
              payload={payload}
              isLoadingOut={vehicleWiseLoading}
              handleSearch={handleSearch}
              pageChange={pageChange}
              newPage={newPage}
              rowsPerPage={rowsPerPage}
              totalElements={totalElements}
            />
          )}
          {hideDetails === "DayWise" && (
            <MonthlySummaryDayWiseReport
              data={monthlyDayWiseData}
              payload={payload}
            />
          )}
        </>
      )}

      {/* Only for Montra */}
      {value === 1 && (
        <>
          <CardContent sx={{ py: 1 }}>
            <Box sx={{ height: "100vh" }}>
              <iframe
                className="iframe"
                src={dashboardUrl}
                width="100%"
                height="98%"
                ref={iframeReload}
              ></iframe>
            </Box>
          </CardContent>
        </>
      )}
    </StyledPageLayout>
  );
};
export default MonthlySummaryReport;
