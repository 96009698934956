import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import userServices from "../../services/userServices";
import {useGetUserActivationMutation } from '../../store/slice/ApiSlice';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';
import {
  SoftButton,
  StyledFormButtonsRow,
  StyledFormControlLabel,
  StyledPaperShadow,
  StyledTextField,
} from "../../theme/StyledEle";
import {
  Collapse,
  FormLabel,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  CardMedia,
  Stack,
  useTheme,
} from "@mui/material";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useForm } from "react-hook-form";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import moment from "moment";
import powerBlockNesh from '../../assets/images/NeshPoeweLogo_blck.svg'


export default function SignIn2() {
  const [open, setOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const navigate = useNavigate();
  const [responseData, setResponseData] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [openPop, setOpenPop] = useState(false);
  const [passwordPop, setPasswordPop] = useState(false);
  const [expiredPop, setExpiredPop] = useState(false);
  const [validation, setValidation] = useState(false);

const [lower, setLower] = useState(false);
const [upper, setUpper] = useState(false);
const [number, setNumber] = useState(false);
const [special, setSpecial] = useState(false);
const [noSpace, setNoSpace] = useState(false);
const theme = useTheme();

//validate
var lowerCaseLetters = new RegExp(/[a-z]/);
var upperCaseLetters = new RegExp(/[A-Z]/);
var numbers = new RegExp(/[0-9]/);
var space = new RegExp(/\s/);
var specialChar = new RegExp(/[!@#$%^&*]/);

const passwordValidate = (password) => {
    if(password.length > 0){
      setValidation(true);
    }else{
      setValidation(false);
    }
   if(lowerCaseLetters.test(password) ){
      setLower(true);
   }else{
    setLower(false);
   }

   if(upperCaseLetters.test(password)){
    setUpper(true);
  }else{
   setUpper(false);
 }
   
   if(numbers.test(password) ){
    setNumber(true);
     }else{
      setNumber(false);
     }
     if(!space.test(password) ){
      setNoSpace(true);
     }else{
      setNoSpace(false);
     }if(specialChar.test(password) ){
      setSpecial(true);
     }else{
      setSpecial(false);
     }
    
  }
  // function PasswordNote() {
  //   return (
  //     <List sx={{ listStyleType: "disc", listStylePosition: "inside" }}>
  //       <ListItem>Password must meet the following requirements:</ListItem>
  //       <ListItem sx={{ display: "list-item", fontWeight: 400,color:lower?"green":"red" }}>
  //         At least one lowercase
  //       </ListItem>
  //       <ListItem sx={{ display: "list-item", fontWeight: 400,color:upper?"green":"red" }}>
  //         At least one uppercase
  //       </ListItem>
  //       <ListItem sx={{ display: "list-item", fontWeight: 400,color:number?"green":"red" }}>
  //         At least one number
  //       </ListItem>
  //       <ListItem sx={{ display: "list-item", fontWeight: 400,color:special?"green":"red" }}>
  //         Special character
  //       </ListItem>
  //       <ListItem sx={{ display: "list-item", fontWeight: 400,color:noSpace?"green":"red" }}>
  //         No spacing
  //       </ListItem>
  //     </List>
  //   );
  // }
  let params = window.location.href;
  var hashCode = params?.split("?")[1]?.split("=")[1];



  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
    defaultValues: {
      name: null,
      password: null,
      captcha: null,
    },
  });

  const [getUserActivation, { data: getUserActivationData, isLoading: getUserActivationLoading, isSuccess: getUserActivationSucccess }] = useGetUserActivationMutation();

  console.log("getUserActivationData",getUserActivationData)
  console.log("getUserActivationLoading",getUserActivationLoading)
    useEffect(() => {
        const payload = {
          hashCode: hashCode,
        };
        getUserActivation(payload)
    }, [])

    useEffect(()=>{
    if(getUserActivationData !==undefined && getUserActivationData !==null){
      if(getUserActivationData.resultText === "0"){
        setPasswordPop(true);
              }else{
                setExpiredPop(true);
              }
    }
    },[getUserActivationData])


  const showpassword = () => {
    setShowPassword(!showPassword);
  };
  const handleLogin = () => {
    setOpenPop(true);
    setPasswordPop(false);
  };

  const onSubmit = async (e) => {
    setValidation(false);
    var request = {};
    request.setPassword = e.confirmPassword;
    request.id = hashCode;

    const responseData = await userServices.saveUserPassword(request);
    var obj1 = {};
    obj1.dialogText = "Data Saved Successfully";

    setDialogContent(obj1);
    setDataStatusConfirm("Success");
    setOpen(true);
    if (responseData.status === 200) {
      setOpen(false);
      setTimeout(() => handleLogin(), 2000);
    }
  };

  return (
    <Box width="100%">
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          position: "relative",
          "&::before": {
            position: "absolute",
            top: "0",
            left: "0",
            content: '""',
            background: "linear-gradient(0deg, #ffefeb, #d7d7d7)",
            opacity: ".4",
          },
        }}
      >
         {/* <Stack direction="row" spacing={2}> */}
    
        {getUserActivationLoading ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
          <Skeleton
            variant="rectangular"
            width={350}
            height={20}
            animation="wave"
            sx={{ marginTop: 3 }}
          />
          <Skeleton
            variant="rectangular"
            width={450}
            height={40}
            animation="wave"
            sx={{ marginTop: 3 }}
          />
          <Skeleton
            variant="rectangular"
            width={350}
            height={20}
            animation="wave"
            sx={{ marginTop: 3 }}
          />
          <Skeleton
            variant="rectangular"
            width={450}
            height={40}
            animation="wave"
            sx={{ marginTop: 3 }}
          />
          <Skeleton
            variant="rectangular"
            width={350}
            height={20}
            animation="wave"
            sx={{ marginTop: 3 }}
          />
        </Box>
        
        ) : (<StyledPaperShadow
          elevation={1}
          sx={{
            width: { lg: "auto", md: "auto", sm: "auto" },
            display: "block",
            maxHeight: { lg: "auto", md: "100%", sm: "100%" },
            p: 3,
            boxShadow: "none",
            height: { lg: "fit-content", md: "fit-content" },
            zIndex: 999,
            position: "relative",
            overflow: { lg: "hidden", md: "auto", sm: "auto" },
          }}
        >
          {(passwordPop) ? (
            <>
              <Box sx={{ mb: 3 }}>
                <Typography variant="h5">Password</Typography>
              </Box>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{ width: "100%" }}
              >
                <Stack direction="column" spacing={2}>
                  <StyledFormControlLabel>
                    <FormLabel>Password</FormLabel>

                    <StyledTextField
                      size="small"
                      error={errors.password && true}
                      {...register("password", {
                        required: {
                          value: true,
                          message: "Please enter New Password ",
                        },
                        minLength: {
                          value: 5,
                          message: " At least 8 characters",
                        },
                        maxLength: {
                          value: 8,
                          message: "Not more than 8 characters",
                        },
                        pattern: {
                          value:
                            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                          message: "Invalid Password",
                        },
                      })}
                      onChange={(e) =>{ setValue("password", e.target.value);passwordValidate(e.target.value)}}
                      helperText={errors.password && errors.password.message}
                      type={showPassword ? "text" : "password"}
                      id="password"
                      autoComplete="current-password"
                      fullWidth
                      inputProps={{ maxLength: 8 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={showpassword} size="small">
                              {showPassword ? (
                                <AiFillEyeInvisible />
                              ) : (
                                <AiFillEye />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </StyledFormControlLabel>

                  <StyledFormControlLabel>
                    <FormLabel>Confirm Password</FormLabel>
                    <StyledTextField
                      size="small"
                      error={errors.confirmPassword && true}
                      {...register("confirmPassword", {
                        required: {
                          value: true,
                          message: "Please enter Password",
                        },
                        maxLength: {
                          value: 8,
                          message: "Not more than 8 characters",
                        },
                        // pattern: {
                        //   value:
                        //     /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                        //   message: "Invalid Password",
                        // },

                        validate: {
                          emailAvailable: async (fieldValue) => {
                            var result = "";
                            if (fieldValue === getValues("password")) {
                              result = "Valid Password";
                            } else {
                              result = "InValid Password";
                            }

                            return (
                              result !== "InValid Password" ||
                              "Password does not match"
                            );
                          },
                        },
                      })}
                      onChange={(e) =>
                        setValue("confirmPassword", e.target.value)
                      }
                      helperText={
                        errors.confirmPassword && errors.confirmPassword.message
                      }
                      type={showPassword ? "text" : "password"}
                      id="ConfirmPassword"
                      autoComplete="confirm-password"
                      fullWidth
                      inputProps={{ maxLength: 8 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={showpassword}
                              size="small"
                              sx={{ padding: 0 }}
                            >
                              {showPassword ? (
                                <AiFillEyeInvisible />
                              ) : (
                                <AiFillEye />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </StyledFormControlLabel>
                  <Collapse in={validation}>
                      <Typography variant="caption" sx={{width:'100%' ,color:lower? theme.palette.success.main:theme.palette.error.main}} component={'p'}  >* At least one lowercase</Typography>
                      <Typography variant="caption" sx={{width:'100%',color:upper? theme.palette.success.main:theme.palette.error.main}} component={'p'}  >* At least one uppercase</Typography>
                      <Typography variant="caption" sx={{width:'100%',color:number? theme.palette.success.main:theme.palette.error.main}} component={'p'}  >* At least one number</Typography>
                      <Typography variant="caption" sx={{width:'100%',color:special?theme.palette.success.main:theme.palette.error.main}} component={'p'}  >* Special character</Typography>
                      <Typography variant="caption" sx={{width:'100%',color:noSpace?theme.palette.success.main:theme.palette.error.main}} component={'p'}  >* No spacing</Typography>
                  </Collapse>
                </Stack>
                <StyledFormButtonsRow
                  direction="row"
                  sx={{ mt: 5 }}
                  spacing={2}
                >
                                    <SoftButton
                    size="small"
                    variant="contained"
                    color="error"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Cancel
                  </SoftButton>
                  <SoftButton
                    type="submit"
                    size="small"
                    variant="contained"
                    color="success"
                  >
                    Submit
                  </SoftButton>

                </StyledFormButtonsRow>
                {/* <PasswordNote sx={{ mt: 4 }} /> */}
              </Box>
            </>
          ) : (
            expiredPop && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "1px solid white",
              }}
            >
              <Typography component="h5" variant="h5" sx={{ mt: 1 }}>
                Sorry, this password link is no longer valid.
              </Typography>
              <SoftButton
                size="small"
                variant="contained"
                color="success"
                onClick={() => {
                  navigate("/");
                }}
                sx={{ mt: 3 }}
              >
                Back to Login
              </SoftButton>
            </Box>
         ) )}
          {openPop && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "1px solid white",
              }}
            >
              <Typography component="h5" variant="h5" sx={{ mt: 1 }}>
                Your Password has been created successfully. Please use your new
                password to login into portal.
              </Typography>
              <SoftButton
                size="small"
                variant="contained"
                color="success"
                onClick={() => {
                  navigate("/");
                }}
                sx={{ mt: 3 }}
              >
                Back to Login
              </SoftButton>
            </Box>


          )}
             </StyledPaperShadow>
             )}
            {/* {validation &&
           <StyledPaperShadow
          elevation={1}
          sx={{
            width: { lg: "380px", md: "350px", sm: "350px" },
            display: "block",
            maxHeight: { lg: "auto", md: "100%", sm: "100%" },
            p: 3,
            boxShadow: "none",
            height: { lg: "fit-content", md: "fit-content" },
            zIndex: 999,
            position: "relative",
            overflow: { lg: "hidden", md: "auto", sm: "auto" },
          }}
        >
          <Box
sx={{
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: "1px solid white",
}}
>
<PasswordNote/>
</Box>
</StyledPaperShadow>
} */}
{/* </Stack> */}


<Typography variant="caption" sx={{ mt: 3, zIndex:99, display:'flex', alignItems:'center'}}>
        Copyrights @ {moment().format('YYYY')}, All rights reserved nesh.live
      </Typography>
      <Typography variant="caption" sx={{ mt: 0.5, zIndex:99 }}>
        {" "}
        {process.env.REACT_APP_VERSION}
      </Typography>
      </Box>
      {open && (
        <StyledSnackBar
          open={true}
          message={dialogContent.dialogText}
          status={dataStatusConfirm}
        />
      )}
    </Box>
  );
}
