import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import {
  CardHeader,
  CardContent,
  Typography,
  Button,
  Icon,
  Stack,
  Tooltip,
} from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import ReportNewDropDown from "../shared/ReportsDropDownNew";
import { ButtonAlign } from "../shared/button/ButtonAlign";
import StyledTooltip from "../shared/StyledTooltip";
import logServices from "../../services/logService";
import { GoDotFill } from "react-icons/go";
import {
  StyledCardShadow,
  Exportoptions,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import {
  useAuditLogMutation,
  useTripMutation,
} from "../../store/slice/ApiSlice";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import FormDialog from "../../Utils/FormDialog";
import { useNavigate } from "react-router-dom";

const text =
  "A Trip is defined as distance traveled by the vehicle between On and Off state. The minimum distance traveled must be greater than 1km to be considered in this trip report. Energy Efficiency is calculated for vehicles that have traveled more than 10km per trip.";

const TripReport = () => {
  const navigate = useNavigate();
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  const theme = useTheme();

  useEffect(async () => {
    // await fetchTripReportReportDetails();
    // var request = {};
    // request.message = "Reports > TripReport";
    // request.action = "View";
    // request.featureName = "TripReport";
    // request.customerName = "All";
    // request.fleetName = "All";
    // request.result = "Success";
    // request.access = "Portal";
    // request.severity = "INFO";
    // request.orgId = globalOrgId;
    // request.userId = globaluserId;
    // request.orgName = globalOrgName;
    // request.userName= globalUserName;
    //const responseData = await logServices.saveAuditLogDetails(request);
    auditPayload.message = "Reports > Trip Report";
    auditPayload.action = "View";
    auditPayload.featureName = "Trip Report";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Vehicle Number",
        accessor: "vehicleno",
        width:180,
      },

      {
        Header: "Start Location",
        accessor: "startLoc",
        width:350,
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.startLoc}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.startLoc}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.startLoc}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.startLoc}
                >
                  {row.original.startLoc}
                </a>
                <a
                  className="address"
                  ref={refAddres}
                  id={row.original.startLoc}
                  key={row.original.startLoc}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "End Location",
        accessor: "endLoc",
        width:350,
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.endLoc}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.endLoc}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.endLoc}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.endLoc}
                >
                  {row.original.endLoc}
                </a>
                <a
                  className="address"
                  ref={refAddres}
                  id={row.original.endLoc}
                  key={row.original.endLoc}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "Start Time",
        accessor: "tripStartTime",
        width:180,
      },
      {
        Header: "End Time",
        accessor: "tripEndTime",
        width:180,
      },
      {
        Header: "Duration (hh:mm)",
        accessor: "duration",
      },

      {
        Header: "Gps Start Odometer (km)",
        accessor: "startOdometer",
      },
      {
        Header: "Gps End Odometer (km)",
        accessor: "endOdometer",
      },
      {
        Header: "GPS Distance Traveled (km)",
        accessor: "distTravelled",
      },
      {
        Header: "GPS Average Speed (km/h)",
        accessor: "avgSpeed",
      },

      {
        Header: "GPS Max Speed (km/h)",
        accessor: "maxSpeed",
      },
      {
        Header: "Moving (hh:mm)",
        accessor: "movminsinhrs",
      },
      {
        Header: "CAN Start Odometer (km)",
        accessor: "canStartOdometer",
      },
      {
        Header: "CAN End Odometer (km)",
        accessor: "canEndOdometer",
      },
      {
        Header: "CAN Distance Traveled (km)",
        accessor: "canDistTravelled",
      },
      {
        Header: "Start Voltage (V)",
        accessor: "startVoltage",
      },
      {
        Header: "End Voltage (V)",
        accessor: "endVoltage",
      },
      {
        Header: "Battery Temperature Rise (℃)",
        accessor: "temprise",
      },

      {
        Header: "Max Battery Temp (℃)",
        accessor: "maxBtTemp",
      },
      {
        Header: "Avg Battery Temp (℃)",
        accessor: "avgBtTemp",
      },
      {
        Header: "Max Battery Current (A)",
        accessor: "maxBtCurrent",
      },

      // {
      //   Header: "Avg Battery Current (A)",
      //   accessor: "avgBtCurrent",
      // },
      {
        Header:
          resellerId === 110224 ? "Start Energy (kWh)" : "Start Energy (Wh)",
        accessor: "startEnergy",
      },
      {
        Header: resellerId === 110224 ? "End Energy (kWh)" : "End Energy (Wh)",
        accessor: "endEnergy",
      },
      {
        Header:
          resellerId === 110224
            ? "Energy Consumed (kWh)"
            : "Energy Consumed (Wh)",
        accessor: "energyConsumed",
      },
      {
        Header: (
          <StyledTooltip
            header={
              resellerId === 110224
                ? "Energy Efficiency (km/kWh)"
                : "Energy Efficiency (wh/km)"
            }
            title={"Considered for Distance > 10km/day"}
          />
        ),
        accessor: "energyefficiency",
        Cell: ({ row }) => {
          if(  row.original.energyefficiency === 0 ){
            return "-"
          }else{
            return row.original.energyefficiency 
          }
        }
      },
    
      // {
      //   Header:globalResellerId===110224?'Energy Efficiency (km/kWh)':(globalResellerId===109882?'Energy Efficiency (wh/km)':'Energy Efficiency (km/Wh)'),
      //   accessor: "energyefficiency",
      // },
      // {
      // Header: (
      //   <StyledTooltip
      //     header={globalResellerId===110224?'Energy Efficiency (kWh/km)':(globalResellerId===109882?'Energy Efficiency (km/Wh)':'Energy Efficiency (Wh/km)')}
      //     title={"Considered for Distance > 10km/day"}
      //   />

      // ),
      // accessor: "sss",
      // },
      // {
      //   Header:globalResellerId===110224?'Energy Efficiency (kWh/km)':(globalResellerId===109882?'Energy Efficiency (km/Wh)':'Energy Efficiency (Wh/km)'),
      //   accessor: "",
      // },
      {
        Header: "Start SOC (%)",
        accessor: "strstartsoc",
      },
      {
        Header: "End SOC (%)",
        accessor: "strendsoc",
      },
      {
        Header: "Trip Status",
        accessor: "tripStatus",
      },
    ],
    []
  );

  const columns1 = useMemo(
    () => [
      {
        Header: "Vehicle Number",
        accessor: "vehicleno",
        width:180,
      },

      {
        Header: "Start Location",
        accessor: "startLoc",
        width:350,
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.startLoc}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.startLoc}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.startLoc}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.startLoc}
                >
                  {row.original.startLoc}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.startLoc}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.startLoc}
                  key={row.original.startLoc}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "End Location",
        accessor: "endLoc",
        width:350,
        Cell: ({ row, column, cell }) => {
  
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.endLoc}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.endLoc}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.endLoc}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.endLoc}
                >
                  {row.original.endLoc}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.endLoc}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.endLoc}
                  key={row.original.endLoc}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "Start Time",
        accessor: "tripStartTime",
      },
      {
        Header: "End Time",
        accessor: "tripEndTime",
      },
      {
        Header: "Duration (hh:mm)",
        accessor: "duration",
      },

      {
        Header: "Average Speed (km/h)",
        accessor: "avgSpeed",
      },
      {
        Header: "Sweet Spot Speed (km/h)",
        accessor: "minSpeedRange",
        Cell: ({ row }) => {
         
          if (resellerId === 110224) {
            return (
              <Box>
                <Stack
                  direction={"row"}
                  spacing={1}
                  justifyContent={"center !important"}
                >
                  <Tooltip
                    title={
                      parseFloat(row.original.avgSpeed) > parseFloat(row.original.maxSpeedRange)
                        ? "Greater than Nominal Speed Range"
                        :   parseFloat(row.original.avgSpeed) <   parseFloat(row.original.minSpeedRange)
                        ? "Less than Nominal Speed Range"
                        : "Between Nominal Speed Range"
                    }
                    arrow
                  >
                    <Icon
                      sx={{ width: "auto", height: "auto", display: "flex" }}
                      color={
                        parseFloat(row.original.avgSpeed) > parseFloat(row.original.maxSpeedRange)
                          ? "error"
                          :   parseFloat(row.original.avgSpeed) <   parseFloat(row.original.minSpeedRange)
                          ? "info"
                          : "success"
                      }
                    >
                      <GoDotFill />
                    </Icon>
                  </Tooltip>
                </Stack>
              </Box>
            );
          }
        },
      },
      {
        Header: "Max Speed (km/h)",
        accessor: "maxSpeed",
      },

      {
        Header: "Moving (hh:mm)",
        accessor: "movminsinhrs",
      },
      {
        Header: "Start Odometer (km)",
        accessor: "canStartOdometer",
      },
      {
        Header: "End Odometer (km)",
        accessor: "canEndOdometer",
      },
      {
        Header: "Distance Traveled (km)",
        accessor: "canDistTravelled",
      },
      {
        Header: "Start Voltage (V)",
        accessor: "startVoltage",
      },
      {
        Header: "End Voltage (V)",
        accessor: "endVoltage",
      },
      {
        Header: "Battery Temperature Rise (℃)",
        accessor: "temprise",
      },

      {
        Header: "Max Battery Temp (℃)",
        accessor: "maxBtTemp",
      },
      {
        Header: "Avg Battery Temp (℃)",
        accessor: "avgBtTemp",
      },
      {
        Header: "Max Battery Current (A)",
        accessor: "maxBtCurrent",
      },
      // {
      //   Header: "Avg Battery Current (A)",
      //   accessor: "avgBtCurrent",
      // },
      {
        Header:
          resellerId === 110224 ? "Start Energy (kWh)" : "Start Energy (Wh)",
        accessor: "startEnergy",
      },
      {
        Header: resellerId === 110224 ? "End Energy (kWh)" : "End Energy (Wh)",
        accessor: "endEnergy",
      },
      {
        Header:
          resellerId === 110224
            ? "Energy Consumed (kWh)"
            : "Energy Consumed (Wh)",
        accessor: "energyConsumed",
      },
      {
        Header:
          resellerId === 110224
            ? "Energy Efficiency (km/kWh)"
            : resellerId === 109882
            ? "Energy Efficiency (wh/km)"
            : "Energy Efficiency (km/Wh)",
        accessor: "energyefficiency",
      },
      {
        Header: (
          <StyledTooltip
            header={
              resellerId === 110224
                ? "Energy Efficiency (kWh/km)"
                : "Energy Efficiency (km/Wh)"
            }
            title={"Considered for Distance > 10km/day"}
          />
        ),
        accessor: "energyefficiencyPerKm",
        Cell: ({ row }) => {
      
          if(  row.original.energyefficiencyPerKm === 0  ){
            return "-"
          }else{
            return row.original.energyefficiencyPerKm 
          }
        }
      },
      {
        Header: "Start SOC (%)",
        accessor: "strstartsoc",
      },
      {
        Header: "End SOC (%)",
        accessor: "strendsoc",
      },
      {
        Header: "Trip Status",
        accessor: "tripStatus",
      },
    ],
    []
  );
  const columns2 = useMemo(
    () => [
      {
        Header: "Vehicle Number",
        accessor: "vehicleno",
      },

      {
        Header: "Start Location",
        accessor: "startLoc",

        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.startLoc}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.startLoc}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.startLoc}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.startLoc}
                >
                  {row.original.startLoc}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.startLoc}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.startLoc}
                  key={row.original.startLoc}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "End Location",
        accessor: "endLoc",
        Cell: ({ row, column, cell }) => {

          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.endLoc}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.endLoc}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.endLoc}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.endLoc}
                >
                  {row.original.endLoc}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.endLoc}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.endLoc}
                  key={row.original.endLoc}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "Start Time",
        accessor: "tripStartTime",
      },
      {
        Header: "End Time",
        accessor: "tripEndTime",
      },
      {
        Header: "Duration (hh:mm)",
        accessor: "duration",
      },

      {
        Header: "Average Speed (km/h)",
        accessor: "avgSpeed",
      },
  
      {
        Header: "Max Speed (km/h)",
        accessor: "maxSpeed",
      },

      {
        Header: "Moving (hh:mm)",
        accessor: "movminsinhrs",
      },
      {
        Header: "Start Odometer (km)",
        accessor: "canStartOdometer",
      },
      {
        Header: "End Odometer (km)",
        accessor: "canEndOdometer",
      },
      {
        Header: "Distance Traveled (km)",
        accessor: "canDistTravelled",
      },
      {
        Header: "Start Voltage (V)",
        accessor: "startVoltage",
      },
      {
        Header: "End Voltage (V)",
        accessor: "endVoltage",
      },
      {
        Header: "Battery Temperature Rise (℃)",
        accessor: "temprise",
      },

      {
        Header: "Max Battery Temp (℃)",
        accessor: "maxBtTemp",
      },
      {
        Header: "Avg Battery Temp (℃)",
        accessor: "avgBtTemp",
      },
      {
        Header: "Max Battery Current (A)",
        accessor: "maxBtCurrent",
      },
      // {
      //   Header: "Avg Battery Current (A)",
      //   accessor: "avgBtCurrent",
      // },
      {
        Header:
          resellerId === 110224 ? "Start Energy (kWh)" : "Start Energy (Wh)",
        accessor: "startEnergy",
      },
      {
        Header: resellerId === 110224 ? "End Energy (kWh)" : "End Energy (Wh)",
        accessor: "endEnergy",
      },
      {
        Header:
          resellerId === 110224
            ? "Energy Consumed (kWh)"
            : "Energy Consumed (Wh)",
        accessor: "energyConsumed",
      },
      {
        Header:
          resellerId === 110224
            ? "Energy Efficiency (km/kWh)"
            : resellerId === 109882
            ? "Energy Efficiency (wh/km)"
            : "Energy Efficiency (km/Wh)",
        accessor: "energyefficiency",
      },
      {
        Header: (
          <StyledTooltip
            header={
              resellerId === 110224
                ? "Energy Efficiency (kWh/km)"
                : "Energy Efficiency (km/Wh)"
            }
            title={"Considered for Distance > 10km/day"}
          />
        ),
        accessor: "energyefficiencyPerKm",
        Cell: ({ row }) => {
       
          if(  row.original.energyefficiencyPerKm === 0  ){
            return "-"
          }else{
            return row.original.energyefficiencyPerKm 
          }
        }
      },
      {
        Header: "Start SOC (%)",
        accessor: "strstartsoc",
      },
      {
        Header: "End SOC (%)",
        accessor: "strendsoc",
      },
      {
        Header: "Trip Status",
        accessor: "tripStatus",
      },
    ],
    []
  );



  const columns3 = useMemo(
    () => [
      {
        Header: "Vehicle Number",
        accessor: "vehicleno",
        width:180,
      },

      {
        Header: "Start Location",
        accessor: "startLoc",
        width:350,
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.startLoc}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.startLoc}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.startLoc}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.startLoc}
                >
                  {row.original.startLoc}
                </a>
                <a
                  className="address"
                  ref={refAddres}
                  id={row.original.startLoc}
                  key={row.original.startLoc}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "End Location",
        accessor: "endLoc",
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.endLoc}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.endLoc}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.endLoc}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.endLoc}
                >
                  {row.original.endLoc}
                </a>
                <a
                  className="address"
                  ref={refAddres}
                  id={row.original.endLoc}
                  key={row.original.endLoc}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "Start Time",
        accessor: "tripStartTime",
      },
      {
        Header: "End Time",
        accessor: "tripEndTime",
      },
      {
        Header: "Duration (hh:mm)",
        accessor: "duration",
      },

      {
        Header: "Gps Start Odometer (km)",
        accessor: "startOdometer",
      },
      {
        Header: "Gps End Odometer (km)",
        accessor: "endOdometer",
      },
      {
        Header: "GPS Distance Traveled (km)",
        accessor: "distTravelled",
      },
      {
        Header: "GPS Average Speed (km/h)",
        accessor: "avgSpeed",
      },

      {
        Header: "GPS Max Speed (km/h)",
        accessor: "maxSpeed",
      },
      {
        Header: "Moving (hh:mm)",
        accessor: "movminsinhrs",
      },
      {
        Header: "CAN Start Odometer (km)",
        accessor: "canStartOdometer",
      },
      {
        Header: "CAN End Odometer (km)",
        accessor: "canEndOdometer",
      },
      {
        Header: "CAN Distance Traveled (km)",
        accessor: "canDistTravelled",
      },
      // {
      //   Header: "Start Voltage (V)",
      //   accessor: "startVoltage",
      // },
      // {
      //   Header: "End Voltage (V)",
      //   accessor: "endVoltage",
      // },
      // {
      //   Header: "Battery Temperature Rise (℃)",
      //   accessor: "temprise",
      // },

      // {
      //   Header: "Max Battery Temp (℃)",
      //   accessor: "maxBtTemp",
      // },
      // {
      //   Header: "Avg Battery Temp (℃)",
      //   accessor: "avgBtTemp",
      // },
      // {
      //   Header: "Max Battery Current (A)",
      //   accessor: "maxBtCurrent",
      // },

      // // {
      // //   Header: "Avg Battery Current (A)",
      // //   accessor: "avgBtCurrent",
      // // },
      // {
      //   Header:
      //     resellerId === 110224 ? "Start Energy (kWh)" : "Start Energy (Wh)",
      //   accessor: "startEnergy",
      // },
      // {
      //   Header: resellerId === 110224 ? "End Energy (kWh)" : "End Energy (Wh)",
      //   accessor: "endEnergy",
      // },
      // {
      //   Header:
      //     resellerId === 110224
      //       ? "Energy Consumed (kWh)"
      //       : "Energy Consumed (Wh)",
      //   accessor: "energyConsumed",
      // },
      // {
      //   Header: (
      //     <StyledTooltip
      //       header={
      //         resellerId === 110224
      //           ? "Energy Efficiency (km/kWh)"
      //           : "Energy Efficiency (wh/km)"
      //       }
      //       title={"Considered for Distance > 10km/day"}
      //     />
      //   ),
      //   accessor: "energyefficiency",
      //   Cell: ({ row }) => {
      //     if(  row.original.energyefficiency === 0 ){
      //       return "-"
      //     }else{
      //       return row.original.energyefficiency 
      //     }
      //   }
      // },
    
      // {
      //   Header:globalResellerId===110224?'Energy Efficiency (km/kWh)':(globalResellerId===109882?'Energy Efficiency (wh/km)':'Energy Efficiency (km/Wh)'),
      //   accessor: "energyefficiency",
      // },
      // {
      // Header: (
      //   <StyledTooltip
      //     header={globalResellerId===110224?'Energy Efficiency (kWh/km)':(globalResellerId===109882?'Energy Efficiency (km/Wh)':'Energy Efficiency (Wh/km)')}
      //     title={"Considered for Distance > 10km/day"}
      //   />

      // ),
      // accessor: "sss",
      // },
      // {
      //   Header:globalResellerId===110224?'Energy Efficiency (kWh/km)':(globalResellerId===109882?'Energy Efficiency (km/Wh)':'Energy Efficiency (Wh/km)'),
      //   accessor: "",
      // },
      {
        Header: "Start SOC (%)",
        accessor: "strstartsoc",
      },
      {
        Header: "End SOC (%)",
        accessor: "strendsoc",
      },
      {
        Header: "Trip Status",
        accessor: "tripStatus",
      },
    ],
    []
  );
  // var loadrequest = {};
  // const setloadvalues = () => {
  //   switch (globalOrgType) {
  //     case 8: // login as superadmin and reseller
  //     case 2:

  //     loadrequest.regionId = globalResellerId===113802? 1:-1;
  //     loadrequest.reselId = globalOrgId;
  //     loadrequest.dealerId = globalResellerId===113802? 113806:-1;
  //     loadrequest.custId = -1;
  //     loadrequest.orgId = -1; // fleet
  //     loadrequest.vehId = -1;
  //       break;
  //     // case 2: // login as reseller
  //     //     loadrequest.regionId= -1;
  //     //     loadrequest.reselId = globalOrgId;
  //     //     loadrequest.dealerId= -1;
  //     //     loadrequest.customerId = -1;
  //     //     loadrequest.dealerId= -1;
  //     //     loadrequest.orgId = -1; // fleet
  //     //     loadrequest.vehicleId = -1;
  //     //      break;

  //     case 5: // login as Dealer
  //       loadrequest.regionId = 0;
  //       loadrequest.dealerId = globalOrgId;
  //       loadrequest.custId = -1;
  //       loadrequest.orgId = -1; // fleet
  //       loadrequest.vehId = -1;

  //       break;
  //     case 3: // login as customer
  //       loadrequest.regionId = 0;
  //       loadrequest.custId = globalOrgId;
  //       loadrequest.dealerId = 0;
  //       loadrequest.orgId = -1; // fleet
  //       loadrequest.vehId = -1;

  //       break;
  //     case 1: // login as fleet
  //       loadrequest.regionId = 0;
  //       loadrequest.custId = globalCustomerId;
  //       loadrequest.dealerId = 0;
  //       loadrequest.orgId = globalOrgId; // fleet
  //       loadrequest.vehId = -1;

  //       break;
  //   }
  // };

  // {
  //   "orgId": 109925,
  //   "regionId": 1,
  //   "reselId": 109898,
  //   "vehId": 26202,
  //   "startDate": "2023-03-01 13:49:41",
  //   "endDate": "2023-03-22 13:49:41",
  //   "sortBy": "desc",
  //   "page": 0,
  //   "size": 10,
  //   "search": "",
  //   "dealerId": 109926,
  //   "custId": 109918
  // }

  // const fetchTripReportReportDetails = async () => {
  //   setloadvalues();
  //   loadrequest.size = rowsPerPage;
  //   loadrequest.page = newPage;
  //   const fromDate =
  //     format(addDays(new Date(), -1), "yyyy-MM-dd") + " 00:00:00";
  //   const toDate = format(addDays(new Date(), -1), "yyyy-MM-dd") + " 23:59:59";

  //   loadrequest.search = "";
  //   loadrequest.startDate = fromDate;
  //   loadrequest.endDate = toDate;
  //   loadrequest.sortBy = "desc";

  //   setAssignedval(loadrequest);

  //   const responseData = (await ReportServices.TripReport(loadrequest)).data;
  //   setResponseData([]);
  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  //   setTotalElements(5);
  //   setTotalPages(0);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);
  // };
  // const callbackChangePage = async (newPage, rowsPerPage) => {
  //   setNewPage(newPage);
  //   setRowsPerPage(rowsPerPage);
  //   // var request = {};
  //   // request.orgId = 1;
  //   // request.vehicleId = 25909;
  //   loadrequest = assignedval;
  //   loadrequest.size = rowsPerPage;
  //   loadrequest.page = newPage;

  //   setAssignedval(loadrequest);
  //   const responseData = (await ReportServices.TripReport(loadrequest)).data;
  //   setResponseData([]);
  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  //   // setTotalElements(5);
  //   // setTotalPages(0);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);
  // };

  // const onChangeXcl = async () => {
  //   loadrequest = assignedval;

  //   loadrequest.length = rowsPerPage;
  //   loadrequest.start = newPage;

  //   try {
  //     var url =
  //       process.env.REACT_APP_API_BASE_URL +
  //       "TripReport/tripreportXLS.xls?orgId=" +
  //       loadrequest.orgId +
  //       "&regionId=" +
  //       loadrequest.regionId +
  //       "&reselId=" +
  //       loadrequest.reselId +
  //       "&vehId=" +
  //       loadrequest.vehId +
  //       "&startDate=" +
  //       loadrequest.startDate +
  //       "&sortBy=" +
  //       loadrequest.sortBy +
  //       "&endDate=" +
  //       loadrequest.endDate +
  //       "&search=" +
  //       loadrequest.search +
  //       "&dealerId=" +
  //       loadrequest.dealerId +
  //       "&custId=" +
  //       loadrequest.custId;

  //     window.open(url);

  //     var request = {};
  //     request.message = "Reports > XLS";
  //     request.action = "View";
  //     request.featureName = "TripReport";
  //     request.customerName = "All";
  //     request.fleetName = "All";
  //     request.result = "Success";
  //     request.access = "Portal";
  //     request.severity = "INFO";
  //     request.orgId = globalOrgId;
  //     request.userId = globaluserId;
  //     request.orgName = globalOrgName;
  //     request.userName= globalUserName;

  //     const responseData = await logServices.saveAuditLogDetails(request);

  //   } catch (error) {}
  // };

  // const onChangePDF = async () => {
  //   loadrequest = assignedval;

  //   loadrequest.length = rowsPerPage;
  //   loadrequest.start = newPage;

  //   try {
  //     var url =
  //       process.env.REACT_APP_API_BASE_URL +
  //       "TripReport/tripreportPDF.pdf?orgId=" +
  //       loadrequest.orgId +
  //       "&regionId=" +
  //       loadrequest.regionId +
  //       "&reselId=" +
  //       loadrequest.reselId +
  //       "&vehId=" +
  //       loadrequest.vehId +
  //       "&startDate=" +
  //       loadrequest.startDate +
  //       "&sortBy=" +
  //       loadrequest.sortBy +
  //       "&endDate=" +
  //       loadrequest.endDate +
  //       "&search=" +
  //       loadrequest.search +
  //       "&dealerId=" +
  //       loadrequest.dealerId +
  //       "&custId=" +
  //       loadrequest.custId;
  //     window.open(url);

  //     var request = {};
  //     request.message = "Reports > PDF";
  //     request.action = "View";
  //     request.featureName = "TripReport";
  //     request.customerName = "All";
  //     request.fleetName = "All";
  //     request.result = "Success";
  //     request.access = "Portal";
  //     request.severity = "INFO";
  //     request.orgId = globalOrgId;
  //     request.userId = globaluserId;
  //     request.orgName = globalOrgName;
  //     request.userName= globalUserName;

  //     const responseData = await logServices.saveAuditLogDetails(request);
  //   } catch (error) {}
  // };

  // const handleSearch = async (searchText) => {
  //   setSearchVal(searchText);
  //   loadrequest = assignedval;
  //   loadrequest.search = searchText;
  //   setAssignedval(loadrequest);
  //   const responseData = (await ReportServices.TripReport(loadrequest)).data;
  //   setResponseData([]);
  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  //   setTotalElements(5);
  //   setTotalPages(0);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);
  // };

  // const handleSubmit = async (data) => {
  //   loadrequest.regionId = data.region;
  //   loadrequest.reselId = data.reseller;
  //   //loadrequest.resellerId = 5;
  //   loadrequest.dealerId = data.dealer;
  //   loadrequest.custId = data.customer;
  //   //loadrequest.dealerId= data.dealer;
  //   loadrequest.orgId = data.fleet; // fleet
  //   loadrequest.vehId = data.vehicle;
  //   loadrequest.size = rowsPerPage;
  //   loadrequest.page = newPage;
  //   loadrequest.startDate = data.from_date;
  //   loadrequest.endDate = data.to_date;
  //   loadrequest.sortBy = "desc";
  //   // loadrequest.search = "";
  //   loadrequest.search = searchval;
  //   setAssignedval(loadrequest);
  //   const responseData = (await ReportServices.TripReport(loadrequest)).data;
  //   setResponseData([]);
  //   setResponseData(responseData.data.content);
  //   setLoading(responseData.resultText);
  //   setTotalElements(5);
  //   setTotalPages(0);
  //   setTotalElements(responseData.data.totalElements);
  //   setTotalPages(responseData.data.totalPages);
  // };

  // Get Data from New Dropdown
  const [getTripReport, { data: oversppedData, isLoading, isSuccess }] =
    useTripMutation();
  let commonPayload = {};
  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.reselId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.custId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehId = data.vehicleId;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.sortBy = "desc";
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    //Service Call
    setPayload(commonPayload);
    getTripReport(commonPayload);

    auditPayload.message = "Reports > Trip Report > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Trip Report";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  //Export PDF & Excel
  const [payload, setPayload] = useState(null);
  const onChangeXcl = async () => {
    if(totalElements > 5000){
      navigate('#popup');
    }else{
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "TripReport/tripreportXLS.xls?orgId=" +
        payload.orgId +
        "&regionId=" +
        payload.regionId +
        "&reselId=" +
        payload.reselId +
        "&vehId=" +
        payload.vehId +
        "&startDate=" +
        payload.startDate +
        "&sortBy=" +
        payload.sortBy +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        "&dealerId=" +
        payload.dealerId +
        "&custId=" +
        payload.custId;

      window.open(url);

      auditPayload.message = "Reports > Trip Report > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "TripReport";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
    } catch (error) {}
  }
  };
  const onChangePDF = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "TripReport/tripreportPDF.pdf?orgId=" +
        payload.orgId +
        "&regionId=" +
        payload.regionId +
        "&reselId=" +
        payload.reselId +
        "&vehId=" +
        payload.vehId +
        "&startDate=" +
        payload.startDate +
        "&sortBy=" +
        payload.sortBy +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        "&dealerId=" +
        payload.dealerId +
        "&custId=" +
        payload.custId;

      window.open(url);

      auditPayload.message = "Reports > Trip Report >PDF";
      auditPayload.action = "View";
      auditPayload.featureName = "TripReport";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
    } catch (error) {}
  };
  //Search Text
  const handleSearch = async (searchText) => {
      setNewPage(0);
      setRowsPerPage(25);
      payload.page = 0;
      payload.rowsPerPage = 25;
      payload.search=searchText;
    if (payload.search.length >= 3) {
      getTripReport(payload);
    } else if (payload.search.length === 0) {
      getTripReport(payload);
    }
  };
  let initialState1 = {};
  var arr = [];

  // if (resellerId !== 110224 ) {
  //   arr.push("minSpeedRange");

  //   initialState1.hiddenColumns = arr;
  // }
  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getTripReport(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getTripReport(payload);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (oversppedData.data !== null) {
        setResponseData(oversppedData.data.content);
        setTotalElements(oversppedData.data.totalElements);
        setTotalPages(oversppedData.data.totalPages);
      }

    }
  }, [isSuccess]);

  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Reports"
        subDiv="Trip Report"
        includePopup={true}
      ></Breadcrumb>
      <MouseOverPopover text={text} title={"Trip Report"} />
      <Box>
        {/* <ReportNewDropDown
          onSubmit={handleSubmit}
          isShowVeh={true}
          displayDate={true}
          allowDays={7}
        /> */}
        <FilterDropdown getData={onSubmitForm} thisMonth={true} currentMonth={true} status={false} allowDays={resellerId === 110224 ? 31 : 7} time={true} allowedMonth = {resellerId === 110224 ? 6 : 3} last6Month={true} last31={resellerId === 110224 ? true : false} getDataLoading={isLoading}/>
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              isLoading={isLoading}
              // initialState={initialState1}
              columns={
                resellerId === 110224 ?columns1: resellerId === 109882?columns2: resellerId === 114075 ?columns3
                 
                  : resellerId === 114367 ?columns3
                  : columns
              }

              data={responseData}
              onChangeXcl={onChangeXcl}
              onChangePdf={onChangePDF}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              includeSearchBox={true}
              handleChange={handleSearch}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
      <FormDialog title={'Alert'} size='sm'>
      <Typography>Exceeds limit , Use Filters Criteria</Typography>
      </FormDialog>
    </StyledPageLayout>
  );
};

export default TripReport;
