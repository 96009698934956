import { dashboardApi } from "../ApiSlice";

const HelpDeskSlice = dashboardApi.injectEndpoints({
    endpoints:builder => ({
        updateTikect: builder.mutation({
            query: (body) => ({
              url: "/helpdesk/updateTicket",
              method: "POST",
              body: body,
            }),
          }),
        updateDesciption: builder.mutation({
          query: (body) => ({
            url: "/helpdesk/updateDescription",
            method: "POST",
            body: body,
          }),
        }),

        ///helpdesk/getTicketdetails
        getTicketDetails: builder.mutation({
          query: (body) => ({
            url: "/helpdesk/getTicketdetails",
            method: "POST",
            body: body,
          }),
        }),
        //Create
        createTicket: builder.mutation({
          query: (body) => ({
            url: "/helpdesk/createTicket",
            method: "POST",
            body: body,
          }),
        }),

        //View Ticket
        ticketList: builder.mutation({
          query: (body) => ({
            url: "/helpdesk/listServiceRequestByMultiSel",
            method: "POST",
            body: body,
          }),
        }),

         //View Ticket - Admin Filter
         ticketFilterList: builder.mutation({
          query: (body) => ({
            url: "/helpdesk/listServiceRequestByViewType",
            method: "POST",
            body: body,
          }),
        }),
        ///getVehicle List
        getVehicleList: builder.mutation({
          query: (body) => ({
            url: "helpdesk/getSearchVehicle",
            method: "POST",
            body: body,
          }),
        }),

        ///helpdesk/uploadFileList
        attachemntUpload: builder.mutation({
          query: (body) => ({
            url: "helpdesk/uploadFileList",
            method: "POST",
            body: body,
          }),
        }),

        //Get Comments List
        getComments: builder.mutation({
          query: (body) => ({
            url: "helpdesk/getCommentsList ",
            method: "POST",
            body: body,
          }),
        }),

        ///REf Slice
        ///helpdesk/listReferenceByCategory
        categoryList: builder.query({
          query: () => ({
            url: "helpdesk/listReferenceByCategory",
            method: "GET"
          }),
        }),
        categorySettings: builder.query({
          query: () => ({
            url: "helpdesk/listTicketCategory",
            method: "GET",
          }),
        }),        
        //Dropdown
        getDropDown: builder.mutation({
          query: () => ({
            url: "helpdesk/listReferenceByCategory",
            method: "GET",
          }),
        }),
        //helpdesk/getVehicleDeviceByOrgId
        getVehicleListByOrg: builder.mutation({
          query: (body) => ({
            url: "helpdesk/getVehicleDeviceByOrgId",
            method: "POST",
            body:body
          }),
        }),

        //Assign to helpdesk/listOrgUsers
        getUserList: builder.mutation({
          query: (body) => ({
            url: "helpdesk/listOrgUsers",
            method: "POST",
            body:body
          }),
        }),
        //helpdesk/uploadFileList
        uploadAttach: builder.mutation({
          query: (body) => ({
            url: "helpdesk/uploadFileList",
            method: "POST",
            body:body
          }),
        }),

        ///helpdesk/getServiceHistory
        listActivity: builder.mutation({
          query: (body) => ({
            url: "helpdesk/getServiceHistory",
            method: "POST",
            body:body
          }),
        }),
        //helpdesk/serviceRequestCommentEntry
        commentListService: builder.mutation({
          query: (body) => ({
            url: "helpdesk/serviceRequestCommentEntry",
            method: "POST",
            body:body
          }),
        }),
        ///helpdesk/saveHelpDeskSettingsCreate
        mailToSend: builder.mutation({
          query: (body) => ({
            url: "helpdesk/saveAssignedSettings",
            method: "POST",
            body:body
          }),
        }),
        ///helpdesk/getAssignTo
        getAssignTo: builder.mutation({
          query: (body) => ({
            url: "helpdesk/getAssignedTo",
            method: "POST",
            body:body
          }),
        }),
        ///helpdesk/deleteImage
         deleteImage: builder.mutation({
          query: (body) => ({
            url: "helpdesk/deleteImage",
            method: "POST",
            body:body
          }),
        }),

        //Get Users List based dropdown selection
        getUserListSlice: builder.mutation({
          query: (body) => ({
            url: "helpdesk/getUsers",
            method: "POST",
            body:body
          }),
        }),


        //New Get Userslice
        getNewUserList: builder.mutation({
          query: (body) => ({
            url: "helpdesk/getSettingUsersList",
            method: "POST",
            body:body
          }),
        }),

        //Assinged User
        getNewAssignedUser: builder.mutation({
          query: (body) => ({
            url: "helpdesk/getAssignedUser",
            method: "POST",
            body:body
          }),
        }),

        //Get Settings getSettings
        getSettings:builder.mutation({
          query: (body) => ({
            url: "helpdesk/getAssignedUser",
            method: "POST",
            body:body
          }),
        }),

        //Save Escalation
        saveEscalation:builder.mutation({
          query: (body) => ({
            url: "helpdesk/saveEscalationSettings",
            method: "POST",
            body:body
          }),
        }),
        escalationGetTime:builder.query({
          query: () => ({
            url: "helpdesk/getEscalationTime",
            method: "GET",
          }),
        }),
        //Escalation Assins
        asssignEscalation:builder.mutation({
          query: (body) => ({
            url: "helpdesk/getEscalationAssignUser",
            method: "POST",
            body:body
          }),
        }),

        //Save Admin Filter
        saveAdminFilter:builder.mutation({
          query: (body) => ({
            url: "helpdesk/saveHelpDeskView",
            method: "POST",
            body:body
          }),
        }),

         //Get Admin Filter
         getAdminFilter:builder.mutation({
          query: (body) => ({
            url: "helpdesk/getHelpDeskView",
            method: "POST",
            body:body
          }),
        }),
    })
})


export const { useUpdateTikectMutation, useUpdateDesciptionMutation, useGetTicketDetailsMutation, useCreateTicketMutation, useTicketListMutation, useVehicleListMutation, useGetVehicleListMutation, useAttachemntUploadMutation, useCategoryListQuery, useCategorySettingsQuery, useGetDropDownMutation, useGetVehicleListByOrgMutation, useGetUserListMutation, useGetCommentsMutation, useUploadAttachMutation, useListActivityMutation, useCommentListServiceMutation, useMailToSendMutation, useGetAssignToMutation,useDeleteImageMutation, useGetUserListSliceMutation, useGetNewUserListMutation, useGetNewAssignedUserMutation, useGetSettingsMutation, useSaveEscalationMutation, useEscalationGetTimeQuery, useAsssignEscalationMutation, useSaveAdminFilterMutation, useGetAdminFilterMutation, useTicketFilterListMutation } = HelpDeskSlice;