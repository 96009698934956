import { Alert, Avatar, Box, Card, FormControl, FormHelperText, Icon, InputBase, ListItem, ListItemAvatar, ListItemText, Paper, Snackbar, Stack, Typography, alpha, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { SoftButton, StyledFormControl, StyledPaperShadow } from '../../theme/StyledEle'
import { RiUser3Fill } from 'react-icons/ri'
import { IoCall, IoMail } from 'react-icons/io5'
import { BsCalendarFill } from 'react-icons/bs'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import DatePicker from 'rsuite/DatePicker';
import moment from 'moment'
import { isBefore, isPast } from 'date-fns'
import { ScaleLoader } from 'react-spinners'
import dealerAvator from '../../assets/images/dealerAvator.png'
import banner from '../../assets/images/testRidebanner.webp'
import { useLocation, useNavigate } from 'react-router-dom'

function EnqueryForm({handleTab, selectDealer, saveData, state, city, isLoading, apiKey}) {
    const theme = useTheme();
    let location = window.location.origin;
    //const location = useLocation();
    const { register, formState:{errors, isValid},handleSubmit,  watch, setValue, getValues, reset,  } = useForm({mode:'all'})
    const [date, setDate] = useState(moment().add(1, 'days').format('DD-MM-yyyy HH:mm'));
    const [time, setTime] = useState(moment().format('hh:mm:ss'));
    const [error, setError] = useState({error:false, msg:null, status:null});
    const [errorMsg, seterrorMsg] = useState(null);
    const navigate = useNavigate();
    const StyledIcon = styled(Icon)(({theme}) => ({
        display:'flex', 
        fontSize:'18px', 
        marginRight:'15px',
        color:'#008000'
    }))

    const InputPaper = styled(Paper)(({theme}) => ({
        display:'flex',
        alignItems:'center',
        width:'100%',
        '& .MuiInputBase-root':{
            width:'100%',
            fontWeight:400,

            '&::placeholder':{
                color:errors ? 'red' : '#000'
            }
        }
    }))

    const StyledFormBox = styled(FormControl)(({theme}) => ({
        borderBottom:'1px solid #f9f9f9',
        '& .MuiFormHelperText-root ':{
            marginLeft:'30px'
        }
    }))

    const StyledCalBox = styled(Box)(({theme}) => ({
        width:"100%",
        '& .rs-picker':{
            boxShadow:'none',
            width:"100%",
        },
        '& .rs-picker-toggle-value':{
            color:'#28282b !important',
        }
    }))

    const getDate = (date, event) => {
        setDate(moment(date).format('YYYY/MM/DD HH:mm'));
    }

   
    
    const onSubmit = async(data) => {
        let payload = {
                "cityId": city,
                "resellerId": 0,
                "userName": data.name,
                "mobileNo": data.mobile,
                "email": data.email,
                "date": date,
                "dealer": selectDealer.name,
                "address": selectDealer.address,
                "slotBooked": 0,
                "stateId": state,
                "apiKey":apiKey,
        }
        const saveRide = await saveData(payload);
        if(saveRide?.data?.resultText === 'Failed'){
            setError({error:true, status:saveRide?.data?.resultText, msg:saveRide?.data?.resultMessage});
            seterrorMsg(saveRide?.data?.resultMessage);

        }else{
            setError({error:true, status:saveRide?.data?.resultText, msg:saveRide?.data?.resultMessage});
            seterrorMsg(saveRide?.data?.resultMessage);
            setTimeout(() => {
                // handleTab(0);
                window.location.replace(`${location}/`)
            },3500)
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setError(false);
      };

    const { afterToday, allowedRange, allowedMaxDays, combine } = DatePicker;
    const acceptOnlyNum = (event) => {
        setValue('mobile', event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'))
        
    }

  return (
    <Box  sx={{width:{lg:'555px', md:'100%', sm:'100%'}, height:'100vh', margin:'auto', background:'#fff', position:'relative'}}>
       <Box sx={{height:'250px', background:`url(${banner})`, backgroundSize:'cover', borderRadius:'0px 0px 35px 35px', position:'absolute', top:'0px', width:'100%',
       '&:before':{
        position:'absolute',
        width:'100%',
        height:'100%',
        content:"''",
        background:alpha(theme.palette.common.black, .2),
        borderRadius:'0px 0px 35px 35px',
    }
    }}></Box>
                <Stack sx={{display:'grid', gridTemplateRows:'auto 1fr auto', height:'100%', position:'relative', zIndex:99}} component={'form'} onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{marginTop:'20%'}}>
                        <Typography sx={{textAlign:'center', color:'#fff', fontWeight:500}} gutterBottom variant='h5'>BOOK YOUR TEST RIDE</Typography>
                    </Box>
                    <Box sx={{px:3, pt:2}}>
                        <StyledPaperShadow sx={{display:'grid', boxShadow:'0px 3px 4px #0000000f !important', borderRadius:'15px !important'}}>
                            <Typography variant='56'>Fill up the form</Typography>
                            <Stack spacing={3} sx={{mt:2, border:'.5px solid #ddd', px:2, py:2, borderRadius:'10px'}}>
                                <StyledFormBox >
                                    <InputPaper elevation={0} >
                                        <StyledIcon ><RiUser3Fill /></StyledIcon>
                                        <InputBase placeholder={'Enter Name'} 
                                        type='text'
                                        {...register('name', {
                                            required:{value:true, message:'Name is required'},
                                        })}
                                        inputProps={{
                                            maxLength:'18'
                                        }}
                                        onChange={(e) => setValue('name', e.target.value.replace(/^[0-9\b]+$/, ''))}
                                        error={errors.name ? true : false}
                                        />
                                    </InputPaper>
                                    {
                                    errors.name &&  <FormHelperText sx={{color:'#ff0000'}}>{errors.name?.message}</FormHelperText>
                                    }
                                </StyledFormBox>
                                <StyledFormBox>
                                    <InputPaper elevation={0}>
                                        <StyledIcon><IoCall /></StyledIcon>
                                        <InputBase placeholder={'Enter Mobile'}
                                       type='tel'
                                       error={errors.mobile ? true : false}
                                       {...register('mobile', {
                                           required:{value:true, message:'Mobile number is required'},
                                           minLength:{
                                                value:10,
                                                message:'Enter valid mobile no'
                                           }
                                       })}
                                       inputProps={{
                                           maxLength:'12'
                                       }}
                                       onChange={(e) => acceptOnlyNum(e)}
                                        />
                                    </InputPaper>
                                    {
                                    errors.mobile &&  <FormHelperText sx={{color:'#ff0000'}}>{errors.mobile?.message}</FormHelperText>
                                    }
                                </StyledFormBox>
                                <StyledFormBox>
                                    <InputPaper elevation={0}>
                                        <StyledIcon color={'error'}><IoMail /></StyledIcon>
                                        <InputBase placeholder={'Enter Email'} type='email' sx={{textTransform:'lowercase'}} {...register('email', 
                                        {
                                            required:{value:true, message:'Email is required'},
                                            pattern:{
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Invalid email address"
                                            }
                                        })} 
                                        error={errors.email ? true : false}
                                        onChange={(e) => setValue('email', e.target.value)}
                                        />
                                    </InputPaper>
                                    {
                                    errors.email &&  <FormHelperText sx={{color:'#ff0000'}}>{errors.email?.message}</FormHelperText>
                                    }
                                </StyledFormBox>
                                <StyledFormBox>
                                    <InputPaper elevation={0}>
                                        <StyledIcon><BsCalendarFill /></StyledIcon>
                                        <StyledCalBox sx={{width:'100%'}}>
                                                <DatePicker aria-invalid="true" editable={false}  appearance={'subtle'} format={'dd-MM-yyyy HH:mm'}  onOk={getDate} disabledDate={date => isPast(date, new Date())} value={new Date(moment(date))} />
                                        </StyledCalBox>
                                    </InputPaper>
                                </StyledFormBox>
                            </Stack>
                        </StyledPaperShadow>
                        <Card elevation={0} sx={{mt:2, borderRadius:'15px', boxShadow:'0px 3px 4px #0000000f !important', border:`.5px solid ${theme.palette.divider}`}} >
                            <ListItem>
                            <ListItemAvatar>
                                <Avatar src={dealerAvator}/>
                            </ListItemAvatar>
                            <ListItemText
                                primary={selectDealer !== null && <Typography variant='body1'>{selectDealer.name}</Typography>}
                                secondary={selectDealer !== null && <Typography variant='body2' sx={{fontSize:'.7rem'}}>{selectDealer.address}</Typography>}
                            />
                            </ListItem>
                        </Card>
                    </Box>
                    <Box sx={{px:3, py:3}} >
                        <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
                            <SoftButton sx={{background:'#e8edf5', color:'#28282b'}} size='large' onClick={() => handleTab(1)} disabled={isLoading ? true : false }>
                                Back
                            </SoftButton>
                            <SoftButton variant='contained' sx={{background:'#008000 !important', color:'#fff', boxShadow:'0px 3px 4px #0000000f !important'}} size='large' type='submit' disabled={isLoading ? true : false }>
                                {
                                    isLoading ? <ScaleLoader color='#fff' height={12}/> : 'Book a slot'
                                }
                            </SoftButton>
                           
                        </Stack>
                    </Box>
                </Stack>

            <Snackbar
            open={error.error}
            autoHideDuration={3000}
            onClose={handleClose}
            message={error.msg}
            //action={action}
            sx={{
                bottom: { xs: 90, sm: 0 },
            }}
            >
                <Alert severity={error.status === 'Failed' ? 'error' : 'success'} variant='outlined' sx={{width:'100%'}}>
                    {error.msg}
                </Alert>
            </Snackbar>
    </Box>
  )
}

export default EnqueryForm