export const orgTypedata = [
  {
    id: 1,
    key: "-1",
    value: "All",
  },
  {
    id: 2,
    key: "2",
    value: "Organization",
  },
  {
    id: 3,
    key: "3",
    value: "Customer",
  },
  {
    id: 4,
    key: "5",
    value: "Dealer",
  },

  {
    id: 5,
    key: "1",
    value: "Fleet",
  },

  {
    id: 6,
    key: "37",
    value: "Rider",

  },
];

export const organizationListData = [
  {
    id: 1,
    key: "1",
    value: "Fleet",
  },
  {
    id: 2,
    key: "2",
    value: "Organization",
  },
  {
    id: 3,
    key: "3",
    value: "Customer",
  },
  {
    id: 4,
    key: "4",
    value: "Agency",
  },
  {
    id: 5,
    key: "5",
    value: "Dealer",
  },
  {
    id: 8,
    key: "8",
    value: "System",
  },
];

export const customerAssignedData = [
  // {
  //     id:1,
  //     key:'-1',
  //     value:'All'
  // },
  {
    id: 2,
    key: "1",
    value: "Yes",
  },
  {
    id: 3,
    key: "2",
    value: "No",
  },
];

export const status = [
  {
    id: 1,
    key: "1",
    value: "Active",
  },
  {
    id: 2,
    key: "0",
    value: "Inactive",
  },
];

export const createDateData = [
  {
    id: 1,
    key: "0",
    value: "All",
  },
  {
    id: 2,
    key: "1",
    value: "On",
  },
  {
    id: 3,
    key: "2",
    value: "After",
  },
  {
    id: 4,
    key: "3",
    value: "Before",
  },
  {
    id: 5,
    key: "4",
    value: "Between",
  },
];

export const DeviceStatusData = [
  {
    id: 1,
    key: "0",
    value: "All",
  },
  {
    id: 2,
    key: "1",
    value: "MFG",
  },
  {
    id: 3,
    key: "2",
    value: "VERIFIED",
  },
  {
    id: 4,
    key: "3",
    value: "FITTED",
  },
  {
    id: 5,
    key: "4",
    value: "ACTIVE",
  },
  {
    id: 6,
    key: "5",
    value: "SUSPENDED",
  },
  {
    id: 7,
    key: "6",
    value: "REPAIR",
  },
  {
    id: 8,
    key: "7",
    value: "CANCELLED",
  },
  {
    id: 9,
    key: "8",
    value: "EOL",
  },
  {
    id: 10,
    key: "9",
    value: "MFG-SIM-ACTIVE",
  },
  {
    id: 11,
    key: "10",
    value: "SERVICE",
  },
  {
    id: 12,
    key: "11",
    value: "INACTIVE",
  },
];

export const lastConnectData = [
  {
    id: 1,
    key: "1",
    value: "<5 Hours",
  },
  {
    id: 2,
    key: "2",
    value: ">5 Hours & <12 Hours",
  },
  {
    id: 3,
    key: "3",
    value: ">12 Hours & <24 Hours",
  },
  {
    id: 4,
    key: "4",
    value: ">1 Day & <10 Days",
  },
  {
    id: 5,
    key: "5",
    value: ">10 Days & <30 Days",
  },
  {
    id: 6,
    key: "6",
    value: ">30 Days & <90 Days",
  },
  {
    id: 7,
    key: "7",
    value: ">90 Days",
  },
];

export const firmwareData = [
  {
    id: 1,
    key: "0.0.1",
    value: "0.0.1",
  },
  {
    id: 2,
    key: "0.0.2",
    value: "0.0.2",
  },
];

export const actionData = [
  {
    id: 1,
    key: "activate",
    value: "Activate",
  },
  {
    id: 2,
    key: "Safe Custody",
    value: "Safe Custody",
  },
  {
    id: 3,
    key: "Out Of Safe Custody",
    value: "Out Of Safe Custody",
  },
  {
    id: 4,
    key: "Disconnect",
    value: "Disconnect",
  },
  {
    id: 5,
    key: "Resume from temp disconnect",
    value: "Resume from TD",
  },
  {
    id: 6,
    key: "Plan Change",
    value: "Plan Change",
  },
  {
    id: 7,
    key: "WhiteListing",
    value: "WhiteListing",
  },
];

export const searchByData = [
  {
    id: 1,
    key: "1",
    value: "Date",
  },
];

export const alertModeData = [
  {
    id: 1,
    key: "1",
    value: "Email",
  },
  {
    id: 2,
    key: "2",
    value: "SMS",
  },
  {
    id: 3,
    key: "3",
    value: "Push Notification",
  },
];

export const OtaStatusData = [
  {
    id: 1,
    key: "1",
    value: "Queued",
  },
  {
    id: 2,
    key: "2",
    value: "Initiated",
  },
];
export const commandTypeData = [
  {
    id: 1,
    key: "0",
    value: "All",
  },
  {
    id: 2,
    key: "296",
    value: "Odometer Reading",
  },
];

//KYC LOG
export const kycLogSearchByData = [
  {
    id: 1,
    key: "Date",
    value: "Date",
  },
  {
    id: 2,
    key: "VIN",
    value: "VIN",
  },
  {
    id: 3,
    key: "Invoice Retail Number",
    value: "Invoice Retail Number",
  },
];

export const kycLogProcessingStatusData = [
  {
    id: 1,
    key: "pending",
    value: "Received",
  },
  {
    id: 2,
    key: "Approved",
    value: "Approved",
  },
  {
    id: 3,
    key: "Rejected",
    value: "Rejected",
  },
];

export const kycLogFeedStatusData = [
  {
    id: 1,
    key: "Valid",
    value: "Valid",
  },
  {
    id: 2,
    key: "Invalid",
    value: "Invalid",
  },
];

export const kycLogStatusData = [
  {
    id: 1,
    key: "VIN-IMEI MAPPED",
    value: "VIN-IMEI MAPPED",
  },
  {
    id: 2,
    key: "KYC REJECTED",
    value: "KYC REJECTED",
  },
  {
    id: 3,
    key: "KYC APPROVED",
    value: "KYC APPROVED",
  },
  {
    id: 4,
    key: "KYC RECEIVED",
    value: "KYC RECEIVED",
  },
];

export const consentData = [
  {
    id: 1,
    key: "1",
    value: "Approved",
  },
  {
    id: 2,
    key: "2",
    value: "Not Approved",
  },
];

export const customerAssinData = [
  {
    id: 1,
    key: "1",
    value: "Yes",
  },
  {
    id: 2,
    key: "2",
    value: "No",
  },
];

export const kycStatusData = [
  {
    id: 1,
    key: "Active",
    value: "Active",
  },
  {
    id: 2,
    key: "Inactive",
    value: "Inactive",
  },
];

export const benchStatusData = [
  {
    id: 1,
    key: "1",
    value: "Verified",
  },
];

export const actionLicenseData = [
  {
    id: 1,
    key: "0",
    value: "All",
  },
  {
    id: 2,
    key: "1",
    value: "Invoice Date",
  },
  {
    id: 3,
    key: "2",
    value: "Dispatch Date",
  },
  {
    id: 4,
    key: "3",
    value: "License Assigned Date",
  },
  {
    id: 5,
    key: "4",
    value: "License Activation Date",
  },
  {
    id: 6,
    key: "5",
    value: "License Expiry Date",
  },
];
