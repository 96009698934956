import Service from "./service";

const loginServices = {
  loginUser: function (loginInfo) {
    return Service.post('loginService',loginInfo)
  },
  loginUserData: function (loginInfo) {
    return Service.post('user/getLoginUserDetails',loginInfo)
  },
  getResellerList: function (loginInfo) {
    return Service.post('user/getResellerList',loginInfo)
  }
};

export default loginServices;