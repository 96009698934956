import React, { useEffect, useRef, useState } from "react";
import {
  TreeViewAccodionBox,
  TreeViewListItemBtn,
} from "../Treeview/TreeviewStyledEle";
import { HiMiniFolderMinus, HiMiniFolderPlus, HiUser } from "react-icons/hi2";
import {
  Box,
  Collapse,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Fleet from "./Fleet";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetRegionListMutation,
  useGetVehicleByResellerMutation,
  useGetVehicleCountMutation,
  useAuditLogMutation
} from "../../../store/slice/ApiSlice";
import {
  dropVehicles,
  isLoadingState,
  refresh,
  selectedVehicleData,
  selectedVehicleTrailsData,
  treeViewRollId,
  vehicleCountSlice,
} from "../../../store/slice/ControlsSlice";
import { ScaleLoader } from "react-spinners";

function Customer(props) {
  //const {isLogin , whoIam} = props
  const {
    customers,
    userData,
    customerList,
    dealerId,
    isCutomer,
    buttonIndex,
    index,
    openCustomerId,
    openfleetId,
    regionName,
    dealerName,
  } = props;
  const [open, setOpen] = useState(false);
  const customerRef = useRef(customers);
  const [custId, setCustId] = useState(openCustomerId);
  const theme = useTheme();
  //dispatch
  const dispatch = useDispatch();

  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };


  //Payload Start
  //Dealer Payload
  let getVehiclePayload = {
    resellerId: userData.resellerId,
    regionId: 0,
    orgId: customerRef.current.id,
    start: 0,
    length: 50,
    vehmodel: "All",
    statusFlag: 6,
    value: "fleet",
    search: "",
  };
  //getvehicle Count
  let commonCountPayload = {
    resellerId: userData.resellerId,
  };
  //Get vehicle
  let commonPayload = {
    resellerId: userData.resellerId,
    customerId: customerRef.current !== undefined && customerRef.current.id,
    dealerId: dealerId,
    orgId: userData.resellerId,
    checkboxStatus: false,
    page: 0,
    size: 1000,
    region: 0,
    vehModel: "All",
    searchText: "",
    zoneId: 0,
  };
  //Payload End

  //API Slice
  const [getVehicles, { data, isLoading, isSuccess:getVehicleListSuccess }] = useGetRegionListMutation();

  //Vehicle Count & Vehicle
  const [
    getVehicleCount,
    {
      data: vehicleCountData,
      isLoading: countLoading,
      isSuccess: countSuccess,
    },
  ] = useGetVehicleCountMutation();
  const [
    getVehicleByReseller,
    {
      data: getVehicleData,
      isLoading: getVehicleLoading,
      isSuccess: getVehicleSuccess,
    },
  ] = useGetVehicleByResellerMutation();

  const selectedVehicle = useSelector(
    (state) => state.controls.selectedVehicle
  );

  //Set Customer Data List
  let [fleetList, setFleetList] = useState(null);
  useEffect(() => {
    if (getVehicleListSuccess) {
      setFleetList(data);
      dispatch(isLoadingState(false));
    }
  }, [getVehicleListSuccess]);

  const getCount = () => {
    if(userData.resellerId !== 110224){
      commonCountPayload.selectId = customerRef.current !== undefined && customerRef.current.id; 
      commonCountPayload.selectType = "customer";
      dispatch(treeViewRollId([customerRef.current.id, "customer"]))
        getVehicleCount(commonCountPayload);
    }else{
      commonCountPayload.selectId = ""; 
      commonCountPayload.selectType = "";
      dispatch(treeViewRollId([null, null]))
      getVehicleCount(commonCountPayload);
    }
    
    getVehicleByReseller(commonPayload);
    dispatch(isLoadingState(true));
    if (selectedVehicle !== null) {
      dispatch(selectedVehicleData(null));
      dispatch(selectedVehicleTrailsData(null));
      dispatch(refresh([false, false]));
    }
  };

  //set Vehicle Count
  useEffect(() => {
    if (countSuccess === true || getVehicleSuccess === true) {
      dispatch(vehicleCountSlice(vehicleCountData));
      dispatch(dropVehicles(getVehicleData));
      dispatch(isLoadingState(false));
    }
  }, [countSuccess, getVehicleSuccess]);

  //collabse open
  const collabseOpen = () => {
    setOpen(!open);
    //if(fleetList === null){
      if(!open){
        getVehicles(getVehiclePayload);
        dispatch(isLoadingState(true));
      }
    //}
    if (selectedVehicle !== null) {
      dispatch(selectedVehicleData(null));
      dispatch(selectedVehicleTrailsData(null));
      dispatch(refresh([false, false]));
    }

    auditPayload.message = "Tracking > Map > Treeview > "+userData.orgName+" > "+regionName+" > "+dealerName+" > "+customers.name;
    auditPayload.action = "Select";
    auditPayload.featureName = "Map";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
  //  auditLog(auditPayload);
  };

  const isSearchKeyword = useSelector(
    (state) => state.controls.treeview.searchKeyWord
  );
  const isLoadingStates = useSelector((state) => state.controls.isloading);


  useEffect(() => {
    const dealyDebounce = setTimeout(() => {
      if (
        customers !== null &&
        isSearchKeyword !== null &&
        isSearchKeyword.length >= 3
      ) {
        if (index === 0) {
          setOpen(true);
          getVehiclePayload.search = isSearchKeyword;
          getVehicles(getVehiclePayload);
        }
      }
    }, 1000);
    return () => clearTimeout(dealyDebounce);
  }, [isSearchKeyword]);

  //Onload Expand
  useEffect(() => {
    if (openCustomerId === customers.id && (isSearchKeyword === null || isSearchKeyword === "")) {
      setOpen(true);
      getVehicles(getVehiclePayload);
    }
  }, []);

  // useEffect(() => {
  //   if(selectedVehicle !== null && open){
  //     setOpen(true);
  //     getVehicles(getVehiclePayload);
  //   }
  // },[selectedVehicle])
  return (
    <TreeViewAccodionBox
      rolles={"customer"}
      isOpen={open}
      lineOffset={isCutomer ? 34 : 74}
      key={index}
    >
      <TreeViewListItemBtn
        primary={"customer"}
        secondaryAction={
          <IconButton onClick={collabseOpen}>
            {open ? <HiMiniFolderMinus /> : <HiMiniFolderPlus />}
          </IconButton>
        }
        index={index}
        id={customers.id}
        ref={customerRef}
        data-region-name={customers.name}
        data-vehicle-count={customers.vehicleCount}
        data-role-name={"customer"}
        onClick={getCount}
        data-dealer-id={dealerId}
        buttonIndex={isCutomer ? 25 : buttonIndex + 40}
        key={customers.id}
      >
        <ListItemIcon>
          <HiUser />
        </ListItemIcon>
        <ListItemText
          primary={
            <Stack direction={"row"} spacing={0.5}>
              <Typography>{customers?.name}</Typography>
              <Typography>{`(${customers.vehicleCount})`}</Typography>
            </Stack>
          }
        />
      </TreeViewListItemBtn>
      <Collapse in={open}>
        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", py: 1 }}>
            <ScaleLoader color={theme.palette.secondary.main} height={20} />
          </Box>
        ) : (
 
          fleetList !== null &&
          fleetList.treeviewList !== null &&
          fleetList?.treeviewList[0]?.orgs?.map((fleetItem, index) => (
            
            <Fleet
              fleetItem={fleetItem}
              buttonIndex={buttonIndex}
              isCutomer={isCutomer}
              index={index}
              openfleetId={openfleetId}
              userData={userData}
              regionName={regionName}
              dealerName={dealerName}
              customerName={customers.name}
              payload={getVehiclePayload}
            />
          ))
        )}
      </Collapse>
    </TreeViewAccodionBox>
    // <Typography>Hi</Typography>
  );
}

export default Customer;
