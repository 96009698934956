import { Box, CardMedia, Grid, Paper, Stack, Tab, Tabs, Typography, styled } from '@mui/material'
import React, { useState } from 'react'
import Slide from '../NewLogin/Slide'
import SignIn2 from "../login/userActivation"

 function NewUserActivation() {

  return (
    <Box sx={{p:1, pt:2, height:'48vh',width:"100vw",position:"relative",display:"flex",justifyContent:"center",alignItems:"center"}}>
    <Paper
        sx={{
            width:'calc(100% - 55%)',
            height:'calc(100% - 50%)',
            minHeight:'calc(100% - 80%)',
            borderRadius:'15px',
            boxShadow:'0px 10px 8px rgba(0,0,0,.02)',
            padding:'0px 0px 0px 0px',
            zIndex:999,
            //display:'grid',
            //gridTemplateRows:'auto 1fr auto',
            background:'transparent',
            overflow:'visible'
        }}
    >

          <Grid container spacing={1} sx={{ height: '28vh', margin: '0px', width: '100%' }}>
              <Grid item lg={12} sx={{ p: 0, '&.MuiGrid-item': { padding: '0px' } }}>
                  <Box sx={{ px: 6, py: 2, background: '#fff', borderRadius: '15px 0px 0px 15px' }}>
                      <SignIn2 />

                  </Box>
              </Grid>
             
          </Grid>
        
    </Paper>
    </Box>
  );
}

export default NewUserActivation