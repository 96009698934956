import { Autocomplete, Avatar, Box, Chip, FormControl, Grid, InputBase, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Paper, Stack, TextField, Typography, styled, useTheme } from '@mui/material'
import React, { useEffect, useState, useLayoutEffect } from 'react'
import { useCategoryListQuery, useCommentListServiceMutation, useDeleteImageMutation, useGetCommentsMutation, useGetTicketDetailsMutation, useGetUserListMutation, useGetVehicleListMutation, useListActivityMutation, useUpdateTikectMutation, useUploadAttachMutation } from '../../../store/slice/Helpdesk/HelpDeskSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { SoftButton, StyledFormControlLabel, StyledTextField,StyledFormControl } from '../../../theme/StyledEle';
import { FaRegUser, FaRegUserCircle } from 'react-icons/fa';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { IoBugOutline } from 'react-icons/io5';
import { BsBug } from 'react-icons/bs';
import { HiOutlineCloudUpload } from 'react-icons/hi';
import { MdOutlineViewTimeline } from 'react-icons/md';
import EditTicketUpload from './EditTicketUpload';
import Activity from './Activity';
import { snackService } from '../../../store/slice/ControlsSlice';
import { useDispatch } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars-2';
import SingleDropdownHelpDesk from "../../NewDropdown/SingleDropdownHelpDesk";
import { useGetDropdownListMutation, useGetVehicleDropdownListMutation } from '../../../store/slice/ApiSlice'
import { useCallback } from "react";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

function EditTicket(props) {
  const { ticketId, updateTickets, updateTicketLoading } = props
  const theme = useTheme();
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let orgType = userData.userOrgType;


  const [ticketDetail, setTicketDetail] = useState(null);
  const [isLoad, setIsLoad] = useState(true);
  const [attachments, setAttachments] = useState(null);
  const [commentsLists, setCommentsLists] = useState(null);
  const [listsActivity, setListsActivity] = useState(null);
  //get Vehicle List
  const [vehicleList, setVehicleList] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState([]);
  const [selectedVeh, setSelectedVeh] = useState(null);
  const [vehicle, setVehicle] = useState(0);
  const [search, setSearch] = useState(false);

  const [customerList, setCustomerList] = useState(null);
  const [customer, setCustomer] = useState("-1");
  const [fleet, setFleet] = useState("-1");
  const [region, setRegion] = useState("-1");
  const [dealer, setDealer] = useState("-1");
  const [load, setLoad] = useState(false);
  const [tabs, setTabs] = useState(0);

  //Slice Start
  const [getTicket, { data: getTicketData, isLoading: getTicketLoading, isSuccess: getTicketSuccess }] = useGetTicketDetailsMutation();
  const [getComments, { data: commentsList, isLoading: commentsListLoading, isSuccess: commentsListSuccess }] = useGetCommentsMutation();
  const [uploadFile, { data: uploadFileData, isLoading: uploadFileLoading, isSuccess: uploadFileSuccess }] = useUploadAttachMutation();
  const [listActivity, { data: listActivityData, isLoading: listActivityLoading, isSuccess: listActivitySuccess }] = useListActivityMutation();
  //const [updateTickets, {data:updateTicketData, isLoading:updateTicketLoading, isSuccess:updateTicketSuccess}] = useUpdateTikectMutation();
  const [updateComments, { data: updateCommentData, isLoading: updateCommentLoading, isSuccess: updateCommentSuccess }] = useCommentListServiceMutation();
  const [getVehicleList, { data: vehiclesData }] = useGetVehicleListMutation();
  // const { data:categoryListData, isSuccess } = useCategoryListQuery();
  //const {getCategoryList, data:categoryListData, isSuccess } = useCategoryListMutation();
  //const [getCategoryList, { data: categoryListData, isSuccess: categorySuccess }] = useCategoryListQuery();
  const { data:categoryListData, categorySuccess } = useCategoryListQuery();

  const [getVehicleLst, { data: vehicleData }] = useGetVehicleListMutation();
  const [incidentDateTime, setIncidentDateTime] = useState(moment(new Date()).format("yyyy/MM/DD HH:mm"));
  const [incidentCloseDateTime, setIncidentCloseDateTime] = useState(moment(new Date()).format("yyyy/MM/DD HH:mm"));
  const [incidentLocation, setIncidentLocation] = useState("");
  const [driverNumber, setDriverNumber] = useState("");
  const [tempDate, setTempDate] = useState(moment(new Date()).format("yyyy/MM/DD HH:mm"));
  const [tempCloseDate, setTempCloseDate] = useState(moment(new Date()).format("yyyy/MM/DD HH:mm"));

  //Region List Loaded
  let regionPayload = {
    value: "region",
    searchText: "",
  };
  //Customer & Fleet List
  const [customerLists, setCustomerLists] = useState([]);
  const [fleetList, setfleetList] = useState();

  /*
   const vehicleSearch = useCallback((searchText) => {
     let vehicleSeatchPayload = {
       resellerId: resellerId,
       searchText: searchText,
       page: "0",
       size: "10",
       orgType: orgType,
       dealerId: orgId,
       vehicleAllFilter: true,
       imeiSearch: "",
     };
     if (searchText !== '') {
       getVehicleSearch(vehicleSeatchPayload);
     } else {
       setSelectedVehicle(null);
       //setVehicleList(vehicledata?.data);
     }
   },[search]);
   */
  //getCustomer
  const getCustomer = (dealerId) => {
    setDealer(dealerId);
    if (searchText.name === 'Dealer') {
      DealorPayload.dealerId = '0';
      DealorPayload.value = 'dealer';
      DealorPayload.regionId = region;
      getDealerList(DealorPayload);
      setSearchText({ name: null, value: null });
    }
    if (dealerId !== "-1") {
      CommonPayload.value = "customer";
      CommonPayload.dealerId = dealerId;
      CommonPayload.searchText = "";
      getCustomerList(CommonPayload);
      if (allOption && selectedVehicle === null) {
        setCustomer("-1");
        setFleet("-1");
        setVehicle("-1");
        setCustomerList(null);
        setfleetList(null);
        //setVehicleList(null);
      }
    } else {
      setCustomer("-1");
      setFleet("-1");
      setVehicle("-1");
      setCustomerList(null);
      setfleetList(null);
      //setVehicleList(null);
      setSelectedVehicle(null);
    }
  };

  const [
    getFleetList,
    { data: fleetdata, isLoading: fleetLoading, isSuccess: fleetSuccess },
  ] = useGetDropdownListMutation();

  const [
    getCustomerList,
    {
      data: customerdata,
      isLoading: customerLoading,
      isSuccess: customerSuccess,
    },
  ] = useGetDropdownListMutation();
  const [
    getVehicleSearch,
    {
      data: searchVehicles,
      isLoading: searchVehicleLoading,
      isSuccess: searchVehicleSuccess,
    },
  ] = useGetVehicleDropdownListMutation();

  /*
  useLayoutEffect(() => {
    if (searchVehicleSuccess) {
      if (searchVehicles.data !== null) {
        setVehicleList(searchVehicles.data);
      }
    }
  }, [searchVehicleSuccess]);
  */

  //Dealor Login Payload
  let DealorPayload = {
    resellerId: resellerId, // Set Login Dealer ID
    value: "customer",
    dealerId: orgId,
    searchText: "",
  };

  //Fleet Payload
  let FleetPayload = {
    value: "orgId",
    paramValue: "113849", // Set Login Fleet ID
    searchText: "",
  };

  let vehilePayload = {
    value: "orgId",
    searchText: ""
  };



  const [deleteImage, { data: deleteImageData, isLoading: deleteImageLoading, isSuccess: deleteImageSuccess }] = useDeleteImageMutation();
  const [regionList, setRegionList] = useState(null);
  const [dealerList, setDealerList] = useState(null);
  //Customer Login Payload
  let CustomerPayload = {
    resellerId: resellerId, // Set Login Customer ID
    value: "fleet",
    dealerId: "0",
    customerId: orgId,
    searchText: "",
  };

  //Get Region List
  const [
    getRegionList,
    { data: regionData, isLoading: regionLoading, isSuccess: regionSuccess },
  ] = useGetDropdownListMutation();


  const [
    getDealerList,
    { data: dealerdata, isLoading: dealerLoading, isSuccess: dealerSuccess },
  ] = useGetDropdownListMutation();


  const [getUserList, { data: userListData }] = useGetUserListMutation();
  const [searchText, setSearchText] = useState({ value: null, name: null });
  const [deleteStatus, setDeleteStatus] = useState(false);


  const [allOption, setAllOption] = useState(true);
  let CommonPayload = {
    resellerId: resellerId,
  };


  const getVehicless = async () => {
    let payload = {
      "orgId": resellerId,
      "orgType": 2,
      "roleId": 6,
      "id": userId,
      "limit": 50
    }
    const vehicles = await getVehicleList(payload);
    if (vehicles.data !== null) {
      setVehicleList(vehicles.data.data);
    } else {
      setVehicleList([]);
    }
  }

  //getFleet
  const getFleet = (customerId) => {
    setCustomer(customerId);

    if (searchText.name === 'Customer' && search) {
      CustomerPayload.value = "customer";
      CustomerPayload.dealerId = dealer;
      CustomerPayload.customerId = null;
      getCustomerList(CustomerPayload);
    }

    if (customerId !== "-1") {
      CommonPayload.value = "fleet";
      CommonPayload.dealerId = "0";
      if (orgType === 3) {
        CommonPayload.customerId = orgId;
        CommonPayload.regionId = '0';
      } else {
        CommonPayload.customerId = customerId;
      }
      CommonPayload.searchText = "";
      getFleetList(CommonPayload);
      if (allOption && selectedVehicle === null && orgType !== 3) {
        //setFleet("-1");
        //setVehicle("-1");
        //setfleetList(null);
        //setVehicleList(null);
      }
    } else {
      setFleet("-1");
      setVehicle("-1");
      setfleetList(null);
      setVehicleList(null);
      setSelectedVehicle(null);
    }
  };

  /*
  useLayoutEffect(() => {
    if (vehicleSuccess) {

      setVehicleList(vehicledata?.data?.data);
      if (search) {
        setSearch(false);
      }

      if (selectedVehicle !== null) {
        setVehicle(selectedVehicle[0]?.key);
        setVehicleList(vehicledata?.data);
      }

      if (
        !allOption &&
        selectedVehicle === null
        //&& location.pathname !== "/geozone"
      ) {
        selectVehicle(vehicledata?.data?.content[0]?.key);
        setLoad(true);
      }
    }
    if (
      allOption &&
      selectedVehicle === null
      //&&  location.pathname !== "/geozone"
    ) {
      setVehicle("-1");
      if (location.hash !== "#montraDaywise" && orgType !== 3) {
        setLoad(true);
      }
    }
  }, [vehicleSuccess]);
*/

  useLayoutEffect(() => {
    if (dealerSuccess) {
      setDealerList(dealerdata?.data);
      if (!allOption && selectedVehicle === null) {
        getCustomer(dealerdata?.data.content[0]?.key);
      } else if (selectedVehicle !== null) {
        getCustomer(dealer);
      }
    }
    if (allOption && selectedVehicle === null) {
      if (resellerId === 109951 && orgType !== 3 && orgType !== 5 && region === "1") {
        //KG 2W
        //getCustomer(113749);
        getCustomer("-1");
      } else if (userData.resellerId === 110224 && orgType !== 3 && orgType !== 5 && region === "1") {
        //getCustomer(dealerdata?.data.content[0]?.key);
        getCustomer("-1");
      } else if (!search) {
        setDealer("-1");
      }
    }
  }, [dealerSuccess]);

  useLayoutEffect(() => {
    if (customerSuccess) {
      setCustomerList(customerdata?.data);
      if (!allOption && selectedVehicle === null) {
        getFleet(customerdata?.data?.content[0]?.key);
        let routeListPayload = {
          orgId: customerdata?.data?.content[0]?.key,
          page: 0,
          size: 25,
        };
        var custId = customerdata?.data?.content[0]?.key;
        if (custId !== null && custId !== "-1") {
          //getRouteList(routeListPayload);
        }
      } else if (selectedVehicle !== null) {
        getFleet(customer);
      }
    }
    if (allOption && selectedVehicle === null) {
      if (resellerId === 109951 && orgType !== 3 && orgType !== 5) {
        //KG 2W
        getFleet(113754);
      } else if (userData.resellerId === 110224 && orgType !== 3 && orgType !== 5 && region === "1") {
        getFleet(customer);
      }
      else if (!search) {
        setCustomer("-1");
      }
    }
  }, [customerSuccess]);


  useLayoutEffect(() => {
    if (fleetSuccess) {
      setfleetList(fleetdata?.data);
      setFleet(fleetdata?.data?.content[0]?.key);
      if (!allOption && selectedVehicle === null) {
        //getVehicle(fleetdata?.data?.content[0]?.key);
      } else if (selectedVehicle !== null) {

        //getVehicle(selectedVehicle[0]?.fleetId);
      }
      if (orgType === 3 && selectedVehicle === null) {
        //getVehicle(fleetdata?.data?.content[0]?.key);
        setFleet(fleetdata?.data?.content[0]?.key);
      }
    }
    if (allOption && selectedVehicle === null && !search && orgType !== 3) {
      setFleet("-1");
    }
  }, [fleetSuccess]);


  useEffect(() => {
    if (regionSuccess) {
      setRegionList(regionData?.data);
      if (!allOption && selectedVehicle === null) {
        getDealer(regionData?.data.content[0].key);
        setLoad(true);
      } else if (selectedVehicle !== null) {
        //getDealer(selectedVehicle[0].regionId);
        getDealer(region);
      }
    }
    if (allOption && selectedVehicle === null) {
      if (userData.resellerId === 109951 && orgType !== 3 && orgType !== 5 && orgType !== 1) {
        //KG 2W
        getDealer("-1");
      } else if (userData.resellerId === 110224 && orgType !== 3 && orgType !== 5 && orgType !== 1) {
        getDealer("-1");
      }
      else if (!search) {
        setRegion("-1");
      }
    }

  }, [regionSuccess]);

  //getDealer
  const getDealer = (regionId) => {
    if (searchText.name === 'Region') {
      getRegionList(regionPayload);
      setSearchText({ name: null, value: null });
    }
    setRegion(regionId);
    if (regionId !== "-1") {
      CommonPayload.value = "dealer";
      CommonPayload.regionId = regionId;
      CommonPayload.dealerId = "0";
      CommonPayload.searchText = "";
      getDealerList(CommonPayload);
      if (allOption && selectedVehicle === null) {
        setDealer("-1");
        setCustomer("-1");
        setFleet("-1");
        setVehicle("-1");
        setDealerList(null);
        setCustomerList(null);
        setfleetList(null);
        setVehicleList(null);
      }
    } else {
      setDealer("-1");
      setCustomer("-1");
      setFleet("-1");
      setVehicle("-1");
      setDealerList(null);
      setCustomerList(null);
      setfleetList(null);
      setVehicleList(null);
      setSelectedVehicle(null);
    }
  };

  //Get Vehicle
  const getVehicle = (fleetId) => {
    setFleet(fleetId);
    if (searchText.name === 'Fleet' && search) {
      FleetPayload.value = "fleet";
      FleetPayload.customerId = customer;
      FleetPayload.fleetId = null;
      FleetPayload.dealerId = '0';
      getFleetList(FleetPayload);
    }

    if (fleetId !== "-1") {
      vehilePayload.paramValue = fleetId;
      if (search) {
        vehilePayload.headerValue = vehicle;
        vehilePayload.param = 'search';
      }
      //getVehicleList(vehilePayload);
    } else {
      setVehicle("-1");
      setVehicleList(null);
      setSelectedVehicle(null);
    }

  };

  const getSingleTicket = async () => {
    if (ticketId !== null) {
      const getTicketDetails = await getTicket({ 'id': ticketId });
      setTicketDetail(getTicketDetails?.data?.dataresult[0]);
      if (getTicketDetails?.data?.listDocument !== null && getTicketDetails?.data?.listDocument.length > 0) {
        setAttachments(getTicketDetails?.data?.listDocument);
      }
      const commentsItem = await getComments({ 'id': ticketId });
      setCommentsLists(commentsItem?.data?.data);

      const listActivitys = await listActivity({ 'id': ticketId });
      setListsActivity(listActivitys);
      getAssignee();
      getVehicless();
      let vehicleId = getTicketDetails?.data?.dataresult[0]?.vehicleId?.split(',');
      let vehicleNo = getTicketDetails?.data?.dataresult[0]?.vehicleNo?.split(',');
      selectVehicle(vehicleId !== undefined ? vehicleId[0] : "", vehicleNo !== undefined ? vehicleNo[0] : "");
      
    } else {
      setTicketDetail(null);
    }
  }

  useEffect(async () => {
    getSingleTicket();
  }, [ticketId])



  //Based Login Implemented
  const reportsOnload = () => {
    //Region List Loaded
    let regionPayload = {
      value: "region",
      searchText: "",
    };
    switch (orgType) {
      case 2:
        getRegionList(regionPayload);
        break;

      case 5:
        // getRegionList(regionPayload);
        getCustomer(orgId);
        break;
      case 3:
        getFleet(orgId);
        setCustomer(orgId);
        break;
      case 1:
        getVehicle(orgId);
        setFleet(orgId);

    }
  };

  useEffect(() => {
   // reportsOnload();
  }, []);



  // //getVehicle for service
  // const getVehicless = async () => {
  //   debugger
  //   const vehicles = await getVehicleList(Vehiclepayload);
  //     if (vehicles?.data !== null) {
  //       setVehicleList(vehicles?.data?.data?.content);
  //     } else {
  //       setVehicleList([]);
  //     }
  // }



  const regionSearch = (searchText) => {
    CommonPayload.value = "region";
    CommonPayload.searchText = searchText;
    CommonPayload.dealerId = "-1";
    getRegionList(CommonPayload);
  };
  const dealerSearch = (searchText) => {
    CommonPayload.value = "dealer";
    CommonPayload.searchText = searchText;
    CommonPayload.regionId = region;
    if (region !== "-1") {
      getDealerList(CommonPayload);

    }
  };
  const customerSearch = (searchText) => {
    CommonPayload.value = "customer";
    CommonPayload.searchText = searchText;
    CommonPayload.dealerId = dealer;
    if (dealer !== "-1") {
      getCustomerList(CommonPayload);
    }
  };
  const FleetSearch = (searchText) => {
    CommonPayload.value = "fleet";
    CommonPayload.dealerId = "0";
    CommonPayload.searchText = searchText;
    CommonPayload.customerId = customer;
    if (customer !== "-1") {
      getFleetList(CommonPayload);
    }
  };

  //search
  const dropDownSearch = (searchText, name) => {
    setSearchText({ value: searchText, name: name });
    setSearch(true);
    if (name === "Vehicle") {
      //vehicleSearch(searchText);
    }
    else if (name === "Region") {
      regionSearch(searchText);
    } else if (name === "Dealer") {
      dealerSearch(searchText);
    } else if (name === "Customer") {
      customerSearch(searchText);
    } else if (name === "Fleet") {
      FleetSearch(searchText);
    }

  };
  const getInnerText = (value, label) => {
    /*
    if(label === "Region"){
      setRegionName(value);
    }else if(label === "Dealer"){
      setDealerName(value);
    }else if(label === "Customer"){
      setCustomerName(value);
    } else if(label === "Fleet"){
      setFleetName(value);
    }else if(label === "Vehicle"){
      setVehicleName(value);
    }else if(label === "Status"){
      setStatusName(value);
    }else if(label === "Severity"){
      setSeverityName(value);
    }
    */
  }


  let Vehiclepayload = {
    "orgId": resellerId,
    "orgType": 2,
    "roleId": 6,
    "id": userId,
    "limit": 50,
    'searchText': '',
  }

  useEffect(() => {
    if (ticketDetail !== null) {
      setTickketNo(ticketDetail?.ticketno);
      setType(ticketDetail?.typeval !== null && ticketDetail?.typeval !== 'none' ? ticketDetail?.typeval : 0);
      setCategory(ticketDetail?.categoryval);
      setStatus(ticketDetail?.statusval);
      setServity(ticketDetail?.servityval);
      setTitle(ticketDetail?.title);
      setDescription(ticketDetail?.description);
      setTargetDate(ticketDetail?.targetDate);
      setIncidentLocation(ticketDetail?.incidentLocation);
      setDriverNumber(ticketDetail?.driverNumber);
      var data=ticketDetail?.incidentDateTime===null?moment(new Date()).format("yyyy/MM/DD HH:mm"):moment(ticketDetail?.incidentDateTime).format("yyyy/MM/DD HH:mm");
      setIncidentDateTime(data);
      setTempDate(data); 

      var incdntCloseDateTime=ticketDetail?.incidentCloseDateTime===null?moment(new Date()).format("yyyy/MM/DD HH:mm"):moment(ticketDetail?.incidentCloseDateTime).format("yyyy/MM/DD HH:mm");
      setIncidentCloseDateTime(incdntCloseDateTime);
      setTempCloseDate(incdntCloseDateTime); 


      let vehicleNo = ticketDetail?.vehicleNo?.split(',');
      let vehicleId = ticketDetail?.vehicleId?.split(',');
      let vehicleChassisNo = ticketDetail?.chassisNo?.split(',');

      if (ticketDetail?.categoryval != "Service" && ticketDetail?.categoryval != "Issue" && ticketDetail?.categoryval != "Feature Request") {
        //setVehicle(vehicleId);
        setDeleteStatus(true);
      } else {
        setDeleteStatus(false);
      }
      let newArr = [];
      if (vehicleId !== null && vehicleId !== undefined && vehicleId !== '') {
        for (let i = 0; vehicleId?.length > i; i++) {
          let vinObj = {}
          vinObj.chassisNo = vehicleChassisNo[i];
          vinObj.vehicleNo = vehicleNo[i];
          vinObj.vehicleId = vehicleId[i];
          newArr.push(vinObj);
        }
        setSelectedVehicle([...newArr]);
        // let searchTxt={};
        // searchTxt.name="Vehicle";
        // searchTxt.value=vehicleId;
        // setSearchText(searchTxt);
        // setSearch(true);
      } else {
        setSelectedVehicle([]);
      }


      //Set Assignee
      let assignUserObj = {
        id: ticketDetail?.assignedTo,
        name: ticketDetail?.assignedToUserName
      }
      if (ticketDetail?.assignedToUserName !== null && ticketDetail?.assignedToUserName !== '-' && ticketDetail?.assignedToUserName !== undefined) {
        setAssignee(assignUserObj);
      }
    }
  }, [ticketDetail])


  //Get Assignee
  const getAssignee = async () => {
    let payload = {
      "orgId": orgId,
      "orgType": orgType,
      "limit": 1000,
      "subOrgType": 0
    }
    const assignList = await getUserList(payload);
    setAssigneeList(assignList?.data?.dataresult);
  }



  //Start State
  const [description, setDescription] = useState('');
  const [comment, setComment] = useState('');
  const [assigneeList, setAssigneeList] = useState([]);
  //Value State
  const [ticketNo, setTickketNo] = useState(null);
  const [assignee, setAssignee] = useState(null);
  const [category, setCategory] = useState(0);
  const [type, setType] = useState(0);
  const [status, setStatus] = useState('Open');
  const [servity, setServity] = useState('Medium');
  const [title, setTitle] = useState(null);
  const [typeList, setTypeList] = useState(null);
  const [targetDate, setTargetDate] = useState(null);


  useEffect(async () => {
    //filter category based set type list
    if (category !== 0) {
      var categoryPayload = {};
      categoryPayload.isMobile = "false";
     //const categoryList = await getCategoryList(categoryPayload);
      const typeData = categoryListData?.data?.type.filter((item) => item.category === category);
      setTypeList(typeData);
      if (isLoad === false) {
        setType(0);
      }
      setIsLoad(false);
    } else {
      setTypeList(null);
    }
  }, [category])

  let vehicleNo = [];
  let vehicleId = [];
  let vehicleChassisNo = [];

  //Vehicle Convert
  const convertToString = async () => {
    selectedVehicle.length > 0 && selectedVehicle.forEach((key, index) => {
      vehicleNo.push(key.vehicleNo);
      vehicleId.push(key.vehicleId);
      vehicleChassisNo.push(key.chassisNo);
    })
  }

  //Render HTML
  const renderHTML = (rawHTML) => React.createElement("p", {
    dangerouslySetInnerHTML: { __html: rawHTML },
  });


  let UpdatePayload = {
    "assignedTo": assignee?.id,
    "assingedToName": assignee?.name,
    "statusval": status,
    "categoryval": category,
    "typeval": type,
    "ticketno": ticketNo,
    "id": ticketId,
    "servityval": servity,
    "targetDate": targetDate,
    "comment": comment,
    "requestdetailid": ticketId,
    "commentType": 'Text',
    "orgId": 113802,
    "createdBy": userId,
    "changeStatus": "UPDATED",
    "orgType": orgType,
  }
  const [validate, setValidate] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const updateTicket = async (event) => {
    convertToString();
    UpdatePayload.chassisNo = vehicleChassisNo.toString();
    UpdatePayload.vehicleNo = vehicleNo.toString();
    UpdatePayload.vehicleId = vehicleId.toString();
    UpdatePayload.vehicleId = vehicleId.toString();
    UpdatePayload.incidentDateTime = moment(incidentDateTime).format("yyyy-MM-DD HH:mm");
    UpdatePayload.incidentLocation = incidentLocation;
    UpdatePayload.driverNumber = driverNumber;
    if(status==="Closed" || status==="Resolved" || status==="Reopen"){
      UpdatePayload.incidentCloseDateTime = moment(incidentCloseDateTime).format("yyyy-MM-DD HH:mm");
    }    
    const updateTicket = await updateTickets(UpdatePayload);
    const updateComment = await updateComments(UpdatePayload);
    if (updateTicket.data.resultCode === 0) {
      dispatch(snackService([updateTicket.data.resultMessage, updateTicket.data.resultCode, true]));
      navigate(location.pathname);
      setValidate(false);
    }
  }


  const Validation = (event) => {
    event.preventDefault();
    if (title === null || title === '') {
      dispatch(snackService(['Title Required', 1, true]));
    } else if (description === null || description === '') {
      dispatch(snackService(['Description Required', 1, true]));
    } else if (assignee === null || assignee === '') {
      dispatch(snackService(['Assign To Required', 1, true]));
    } else if (comment === null || comment === '') {
      dispatch(snackService(['Comments Required', 1, true]));
    }
    else {
      updateTicket(event);
    }
  }

  //Local Styled
  const StyledListItem = styled(ListItem)(({ theme }) => ({
    padding: '0px',
    '& .MuiListItemIcon-root': {
      fontSize: '20px',
      minWidth: '0px',
      marginRight: '10px',
    },
    '& .MuiButtonBase-root': {
      padding: '8px 20px',
    }
  }))

  //Select Vehicle
  const selectVehicle = async (vehicleId, vehicleNo, data) => {
    let Vehiclepayload = {
      "orgId": resellerId,
      "orgType": 2,
      "roleId": 6,
      "id": userId,
      "limit": 50,
      'searchText': '',
    }

    Vehiclepayload.searchText = vehicleNo;

    let vehicleSeatchPayload = {
      resellerId: resellerId,
      searchText: vehicleNo,
      page: "0",
      size: "10",
      orgType: orgType,
      dealerId: orgId,
      vehicleAllFilter: true,
      imeiSearch: "",
    };
    if (vehicleNo !== '') {
      const vehiclesListdata = await getVehicleSearch(vehicleSeatchPayload);
      if (vehicleId !== "-1" && vehicleId !== undefined) {
        const SelctedVehicleData = vehiclesListdata?.data?.data?.content?.filter(
          (item) => item.key === vehicleId
        );
        if (!allOption && search) {
          setRegion(SelctedVehicleData[0]?.regionId);
          getDealer(SelctedVehicleData[0]?.regionId);
        }
        if (allOption) {
          setRegion(SelctedVehicleData[0]?.regionId);
          setDealer(SelctedVehicleData[0]?.dealerId);          
          setCustomer(SelctedVehicleData[0]?.customerId);
          setFleet(SelctedVehicleData[0]?.fleetId);
          setVehicle(SelctedVehicleData[0]?.key);
          reportsOnload();
          getDealer(SelctedVehicleData[0]?.regionId);
        }
        //setSelectedVehicle(SelctedVehicleData);
        setSearchText({ value: null });
      }
      if (vehicleId === "-1" && selectedVehicle !== null && vehicleId !== undefined && !search) {
        setSelectedVehicle(null);
        getVehicle(fleet);
        setVehicle("-1");
      }
    }
  };


  const selectedVehiclefn = (value) => {
    if (category !== "Vehicle Breakdown" && category !== "Charger Breakdown" && category !== "General Complaint" && category !== "Scheduled Service" && category !== "Telematics Issue" && category !== "Accident") {
      setSelectedVehicle(value);
    }
  }
  const handleDateChange = (newValue) => {
    setTempDate(newValue); 
    var data=moment(newValue).format("yyyy/MM/DD HH:mm");
    setIncidentDateTime(data);
  };
  const handleCloseDateChange = (newValue) => {
    if(moment.utc(moment(newValue, "yyyy/MM/DD HH:mm"))>=moment.utc(moment(incidentDateTime, "yyyy/MM/DD HH:mm"))){
      setTempCloseDate(newValue); 
      var data=moment(newValue).format("yyyy/MM/DD HH:mm");
      setIncidentCloseDateTime(data);
    }else{
      dispatch(snackService(['Incident close time should be greater then incident time', 1, true]));
    }
  };
  const onKeyDown = (e) => {
        e.preventDefault();
    };


  return (
    <Box sx={{ overflowY: 'scroll', maxHeight: '80vh', height: '80vh', }}>
      <Grid container spacing={2}>
        <Grid item lg={3} sx={{ position: 'relative' }}>
          <Paper elevation={0} sx={{ position: 'sticky', top: '0px' }}>
            <List>
              <StyledListItem dense={true} >
                <ListItemButton onClick={() => setTabs(0)}>
                  <ListItemIcon>
                    <BsBug />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography color={tabs === 0 && 'secondary'}>Ticket Details</Typography>}
                  />
                </ListItemButton>
              </StyledListItem>
              <StyledListItem dense={true}
                secondaryAction={
                  attachments !== null &&
                  <Chip label={attachments !== null && attachments.length} size='small' color='secondary' />
                }
              >
                <ListItemButton onClick={() => setTabs(1)} >
                  <ListItemIcon>
                    <HiOutlineCloudUpload />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography color={tabs === 1 && 'secondary'} >Upload Attachment</Typography>}
                  />
                </ListItemButton>
              </StyledListItem>
              <StyledListItem dense={true}>
                <ListItemButton onClick={() => setTabs(2)}>
                  <ListItemIcon>
                    <MdOutlineViewTimeline />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography color={tabs === 2 && 'secondary'}>Activity</Typography>}
                  />
                </ListItemButton>
              </StyledListItem>
            </List>
          </Paper>
        </Grid>
        <Grid item lg={9}>
          {
            tabs === 0 && <Box component={'form'} onSubmit={Validation} enctype="multipart/form-data">
              <Grid container spacing={1}>
                <Grid item lg={12}>
                  <Box>
                    <Stack direction={'row'} spacing={2}>
                      <Box>
                        <Typography gutterBottom color={'secondary'}>Ticket No: {ticketNo} </Typography>
                        <Stack direction={'row'} spacing={2}>
                          <Typography gutterBottom variant='body2'>Reporter: {ticketDetail?.orgName}</Typography>
                          <Typography gutterBottom variant='body2'>Date: {ticketDetail?.createdDate}</Typography>
                        </Stack>
                      </Box>
                    </Stack>

                    <Paper elevation={0} sx={{ py: 1, px: 2 }}>
                      <InputBase
                        placeholder='Enter Ticket Name'
                        value={title}
                        defaultValue={title}
                        onChange={(e) => setTitle(e.target.value)}
                        sx={{ width: "100%" }}
                      />
                    </Paper>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Paper elevation={0} sx={{ py: 1, px: 2 }}>
                      <StyledFormControlLabel required sx={{ mb: 5 }}>Description</StyledFormControlLabel>
                      <CKEditor
                        editor={ClassicEditor}
                        data={description !== undefined ? description : ''}
                        onChange={(event, editor) => {
                          const description = editor.getData();
                          setDescription(description);
                        }}
                      />
                    </Paper>
                  </Box>
                  {/* Ticket Status */}
                  <Box sx={{ mt: 2 }}>
                    <Paper elevation={0} sx={{ py: 1, px: 2 }}>
                      <Grid container rowSpacing={2} columnSpacing={2}>
                        <Grid item lg={6}>
                          <FormControl fullWidth>
                            <StyledFormControlLabel required>Assign To</StyledFormControlLabel>
                            <Autocomplete
                              disablePortal
                              id="assign_user_autocomplete"
                              options={assigneeList !== null && assigneeList !== undefined && assigneeList.length > 0 ? assigneeList.map((opt) => ({ name: opt?.username, id: opt?.userid })) : [] }
                              getOptionLabel={(options) => {
                                return(
                                  (options ? options.name : '')
                                )
                              }}
                              value={assignee}
                              //defaultValue={assignee}
                              onChange={(event, value) => setAssignee(value)}
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <ListItem dense={true} sx={{ px: 0, py: 0 }}>
                                    <ListItemIcon sx={{ minWidth: 'auto', mr: '10px', color: theme.palette.secondary.main }}>
                                      <FaRegUserCircle />
                                    </ListItemIcon>
                                    <ListItemText primary={option.name} />
                                  </ListItem>
                                </Box>
                              )}
                              renderInput={(params) => <TextField {...params} placeholder='Select User' />}
                              size='small'
                              disableClearable
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={6}>
                          <FormControl fullWidth>
                            <StyledFormControlLabel required>Status</StyledFormControlLabel>
                            <StyledTextField size='small' fullWidth select value={status} onChange={(event) => setStatus(event.target.value)}>
                              {
                                categoryListData !== undefined &&
                                categoryListData.data.status.map((item, index) => <MenuItem value={item.value}>{item.title}</MenuItem>)
                              }
                            </StyledTextField>
                          </FormControl>
                        </Grid>
                        <Grid item lg={6}>
                          <FormControl fullWidth>
                            <StyledFormControlLabel required>Severity</StyledFormControlLabel>
                            <StyledTextField size='small' fullWidth select value={servity} onChange={(event) => setServity(event.target.value)}>
                              {
                                categoryListData !== undefined &&
                                categoryListData.data.servity.map((item, index) => <MenuItem value={item.value}>{item.title}</MenuItem>)
                              }
                            </StyledTextField>
                          </FormControl>
                        </Grid>
                        <Grid item lg={6}>
                          <FormControl fullWidth>
                            <StyledFormControlLabel required>Category</StyledFormControlLabel>
                            <StyledTextField size='small' fullWidth select value={category} onChange={(event) => setCategory(event.target.value)}>
                              {
                                categoryListData !== undefined &&
                                categoryListData.data.category.map((item, index) => <MenuItem value={item.value}>{item.title}</MenuItem>)
                              }
                            </StyledTextField>
                          </FormControl>
                        </Grid>
                        {category!=="Accident" && 
                        <>
                        <Grid item lg={6}>
                          <FormControl fullWidth>
                            <StyledFormControlLabel required>Type</StyledFormControlLabel>
                            <StyledTextField size='small' fullWidth select value={type} onChange={(event) => setType(event.target.value)}>
                              <MenuItem value={'0'}><em>Select</em></MenuItem>
                              {
                                typeList !== null &&
                                typeList.map((item, index) => <MenuItem value={item.value}>{item.title}</MenuItem>)
                              }
                            </StyledTextField>
                          </FormControl>
                        </Grid>
                        </>
                        }
                        <Grid item lg={6}>
                          <FormControl fullWidth>
                            <StyledFormControlLabel required>Target Date</StyledFormControlLabel>
                            <StyledTextField size='small' fullWidth value={targetDate} onChange={(event) => setTargetDate(event.target.value)} disabled>
                            </StyledTextField>
                          </FormControl>
                        </Grid>
                        {orgType !== 9 && orgType !== 5 && orgType !== 3 && orgType !== 1 && deleteStatus===true && (
                          <>
                            <Grid item lg={6}>
                              <FormControl fullWidth>
                                <StyledFormControlLabel>

                                  <SingleDropdownHelpDesk
                                    label={"Region"}
                                    listItems={regionList}
                                    handleChange={getDealer}
                                    value={region}
                                    isSearch={dropDownSearch}
                                    showAll={allOption}
                                    getInnerText={getInnerText}
                                    disabled={deleteStatus}
                                  />

                                </StyledFormControlLabel>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                        {orgType !== 9 && orgType !== 5 && orgType !== 3 && orgType !== 1 && deleteStatus===true && (
                          <>
                            <Grid item lg={6}>
                              <FormControl fullWidth>
                                <StyledFormControlLabel>
                                  <SingleDropdownHelpDesk
                                    label={"Dealer"}
                                    listItems={dealerList}
                                    handleChange={getCustomer}
                                    value={dealer}
                                    isSearch={dropDownSearch}
                                    showAll={allOption}
                                    disabled={deleteStatus}
                                  />

                                </StyledFormControlLabel>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                        {orgType !== 3 && orgType !== 1 && deleteStatus===true && (
                          <>
                            <Grid item lg={6}>
                              <FormControl fullWidth>
                                <StyledFormControlLabel>

                                  <SingleDropdownHelpDesk
                                    label={"Customer"}
                                    listItems={customerList}
                                    handleChange={getFleet}
                                    value={customer}
                                    isSearch={dropDownSearch}
                                    showAll={allOption}
                                    disabled={deleteStatus}
                                  />

                                </StyledFormControlLabel>
                              </FormControl>
                            </Grid>
                          </>
                        )}
                        {orgType !== 1 && deleteStatus===true && (
                          <>
                            <Grid item lg={6}>
                              <FormControl fullWidth>
                                <StyledFormControlLabel>

                                  <SingleDropdownHelpDesk
                                    label={"Fleet"}
                                    listItems={fleetList}
                                    //handleChange={getVehicle}
                                    value={fleet}
                                    isSearch={dropDownSearch}
                                    showAll={orgType === 3 ? false : allOption}
                                    disabled={deleteStatus}
                                  />

                                </StyledFormControlLabel>
                              </FormControl>
                            </Grid>
                          </>
                        )}

                        <Grid item lg={6}>
                          <FormControl fullWidth>
                            <StyledFormControlLabel>Vehicle (VIN)</StyledFormControlLabel>
                            <Autocomplete
                              disablePortal
                              readOnly={deleteStatus}
                              id="vehicle_auto_complete"
                              options={vehicleList !== null && vehicleList}
                              getOptionLabel={(options) => {
                                return (options ? options.vehicleNo !== '' ? options.vehicleNo : '' : '')
                              }}
                              value={selectedVehicle}
                              //defaultValue={selectedVehicle}
                              onChange={(event, value) => { selectedVehiclefn(value) }}
                              multiple
                              renderOption={(props, option) => (
                                <Box component="li" {...props}>
                                  <ListItem dense={true} sx={{ px: 0, py: 0 }}>
                                    <ListItemText primary={option.vehicleNo} />
                                  </ListItem>
                                </Box>
                              )}
                              renderInput={(params) => <TextField {...params} placeholder='' />}
                              size='small'
                              disableClearable={false}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <StyledFormControlLabel>
                              {/* {(
        <SingleDropdownHelpDesk
                label={"Vehicle"}
                listItems={vehicleList}
                onClick={() => setSearch(false)}
                handleChange={selectVehicle}
                value={selectedVehicle !== null ? selectedVehicle[0]?.key : vehicle}
                isSearch={dropDownSearch}
                searchText={searchText}
             />
            )} */}
                            </StyledFormControlLabel>
                          </FormControl>
                        </Grid>
                        {deleteStatus===true && 
                        <Grid item lg={6}>
                        <StyledFormControl fullWidth>
                          <StyledFormControlLabel required >
                          Incident Time 
                          </StyledFormControlLabel>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                              inputFormat="dd/MM/yyyy hh:mm a"
                              value={tempDate}
                              onChange={handleDateChange}                     
                                renderInput={(params) => (
                                <StyledTextField
                                  fullWidth
                                  size="small"
                                  onKeyDown={onKeyDown}
                                  {...params}
                                  disabled = {true}
                                  readonly
                                />
                              )}
                              disabled={true}
                              />
                          </LocalizationProvider>
                        </StyledFormControl>
                      </Grid>
                      }
                   {deleteStatus===true && 
                  <Grid item lg={6}>
                    <FormControl fullWidth>
                      <StyledFormControlLabel>Incident Location</StyledFormControlLabel>
                      <StyledTextField name={'title'} size='small' placeholder='Enter Incident Location' value={incidentLocation} fullWidth onChange={(event) => setIncidentLocation(event.target.value)} />
                    </FormControl>
                  </Grid>
                    }
                    {(deleteStatus===true && status==="Closed" || status==="Resolved" || status==="Reopen") && 
                        <Grid item lg={6}>
                        <StyledFormControl fullWidth>
                          <StyledFormControlLabel required >
                          Incident Close Time 
                          </StyledFormControlLabel>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                              inputFormat="dd/MM/yyyy hh:mm a"
                              value={tempCloseDate}
                              onChange={handleCloseDateChange}                     
                                renderInput={(params) => (
                                <StyledTextField
                                  fullWidth
                                  size="small"
                                  onKeyDown={onKeyDown}
                                  {...params}
                                  //disabled = {true}
                                  readonly
                                />
                              )}
                              />
                          </LocalizationProvider>
                        </StyledFormControl>
                      </Grid>
                      }
                     {deleteStatus===true && 
                  <Grid item lg={6}>
                    <FormControl fullWidth>
                      <StyledFormControlLabel>Driver Number</StyledFormControlLabel>
                      <StyledTextField name={'title'} size='small' placeholder='Enter Driver Number' value={driverNumber} fullWidth  inputProps={{ maxLength: 15 }} onChange={(event) => setDriverNumber(event.target.value)} />
                    </FormControl>
                  </Grid>
                        }
                        
                      </Grid>
                    </Paper>
                  </Box>

                  {/* Comments */}
                  <Box sx={{ mt: 2 }}>
                    <Paper elevation={0} sx={{ py: 1, px: 2 }}>
                      <StyledFormControlLabel required sx={{ mb: 5 }}>Comments</StyledFormControlLabel>
                      <CKEditor
                        editor={ClassicEditor}
                        data={comment}
                        onChange={(event, editor) => {
                          const comment = editor.getData();
                          setComment(comment);
                        }}
                      />
                      <Box sx={{ mt: 2, maxHeight: '250px' }}>
                        <Scrollbars
                          autoHeight
                          autoHeightMin={0}
                          autoHeightMax={120}
                        >
                          {
                            commentsListLoading ? <ScaleLoader color={theme.palette.secondary.main} /> :
                              commentsLists !== null && commentsLists.length > 0 && commentsLists?.map((item, index) =>
                                <ListItem dense={true} sx={{ p: 0 }}>
                                  <ListItemIcon sx={{ minWidth: 'auto', mr: 2, fontSize: '20px' }}>
                                    <FaRegUserCircle />
                                  </ListItemIcon>
                                  <ListItemText primary={<Typography variant='subtitle' sx={{ mb: 0, '& p': { mb: 0 } }}>{renderHTML(item.comment)}</Typography>} secondary={`${item.username}, ${item.createdDate}`} />
                                </ListItem>
                              )
                          }
                        </Scrollbars>
                      </Box>
                    </Paper>
                  </Box>

                  <Box sx={{ my: 2 }}>
                    <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'flex-end'}>
                      <SoftButton variant='conatained' className='success' size='small' type='submit' sx={{ cursor: updateTicketLoading ? 'wait' : 'pointer' }}>
                        {
                          updateTicketLoading ? <ScaleLoader color='#fff' height={12} /> : 'Update'
                        }
                      </SoftButton>
                      <SoftButton variant='conatained' className='error' size='small' onClick={() => navigate(location.pathname)}>Cancel</SoftButton>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          }
          {
            tabs === 1 && <EditTicketUpload data={attachments} uploadFile={uploadFile} ticketDetail={ticketDetail} uploadFileLoading={uploadFileLoading} getSingleTicket={getSingleTicket} deleteImage={deleteImage} deleteImageLoading={deleteImageLoading} />
          }
          {
            tabs === 2 && <Activity listsActivity={listsActivity} />
          }

        </Grid>
      </Grid>
    </Box>
  )
}

export default EditTicket