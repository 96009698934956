import React from 'react'
import { StyledDropDown, StyledFormControlLabel, StyledTextField } from '../theme/StyledEle';
import { FormControl, ListSubheader, MenuItem } from '@mui/material';

function Dropdown(props) {
    const { label, listItems=null, all=false, registerValue, search=false, value, handleChange, onSearch, searchText } = props;
    return(
        <FormControl fullWidth>
            <StyledFormControlLabel>{label}</StyledFormControlLabel>
            <StyledDropDown onChange={(event) => handleChange(event, registerValue, all)} size='small' fullWidth defaultValue={value} value={value}
            MenuProps={{
                MenuListProps:{
                  autoFocus:false,
                  autoFocusItem:false
                }
              }}
            >
                {
                    search &&
                    <ListSubheader sx={{px:1, lineHeight:'0px'}}>
                    <StyledTextField
                        size="small"
                        onChange={(event) => onSearch(event)}
                        onKeyDown={(e) => e.stopPropagation()}
                        value={searchText}
                        sx={{pointerEvents:'none', '& input':{pointerEvents:'all'} }}
                        autoFocus
                        fullWidth
                    />
                    </ListSubheader>
                }
                <MenuItem value={0}><em>Select</em></MenuItem>
                {
                    value === 404 && <MenuItem value={404}><em>No {label} found</em></MenuItem>
                }
                
                {all && <MenuItem value={'-1'}>All</MenuItem>}
                { listItems !== null && listItems?.map((item, index) => <MenuItem value={item.key}>{item.value}</MenuItem>) }
            </StyledDropDown>
        </FormControl>
    )
}

export default Dropdown