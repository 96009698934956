import React, { useState, useMemo, useEffect } from "react";
import {Box, Button, CardContent, CardHeader } from "@mui/material";
import { DataTable } from "../shared/data_table/DataTable";
import { Breadcrumb } from "../shared/Breadcrumbs";
import {
  StyledCardShadow,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import {
useCellVoltLogMutation,
} from "../../store/slice/LogsSlice/LogsSlice";
import AlertDropdown from "../view-alerts/AlertsDropdown/AlertDropdown";
import reportServices from "../../services/reportServices";

const CellLog = () => {
  //user data form local storage
  const userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const [
    cellList,
    {
      data: cellListdata,
      isLoading: getCellListLoading,
      isSuccess: getCellListSuccess,
    },
  ] = useCellVoltLogMutation();

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "gpsDate",
        width:80
      },
      {
        Header: "Vehicle No",
        accessor: "vehicleno",
        width:80
      },
      {
        Header: "Gps Location",
        accessor: "gpsLocation",
        width:80,
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await reportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.gpsLocation}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.gpsLocation}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.gpsLocation}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.gpsLocation}
                >
                  {row.original.gpsLocation}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.gpsLocation}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.gpsLocation}
                  key={row.original.gpsLocation}
                ></a>
              </Box>
            </Box>
          );
        },
      
      },

      {
        Header: "Description",
        accessor: "description",
      },


    ],
    []
  );
  let commonPayload = {};
  let [payload, setPayload] = useState(null);
  // Onload and Submit
  const getData = (data) => {
   
    commonPayload.startDate = data.fromDate ;
    commonPayload.endDate = data.toDate ;
    commonPayload.search= "";
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.columnName = "CREATED_DATE";
    commonPayload.orderDir = "desc";
    commonPayload.resellerId = resellerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.type = data.cellType;

    setPayload(commonPayload);
    cellList(commonPayload);
  };
  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
        cellList(payload);
    } else if (payload.search.length === 0) {
        cellList(payload);
    }
  };
  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      cellList(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      cellList(payload);
    }
  };

  useEffect(() => {
    if (getCellListSuccess) {
      setResponseData([]);
      if (cellListdata.data !== null) {
        setResponseData(cellListdata.data.content);

        setTotalElements(cellListdata.data.totalElements);
        setTotalPages(cellListdata.data.totalPages);
      }
    }
   
  }, [getCellListSuccess]);
 
  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Logs" subDiv="Battery Cell Log"></Breadcrumb>
      <Box>
      <AlertDropdown getData={getData} isShowAll={true} />
        <StyledCardShadow sx={{ mt: 2 }}>
        <CardHeader title={<StyledTableTitle>Battery Cell Log</StyledTableTitle>} />
          <CardContent sx={{ py: 0 }}>
            <DataTable
              isLoading={getCellListLoading}
              includeSearchBox={true}
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              handleChange={handleSearch}
              includeExportButton={false}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};
export default CellLog;
