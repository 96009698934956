import { Box } from "@mui/material";
import StyledTabs from "../shared/StyledTabs";
import CreateUser from "./CreateUser";
import ListUser from "./ListUser";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { StyledPageLayout } from "../../theme/StyledEle";
import React, { useState, useEffect } from "react";
import logServices from "../../services/logService";
import { useDispatch, useSelector } from "react-redux";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { snackService } from "../../store/slice/ControlsSlice";
import { useLocation, useNavigate } from "react-router-dom";
import FormDialog from "../../Utils/FormDialog";
import ViewUser from "./ViewUser";
import OppositeContentTimeline from "./RecentActivityUser"

const User = () => {

  const location = useLocation();
  let hashId = location.hash;
  const globalJson1 =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : null;
  var globalOrgId = 1;
  var globaluserId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalResellerId = 2;
  var globalOrgType = 2;
  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
    globalOrgId = globalJson.orgId;
    globalOrgType = globalJson.userOrgType;
    globaluserId = globalJson.id;
    globalRoleId = globalJson.roleId;
    globalResellerId = globalJson.resellerId;
  }

  const [userToEdit, setuserToEdit] = React.useState(null);
  const [commonFilter, setCommonFilter] = React.useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,
  });
  const [tempcommonFilter, setTempcommonFilter] = React.useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,
  });

  useEffect(async () => {
    var request = {};
    request.message = "Admin > User > List User";
    request.action = "View";
    request.featureName = "user";
    request.customerName = "All";
    request.fleetName = "All";
    request.result = "Success";
    request.access = "Portal";
    request.severity = "INFO";
    request.orgId = globalOrgId;
    request.userId = globaluserId;

    const responseData = await logServices.saveAuditLogDetails(request);
  }, []);

  const [tabShow, setTabShow] = React.useState("1");
  const [menuUser, setMenuUser] = useState();
  const [create, setCreate] = useState("Create User");

  const callfun = () => {
    if (
      "null" !== JSON.parse(localStorage.getItem("menuList")) &&
      null !== JSON.parse(localStorage.getItem("menuList"))
    ) {
      if (JSON.parse(localStorage.getItem("menuList")) !== null) {
        for (
          let i = 0;
          i < JSON.parse(localStorage.getItem("menuList")).length;
          i++
        ) {
          if (JSON.parse(localStorage.getItem("menuList"))[i].sub.length > 0) {
            for (
              let j = 0;
              j < JSON.parse(localStorage.getItem("menuList"))[i].sub.length;
              j++
            ) {
              if (
                JSON.parse(localStorage.getItem("menuList"))[i].sub[j].route ===
                "user"
              ) {
                setMenuUser(
                  JSON.parse(localStorage.getItem("menuList"))[i].sub[j].button
                );
                var dataStr = {};
                dataStr.create = false;
                dataStr.edit = false;
                dataStr.delete = false;
                dataStr.view = false;
                dataStr.recentActivation = false;
                dataStr.importfunction = false;
                dataStr.excel = false;
                dataStr.pdf = false;
                dataStr.resendActivation = false;
                var strval;
                strval = JSON.parse(localStorage.getItem("menuList"))[i].sub[
                  j
                ].button.split(",");
                var edit = false;
                for (var k = 0; k < strval.length; k++) {
                  if (parseInt(strval[k]) === 1) {
                    dataStr.create = true;
                  }
                  if (parseInt(strval[k]) === 2) {
                    dataStr.view = true;
                  }
                  if (parseInt(strval[k]) === 4) {
                    dataStr.edit = true;
                  }
                  if (parseInt(strval[k]) === 3) {
                    dataStr.delete = true;
                  }
                  if (parseInt(strval[k]) === 18) {
                    dataStr.recentActivation = true;
                  }
                  if (parseInt(strval[k]) === 9) {
                    dataStr.importfunction = true;
                  }
                  if (parseInt(strval[k]) === 5) {
                    dataStr.excel = true;
                  }
                  if (parseInt(strval[k]) === 6) {
                    dataStr.pdf = true;
                  }
                  if (parseInt(strval[k]) === 38) {
                    dataStr.resendActivation = true;
                  }
                }
                setCommonFilter(dataStr);
                setTempcommonFilter(dataStr);
                break;
              }
            }
          }
        }
      }
    }

    var tabshowval = "6";
    var displaydefaulttab = 1;
    if (
      dataStr.create === false &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false
    ) {
      setTabShow("2");
      tabshowval = "2";
      displaydefaulttab = 0;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false
    ) {
      setTabShow("1");
      tabshowval = "1";
      displaydefaulttab = 1;
    }else if (
      dataStr.create === true &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === false
    ) {
      setTabShow("3");
      tabshowval = "1";
      displaydefaulttab = 2;
    }
    return displaydefaulttab;
  };

  const [tabValue, setTabValue] = useState(() => callfun());

  const handleTabChange = (event, newValue) => {
    setuserToEdit();
    setTabValue(newValue);
    setCreate("Create User");
  };


  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const handleUserView = (userEditedId) => {
    // var displaydefaulttab = 2;
    // if (tabShow === "1") {
    //   displaydefaulttab = 0;
    // }
    // setTabValue(displaydefaulttab);
    // var tempProps = JSON.parse(JSON.stringify(userEditedId));
    // tempProps.clickedval = "view";
    // setuserToEdit(Object.preventExtensions(tempProps));
    // setCreate("View User");
    // setTabShow("1");

    navigate('#view');
    setUserData(userEditedId);
  };


  const handleUserEdit = (userEditedId) => {
    var displaydefaulttab = 1;
    if (tabShow === "1") {
      displaydefaulttab = 0;
    }else  if (tabShow === "2") {
      displaydefaulttab = 1;
    }
    setTabValue(displaydefaulttab);
    var tempProps = JSON.parse(JSON.stringify(userEditedId));
    tempProps.clickedval = "edit";
    setuserToEdit(Object.preventExtensions(tempProps));
    setCreate("Edit User");
    // setTabShow("1");
  };

  const handleCancel = () => {
    setCreate("Create User");
    var dataStr = { ...tempcommonFilter };
    var tabshowval = "6";
    var displaydefaulttab = 2;
    if (
      dataStr.create === true &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false
    ) {
      setTabShow("1");
      tabshowval = "1";
      displaydefaulttab = 1;
    }
    setTabValue(displaydefaulttab);
  };

  const TABS = [
    {
      label: create,
      children: <CreateUser editUser={userToEdit} onCancel={handleCancel} />,
    },
    {
      label: "Users",
      children: (
        <ListUser
          onEditUser={handleUserEdit}
          onViewUser={handleUserView}
          menuUser={menuUser}
        />
      ),
    },
  ];
  const TABS2 = [
    {
      label: "Users",
      children: (
        <ListUser
          onEditUser={handleUserEdit}
          onViewUser={handleUserView}
          menuUser={menuUser}
        />
      ),
    },
  ];
  const TABS3 = [
    {
      label: "Recent Activity",
      children: <OppositeContentTimeline />,
    },
    {
      label: create,
      children: <CreateUser editUser={userToEdit} onCancel={handleCancel} />,
    },
    {
      label: "Users",
      children: (
        <ListUser
          onEditUser={handleUserEdit}
          onViewUser={handleUserView}
          menuUser={menuUser}
        />
      ),
    }
  ];
  //sector
  const isSuccess = useSelector((state) => state.controls.alert);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isSuccess.isSuccess) {
      setTimeout(() => dispatch(snackService([null, null, null])), 6000);
    }
  }, [isSuccess.isSuccess]);
  
  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Admin"
        subDiv="User"
        title="Create User"
      ></Breadcrumb>
      {tabShow === "1" && (
        <StyledTabs
          tabs={TABS}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {tabShow === "2" && (
        <StyledTabs
          tabs={TABS2}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
       {tabShow === "3" && (
        <StyledTabs
          tabs={TABS3}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
{userData !== null && hashId === "#view" && (
<FormDialog title={'View User'} padding={false} size="md">
        <ViewUser data={userData}/>
    </FormDialog>)}
      {isSuccess.isSuccess && (
        <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
      )}
    </StyledPageLayout>
  );
};

export default User;
