import { vehicleLatestDetailTabs } from "../../Utils/Constans";
import { StyledTab, StyledTabBox} from "../../theme/StyledEle";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { StyledPageLayout } from "../../theme/StyledEle";
import { useSelector } from "react-redux";
import React,{ useEffect } from "react";
import {useAuditLogMutation} from "../../store/slice/ApiSlice";
import { Tabs,Box,} from "@mui/material";
import VehicleDetailVolt from "./VehicleDetailVoltReport";
import VehicleLatestDetailReport from "./VehicleLatestDetailReport";
import VehicleDetailBatteryHealth from "./VehicleDetailBatteryHealth";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box >{children}</Box>}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  
  export default function VehicleLatestDetailMain(){
    const tabsOpt = useSelector((state) => state.controls.customizer.currentPage.buttonOpt);
    const [value, setValue] = React.useState(51);
    let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let roleId = userData.roleId;
  let userOrgType = userData.userOrgType;
    let navigate = useNavigate();
//   const theme = useTheme();

 
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  const [auditLog] = useAuditLogMutation();
 
    const handleChange = async (event, newValue) => {
        setValue(newValue);
        if (newValue === 48) {
          auditPayload.message = "Reports > Vehicle Last Connected";
          navigate('#charging');
        } else if (newValue === 49) {
        
          auditPayload.message = "Reports > Voltage Difference";
          navigate('#chargeTemp');
        } else if (newValue === 50) {
        
          auditPayload.message = "Reports > Dehydration";
          navigate('#disChargeTemp');
        } 
        auditPayload.action = "Select";
        auditPayload.featureName = "Reports";
        auditPayload.customerName = "All";
        auditPayload.fleetName = "All";
        auditPayload.result = "Success";
        auditPayload.access = "Portal";
        auditPayload.severity = "INFO";
     
        auditLog(auditPayload);
    }
      
    return (
       
        <StyledPageLayout>
        <Breadcrumb
            mainDiv="Reports"
            subDiv="Vehicle Latest Detail"
            title="Vehicle Latest Detail"
            includePopup={true}
        ></Breadcrumb>
         <StyledTabBox>
         { resellerId === 109882 &&  <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
        { vehicleLatestDetailTabs?.map((item) => {
            if(tabsOpt?.includes(item.valaue)){
            return( <StyledTab value={item.valaue} label={item.label} /> )
            } 
        }) }
      
        </Tabs>}
        </StyledTabBox>
            <CustomTabPanel value={value} index={51}>
            
                <VehicleLatestDetailReport></VehicleLatestDetailReport>
            
            </CustomTabPanel>
            <CustomTabPanel value={value} index={52}>
            
                <VehicleDetailVolt></VehicleDetailVolt>
            
            </CustomTabPanel>
            <CustomTabPanel value={value} index={53}>
            
                <VehicleDetailBatteryHealth></VehicleDetailBatteryHealth>
            
            </CustomTabPanel>
        </StyledPageLayout>
                
    )
}
