import { Button, CardContent } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import ReportServices from "../../services/reportServices";
import {
  useAuditLogMutation,
  useDisChargingMutation,
} from "../../store/slice/ApiSlice";
import { StyledCardShadow, StyledPageLayout, StyledToggleButton, StyledToggleButtonGroup } from "../../theme/StyledEle";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import MouseOverPopover from "../shared/PopOver";
import StyledTooltip from "../shared/StyledTooltip";
import { DataTable } from "../shared/data_table/DataTable";

const text =
"This report shows the instances of vehicles when temperature rise is greater than 30 degree celsius .";

const text1 =
"This report shows the instances of vehicles when maximum temperature is greater than 30 degree celsius .";

const DisChargingTempBehaviour = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let roleId = userData.roleId; 

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [auditLog] = useAuditLogMutation();
  const [hideDetails, setHidedetails] = useState("tempRise");
  const [alignment, setAlignment] = React.useState("tempRise");
  const theme = useTheme();
  const handleAlignment = (event, newAlignment) => {
    if(newAlignment !== null) {
      setAlignment(newAlignment);
      setHidedetails(newAlignment);
      setResponseData([]);
    if(newAlignment === 'maxTemp'){
      payload.maxTemp = 30;
      payload.tempRise  = 0;
   
      getDisCharging(payload);
      setPayload(payload);
    }else{
      payload.maxTemp = 0;
      payload.tempRise  = 30;
      getDisCharging(payload);
      setPayload(payload);
    }
      
    }

  };
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  useEffect(async () => {
    auditPayload.message = "Reports > Charging";
    auditPayload.action = "View";
    auditPayload.featureName = "Charging Report";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);



  const columns2 = useMemo(
    () => [
      {
        Header: "Vehicle Number",
        accessor: "vehicleno",
      },

      {
        Header: "Start Location",
        accessor: "startLoc",

        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.startLoc}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.startLoc}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.startLoc}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.startLoc}
                >
                  {row.original.startLoc}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.startLoc}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.startLoc}
                  key={row.original.startLoc}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "End Location",
        accessor: "endLoc",
        Cell: ({ row, column, cell }) => {

          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.endLoc}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.endLoc}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.endLoc}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.endLoc}
                >
                  {row.original.endLoc}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.endLoc}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.endLoc}
                  key={row.original.endLoc}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "Start Time",
        accessor: "tripStartTime",
      },
      {
        Header: "End Time",
        accessor: "tripEndTime",
      },
      {
        Header: "Duration (hh:mm)",
        accessor: "duration",
      },

      {
        Header: "Average Speed (km/h)",
        accessor: "avgSpeed",
      },
  
      {
        Header: "Max Speed (km/h)",
        accessor: "maxSpeed",
      },

      {
        Header: "Moving (hh:mm)",
        accessor: "movminsinhrs",
      },
      {
        Header: "Start Odometer (km)",
        accessor: "canStartOdometer",
      },
      {
        Header: "End Odometer (km)",
        accessor: "canEndOdometer",
      },
      {
        Header: "Distance Traveled (km)",
        accessor: "canDistTravelled",
      },
      {
        Header: "Start Voltage (V)",
        accessor: "startVoltage",
      },
      {
        Header: "End Voltage (V)",
        accessor: "endVoltage",
      },
      {
        Header: "Battery Temperature Rise (℃)",
        accessor: "temprise",
      },

      {
        Header: "Max Battery Temp (℃)",
        accessor: "maxBtTemp",
      },
      {
        Header: "Avg Battery Temp (℃)",
        accessor: "avgBtTemp",
      },
      {
        Header: "Max Battery Current (A)",
        accessor: "maxBtCurrent",
      },
      // {
      //   Header: "Avg Battery Current (A)",
      //   accessor: "avgBtCurrent",
      // },
      {
        Header:
          resellerId === 110224 ? "Start Energy (kWh)" : "Start Energy (Wh)",
        accessor: "startEnergy",
      },
      {
        Header: resellerId === 110224 ? "End Energy (kWh)" : "End Energy (Wh)",
        accessor: "endEnergy",
      },
      {
        Header:
          resellerId === 110224
            ? "Energy Consumed (kWh)"
            : "Energy Consumed (Wh)",
        accessor: "energyConsumed",
      },
      {
        Header:
          resellerId === 110224
            ? "Energy Efficiency (km/kWh)"
            : resellerId === 109882
            ? "Energy Efficiency (wh/km)"
            : "Energy Efficiency (km/Wh)",
        accessor: "energyefficiency",
      },
      {
        Header: (
          <StyledTooltip
            header={
              resellerId === 110224
                ? "Energy Efficiency (kWh/km)"
                : "Energy Efficiency (km/Wh)"
            }
            title={"Considered for Distance > 10km/day"}
          />
        ),
        accessor: "energyefficiencyPerKm",
        Cell: ({ row }) => {
       
          if(  row.original.energyefficiencyPerKm === 0  ){
            return "-"
          }else{
            return row.original.energyefficiencyPerKm 
          }
        }
      },
      {
        Header: "Start SOC (%)",
        accessor: "strstartsoc",
      },
      {
        Header: "End SOC (%)",
        accessor: "strendsoc",
      },
      {
        Header: "Trip Status",
        accessor: "tripStatus",
      },
    ],
    []
  );



  let initialState1 = {};
  var arr = [];



  if (resellerId !== 110224) {
    arr.push("chargingType");
    arr.push("zoneName");
    arr.push("customerName");
    initialState1.hiddenColumns = arr;
   
  }


  // if( resellerId === 110224 && roleId !== 17){
  //   arr.push("maxBtCur");
  //   arr.push("stEnergy");
  //   arr.push("edEnergy");
  //   initialState1.hiddenColumns= arr;
  // }else{
  // arr.push("maxBtCur");
  // arr.push("stEnergy");
  // arr.push("edEnergy");
  // initialState1.hiddenColumns= arr;

  // }

  // Get Data from New Dropdown
  const [getDisCharging, { data: oversppedData, isLoading, isSuccess }] =
    useDisChargingMutation();
  let commonPayload = {};
  // const [exportOpt, setExportOpt] = useState(false);

  const onSubmitForm = (data) => {
    // if(data.regionId ==="-1" && data.dealerId ==="-1" && data.customerId ==="-1" && data.fleetId ==="-1" && data.vehicleId === '-1' && data.selectedPriod ===2){
    //   setExportOpt(false);
    // }else if(data.regionId ==="-1" && data.dealerId ==="-1" && data.customerId ==="-1" && data.fleetId ==="-1" && data.vehicleId === '-1' && data.selectedPriod ===3){
    //   setExportOpt(false);
    // }else{
    //   setExportOpt(true);
    // }
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.reselId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.custId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehId = data.vehicleId;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.sortBy = "desc";
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.chargingType = data.chargeType
    commonPayload.maxTemp = data.maxTemp;
    commonPayload.tempRise  = data.tempRaise;
    commonPayload.disChargeFlag  = 1;
    
    if(hideDetails === 'maxTemp'){
      commonPayload.maxTemp = 30;
      commonPayload.tempRise  = 0;
    }else{
      commonPayload.maxTemp = 0;
      commonPayload.tempRise  = 30;
    }

    setPayload(commonPayload);
    getDisCharging(commonPayload);

    auditPayload.message = "Reports > Charging Report > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Charging Report";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  //Export PDF & Excel
  const [payload, setPayload] = useState(null);
  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "ChargingReport/getDisChargingReport.csv?startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        "&orgId=" +
        payload.orgId +
        "&vehId=" +
        payload.vehId +
        "&reselId=" +
        payload.reselId +
        "&regionId=" +
        payload.regionId +
        "&dealerId=" +
        payload.dealerId +
        "&custId=" +
        payload.custId +
        "&chargingType=" +
        payload.chargingType +
        "&sortBy=" +
        payload.sortBy +
        "&page=" +
        payload.page +
        "&size=" +
        payload.size +
        "&maxTemp=" +
        payload.maxTemp+
        "&tempRise=" +
        payload.tempRise+
        "&disChargeFlag=" +
        payload.disChargeFlag;
      window.open(url);

      auditPayload.message = "Reports > Charging > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "Charging Report";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };
  const onChangePDF = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "ChargingReport/ChargingreportPDF.pdf?startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&reselId=" +
        payload.reselId +
        "&regionId=" +
        payload.regionId +
        "&dealerId=" +
        payload.dealerId +
        "&custId=" +
        payload.custId +
        "&chargingType=" +
        payload.chargingType +

        "&maxTemp=" +
        payload.maxTemp+
        "&tempRise=" +
        payload.tempRise+
        "&disChargeFlag=" +
        payload.disChargeFlag;;

      window.open(url);

      auditPayload.message = "Reports > Charging > PDF";
      auditPayload.action = "View";
      auditPayload.featureName = "Charging Report";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
    } catch (error) {}
  };
  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    setNewPage(0);
    setRowsPerPage(25);
    if (payload.search.length >= 3) {
      getDisCharging(payload);
    } else if (payload.search.length === 0) {
      getDisCharging(payload);
    }

  };
  
  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getDisCharging(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getDisCharging(payload);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
    
      if (oversppedData.data !== null) {
      
        setResponseData(oversppedData.data.content);
        setTotalElements(oversppedData.data.totalElements);
        setTotalPages(oversppedData.data.totalPages);
      }
    }
  }, [isSuccess]);



  return (
    <StyledPageLayout sx={{ p: 0}}>

      <MouseOverPopover text={hideDetails === 'tempRise' ?  text :text1} title={"Charging Report"} />
      <Box>
        
        <FilterDropdown getData={onSubmitForm} status={false} allowDays = {7} allowedMonth = {resellerId === 110224 ? 6 : 3} time = {true}  thisMonth={true} currentMonth={true} chargeTypeShow={resellerId === 110224 ? true : false} />
        <StyledToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleAlignment}
            size="small"
            sx={{ mt: 2 }}
          >
            <StyledToggleButton value={"tempRise"}>
            Temperature Rise
            </StyledToggleButton>
            
            <StyledToggleButton value={"maxTemp"} >Max Temperature</StyledToggleButton> 
        
          </StyledToggleButtonGroup>
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              payload={payload}
              exportWithAll={false}
              isLoading={isLoading}
              columns={columns2}
              initialState={initialState1}
              data={responseData}
              onChangeXcl={onChangeXcl}
              onChangePdf={onChangePDF}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              includeSearchBox={true}
              handleChange={handleSearch}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              includeExportButton={true}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};

export default DisChargingTempBehaviour;
