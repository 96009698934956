import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  TextField,
  MenuItem,
  Button,
  FormControl,
  FormHelperText,
  CardActions,
  CardHeader,
  Box,
  Grid,
  CardContent,
  useTheme,
  InputAdornment,
} from "@mui/material";
import {
  StyledCardShadow,
  StyledDropDown,
  StyledFormButtonsRow,
  StyledFormControl,
  StyledFormControlLabel,
  StyledTableTitle,
  StyledTextField,
  SoftButton,
  StyledFilterSearch,
} from "../../theme/StyledEle";
import { PulseLoader } from "react-spinners";
import OrganizationServices from "../../services/organizationServices";
import DraggableDialog from "../shared/DailogDrag";
import dropdownServices from "../../services/dropdownServices";
import { BackDropLoader } from "../shared/StyledLoader";
import logServices from "../../services/logService";
import { useDispatch } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
import {
  useAuditLogMutation,
  useOrgCreateMutation,
  useOrgDetMutation,
  useOrgUpdateMutation,
} from "../../store/slice/ApiSlice";
import SearchIcon from "@mui/icons-material/Search";
const shouldIncludeAllOption = true;
let url = window.location.origin;


const getOptions = (options) => {
  const allOption = {
    title: "Select",
    value: "0",
  };
  return shouldIncludeAllOption ? [allOption, ...options] : options || [];
};

export default function CreateOrganizationForm({ orgToEdit, onCancel }) {
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedOrgType, setSelectedOrgType] = React.useState([]);
  const [states, setStates] = useState(getOptions([]));
  const [cities, setCities] = useState(getOptions([]));
  const [selectOrgType, setSelectOrgType] = useState("");
  const [selectedParentId, setSelectedParentId] = useState(1);
  const [custrrsllrOptions, setCustrrsllrOptions] = React.useState([]);
  const [region, setRegion] = useState([]);
  // const [circularOpen, setCircularOpen] = React.useState(false);
  const [clickedval, setClickedval] = React.useState(false);
  const [dealer, setDealer] = useState([]);
  const [selectedDealer, setSelectedDealer] = useState("");
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(null);
  const [auditLog] = useAuditLogMutation();
  const [hideState, setHideState] = useState(false);
  const [selectDealerId, setSelectDealerId] = useState(null);
  const [selectRegionId, setSelectRegionId] = useState(null);
  const [customer, setCustomer] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectCustomerId, setSelectCustomerId] = useState(null);
  const theme = useTheme();

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let orgLogo = userData.orgLogo;
  let userId = userData.id;
  let parentOrgLogo = userData.parentOrgLogo;
  let roleId = userData.roleId;
  let userOrgType = userData.userOrgType;
  let orgName = userData.orgName;
  let userName = userData.userName;

  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };

  const {
    register,
    setValue,
    watch,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  useEffect(() => {
    orgType();
    onChangeData();
    loadRegion();
    loadState();
    loadCity();
    getData();
  }, []);

  const goList = () => {
    onCancel();
  };
  const getData = async () => {
    if (orgToEdit) {
      if (orgToEdit.clickedval === "view") {
        setClickedval(true);
      }
      // const responseData = (
      //   await OrganizationServices.editOrgById(orgToEdit.id)

      // ).data.data;
      var payload = {};
      payload.orgId = orgToEdit.id;
      getOrgDet(payload);

      auditPayload.message = "Admin > Organization > Edit Organization";
      auditPayload.action = "view";
      auditPayload.featureName = "Organization";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName =userName;

      auditLog(auditPayload);
    }
  };

  //   const globalJson2 =
  //   localStorage.getItem("userData") !== null
  //     ? JSON.parse(localStorage.getItem("userData"))
  //     : null;
  // var globalOrgId = 1;
  // var globaluserId = 1;
  // var globalRoleId = 0;
  // var globalJson = {};
  // var globalResellerId = 2;
  // var globalOrgType = 2;
  // var globalCustomerId = 0;
  // if (
  //   "null" === localStorage.getItem("userData") ||
  //   null === localStorage.getItem("userData")
  // ) {
  // } else {
  //   globalJson =
  //     localStorage.getItem("userData") !== null
  //       ? JSON.parse(localStorage.getItem("userData"))
  //       : 0;
  //   globalOrgId = globalJson.orgId;
  //   globalOrgType = globalJson.userOrgType;
  //   globaluserId = globalJson.id;
  //   globalRoleId = globalJson.roleId;
  //   globalResellerId = globalJson.resellerId;
  //   globalCustomerId = globalJson.customerId;
  // }

  //Slice service start

  const [
    orgCreate,
    {
      data: orgCreateData,
      isLoading: orgCreateLoading,
      isSuccess: orgCreateSuccess,
    },
  ] = useOrgCreateMutation();
  const [
    orgUpdate,
    {
      data: orgUpdateData,
      isLoading: orgUpdateLoading,
      isSuccess: orgUpdateSuccess,
    },
  ] = useOrgUpdateMutation();
  const [
    getOrgDet,
    { data: getOrgData, isLoading: orgDetLoading, isSuccess: orgDetSuccess },
  ] = useOrgDetMutation();
  // const [simCreate, {data:simCreateData, isLoading:simCreateLoading, isSuccess:simCreateSuccess }] = useSimCreateMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (orgUpdateSuccess) {
      // setMessage(orgUpdateData?.resultMessage);
      // setStatus(orgUpdateData?.resultText);
      dispatch(
        snackService([
          orgUpdateData?.resultMessage,
          orgUpdateData?.resultText,
          orgUpdateSuccess,
        ])
      );
      onCancel();
    } else if (orgCreateSuccess) {
      // setMessage(orgCreateData?.resultMessage);
      // setStatus(orgCreateData?.resultText);
      dispatch(
        snackService([
          orgCreateData?.resultMessage,
          orgCreateData?.resultText,
          orgCreateSuccess,
        ])
      );
      onCancel();
    } else if (orgDetSuccess) {
     
      reset({
        firstName: getOrgData.data.firstName,
        lastName: getOrgData.data.lastName,
        userName: getOrgData.data.userName,
        email: getOrgData.data.email,
        mobile: getOrgData.data.mobile,
        name: getOrgData.data.name,
        address: getOrgData.data.address,
        zipCode: getOrgData.data.zipCode,
      });
      // if( getOrgData.data.orgRegistrationNo === "AssignedDealerCustomer" || getOrgData.data.orgRegistrationNo === "UnassignedDealerCustomer"){
      setHideState(true);
      // }
      loadCity(getOrgData.data.stateId);
      onChangeData(getOrgData.data.orgType);
      setSelectOrgType(getOrgData.data.orgType);
      setSelectDealerId(getOrgData.data.dealerId);
      setSelectRegionId(getOrgData.data.regionId);
      setSelectCustomerId(getOrgData.data.customerId);
    }
  }, [orgCreateSuccess, orgUpdateSuccess, orgDetSuccess]);

  useEffect(async () => {
    auditPayload.message = "Admin > Organization > Create Organization";
    auditPayload.action = "View";
    auditPayload.featureName = "Organization";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.orgId = orgId;
    auditPayload.userId = userId;
    auditPayload.userName = userName;

    auditLog(auditPayload);

    // const responseData = await logServices.saveAuditLogDetails(request);
  }, []);

  const loadState = async () => {
    const { data: statedetails } = await OrganizationServices.loadState();
    setStates(getOptions(statedetails));
  };
  const loadCity = async (val) => {
    const request = {
      id: val,
    };
    const { data: result } = await OrganizationServices.loadCity(request);

    setCities(getOptions(result));
  };

  const loadRegion = async () => {
    const request = {
      value: "region",
      searchText: "",
    };

    const { data: result } = await dropdownServices.getResellers(request);

    setRegion(result.data.content);
  };
  const orgType = async () => {
    if (parseInt(userOrgType) === 2 || parseInt(userOrgType) === 8) {
      const orgTypePayload = {
        value: "orgType",
        resellerId: resellerId != null ? resellerId : "-1",
        dealerId: 0,
        searchText: "",
        roleId: roleId != null ? roleId : "-1",
      };
      var orgTypeData = [];
      const { data: orgTypeResponseNew } = await dropdownServices.getResellers(
        orgTypePayload
      );
      const { content: orgTypeResponse } = orgTypeResponseNew.data;

      if (parseInt(userOrgType) === 2) {
        orgTypeData = orgTypeResponse.filter(
          (notification) =>
            notification.key.includes(3) || notification.key.includes(5) || notification.key.includes(1)
        );
        setSelectedOrgType(orgTypeData);
      } else if (parseInt(userOrgType) === 8) {
        setSelectedOrgType(orgTypeResponse);
      }
    }
  };
  const onChangeData = async (val) => {
    setSelectOrgType(parseInt(val));
    switch (parseInt(val)) {
      // case 1:
      //   const customersListPayload = {
      //     value: "customer",
      //     resellerId: resellerId != null ? resellerId : "-1",
      //     dealerId: 0,
      //     searchText: "",
      //   };
      //   const { data: customerListResponse } =
      //     await dropdownServices.getResellers(customersListPayload);
      //   const { content: customersList } = customerListResponse.data;
      //   setCustrrsllrOptions(customersList);
      //   setSelectedParentId(customersList[0]?.key);
      //   break;
      case 2:
        setCustrrsllrOptions([{ value: "None", key: "-1" }]);
        setSelectedParentId(0);
        break;
      // case 3:
      //   const resellerPayload = {
      //     value: "reseller",
      //     orgType: "2",
      //     resellerId: globalJson1 != null ? globalJson1.resellerId : "-1",
      //     dealerId: 0,
      //     roleId: globalJson1 != null ? globalJson1.roleId : "-1",
      //     id: globalJson1 != null ? globalJson1.resellerId : "-1",
      //     searchText: "",
      //   };
      //   const { data: resellerResponseNew } =
      //     await dropdownServices.getResellers(resellerPayload);
      //   const { content: orgnListJSON } = resellerResponseNew.data;
      //   setCustrrsllrOptions(orgnListJSON);
      //   setSelectedParentId(orgnListJSON[0]?.key);
      //   break;
      // case 4:
      //   const rsllrPyld = {
      //     value: "reseller",
      //     orgType: "2",
      //     resellerId: globalJson1 != null ? globalJson1.resellerId : "-1",
      //     dealerId: 0,
      //     roleId: globalJson1 != null ? globalJson1.roleId : "-1",
      //     id: globalJson1 != null ? globalJson1.resellerId : "-1",
      //     searchText: "",
      //   };
      //   const { data: rsllrResp } = await dropdownServices.getResellers(
      //     rsllrPyld
      //   );
      //   const { content: orgnLstJsn } = rsllrResp.data;
      //   setCustrrsllrOptions(orgnLstJsn);
      //   setSelectedParentId(orgnLstJsn[0]?.key);
      //   break;
      // case 5:
      //   const resellerPyld = {
      //     value: "reseller",
      //     orgType: "2",
      //     resellerId: globalJson1 != null ? globalJson1.resellerId : "-1",
      //     dealerId: 0,
      //     roleId: globalJson1 != null ? globalJson1.roleId : "-1",
      //     id: globalJson1 != null ? globalJson1.resellerId : "-1",
      //     searchText: "",
      //   };
      //   const { data: resellerResp } = await dropdownServices.getResellers(
      //     resellerPyld
      //   );
      //   const { content: orgnListJsn } = resellerResp.data;
      //   setCustrrsllrOptions(orgnListJsn);
      //   setSelectedParentId(orgnListJsn[0]?.key);
      //   break;
      // case 7:
      //   const resllrPyld = {
      //     value: "reseller",
      //     orgType: "2",
      //     resellerId: globalJson1 != null ? globalJson1.resellerId : "-1",
      //     dealerId: 0,
      //     roleId: globalJson1 != null ? globalJson1.roleId : "-1",
      //     id: globalJson1 != null ? globalJson1.resellerId : "-1",
      //     searchText: "",
      //   };
      //   const { data: resllrResp } = await dropdownServices.getResellers(
      //     resllrPyld
      //   );
      //   const { content: orgnLsstJsn } = resllrResp.data;
      //   setCustrrsllrOptions(orgnLsstJsn);
      //   setSelectedParentId(orgnLsstJsn[0]?.key);
      //   break;
      
      case 1:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        const resllrPyld = {
          value: "reseller",
          orgType: "2",
          resellerId: resellerId != null ? resellerId : "-1",
          dealerId: 0,
          roleId: roleId != null ? roleId : "-1",
          id: resellerId != null ? resellerId : "-1",
          searchText: "",
        };
        const { data: resllrResp } = await dropdownServices.getResellers(
          resllrPyld
        );
        const { content: orgnLsstJsn } = resllrResp.data;
        setCustrrsllrOptions(orgnLsstJsn);
        setSelectedParentId(orgnLsstJsn[0]?.key);
        if (parseInt(val) === 3 || parseInt(val) === 1) {
         
          const dealerpayload = {
            value: "dealer",
            orgType: "2",
            resellerId: resellerId != null ? resellerId : "-1",
            dealerId: 0,

            searchText: "",
          };
          const { data: result } = await dropdownServices.getResellers(
            dealerpayload
          );
          const { content: dealerLsstJsn } = result.data;
          setDealer(dealerLsstJsn);
          setSelectedDealer(dealerLsstJsn[0]?.key);
          if (parseInt(val) === 1 && selectDealerId !==null) {
            const customerPayload = {
              value: "customer",
              orgType: "3",
              resellerId: resellerId != null ? resellerId : "-1",
              dealerId:selectDealerId,
              paramValue : "vehicle",
              searchText: "",
            };
            const { data: result } = await dropdownServices.getResellers(
              customerPayload
            );
            const { content: customerLsstJsn } = result.data;
            setCustomer(customerLsstJsn);
            setSelectedCustomer(customerLsstJsn[0]?.key);
          }
        }
        break;
      default:
        setCustrrsllrOptions([{ value: "None", key: "-1" }]);
    }
  };

  const onSubmit = (data) => {
    // console.log("data",data);
    if (!orgToEdit) {
      saveOrgDetails(data);

      // setCircularOpen(true);
    } else {
      updateOrgDetails(data);
      // setCircularOpen(true);
    }
  };
  // //Onsubmit
  // const onSubmit = (data) => {
  //   if (!simToEdit) {
  //     saveSim(data); //Create Sim
  //   } else {
  //     updateSim(data); // UpdateSim
  //   }
  // };
  const saveOrgDetails = async (obj) => {
    obj.orgLogo = orgLogo != null ? orgLogo : "-1";
    obj.parentOrgLogo = parentOrgLogo != null ? parentOrgLogo : "-1";
    obj.createdBy = userId != null ? userId : "-1";
    obj.path = url;
    console.log(obj);
    orgCreate(obj);

  };

  const updateOrgDetails = async (obj) => {
    obj.id = orgToEdit.id;
    obj.createdBy = userId != null ? userId : "-1";

    orgUpdate(obj);

  };
  const re = /^[0-9\b]+$/;
  const handleSearchDropdown = async (searchText, name) => {
    console.log("handleSearchDropdown",selectDealerId);
    if (name === "dealer") {
      const dealerListPayload = {
        value: "dealer",
        resellerId: resellerId,
        searchText: searchText,
        regionId: -1,
      };
      const { data: dealerListResponse } = await dropdownServices.getResellers(
        dealerListPayload
      );
      const { content: dealerList } = dealerListResponse.data;
      setDealer(dealerList);
    }
    // if (name === "customer") {
    //   const customerPayload = {
    //     value: "customer",
    //     orgType: "3",
    //     resellerId: resellerId != null ? resellerId : "-1",
    //     dealerId: "",
    //     paramValue : "vehicle",
    //     searchText: searchText,
    //   };
    //   const { data: customerListResponse } = await dropdownServices.getResellers(
    //     customerPayload
    //   );
    //   const { content: customerList } = customerListResponse.data;
    //   setCustomer(customerList);
    // }
  };
  const getCustomerList = async (value) => {
   
    console.log("value",value);
  
      const customerPayload = {
        value: "customer",
        orgType: "3",
        resellerId: resellerId != null ? resellerId : "-1",
        dealerId: value,
        paramValue : "vehicle",
        searchText: "",
      };
      const { data: customerListResponse } = await dropdownServices.getResellers(
        customerPayload
      );
      const { content: customerList } = customerListResponse.data;
      setCustomer(customerList);
    
  };
  
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledCardShadow elevation={0}>
          <CardHeader
            title={<StyledTableTitle>Create Organization</StyledTableTitle>}
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Organization Type
                  </StyledFormControlLabel>
                  <StyledDropDown
                    error={errors.orgType && true}
                    {...register("orgType", {
                      required: {
                        value: true,
                        message: "Required Field",
                      },
                      validate: (fieldValue) => {
                        return fieldValue !== "0" || " Required Field";
                      },
                    })}
                    onChange={(e) => {
                      onChangeData(e.target.value);
                      setValue("orgType", e.target.value);
                    }}
                    disabled={clickedval}
                    defaultValue={orgToEdit !== null ? orgToEdit.orgType : "0"}
                    size="small"
                  >
                    <MenuItem value="0">Select</MenuItem>
                    {selectedOrgType.length > 0 &&
                      selectedOrgType.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.key}>
                            {option.value}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>
                  <FormHelperText error={errors.orgType && true}>
                    {errors.orgType?.message}
                  </FormHelperText>
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Select Organization
                  </StyledFormControlLabel>
                  <StyledDropDown
                    error={errors.parentOrgId && true}
                    {...register("parentOrgId", {
                      required: {
                        value: true,
                        message: "Required Field",
                      },
                      validate: (fieldValue) => {
                        return fieldValue !== "0" || " Required Field";
                      },
                    })}
                    onChange={(e) => {
                      setValue("parentOrgId", e.target.value);
                    }}
                    disabled={clickedval}
                    defaultValue={
                      orgToEdit !== null ? orgToEdit.parentOrgId : "0"
                    }
                    size="small"
                  >
                    <MenuItem value="0">Select</MenuItem>
                    {custrrsllrOptions.length > 0 &&
                      custrrsllrOptions.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.key}>
                            {option.value}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>
                  <FormHelperText error={errors.parentOrgId && true}>
                    {errors.parentOrgId?.message}
                  </FormHelperText>
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Organization Name
                  </StyledFormControlLabel>
                  <StyledTextField
                    error={errors.name && true}
                    size="small"
                    fullWidth
                    {...register("name", {
                      required: {
                        value: true,
                        message: "Required Field ",
                      },
                      validate: {
                        emailAvailable: async (fieldValue) => {
                          if (fieldValue !== getValues("name")) {
                            var request = {};
                            request.name = fieldValue;
                            request.orgId =
                              orgToEdit !== null ? orgToEdit.id : 0;
                            request.parentOrgId =
                              orgToEdit !== null ? orgToEdit.parentOrgId : 0;
                            const orgname =
                              await OrganizationServices.chkOrgName(request);

                            return (
                              orgname.data.resultMessage !==
                                "Organization Name Already Exists" ||
                              "Organization Name Already Exists"
                            );
                          }
                        },
                      },
                      //chkOrgName
                    })}
                    onChange={(e) => setValue("name", e.target.value)}
                    disabled={clickedval}
                    helperText={errors.name && errors.name.message}
                  />
                </StyledFormControl>
              </Grid>
              { selectOrgType === 5 && (
                <Grid item lg={4}>
                  <StyledFormControl fullWidth>
                    <StyledFormControlLabel required>
                      Region
                    </StyledFormControlLabel>
                    <StyledDropDown
                      error={errors.regionId && true}
                      {...register("regionId", {
                        required: {
                          value: true,
                          message: "Required Field",
                        },
                        validate: (fieldValue) => {
                          return fieldValue !== "0" || " Required Field";
                        },
                      })}
                      onChange={(e) => {
                        setValue("regionId", e.target.value);
                      }}
                      disabled={clickedval}
                      defaultValue={orgToEdit !== null ? selectRegionId : "0"}
                      size="small"
                    >
                      <MenuItem value="0">Select</MenuItem>
                      {region.length > 0 &&
                        region.map((option, index) => {
                          return (
                            <MenuItem key={index} value={option.key}>
                              {option.value}
                            </MenuItem>
                          );
                        })}
                    </StyledDropDown>
                    <FormHelperText error={errors.regionId && true}>
                      {errors.regionId?.message}
                    </FormHelperText>
                  </StyledFormControl>
                </Grid>
              )}
              {(selectOrgType === 3 || selectOrgType === 1) ? (
                <Grid item lg={4}>
                  <StyledFormControl fullWidth>
                    <StyledFormControlLabel required>
                      Dealer
                    </StyledFormControlLabel>
                    <StyledDropDown
                      error={errors.dealerId && true}
                      {...register("dealerId", {
                        required: {
                          value: true,
                          message: "Required Field",
                        },
                        validate: (fieldValue) => {
                          return fieldValue !== "0" || " Required Field";
                        },
                      })}
                      onChange={(e) => {
                        getCustomerList(e.target.value);
                        setValue("dealerId", e.target.value);
                      }}
                      disabled={clickedval}
                      defaultValue={orgToEdit !== null ? selectDealerId : "0"}
                      size="small"
                    >
                      <StyledFilterSearch
                        size="small"
                        name="dealer"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon sx={{ fontSize: "inherit" }} />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) =>
                          handleSearchDropdown(e.target.value, e.target.name)
                        }
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                      <MenuItem value="0">Select</MenuItem>
                      {dealer.length > 0 &&
                        dealer.map((option, index) => {
                          return (
                            <MenuItem key={index} value={option.key}>
                              {option.value}
                            </MenuItem>
                          );
                        })}
                    </StyledDropDown>
                    <FormHelperText error={errors.dealerId && true}>
                      {errors.dealerId?.message}
                    </FormHelperText>
                  </StyledFormControl>
                </Grid>
              ):""}
               {selectOrgType === 1 && (
                <Grid item lg={4}>
                  <StyledFormControl fullWidth>
                    <StyledFormControlLabel required>
                      Customer
                    </StyledFormControlLabel>
                    <StyledDropDown
                      error={errors.customerId && true}
                      {...register("customerId", {
                        required: {
                          value: true,
                          message: "Required Field",
                        },
                        validate: (fieldValue) => {
                          return fieldValue !== "0" || " Required Field";
                        },
                      })}
                      onChange={(e) => {
                        setValue("customerId", e.target.value);
                      }}
                      disabled={clickedval}
                      defaultValue={orgToEdit !== null ? selectCustomerId : "0"}
                      size="small"
                    >
                      <StyledFilterSearch
                        size="small"
                        name="customer"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon sx={{ fontSize: "inherit" }} />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) =>
                          handleSearchDropdown(e.target.value, e.target.name)
                        }
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                      />
                      <MenuItem value="0">Select</MenuItem>
                      {customer.length > 0 &&
                        customer.map((option, index) => {
                          return (
                            <MenuItem key={index} value={option.key}>
                              {option.value}
                            </MenuItem>
                          );
                        })}
                    </StyledDropDown>
                    <FormHelperText error={errors.customerId && true}>
                      {errors.customerId?.message}
                    </FormHelperText>
                  </StyledFormControl>
                </Grid>
                
              )}
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Address
                  </StyledFormControlLabel>
                  <StyledTextField
                    error={errors.address && true}
                    size="small"
                    fullWidth
                    {...register("address", {
                      required: {
                        value: true,
                        message: "Required Field ",
                      },
                    })}
                    disabled={clickedval}
                    onChange={(e) => setValue("address", e.target.value)}
                    helperText={errors.address && errors.address.message}
                  />
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Country
                  </StyledFormControlLabel>
                  <StyledDropDown
                    error={errors.countryId && true}
                    {...register("countryId", {
                      required: {
                        value: true,
                        message: "Required Field",
                      },
                      validate: (fieldValue) => {
                        return fieldValue !== "0" || " Required Field";
                      },
                    })}
                    disabled={clickedval}
                    onChange={(e) => {
                      setValue("countryId", e.target.value);
                    }}
                    defaultValue={
                      orgToEdit !== null ? orgToEdit.countryId : "102"
                    }
                    size="small"
                  >
                    <MenuItem value="0">Select</MenuItem>
                    <MenuItem value="102">India(IND)</MenuItem>
                  </StyledDropDown>
                  <FormHelperText error={errors.countryId && true}>
                    {errors.countryId?.message}
                  </FormHelperText>
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    State
                  </StyledFormControlLabel>
                  <StyledDropDown
                    error={errors.stateId && true}
                    {...register("stateId", {
                      required: {
                        value: true,
                        message: "Required Field",
                      },
                      validate: (fieldValue) => {
                        return fieldValue !== "0" || " Required Field";
                      },
                    })}
                    onChange={(e) => {
                      loadCity(e.target.value);
                      setValue("stateId", e.target.value);
                    }}
                    disabled={clickedval}
                    defaultValue={orgToEdit !== null ? orgToEdit.stateId : "0"}
                    size="small"
                  >
                    {states.length > 0 &&
                      states.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.value}>
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>

                  <FormHelperText error={errors.stateId && true}>
                    {errors.stateId?.message}
                  </FormHelperText>
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>City</StyledFormControlLabel>
                  <StyledDropDown
                    error={errors.cityId && true}
                    {...register("cityId", {
                      required: {
                        value: true,
                        message: "Required Field",
                      },
                      validate: (fieldValue) => {
                        return fieldValue !== "0" || " Required Field";
                      },
                    })}
                    disabled={clickedval}
                    onChange={(e) => {
                      setValue("cityId", e.target.value);
                    }}
                    defaultValue={orgToEdit !== null ? orgToEdit.cityId : "0"}
                    size="small"
                  >
                    {cities.length > 0 &&
                      cities.map((option, index) => {
                        return (
                          <MenuItem key={index} value={option.value}>
                            {option.title}
                          </MenuItem>
                        );
                      })}
                  </StyledDropDown>
                  <FormHelperText error={errors.cityId && true}>
                    {errors.cityId?.message}
                  </FormHelperText>
                </StyledFormControl>
              </Grid>

              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Pin code
                  </StyledFormControlLabel>
                  <StyledTextField
                    fullWidth
                    size="small"
                    error={errors.zipCode && true}
                    {...register("zipCode", {
                      required: {
                        value: true,
                        message: "Required Field ",
                      },
                    })}
                    disabled={clickedval}
                    // onChange={(e) => setValue("zipCode", e.target.value)}
                    onChange={(event) =>
                      event.target.value === "" || re.test(event.target.value)
                        ? setValue("zipCode", event.target.value)
                        : setValue("zipCode", "")
                    }
                    helperText={errors.zipCode && errors.zipCode.message}
                    inputProps={{ maxLength: "6" }}
                  />
                </StyledFormControl>
              </Grid>
              <Grid item lg={4}>
                <StyledFormControl fullWidth>
                  <StyledFormControlLabel required>
                    Time Zone
                  </StyledFormControlLabel>
                  <StyledDropDown
                    error={errors.timeZoneId && true}
                    {...register("timeZoneId")}
                    onChange={(e) => {
                      setValue("timeZoneId", e.target.value);
                    }}
                    // disabled={true}
                    disabled={clickedval}
                    defaultValue={"67"}
                    size="small"
                  >
                    <MenuItem value="67">(UTC+05:30)</MenuItem>
                  </StyledDropDown>

                  <FormHelperText error={errors.timeZoneId && true}>
                    {errors.timeZoneId?.message}
                  </FormHelperText>
                </StyledFormControl>
              </Grid>
            </Grid>
          </CardContent>
        </StyledCardShadow>
        {/* {selectOrgType !== 1 && ( */}
          <StyledCardShadow elevation={0} sx={{ mt: 2, my: 2 }}>
            <CardHeader
              title={
                <StyledTableTitle>Organization Admin Detail</StyledTableTitle>
              }
            />
            <CardContent>
              <Grid container spacing={3}>
                {!hideState && (
                  <Grid item lg={4}>
                    <StyledFormControl fullWidth>
                      <StyledFormControlLabel required>
                        First Name
                      </StyledFormControlLabel>
                      <StyledTextField
                        error={errors.firstName && true}
                        size="small"
                        fullWidth
                        {...register("firstName", {
                          required: {
                            value: true,
                            message: "Required Field ",
                          },
                        })}
                        disabled={clickedval}
                        onChange={(e) => setValue("firstName", e.target.value)}
                        helperText={
                          errors.firstName && errors.firstName.message
                        }
                      />
                    </StyledFormControl>
                  </Grid>
                )}
                {!hideState && (
                  <Grid item lg={4}>
                    <StyledFormControl fullWidth>
                      <StyledFormControlLabel required>
                        Last Name
                      </StyledFormControlLabel>
                      <StyledTextField
                        error={errors.lastName && true}
                        size="small"
                        fullWidth
                        {...register("lastName", {
                          required: {
                            value: true,
                            message: "Required Field ",
                          },
                        })}
                        disabled={clickedval}
                        onChange={(e) => setValue("lastName", e.target.value)}
                        helperText={errors.lastName && errors.lastName.message}
                      />
                    </StyledFormControl>
                  </Grid>
                )}
                {!hideState && (
                  <Grid item lg={4}>
                    <StyledFormControl fullWidth>
                      <StyledFormControlLabel required>
                        User Name [Eg: abc@gmail.com]
                      </StyledFormControlLabel>
                      <StyledTextField
                        error={errors.userName && true}
                        size="small"
                        fullWidth
                        {...register("userName", {
                          required: {
                            value: true,
                            message: "Required Field ",
                          },
                          pattern: {
                            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                            message: "Invalid Email Address",
                          },
                          validate: {
                            emailAvailable: async (fieldValue) => {
                              var request = {};
                              request.userName = fieldValue;
                              request.orgId =orgId ;
                                request.resellerId =resellerId;
                              const chkUserName =
                                await OrganizationServices.chkUserName(request);
                              return (
                                chkUserName.data.resultMessage !==
                                  "UserName Already Exists" ||
                                "User Name Already Exists"
                              );
                            },
                          },
                        })}
                        disabled={clickedval}
                        onChange={(e) => setValue("userName", e.target.value)}
                        helperText={errors.userName && errors.userName.message}
                      />
                    </StyledFormControl>
                  </Grid>
                )}
                <Grid item lg={4}>
                  <StyledFormControl fullWidth>
                    <StyledFormControlLabel required>
                      Mobile
                    </StyledFormControlLabel>
                    <StyledTextField
                      fullWidth
                      size="small"
                      error={errors.mobile && true}
                      {...register("mobile", {
                        required: {
                          value: true,
                          message: "Required Field ",
                        },
                        minLength: {
                          value: 10,
                          message: "Invalid",
                        },
                        maxLength: {
                          value: 10,
                          message: "Invalid",
                        },
                      })}
                      disabled={clickedval}
                      onChange={(event) =>
                        event.target.value === "" || re.test(event.target.value)
                          ? setValue("mobile", event.target.value)
                          : setValue("mobile", "")
                      }
                      helperText={errors.mobile && errors.mobile.message}
                      inputProps={{ maxLength: "10" }}
                    />
                  </StyledFormControl>
                </Grid>
                <Grid item lg={4}>
                  <StyledFormControl fullWidth>
                    <StyledFormControlLabel required>
                      Email Id
                    </StyledFormControlLabel>
                    <StyledTextField
                      error={errors.email && true}
                      size="small"
                      fullWidth
                      {...register("email", {
                        required: {
                          value: true,
                          message: "Required Field ",
                        },
                        pattern: {
                          value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                          message: "Invalid Email Address",
                        },

                        validate: {
                          emailAvailable: async (fieldValue) => {
                            var request = {};
                            request.userName = fieldValue;
                            request.orgId =orgId ;
                              request.resellerId =resellerId;
                            const chkUserName =
                              await OrganizationServices.chkUserName(request);
                            return (
                              !orgToEdit && chkUserName.data.resultMessage ===
                                "UserName Already Exists"?
                              "User Name Already Exists":true
                            );
                          },
                        },
                      })}
                      disabled={clickedval}
                      onChange={(e) => setValue("email", e.target.value)}
                      helperText={errors.email && errors.email.message}
                    />
                  </StyledFormControl>
                </Grid>
              </Grid>
            </CardContent>
          </StyledCardShadow>
         {/* )} */}
        <StyledFormButtonsRow direction="row" sx={{ mt: 2 }} spacing={2}>
          {/* <Button size="small" onClick={handleCancelClick} color="error">
            Cancel
          </Button>

          {clickedval===false && 
          <Button size="small" variant="contained" type="submit">
            {orgToEdit ? "Update" : "Submit"}
          </Button>
        } */}

          <SoftButton
            size="small"
            onClick={goList}
            color="error"
            className="error"
          >
            Cancel
          </SoftButton>
          {clickedval === false && (
            <SoftButton
              size="small"
              className="success"
              color="success"
              type="submit"
            >
              {orgUpdateLoading || orgCreateLoading ? (
                <PulseLoader
                  color={theme.palette.success.contrastText}
                  size={6}
                />
              ) : orgToEdit ? (
                "Update"
              ) : (
                "Submit"
              )}
            </SoftButton>
          )}
        </StyledFormButtonsRow>
        {/* {circularOpen && <BackDropLoader />} */}
      </form>
      {/* {dataStatusConfirm !== false && (
        <DraggableDialog
          open={open}
          handleSbmtClose={handleSbmtClose}
          dialogText={dialogContent.dialogText}
          handleClearCls={handleClearCls}
        />
      )}
      {dataStatus !== false && (
        <DraggableDialog
          open={open}
          handleSbmtClose={handleSbmtClose}
          dialogText={dialogContent.dialogText}
          handleClearCls={handleClearCls}
        />
      )} */}
    </Box>
  );
}
