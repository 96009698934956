import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import {
  CardHeader,
  CardContent,
  Typography,
  Button,
  Icon,
  Stack,
  Tooltip,
} from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import ReportNewDropDown from "../shared/ReportsDropDownNew";
import { ButtonAlign } from "../shared/button/ButtonAlign";
import StyledTooltip from "../shared/StyledTooltip";
import logServices from "../../services/logService";
import { GoDotFill } from "react-icons/go";
import {
  StyledCardShadow,
  Exportoptions,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import {
  useAuditLogMutation
} from "../../store/slice/ApiSlice";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import FormDialog from "../../Utils/FormDialog";
import { useNavigate } from "react-router-dom";
import { useGetTripHistoryMutation } from "../../store/slice/ReportSlice/ReportSlice";

const text =
  "A Trip is defined as distance traveled by the vehicle between On and Off state. The minimum distance traveled must be greater than 1km to be considered in this trip report. Energy Efficiency is calculated for vehicles that have traveled more than 10km per trip.";

const TripReportHistory = () => {
  const navigate = useNavigate();
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  const theme = useTheme();

  useEffect(async () => {
   
    auditPayload.message = "Reports > Trip Report History";
    auditPayload.action = "View";
    auditPayload.featureName = "Trip Report History";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Vehicle Number",
        accessor: "vehicleno",
        width:180,
      },

      {
        Header: "Start Location",
        accessor: "startLoc",
        width:350,
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.startLoc}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.startLoc}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.startLoc}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.startLoc}
                >
                  {row.original.startLoc}
                </a>
                <a
                  className="address"
                  ref={refAddres}
                  id={row.original.startLoc}
                  key={row.original.startLoc}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "End Location",
        accessor: "endLoc",
        width:350,
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.endLoc}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.endLoc}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.endLoc}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.endLoc}
                >
                  {row.original.endLoc}
                </a>
                <a
                  className="address"
                  ref={refAddres}
                  id={row.original.endLoc}
                  key={row.original.endLoc}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "Start Time",
        accessor: "tripStartTime",
        width:180,
      },
      {
        Header: "End Time",
        accessor: "tripEndTime",
        width:180,
      },
      {
        Header: "Duration (hh:mm)",
        accessor: "duration",
      },

      {
        Header: "Gps Start Odometer (km)",
        accessor: "startOdometer",
      },
      {
        Header: "Gps End Odometer (km)",
        accessor: "endOdometer",
      },
      {
        Header: "GPS Distance Traveled (km)",
        accessor: "distTravelled",
      },
      {
        Header: "GPS Average Speed (km/h)",
        accessor: "avgSpeed",
      },

      {
        Header: "GPS Max Speed (km/h)",
        accessor: "maxSpeed",
      },
      {
        Header: "Moving (hh:mm)",
        accessor: "movminsinhrs",
      },
      {
        Header: "CAN Start Odometer (km)",
        accessor: "canStartOdometer",
      },
      {
        Header: "CAN End Odometer (km)",
        accessor: "canEndOdometer",
      },
      {
        Header: "CAN Distance Traveled (km)",
        accessor: "canDistTravelled",
      },
      {
        Header: "Start Voltage (V)",
        accessor: "startVoltage",
      },
      {
        Header: "End Voltage (V)",
        accessor: "endVoltage",
      },
      {
        Header: "Battery Temperature Rise (℃)",
        accessor: "temprise",
      },

      {
        Header: "Max Battery Temp (℃)",
        accessor: "maxBtTemp",
      },
      {
        Header: "Avg Battery Temp (℃)",
        accessor: "avgBtTemp",
      },
      {
        Header: "Max Battery Current (A)",
        accessor: "maxBtCurrent",
      },

      // {
      //   Header: "Avg Battery Current (A)",
      //   accessor: "avgBtCurrent",
      // },
      {
        Header:
          resellerId === 110224 ? "Start Energy (kWh)" : "Start Energy (Wh)",
        accessor: "startEnergy",
      },
      {
        Header: resellerId === 110224 ? "End Energy (kWh)" : "End Energy (Wh)",
        accessor: "endEnergy",
      },
      {
        Header:
          resellerId === 110224
            ? "Energy Consumed (kWh)"
            : "Energy Consumed (Wh)",
        accessor: "energyConsumed",
      },
      {
        Header: (
          <StyledTooltip
            header={
              resellerId === 110224
                ? "Energy Efficiency (km/kWh)"
                : "Energy Efficiency (wh/km)"
            }
            title={"Considered for Distance > 10km/day"}
          />
        ),
        accessor: "energyefficiency",
        Cell: ({ row }) => {
          if(  row.original.energyefficiency === 0 ){
            return "-"
          }else{
            return row.original.energyefficiency 
          }
        }
      },
    
      // {
      //   Header:globalResellerId===110224?'Energy Efficiency (km/kWh)':(globalResellerId===109882?'Energy Efficiency (wh/km)':'Energy Efficiency (km/Wh)'),
      //   accessor: "energyefficiency",
      // },
      // {
      // Header: (
      //   <StyledTooltip
      //     header={globalResellerId===110224?'Energy Efficiency (kWh/km)':(globalResellerId===109882?'Energy Efficiency (km/Wh)':'Energy Efficiency (Wh/km)')}
      //     title={"Considered for Distance > 10km/day"}
      //   />

      // ),
      // accessor: "sss",
      // },
      // {
      //   Header:globalResellerId===110224?'Energy Efficiency (kWh/km)':(globalResellerId===109882?'Energy Efficiency (km/Wh)':'Energy Efficiency (Wh/km)'),
      //   accessor: "",
      // },
      {
        Header: "Start SOC (%)",
        accessor: "strstartsoc",
      },
      {
        Header: "End SOC (%)",
        accessor: "strendsoc",
      },
      {
        Header: "Trip Status",
        accessor: "tripStatus",
      },
    ],
    []
  );

  const columns1 = useMemo(
    () => [
      {
        Header: "Vehicle Number",
        accessor: "vehicleno",
        width:180,
      },

      {
        Header: "Start Location",
        accessor: "startLoc",
        width:350,
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.startLoc}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.startLoc}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.startLoc}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.startLoc}
                >
                  {row.original.startLoc}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.startLoc}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.startLoc}
                  key={row.original.startLoc}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "End Location",
        accessor: "endLoc",
        width:350,
        Cell: ({ row, column, cell }) => {
  
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.endLoc}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.endLoc}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.endLoc}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.endLoc}
                >
                  {row.original.endLoc}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.endLoc}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.endLoc}
                  key={row.original.endLoc}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "Start Time",
        accessor: "tripStartTime",
      },
      {
        Header: "End Time",
        accessor: "tripEndTime",
      },
      {
        Header: "Duration (hh:mm)",
        accessor: "duration",
      },

      {
        Header: "Average Speed (km/h)",
        accessor: "avgSpeed",
      },
      {
        Header: "Sweet Spot Speed (km/h)",
        accessor: "minSpeedRange",
        Cell: ({ row }) => {
         
          if (resellerId === 110224) {
            return (
              <Box>
                <Stack
                  direction={"row"}
                  spacing={1}
                  justifyContent={"center !important"}
                >
                  <Tooltip
                    title={
                      parseFloat(row.original.avgSpeed) > parseFloat(row.original.maxSpeedRange)
                        ? "Greater than Nominal Speed Range"
                        :   parseFloat(row.original.avgSpeed) <   parseFloat(row.original.minSpeedRange)
                        ? "Less than Nominal Speed Range"
                        : "Between Nominal Speed Range"
                    }
                    arrow
                  >
                    <Icon
                      sx={{ width: "auto", height: "auto", display: "flex" }}
                      color={
                        parseFloat(row.original.avgSpeed) > parseFloat(row.original.maxSpeedRange)
                          ? "error"
                          :   parseFloat(row.original.avgSpeed) <   parseFloat(row.original.minSpeedRange)
                          ? "info"
                          : "success"
                      }
                    >
                      <GoDotFill />
                    </Icon>
                  </Tooltip>
                </Stack>
              </Box>
            );
          }
        },
      },
      {
        Header: "Max Speed (km/h)",
        accessor: "maxSpeed",
      },

      {
        Header: "Moving (hh:mm)",
        accessor: "movminsinhrs",
      },
      {
        Header: "Start Odometer (km)",
        accessor: "canStartOdometer",
      },
      {
        Header: "End Odometer (km)",
        accessor: "canEndOdometer",
      },
      {
        Header: "Distance Traveled (km)",
        accessor: "canDistTravelled",
      },
      {
        Header: "Start Voltage (V)",
        accessor: "startVoltage",
      },
      {
        Header: "End Voltage (V)",
        accessor: "endVoltage",
      },
      {
        Header: "Battery Temperature Rise (℃)",
        accessor: "temprise",
      },

      {
        Header: "Max Battery Temp (℃)",
        accessor: "maxBtTemp",
      },
      {
        Header: "Avg Battery Temp (℃)",
        accessor: "avgBtTemp",
      },
      {
        Header: "Max Battery Current (A)",
        accessor: "maxBtCurrent",
      },
      // {
      //   Header: "Avg Battery Current (A)",
      //   accessor: "avgBtCurrent",
      // },
      {
        Header:
          resellerId === 110224 ? "Start Energy (kWh)" : "Start Energy (Wh)",
        accessor: "startEnergy",
      },
      {
        Header: resellerId === 110224 ? "End Energy (kWh)" : "End Energy (Wh)",
        accessor: "endEnergy",
      },
      {
        Header:
          resellerId === 110224
            ? "Energy Consumed (kWh)"
            : "Energy Consumed (Wh)",
        accessor: "energyConsumed",
      },
      {
        Header:
          resellerId === 110224
            ? "Energy Efficiency (km/kWh)"
            : resellerId === 109882
            ? "Energy Efficiency (wh/km)"
            : "Energy Efficiency (km/Wh)",
        accessor: "energyefficiency",
      },
      {
        Header: (
          <StyledTooltip
            header={
              resellerId === 110224
                ? "Energy Efficiency (kWh/km)"
                : "Energy Efficiency (km/Wh)"
            }
            title={"Considered for Distance > 10km/day"}
          />
        ),
        accessor: "energyefficiencyPerKm",
        Cell: ({ row }) => {
      
          if(  row.original.energyefficiencyPerKm === 0  ){
            return "-"
          }else{
            return row.original.energyefficiencyPerKm 
          }
        }
      },
      {
        Header: "Start SOC (%)",
        accessor: "strstartsoc",
      },
      {
        Header: "End SOC (%)",
        accessor: "strendsoc",
      },
      {
        Header: "Trip Status",
        accessor: "tripStatus",
      },
    ],
    []
  );
  const columns2 = useMemo(
    () => [
      {
        Header: "Vehicle Number",
        accessor: "vehicleno",
      },

      {
        Header: "Start Location",
        accessor: "startLoc",

        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.startLoc}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.startLoc}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.startLoc}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.startLoc}
                >
                  {row.original.startLoc}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.startLoc}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.startLoc}
                  key={row.original.startLoc}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "End Location",
        accessor: "endLoc",
        Cell: ({ row, column, cell }) => {

          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.endLoc}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.endLoc}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.endLoc}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.endLoc}
                >
                  {row.original.endLoc}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.endLoc}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.endLoc}
                  key={row.original.endLoc}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "Start Time",
        accessor: "tripStartTime",
      },
      {
        Header: "End Time",
        accessor: "tripEndTime",
      },
      {
        Header: "Duration (hh:mm)",
        accessor: "duration",
      },

      {
        Header: "Average Speed (km/h)",
        accessor: "avgSpeed",
      },
  
      {
        Header: "Max Speed (km/h)",
        accessor: "maxSpeed",
      },

      {
        Header: "Moving (hh:mm)",
        accessor: "movminsinhrs",
      },
      {
        Header: "Start Odometer (km)",
        accessor: "canStartOdometer",
      },
      {
        Header: "End Odometer (km)",
        accessor: "canEndOdometer",
      },
      {
        Header: "Distance Traveled (km)",
        accessor: "canDistTravelled",
      },
      {
        Header: "Start Voltage (V)",
        accessor: "startVoltage",
      },
      {
        Header: "End Voltage (V)",
        accessor: "endVoltage",
      },
      {
        Header: "Battery Temperature Rise (℃)",
        accessor: "temprise",
      },

      {
        Header: "Max Battery Temp (℃)",
        accessor: "maxBtTemp",
      },
      {
        Header: "Avg Battery Temp (℃)",
        accessor: "avgBtTemp",
      },
      {
        Header: "Max Battery Current (A)",
        accessor: "maxBtCurrent",
      },
      // {
      //   Header: "Avg Battery Current (A)",
      //   accessor: "avgBtCurrent",
      // },
      {
        Header:
          resellerId === 110224 ? "Start Energy (kWh)" : "Start Energy (Wh)",
        accessor: "startEnergy",
      },
      {
        Header: resellerId === 110224 ? "End Energy (kWh)" : "End Energy (Wh)",
        accessor: "endEnergy",
      },
      {
        Header:
          resellerId === 110224
            ? "Energy Consumed (kWh)"
            : "Energy Consumed (Wh)",
        accessor: "energyConsumed",
      },
      {
        Header:
          resellerId === 110224
            ? "Energy Efficiency (km/kWh)"
            : resellerId === 109882
            ? "Energy Efficiency (wh/km)"
            : "Energy Efficiency (km/Wh)",
        accessor: "energyefficiency",
      },
      {
        Header: (
          <StyledTooltip
            header={
              resellerId === 110224
                ? "Energy Efficiency (kWh/km)"
                : "Energy Efficiency (km/Wh)"
            }
            title={"Considered for Distance > 10km/day"}
          />
        ),
        accessor: "energyefficiencyPerKm",
        Cell: ({ row }) => {
       
          if(  row.original.energyefficiencyPerKm === 0  ){
            return "-"
          }else{
            return row.original.energyefficiencyPerKm 
          }
        }
      },
      {
        Header: "Start SOC (%)",
        accessor: "strstartsoc",
      },
      {
        Header: "End SOC (%)",
        accessor: "strendsoc",
      },
      {
        Header: "Trip Status",
        accessor: "tripStatus",
      },
    ],
    []
  );



  const columns3 = useMemo(
    () => [
      {
        Header: "Vehicle Number",
        accessor: "vehicleno",
        width:180,
      },

      {
        Header: "Start Location",
        accessor: "startLoc",
        width:350,
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.startLoc}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.startLoc}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.startLoc}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.startLoc}
                >
                  {row.original.startLoc}
                </a>
                <a
                  className="address"
                  ref={refAddres}
                  id={row.original.startLoc}
                  key={row.original.startLoc}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "End Location",
        accessor: "endLoc",
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async (e) => {
            if (refAddres.current.innerText === "") {
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (
                await ReportServices.getGeocodeAddress(request)
              ).data;
              refAddres.current.innerText = responseData;
              if (responseData !== "") {
                refLink.current.style.display = "none";
                refAddres.current.style.display = "block";
                toggleAddressBtn.current.style.display = "block";
                getAddressBtn.current.style.display = "none";
                toggleAddressBtn.current.innerText = "LatLng";
              }
            }
          };

          //Address toggle
          const ToggleAdderss = () => {
            if (refAddres.current.style.display === "block") {
              refAddres.current.style.display = "none";
              refLink.current.style.display = "block";
              toggleAddressBtn.current.innerText = "Address";
            } else {
              refAddres.current.style.display = "block";
              refLink.current.style.display = "none";
              toggleAddressBtn.current.innerText = "LatLng";
            }
          };
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.endLoc}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  onClick={(e) => getAddress(e)}
                  value={row.original.endLoc}
                  ref={getAddressBtn}
                >
                  Address
                </Button>
                <Button
                  onClick={(e) => ToggleAdderss(e)}
                  ref={toggleAddressBtn}
                  sx={{ display: "none" }}
                >
                  LatLng
                </Button>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.endLoc}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.endLoc}
                >
                  {row.original.endLoc}
                </a>
                <a
                  className="address"
                  ref={refAddres}
                  id={row.original.endLoc}
                  key={row.original.endLoc}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "Start Time",
        accessor: "tripStartTime",
      },
      {
        Header: "End Time",
        accessor: "tripEndTime",
      },
      {
        Header: "Duration (hh:mm)",
        accessor: "duration",
      },

      {
        Header: "Gps Start Odometer (km)",
        accessor: "startOdometer",
      },
      {
        Header: "Gps End Odometer (km)",
        accessor: "endOdometer",
      },
      {
        Header: "GPS Distance Traveled (km)",
        accessor: "distTravelled",
      },
      {
        Header: "GPS Average Speed (km/h)",
        accessor: "avgSpeed",
      },

      {
        Header: "GPS Max Speed (km/h)",
        accessor: "maxSpeed",
      },
      {
        Header: "Moving (hh:mm)",
        accessor: "movminsinhrs",
      },
      {
        Header: "CAN Start Odometer (km)",
        accessor: "canStartOdometer",
      },
      {
        Header: "CAN End Odometer (km)",
        accessor: "canEndOdometer",
      },
      {
        Header: "CAN Distance Traveled (km)",
        accessor: "canDistTravelled",
      },
      // {
      //   Header: "Start Voltage (V)",
      //   accessor: "startVoltage",
      // },
      // {
      //   Header: "End Voltage (V)",
      //   accessor: "endVoltage",
      // },
      // {
      //   Header: "Battery Temperature Rise (℃)",
      //   accessor: "temprise",
      // },

      // {
      //   Header: "Max Battery Temp (℃)",
      //   accessor: "maxBtTemp",
      // },
      // {
      //   Header: "Avg Battery Temp (℃)",
      //   accessor: "avgBtTemp",
      // },
      // {
      //   Header: "Max Battery Current (A)",
      //   accessor: "maxBtCurrent",
      // },

      // // {
      // //   Header: "Avg Battery Current (A)",
      // //   accessor: "avgBtCurrent",
      // // },
      // {
      //   Header:
      //     resellerId === 110224 ? "Start Energy (kWh)" : "Start Energy (Wh)",
      //   accessor: "startEnergy",
      // },
      // {
      //   Header: resellerId === 110224 ? "End Energy (kWh)" : "End Energy (Wh)",
      //   accessor: "endEnergy",
      // },
      // {
      //   Header:
      //     resellerId === 110224
      //       ? "Energy Consumed (kWh)"
      //       : "Energy Consumed (Wh)",
      //   accessor: "energyConsumed",
      // },
      // {
      //   Header: (
      //     <StyledTooltip
      //       header={
      //         resellerId === 110224
      //           ? "Energy Efficiency (km/kWh)"
      //           : "Energy Efficiency (wh/km)"
      //       }
      //       title={"Considered for Distance > 10km/day"}
      //     />
      //   ),
      //   accessor: "energyefficiency",
      //   Cell: ({ row }) => {
      //     if(  row.original.energyefficiency === 0 ){
      //       return "-"
      //     }else{
      //       return row.original.energyefficiency 
      //     }
      //   }
      // },
    
      // {
      //   Header:globalResellerId===110224?'Energy Efficiency (km/kWh)':(globalResellerId===109882?'Energy Efficiency (wh/km)':'Energy Efficiency (km/Wh)'),
      //   accessor: "energyefficiency",
      // },
      // {
      // Header: (
      //   <StyledTooltip
      //     header={globalResellerId===110224?'Energy Efficiency (kWh/km)':(globalResellerId===109882?'Energy Efficiency (km/Wh)':'Energy Efficiency (Wh/km)')}
      //     title={"Considered for Distance > 10km/day"}
      //   />

      // ),
      // accessor: "sss",
      // },
      // {
      //   Header:globalResellerId===110224?'Energy Efficiency (kWh/km)':(globalResellerId===109882?'Energy Efficiency (km/Wh)':'Energy Efficiency (Wh/km)'),
      //   accessor: "",
      // },
      {
        Header: "Start SOC (%)",
        accessor: "strstartsoc",
      },
      {
        Header: "End SOC (%)",
        accessor: "strendsoc",
      },
      {
        Header: "Trip Status",
        accessor: "tripStatus",
      },
    ],
    []
  );

  // Get Data from New Dropdown
  const [getTripReport, { data: oversppedData, isLoading, isSuccess }] =
  useGetTripHistoryMutation();
  let commonPayload = {};
  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.reselId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.custId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehId = data.vehicleId;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.sortBy = "desc";
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    //Service Call
    setPayload(commonPayload);
    getTripReport(commonPayload);
  };
  //Export PDF & Excel
  const [payload, setPayload] = useState(null);
  const onChangeXcl = async () => {
    if(totalElements > 5000){
      navigate('#popup');
    }else{
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "TripReport/tripHistoryreportXLS.xls?orgId=" +
        payload.orgId +
        "&regionId=" +
        payload.regionId +
        "&reselId=" +
        payload.reselId +
        "&vehId=" +
        payload.vehId +
        "&startDate=" +
        payload.startDate +
        "&sortBy=" +
        payload.sortBy +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        "&dealerId=" +
        payload.dealerId +
        "&custId=" +
        payload.custId;

      window.open(url);

      auditPayload.message = "Reports > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "TripReport";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
    } catch (error) {}
  }
  };
  const onChangePDF = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "TripReport/tripreportPDF.pdf?orgId=" +
        payload.orgId +
        "&regionId=" +
        payload.regionId +
        "&reselId=" +
        payload.reselId +
        "&vehId=" +
        payload.vehId +
        "&startDate=" +
        payload.startDate +
        "&sortBy=" +
        payload.sortBy +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        "&dealerId=" +
        payload.dealerId +
        "&custId=" +
        payload.custId;

      window.open(url);

      auditPayload.message = "Reports > PDF";
      auditPayload.action = "View";
      auditPayload.featureName = "TripReport";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
    } catch (error) {}
  };
  //Search Text
  const handleSearch = async (searchText) => {
      setNewPage(0);
      setRowsPerPage(25);
      payload.page = 0;
      payload.rowsPerPage = 25;
      payload.search=searchText;
    if (payload.search.length >= 3) {
      getTripReport(payload);
    } else if (payload.search.length === 0) {
      getTripReport(payload);
    }
  };
  let initialState1 = {};
  var arr = [];

  // if (resellerId !== 110224 ) {
  //   arr.push("minSpeedRange");

  //   initialState1.hiddenColumns = arr;
  // }
  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getTripReport(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getTripReport(payload);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (oversppedData.data !== null) {
        setResponseData(oversppedData.data.content);
        setTotalElements(oversppedData.data.totalElements);
        setTotalPages(oversppedData.data.totalPages);
      }

    }
  }, [isSuccess]);

  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Reports"
        subDiv="Trip History Report"
        includePopup={true}
      ></Breadcrumb>
      <MouseOverPopover text={text} title={"Trip History Report"} />
      <Box>
        {/* <ReportNewDropDown
          onSubmit={handleSubmit}
          isShowVeh={true}
          displayDate={true}
          allowDays={7}
        /> */}
        <FilterDropdown getData={onSubmitForm} status={false} allowDays={7} getDataLoading={isLoading}/>
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              isLoading={isLoading}
              // initialState={initialState1}
              columns={
                resellerId === 110224 ?columns1: (resellerId === 109882 || resellerId === 116269 )?columns2: resellerId === 114075 ?columns3
                 
                  : resellerId === 114367 ?columns3
                  : columns
              }
              payload={payload}
              exportWithAll={false}
              data={responseData}
              onChangeXcl={onChangeXcl}
              onChangePdf={onChangePDF}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              includeSearchBox={true}
              handleChange={handleSearch}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
      <FormDialog title={'Alert'} size='sm'>
      <Typography>Exceeds limit , Use Filters Criteria</Typography>
      </FormDialog>
    </StyledPageLayout>
  );
};

export default TripReportHistory;
