import React, { useMemo, useState, useEffect } from "react";
import { Box, color } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { CardHeader, CardContent, Button, Chip} from "@mui/material";
import { createTheme } from '@mui/material/styles';
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import ReportNewDropDown from "../shared/ReportsDropDownNew";
import { ButtonAlign } from "../shared/button/ButtonAlign";
import logServices from "../../services/logService";


import {
  StyledCardShadow,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import {
  useAuditLogMutation,
  useOverSpeedMutation,
} from "../../store/slice/ApiSlice";
import { useQcVerifiedVehicleReportMutation, useVehicleLatestDetailMutation } from "../../store/slice/ReportSlice/ReportSlice";
const text =
  "This report shows the QC Verified vehicle details.";
  
const QCVerifiedReport = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
 

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [assignedval, setAssignedval] = useState([]);
  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  useEffect(async () => {
    auditPayload.message = "Reports > qcVerified";
    auditPayload.action = "View";
    auditPayload.featureName = "qcVerified";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

 
  const columns = React.useMemo(() => [
 
    {
      Header: "Vehicle VIN",
      accessor: "vin",
      width:150,
    },
    {
      Header: "Vehicle Battery Sl No",
      accessor: "batteryNo",
      width:150,
    },
    {
      Header: "Controller No",
      accessor: "controllerNo",
      width:150,
    },
    {
      Header: "Cluster Id",
      accessor: "clusterId",
      width:150,
    },
    {
      Header: "SOC (%)",
      accessor: "soc",
      width:120,
    },
    {
      Header: "Voltage (V)",
      accessor: "voltage",
      width:120,
    },
  ],
  []
);

  let initialState1 = {};
  
  // Get Data from New Dropdown
  const [getgridView, { data: qcVerified, isLoading, isSuccess }] =
    useQcVerifiedVehicleReportMutation();
  let commonPayload = {};

  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.resellerId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.sortBy = "desc";
    commonPayload.start = newPage;
    commonPayload.length = rowsPerPage;
    
    //Service Call
    setPayload(commonPayload);
    getgridView(commonPayload);

    auditPayload.message = "Reports > LatestDetail > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "LatestDetail";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  //Export PDF & Excel
  const [payload, setPayload] = useState(null);
  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "QCVerifiedReport/qCVerifiedReportXLS.csv?regionId=" +
        payload.regionId +
        "&resellerId=" +
        payload.resellerId +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&dealerId=" +
        payload.dealerId +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&customerId=" +
        payload.customerId;
      window.open(url);

      auditPayload.message = "Reports > QcVerified > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "QcVerified";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };
  
  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getgridView(payload);
    } else if (payload.search.length === 0) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getgridView(payload);
      
    }
  };

  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    payload.length = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getgridView(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getgridView(payload);
    }
  };

  
  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      console.log('qcVerified detail',qcVerified);
      if (qcVerified.data !== null) {
        setResponseData(qcVerified.data.content);
        setTotalElements(qcVerified.data.totalElements);
        setTotalPages(qcVerified.data.totalPages);
      }
    }
  }, [isSuccess]);

  return (
    <StyledPageLayout>
        <Breadcrumb
        mainDiv="Reports"
        subDiv="QcVerified"
        includePopup={true}
      ></Breadcrumb>
      <MouseOverPopover text={text} title={"QcVerified"} />
      <Box>
      <FilterDropdown
          getData={onSubmitForm}
          status={false}
          showCalendar={false}
        />

        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              initialState={initialState1}
              payload={payload}
              exportWithAll={true}
              columns={columns}
              includeSearchBox={true}
              onChangeXcl={onChangeXcl}
              handleChange={handleSearch}
              includeExportButton={true}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              isLoading={isLoading}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};

export default QCVerifiedReport;