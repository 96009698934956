import React, { useEffect, useState } from "react";
import { StyledFiterButton, StyledPaperShadow } from "../../theme/StyledEle";
import SingleDropdown from "../NewDropdown/SingleDropdown";
import {
  useGetDropdownListMutation,
  useGetVehicleDropdownListMutation,
} from "../../store/slice/ApiSlice";
import { useLayoutEffect } from "react";
import {
  OtaStatusData,
  commandTypeData,
  benchStatusData,
} from "../AdminDropdown/MockData";
import DatepickerDropdown from "../AdminDropdown/DatepickerDropdown";
import moment from "moment";
import { useLocation } from "react-router-dom";

function OTADropdown(props) {
  const {
    getData,
    vehicleDrop = false,
    regionDrop = false,
    dealerDrop = false,
    customerDrop = false,
    fleetDrop = false,
    otaDrop = false,
    onLoadData,
    imeiDrop = false,
    benchDrop = false,
    otaSupport = false,
    time,
    allowDays,
    allowedMonth,
    getDataLoading
  } = props;
  //user data form local storage
  const userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let RoleId = userData.roleId;
  let userOrgType = userData.userOrgType;

  //To get path name
  const location = useLocation();

  //Is show all
  const [allOption, setAllOption] = useState(true);

  // All service -------------------------------------------------------------------
  const [
    getRegionList,
    { data: regionData, isLoading: regionLoading, isSuccess: regionSuccess },
  ] = useGetDropdownListMutation();
  const [
    getDealerList,
    { data: dealerdata, isLoading: dealerLoading, isSuccess: dealerSuccess },
  ] = useGetDropdownListMutation();
  const [
    getCustomerList,
    {
      data: customerdata,
      isLoading: customerLoading,
      isSuccess: customerSuccess,
    },
  ] = useGetDropdownListMutation();
  const [
    getFleetList,
    { data: fleetdata, isLoading: fleetLoading, isSuccess: fleetSuccess },
  ] = useGetDropdownListMutation();
  const [
    getVehicleList,
    { data: vehicledata, isLoading: vehicleLoading, isSuccess: vehicleSuccess },
  ] = useGetVehicleDropdownListMutation();
  const [
    getVehicleSearch,
    {
      data: searchVehicles,
      isLoading: searchVehicleLoading,
      isSuccess: searchVehicleSuccess,
    },
  ] = useGetVehicleDropdownListMutation();
  const [
    getImeiList,
    { data: imeidata, isLoading: imeiLoading, isSuccess: imeiSuccess },
  ] = useGetDropdownListMutation();
  // const [getVehicleSearch, {data:searchVehicles, isLoading:searchVehicleLoading, isSuccess:searchVehicleSuccess}] = useGetVehicleDropdownListMutation();

  //All States ---------------------------------------------------------------------
  const [region, setRegion] = useState(null);
  const [dealer, setDealer] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [fleet, setFleet] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [imei, setImei] = useState(null);
  const [otaStatus, setOtaStatus] = useState("0");
  const [commandType, setCommandType] = useState(
    "296" 
  );
  const [benchStatus, setBenchStatus] = useState("0");

  // All State API Responce List
  const [regionList, setRegionList] = useState(null);
  const [dealerList, setDealerList] = useState(null);
  const [customerList, setCustomerList] = useState(null);
  const [fleetList, setFleetList] = useState(null);
  const [vehicleList, setVehicleList] = useState(null);
  const [imeiList, setImeiList] = useState(null);

  const [regionName, setRegionName] = useState("All");
  const [dealerName, setDealerName] = useState("All");
  const [customerName, setCustomerName] = useState("All");
  const [fleetName, setFleetName] = useState("All");
  const [vehicleName, setVehicleName] = useState("All");
  const [statusName, setStatusName] = useState("All");

  //Other Dropdown list
  const [otaStatusList, setOtaStatusList] = useState({
    content: OtaStatusData,
  });
  const [commandTypeList, setCommandTypeList] = useState({
    content: commandTypeData,
  });
  const [benchStatusList, setBenchStatusList] = useState({
    content: benchStatusData,
  });
  //If search and get vehicle
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  //Onload set
  const [load, setLoad] = useState(false);

  //Date Pickers
  const [fromDate, setFromDate] = useState(
    moment().format("YYYY-MM-DD 00:00:00")
  );
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD HH:mm:ss"));
  const [activeLabel, setActiveLabel] = useState("today");
  const [label, setLabel] = useState("Today");
  const GetDate = (fromDate, toDate, name) => {
    setFromDate(fromDate);
    setToDate(toDate);
    setLabel(name);
  };

  const [searchText, setSearchText] = useState({ value: null, name: null });
  const [search, setSearch] = useState(false);

  //Login Implemented
  //Based Login Implemented
  const OTADropdown = () => {
    switch (RoleId) {
      case 6:
        getRegionList(regionPayload);
        break;
      case 17:
        getRegionList(regionPayload);
        break;
      case 20:
        getRegionList(regionPayload);
        getCustomer(orgId);
        break;
      case 9:
        getCustomer("0");
      //setCustomer(orgId);
    }
    if (
      location.pathname === "/deviceOTA" ||
      location.pathname === "/deviceDataReport"
    ) {
      getImeiList(imeiPayload);
    }
  };

  useEffect(() => {
    OTADropdown();
  }, []);

  //Payloads ---------------------------------------------------------------------
  //Common Payloads
  let CommonPayload = {
    resellerId: resellerId,
  };

  //Region List Loaded
  let regionPayload = {
    value: "region",
    searchText: "",
  };

  //imei List
  let imeiPayload = {
    value: "imei",
    searchText: "",
    resellerId: resellerId,
  };

  //Load Region ------------------------------------------------------------------
  useEffect(() => {
    if (allOption) {
      setRegion("-1");
    }
    if (RoleId === 6) {
      commandTypeRemoves([0]);
    } else {
      commandTypeRemoves([1]);
    }
  }, []);

  useLayoutEffect(() => {
    if (regionSuccess) {
      setRegionList(regionData?.data);
    }
  }, [regionSuccess]);

  //Handle Get Dealer ------------------------------------------------------------
  const getDealer = (regionId) => {
    setRegion(regionId);
    if (regionId !== "-1") {
      CommonPayload.value = "dealer";
      CommonPayload.regionId = regionId;
      CommonPayload.dealerId = "0";
      CommonPayload.searchText = "";
      getDealerList(CommonPayload);
    } else {
      setDealer("-1");
      setDealerList(null);
      setCustomer("-1");
      setCustomerList(null);
      setFleet("-1");
      setFleetList(null);
      setVehicle("-1");
      setVehicleList(null);
      setImei("-1");
      setImeiList(null);
    }
  };

  useLayoutEffect(() => {
    if (dealerSuccess) {
      setDealerList(dealerdata?.data);
      if (search) {
        getCustomer(selectedVehicle[0]?.dealerId);
      }
    }
    if (allOption && selectedVehicle === null) {
      setDealer("-1");
    }
  }, [dealerSuccess]);

  //Handle Get Customer -----------------------------------------------------------
  const getCustomer = (dealerId) => {
    setDealer(dealerId);
    if (dealerId !== "-1") {
      CommonPayload.value = "customer";
      CommonPayload.dealerId = dealerId;
      CommonPayload.searchText = "";
      getCustomerList(CommonPayload);
    } else {
      setCustomer("-1");
      setCustomerList(null);
      setFleet("-1");
      setFleetList(null);
      setVehicle("-1");
      setVehicleList(null);
      setImei("-1");
      setImeiList(null);
    }
  };

  useLayoutEffect(() => {
    if (customerSuccess) {
      if (RoleId === 9) {
        let customerName = customerdata?.data.content.filter(
          (id) => parseInt(id.key) === orgId
        );
        setCustomerList({ content: customerName });
        setCustomer(customerName[0].key);
        getFleet(customerName[0].key);
      } else {
        setCustomerList(customerdata?.data);
      }
      if (search) {
        getFleet(selectedVehicle[0]?.customerId);
      }
    }
    if (allOption && selectedVehicle === null && RoleId !== 9) {
      setCustomer("-1");
    }
  }, [customerSuccess]);

  //Handle Get Fleet -------------------------------------------------------------
  const getFleet = (customerId) => {
    setCustomer(customerId);
    if (customerId !== "-1") {
      CommonPayload.value = "fleet";
      CommonPayload.dealerId = "0";
      CommonPayload.customerId = customerId;
      CommonPayload.searchText = "";
      getFleetList(CommonPayload);
    } else {
      setFleet("-1");
      setFleetList(null);
      setVehicle("-1");
      setVehicleList(null);
      setImei("-1");
      setImeiList(null);
    }
  };
  useLayoutEffect(() => {
    if (fleetSuccess) {
      setFleetList(fleetdata?.data);
      if (search) {
        getvehicle(selectedVehicle[0]?.fleetId);
      }
    }
    if (allOption && selectedVehicle === null) {
      setFleet("-1");
    }
  }, [fleetSuccess]);

   
  let vehilePayload = {
    value: "orgId",
    searchText: ""
  };

  //Handle Get Dealer -----------------------------------------------------------
  const getvehicle = (fleetId) => {
    setFleet(fleetId);
    if (fleetId !== "-1") {
      vehilePayload.paramValue = fleetId;
      if(search){
        vehilePayload.headerValue = vehicle;
        vehilePayload.param = 'search';
      }
      getVehicleList(vehilePayload);
    } else {
      //setVehicle("-1");
      setVehicleList(null);
    }
    //getVehicleList
  };


  

  useLayoutEffect(() => {
    if (vehicleSuccess) {
      setVehicleList(vehicledata?.data);
      setImeiList(vehicledata?.data);
      if (search) {
        setSearch(false);
      }
    }
    if (allOption && selectedVehicle === null && onLoadData && location.pathname !== "/deviceDataReport" && location.hash !== '#otaSupport'){
      setVehicle("-1");
      setImei("-1");
      setLoad(true);
    } else if(!search && !onLoadData && location.pathname !== "/deviceDataReport" && location.hash !== '#otaSupport'){
      setVehicle("-1");
      setImei("-1");
    }
  }, [vehicleSuccess]);


  //Handle Select Vehicle --------------------------------------------------------
  const selectVehicle = (vehicleId) => {
    setVehicle(vehicleId);

    //Find IEMI Id based on vehicle selection
    if (search && vehicleId !== "-1"){
      const SelctedVehicleData = searchVehicles?.data?.content.filter((item) => item.key === vehicleId);
      if (allOption && search) {
        setRegion(SelctedVehicleData[0]?.regionId);
        getDealer(SelctedVehicleData[0]?.regionId);
        //setVehicle(SelctedVehicleData[0]?.key);
        selectIemi(SelctedVehicleData[0]?.refVal);
      }

      
    // if (vehicleId !== "-1" && vehicledata !== undefined) {
    //   let SelectVehicleIemi = vehicledata?.data.content.find(
    //     (item, index) => item.key === vehicleId
    //   );
    //   setImei(SelectVehicleIemi.refVal);
    // } else if(selectedVehicle === null){
    //   setImei("-1")
    // }


      setSelectedVehicle(SelctedVehicleData);

      //getVehicle(SelctedVehicleData[0].fleetId);
      setSearchText({ value: null });
    }else{
        if (vehicleId !== "-1" && vehicledata !== undefined) {
          let SelectVehicleIemi = vehicledata?.data.content.find(
          (item, index) => item.key === vehicleId
        );
          setImei(SelectVehicleIemi.refVal);
        } else if(selectedVehicle === null){
          setImei("-1")
        }
    }



  };


  useLayoutEffect(() => {
    if (
      location.pathname === "/deviceOTA" ||
      location.pathname === "/deviceDataReport"
    ) {
      if (imeiSuccess) {
        setImeiList(imeidata?.data);
        if (location.pathname === "/deviceDataReport" || otaSupport) {
          setLoad(true);
          selectIemi(imeidata?.data?.content[0]?.value);
         
        } else {
          setImei("-1");
        }
        setVehicle("-1");
      }
    }
  }, [imeiSuccess]);


  //Handle IEMI Select
  const selectIemi = (iemiId) => {
    setImei(iemiId);
    
    if ((location.pathname !== "/deviceOTA" || location.pathname !== "/deviceDataReport") && !search) {
      if (iemiId !== "-1") {
        let getVehicleId = vehicleList?.content?.find((item, index) => item.refVal === iemiId);
        setVehicle(getVehicleId?.key);
      } else {
        setVehicle("-1");
      }
    }
  };
 
  useLayoutEffect(() => {
   
 if(!onLoadData && location.hash === '#ota' && imei !== "-1" && imei !== null){
      onSubmit();
    }
  }, [imei]);


  const commandTypeRemoves = (removeItems) => {
    let orgData = commandTypeData.filter((item, idx) => {
      return !removeItems.includes(idx);
    });
    setCommandTypeList({ content: orgData });
  };

  //handleOtaStatusList ---------------------------------------------------------------
  const handleOtaStatusList = (OtaStatus) => {
    setOtaStatus(OtaStatus);
  };

  //handleOtaStatusList ---------------------------------------------------------------
  const handleCommandTypeList = (commandType) => {
    setCommandType(commandType);
  };

  //handleBenchStatusList ---------------------------------------------------------------
  const handleBenchStatusList = (benchStatus) => {
    setBenchStatus(benchStatus);
  };


  const getInnerText = (value, label) =>{
    if(label === "Region"){
      setRegionName(value);
    }else if(label === "Dealer"){
      setDealerName(value);
    }else if(label === "Customer"){
      setCustomerName(value);
    } else if(label === "Fleet"){
      setFleetName(value);
    }else if(label === "Vehicle"){
      setVehicleName(value);
    }else if(label === "Status"){
      setStatusName(value);
    }
  }
  
  //Onsubmit Data
  const onSubmit = () => {
    let formdata = {
      region: region,
      dealer: dealer,
      customer: customer,
      fleet: fleet,
      vehicle: vehicle,
      imei: imei,
      fromDate: fromDate,
      toDate: label === 'Today' ? moment().format('yyyy-MM-DD HH:mm:ss') : toDate,
      commandType: RoleId === 6 && resellerId === 109882?commandType:"0",
      otaStatus: otaStatus,
      benchStatus: benchStatus,
      regionName:regionName,
      dealerName:dealerName,
      customerName:customerName,
      fleetName:fleetName,
      vehicleName:vehicleName,
      statusName:statusName,
    };

    getData(formdata);
  
  };
 
  useEffect(() => {
    if (load) {
      onSubmit();
    }
  }, [load]);

  useEffect(() => {
    if(imei !== null && location.hash === '#otaSupport'  ){
      onSubmit();
    } 
  },[imei])

  //Search--------------------------------------------------------------------------

  // vehicleSearch

  const vehicleSearch = (searchText) => {
    let vehicleSeatchPayload = {
      resellerId: resellerId,
      searchText: searchText,
      page: "0",
      size: "10",
      orgType: userOrgType,
      dealerId: orgId,
      vehicleAllFilter: true,
      imeiSearch: "",
    };
    if (searchText !== '') {
      getVehicleSearch(vehicleSeatchPayload);
    } else {
      setSelectedVehicle(null);
      setVehicleList(vehicledata?.data);
    }
  };

  useLayoutEffect(() => {
    if (searchVehicleSuccess) {
      if (searchVehicles.data !== null) {
        setVehicleList(searchVehicles.data);
      }
    }
  }, [searchVehicleSuccess]);


  

  const regionSearch = (searchText) => {
    CommonPayload.value = "region";
    CommonPayload.searchText = searchText;
    CommonPayload.dealerId = "-1";
    getRegionList(CommonPayload);
  };
  const dealerSearch = (searchText) => {
    CommonPayload.value = "dealer";
    CommonPayload.searchText = searchText;
    CommonPayload.regionId = region;
    if (region !== "-1") {
      getDealerList(CommonPayload);
    }
  };
  const customerSearch = (searchText) => {
    CommonPayload.value = "customer";
    CommonPayload.searchText = searchText;
    CommonPayload.dealerId = dealer;
    if (dealer !== "-1") {
      getCustomerList(CommonPayload);
    }
  };
  const FleetSearch = (searchText) => {
    CommonPayload.value = "fleet";
    CommonPayload.dealerId = "0";
    CommonPayload.searchText = searchText;
    CommonPayload.customerId = customer;
    if (customer !== "-1") {
      getFleetList(CommonPayload);
    }
  };

  const ImeiSearch = (searchText) => {
    CommonPayload.value = "imei";
    CommonPayload.searchText = searchText;
    if (
      location.pathname === "/deviceOTA" ||
      location.pathname === "/deviceDataReport"
    ) {
      getImeiList(CommonPayload);
    }
  };

  //search
  const dropDownSearch = (searchText, name) => {
    if (name === "Vehicle") {
      setSearchText({ value: searchText });
      setSearchText({ name: name });
      setSearch(true);
      vehicleSearch(searchText);
    } else if (name === "Region") {
      regionSearch(searchText);
    } else if (name === "Dealer") {
      dealerSearch(searchText);
    } else if (name === "Customer") {
      customerSearch(searchText);
    } else if (name === "Fleet") {
      FleetSearch(searchText);
    } else if (name === "IMEI") {
      ImeiSearch(searchText);
    }
  };

  useEffect(()=> {
    if(getDataLoading){
      document.body.style.cursor = 'wait'
    }else{
      document.body.style.cursor = 'pointer'
    }
  },[getDataLoading])

  return (
    <StyledPaperShadow
      elevation={0}
      sx={{ display: "inline-block", width: "100%" }}
    >
      {regionDrop && RoleId !== 9 && (
        <SingleDropdown
          label={"Region"}
          listItems={regionList}
          handleChange={getDealer}
          value={region}
          isSearch={dropDownSearch}
          showAll={allOption}
          getInnerText={getInnerText}
        />
      )}
      {dealerDrop && RoleId !== 9 && (
        <SingleDropdown
          label={"Dealer"}
          listItems={dealerList}
          handleChange={getCustomer}
          value={dealer}
          isSearch={dropDownSearch}
          showAll={allOption}
          getInnerText={getInnerText}
        />
      )}

      {(customerDrop || RoleId === 9) && (
        <SingleDropdown
          label={"Customer"}
          listItems={customerList}
          handleChange={getFleet}
          value={customer}
          isSearch={dropDownSearch}
          showAll={RoleId !== 9 ? allOption : false}
          getInnerText={getInnerText}
        />
      )}

      {(fleetDrop || RoleId === 9) && (
        <SingleDropdown
          label={"Fleet"}
          listItems={fleetList}
          handleChange={getvehicle}
          value={fleet}
          isSearch={dropDownSearch}
          showAll={allOption}
          getInnerText={getInnerText}
        />
      )}
      {(vehicleDrop || RoleId === 9) && (
        <SingleDropdown
          label={"Vehicle"}
          listItems={vehicleList}
          handleChange={selectVehicle}
          value={vehicle}
          isSearch={dropDownSearch}
          showAll={allOption}
          getInnerText={getInnerText}
        />
      )}

      {(imeiDrop || RoleId !== 9) && (
        <SingleDropdown
          label={"IMEI"}
          listItems={imeiList}
          handleChange={selectIemi}
          value={imei}
          isSearch={dropDownSearch}
          showAll={
            location.pathname === "/deviceDataReport" || otaSupport ? false : allOption
          }
          selectType={"value"}
          isLoading={imeiLoading}
        />
      )}
      {(otaDrop && RoleId === 6 && resellerId === 109882) && (
        <SingleDropdown
          label={"Command Type"}
          listItems={commandTypeList}
          handleChange={handleCommandTypeList}
          value={commandType}
          isSearch={false}
          showAll={false}
          customAllValue={"296"}
        />
      )}
      {otaDrop && (
        <SingleDropdown
          label={"Status"}
          listItems={otaStatusList}
          handleChange={handleOtaStatusList}
          value={otaStatus}
          isSearch={false}
          showAll={allOption}
          customAllValue={"0"}
          getInnerText={getInnerText}
        />
      )}
      {benchDrop && (
        <SingleDropdown
          label={"Status"}
          listItems={benchStatusList}
          handleChange={handleBenchStatusList}
          value={benchStatus}
          isSearch={false}
          showAll={allOption}
          customAllValue={"0"}
        />
      )}

      {(otaDrop || benchDrop) && (
        <DatepickerDropdown
          getDate={GetDate}
          activeLabel={activeLabel}
          showAll={false}
          showLast7Days={false}
          time={time}
          allowDays={allowDays}
          allowedMonth={allowedMonth}

        />
      )}
      {(otaDrop || benchDrop) && (
        <StyledFiterButton
          type="button"
          variant="contained"
          size="small"
          color="success"
          onClick={onSubmit}
          sx={{ marginTop: "25px", cursor: getDataLoading ? 'wait' : 'pointer'}}

        >
          Submit
        </StyledFiterButton>
      )}
    </StyledPaperShadow>
  );
}

export default OTADropdown;
