import React, { useEffect, useMemo, useState } from "react";
import { ExpandTable } from "../shared/data_table/ExpandTable";
import { Box, CardContent, Grid } from "@mui/material";
import { StyledCardShadow,  StyledToggleButton, StyledToggleButtonGroup  } from "../../theme/StyledEle";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import {  useGetCapturedGeoZoneCategoryListMutation, useGetRouteTripMutation, useGetRouteTripSummaryHistoryListMutation } from "../../store/slice/RouteTripSlice/RouteTripSlice";
import moment from "moment";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import RouteTimeline from "../route/RouteTimeline";
import GeozoneListForSingleVehicle from "../route/GeozoneListForSingleVehicle";
import MouseOverPopover from "../shared/PopOver";


const ListRouteTripHistory = () => {

  let userData = JSON.parse(localStorage.getItem("userData"));
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let resellerId = userData.resellerId;


  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [commonFilter, setCommonFilter] = useState({
    excel: false,
    pdf: false,
  });



  const columns = useMemo(
    () => [
      // {
      //   Header: "",
      //   accessor: "expandIcon",
      //   width:60
      // },

      // {
      //   Header: "Date",
      //   accessor: "createdDate",
      // },
      {
        Header: "Route Name",
        accessor: "routeName",
        width:'320',
      },
      {
        Header: "Vehicle Details",
        columns: [
      {
        Header: "Vehicle No",
        accessor: "vehicleNo",
      },
      {
        Header: "Start Location",
        accessor: "startGeozone",
        width:212,
        Cell: ({ row }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.startGeozone}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.startLocation}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.startGeozone}
                >
                  {row.original.startGeozone}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.startLocation}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.startGeozone}
                  key={row.original.startGeozone}
                ></a>
              </Box>
            </Box>
          );
        },
      
      },
      {
        Header: "End Location",
        accessor: "endGeozone",
        width:212,
        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);

          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                id={row.original.endGeozone}
                sx={{ display: "flex", alignItems: "center" }}
              >

                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.endLocation}`}
                  target="_blank"
                  className="latlink"
                  ref={refLink}
                  id={row.original.endGeozone}
                >
                  {row.original.endGeozone}
                </a>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.endLoction}`}
                  target="_blank"
                  className="address"
                  ref={refAddres}
                  id={row.original.endGeozone}
                  key={row.original.endGeozone}
                ></a>
              </Box>
            </Box>
          );
        },
      },
      {
        Header: "Start Time",
        accessor: "startTime",
      },
      {
        Header: "End Time",
        accessor: "endTime",
      },
      {
        Header: "Running Time (hh:mm)",
        accessor: "moveMins",
      },
      {
        Header: "Total Duration (hh:mm)",
        accessor: "travelDuration",
      },
      {
        Header: "Start Odometer (km)",
        accessor: "startCanOdometer",
      },
      {
        Header: "End Odometer (km)",
        accessor: "endCanOdometer",
      },
      {
        Header: "Distance Traveled (km)",
        accessor: "canDistanceTravelled",
      },
      {
        Header: "Max Speed (km/h)",
        accessor: "maxCanSpeed",
      },
      {
        Header: "Average Speed (km/h)",
        accessor: "averageCanSpeed",
      },
      {
        Header: "Energy Consumed (kWh)",
        accessor: "energyConsumed",
      },
      {
        Header: "Efficiency (km/kWh)",
        accessor: "energyEfficiency",
      },
      {
        Header: "Trip Status",
        accessor: "tripStatus",
      },
      
    ]},
     {
      Header: "Battery Details",
        columns: [
          {
            Header: "Start SOC (%)",
            accessor: "startSoc",
          },
          {
            Header: "End SOC (%)",
            accessor: "endSoc",
          },
          {
            Header: "Charging Mins",
            accessor: "chargingSummaryMins",
          },
          {
            Header: "Energy Gained (kWh)",
            accessor: "chargingSummaryEnergyGained",
          },
          {
            Header: "Battery Temperature Rise (℃)",
            accessor: "batteryRiseInTemp",
          },
          {
            Header: "Max Battery Temp (℃)",
            accessor: "maxBatteryTemp",
          },
          {
            Header: "Avg Battery Temp (℃)",
            accessor: "avgBatteryTemp",
          },
          {
            Header: "Max Battery Current (A)",
            accessor: "maxbatteryCurrent",
          },
        ]
     },
  
    ],
    []
  );


  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };

  const [
    getRouteTripSummaryHistoryList,
    {
      data: routeTripHistoryData,
      isLoading: getRouteTripHistoryDataLoading,
      isSuccess: getRouteTripHistorySuccess,
    },
  ] = useGetRouteTripSummaryHistoryListMutation();
  let commonPayload = {};
  let [payload, setPayload] = useState(null);
  let [selectDays, setSelectedDays] = useState();
  // Onload and Submit
  const getData = (data) => {
    let totalHours = moment(data.fromDate, 'YYYY.MM.DD HH:mm:ss').diff(moment(data.toDate, 'YYYY.MM.DD HH:mm:ss'), 'days');
    setSelectedDays(Math.abs(totalHours));
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.orgId = data.fleetId;
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.routeId = data.vehicleId;
    commonPayload.searchText = "";
    commonPayload.customerId = data.customerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;    
    setPayload(commonPayload);
    if(alignment==="viewTrips"){
      getRouteTripSummaryHistoryList(commonPayload);
    }else{
      getCapturedGeoZoneCategory(commonPayload);
    }

    auditPayload.message = "Reports > Route Trip History Report > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Route Trip History Report";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
    setOpenTimeLine(false);
  };


  const [selectRow, setSelectRow] = useState([])

   //Export PDF & Excel
   const onChangeXcl = async () => {

    let rowArr = [];
    for (let i = 0; i < selectRow.length; i++) {
      rowArr.push(selectRow[i].original.routeTripId);
    }

    let arrVal = rowArr.length > 0 ? rowArr.toString() : ''

     try {
       var url =
         process.env.REACT_APP_API_BASE_URL +
         "routeTrip/routeTripHistory.xls?startDate=" +
         payload.startDate +
         "&endDate=" +
         payload.endDate +
         "&searchText=" +
         payload.searchText +
         "&orgId=" +
         payload.orgId +
         "&routeId=" +
         payload.routeId +
         "&customerId=" +
         payload.customerId +
         "&page=" +
         payload.page +
         "&size=" +
         payload.size +
         "&vehicleId=" +
         payload.vehicleId +
         "&routeTripIds=" + 
         arrVal;
         ;
       window.open(url);
 
      //  auditPayload.message = "Reports > View Route > XLS";
      //  auditPayload.action = "View";
      //  auditPayload.featureName = "View Route";
      //  auditPayload.customerName = "All";
      //  auditPayload.fleetName = "All";
      //  auditPayload.result = "Success";
      //  auditPayload.access = "Portal";
      //  auditPayload.severity = "INFO";
      //  auditLog(auditPayload);
 
       fetch(url, {
         headers: {
           Authorization:
             "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
         },
       }) // FETCH BLOB FROM IT
         .then((response) => {
           response.blob();
         })
         .then((blob) => {})
         .catch((err) => {});
     } catch (error) {}
   };


  useEffect(() => {
    if (getRouteTripHistorySuccess) {
      setResponseData([]);
      if (routeTripHistoryData.data !== null) {
        setResponseData(routeTripHistoryData.data.content);
        setTotalElements(routeTripHistoryData.data.totalElements);
        setTotalPages(routeTripHistoryData.data.totalPages);
      }
    }
  }, [getRouteTripHistorySuccess]);

  const pageChange = (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    payload.page = newPage;
    payload.size = rowsPerPage;
    getRouteTripSummaryHistoryList(payload);
  };
  const handleSearch = (searchText) => {
    setNewPage(0);
    setRowsPerPage(25);
    payload.searchText = searchText;
    payload.page = 0;
    payload.size = 25;
    if (payload.searchText.length >= 3) {
      getRouteTripSummaryHistoryList(payload);
    } else if (payload.searchText.length === 0) {
      getRouteTripSummaryHistoryList(payload);
    }
  };

//TimeLine

  const [ getRouteTrip, { data:timelineData, isLoading:timeLineLoading, isSuccess:timeLineSuccess} ] = useGetRouteTripMutation();

  const [openTimeLine, setOpenTimeLine] = useState(false);
  const handleTimeline = async (data) => {
        let tripTimeLinePayload = {
          "vehicleId": payload.vehicleId,
          "startTime": payload.startDate,
          "endTime": payload.endDate,
        }
        setOpenTimeLine(true);
        const timeLineData = await getRouteTrip(tripTimeLinePayload);
  }

  const closeTimeLine = () => {
    setOpenTimeLine(false);
  }
  const [hideDetails, setHidedetails] = useState("viewTrips");
  const [alignment, setAlignment] = React.useState("viewTrips");
  const handleAlignment = (event, newAlignment) => {
    
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      setHidedetails(newAlignment);
    }

  };

  const [
    getCapturedGeoZoneCategory,
    {
      data: getCapturedGeoZoneCategoryData,
      isLoading: getCapturedGeoZoneCategoryLoading,
      isSuccess: getCapturedGeoZoneCategorySuccess,
    },
  ] = useGetCapturedGeoZoneCategoryListMutation();

  useEffect(() => {
    if (getCapturedGeoZoneCategorySuccess) {
      setResponseData([]);
      if (getCapturedGeoZoneCategoryData.data !== null) {
        setResponseData(getCapturedGeoZoneCategoryData.data);
      }
    }
  }, [getCapturedGeoZoneCategorySuccess]);

  // React.useEffect(() => {
  //   if (alignment === "viewTrips") {
  //     if (payload !== null) {
  //       let newobj = {};
  //       newobj = payload;
  //       getRouteTripSummaryHistoryList(newobj);
  //     }
  //   } else {
  //     //Day wise
  //     if (payload !== null) {
  //       let newobj = {};
  //       newobj = payload;
  //       getCapturedGeoZoneCategory(newobj);
  //     }
  //   }
  // }, [alignment]);


  const [exportShow, setExportShow] = useState(false)

  const getRow = (selectRow, rowId) => {
    setSelectRow(selectRow);
    // setSelectRowId(rowId);
    // setSeletedRows(selectRow);
    if(selectRow.length <= 4 && selectRow.length > 0){
      setExportShow(true);
    }else{
      setExportShow(false);
    }
  };


  return (
    <>
    <MouseOverPopover text={"The Route Trip is Vehicle traveling predefined with connecting the geozone route"}  />
    <Box>
      <FilterDropdown
        getData={getData}
        status={false}
        allowDays={hideDetails === "viewTrips"?31:3}
        last7={hideDetails === "viewTrips"?true:false}
        allowToday={hideDetails === "viewTrips"?true:false}
        allowedMonth={resellerId === 110224 ? 6 : 3}
        yesToday={hideDetails === "viewTrips"?true:false}
        onload={false}
        isShowAll={true} 
        last6Month={true}
        last30={hideDetails === "viewTrips"?true:false}
        alignment={alignment}
        getDataLoading={getRouteTripHistoryDataLoading || getCapturedGeoZoneCategoryLoading}
      />
             {/* <FilterDropdown
          getData={getData}
          status={false}
          isShowAll={false}
          allowDays={3}
          last7={false}
          allowedMonth = {resellerId === 110224 ? 6 : 3}
        />  */}
       <StyledToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleAlignment}
            size="small"
            sx={{ mt: 2 }}
          >
            <StyledToggleButton value={"viewTrips"}>
            Trips Summary
            </StyledToggleButton>
            {/* <Tooltip title={"Should select any vehicle"}>
           <span> */}
            <StyledToggleButton value={"routeTripGeozone"} >Geozone Summary</StyledToggleButton> 
          {/* </span> 
          </Tooltip> */}
          </StyledToggleButtonGroup>
          {hideDetails === "viewTrips" && (
       <Grid container sx={{my:0}} columnSpacing={1} rowSpacing={0} rowGap={0}>
            <Grid item lg={openTimeLine ? 9 : 12} >
            <StyledCardShadow sx={{ my: 2 }}>
              <CardContent sx={{ pt: 2 }}>
                <ExpandTable
                  isLoading={getRouteTripHistoryDataLoading}
                  includeSearchBox={false}
                  handleChange={handleSearch}
                  onChangeXcl={onChangeXcl}
                  commonFilter={commonFilter}
                  columns={columns}
                  data={responseData}
                  styles={{ maxWidth: "100vw" }}
                  page={newPage}
                  rowsPerPage={rowsPerPage}
                  callbackChangePage={pageChange}
                  totalElements={totalElements}
                  totalPages={totalPages}
                  includeExportButton={true}
                  disabledExport={selectRow.length > 0 && selectRow.length <= 4 ? false : totalElements <= 25? false : true }
                  timeTime={selectDays <=1 && responseData.length > 0 && payload.vehicleId!=='-1'}
                  handleTimeLine={handleTimeline}
                  selectRow={getRow}
                  checkbox={true}
                />
              </CardContent>
            </StyledCardShadow>
            </Grid>
            {
               openTimeLine &&
                <Grid item xl={3} lg={3}>
                  <StyledCardShadow sx={{ my: 2 }}>
                  <RouteTimeline timeLineData={timelineData} handleTimeline={closeTimeLine}/>
                  </StyledCardShadow>
               </Grid>
            }
            
          </Grid>)}
          {hideDetails === "routeTripGeozone" && (
<GeozoneListForSingleVehicle data={responseData}
              payload={payload}
              isLoadingOut={getCapturedGeoZoneCategoryLoading}/>
          )}
            </Box>
            </>
  );
};

export default ListRouteTripHistory;
