import { Paper } from '@material-ui/core';
import { Avatar, Badge, Tab, Tabs, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dropVehicles, isLoadingState } from '../../store/slice/ControlsSlice';
import { useGetVehicleByResellerNewMutation } from '../../store/slice/TrackingSlice/TrackingSlice';
import { VehicleStatusBar } from '../../theme/StyledEle';
import { AccidentTruck, AllAutos, AllRickshaw, AllScooters, AllTrucks, BreakdownTruck, ChargingAuto, ChargingBike, ChargingCar, ChargingRick, ChargingScvm, ChargingTractorm, ChargingTruck, MovingAuto, MovingBike, MovingCar, MovingRick, MovingScvm, MovingTractorm, MovingTruck, OfflineAuto, OfflineBike, OfflineCar, OfflineRick, OfflineScvm, OfflineTractorm, OfflineTruck, StopAuto, StopBike, StopCar, StopRick, StopScvm, StopTractorm, StopTruck, MovingMapBus, OfflineMapBus, IdleMapBus, StopMapBus, ChargingMapBus, AllBuses } from '../Constans';
function CountBar(props) {
  const { data, showCategory, mapRefetch ,imgData, reCenter, vehicleType} = props;
  const theme = useTheme();
  let dispatch = useDispatch();
  let treeViewRoll = useSelector((state) => state.controls.treeview)
  const vehicleTrails = useSelector((state) => state.controls.vehicleTrails);
  const [show, setShow] = useState(true);
  //get localstorage data

  const userData = JSON.parse(localStorage.getItem('userData'));
  let VehicleType =  userData.vehicleType;
  let userOrgType = userData.userOrgType;
  let [vehicle, setVehicle] = useState(8);

  const getVehicleType = () => {
    switch(VehicleType){
      case 'TwoWheeler':
        setVehicle(2);
      break;
      case 'ThreeWheeler':
        setVehicle(3);
      break;
      case 'Truck':
        setVehicle(4);
      break;
      case 'Car':
        setVehicle(5);
      break;
      case 'Scv':
        setVehicle(6);
      break;
      case 'Tractor':
        setVehicle(7);
      break;
      case 'ERick':
        setVehicle(8);
      break;
      case 'Bus':
        setVehicle(9);
      break;
      default:
        setVehicle(2);
    }
  }

  useEffect(() => {
    if(VehicleType){
      if(userData.resellerId === 113802){
        setVehicle(9);
      }else{
        getVehicleType();
      }
    }
  },[userData.resellerId])


  useEffect(() => {
      getVehicleType();
  },[vehicleType])

  
  

  const [status, setStatus] = useState('none');
  //handleStatusChange
  const handleStatusChange = (event, newStatus) => {
    setStatus(newStatus);
    props.closeTreeViewfn(newStatus);
    mapRefetch();
  }
  
  

  useEffect(() => {
    if(!showCategory){
      setStatus('none');
    }
  },[showCategory])

  const [ getVehicleByResellerNew, { data: getVehicleData, isLoading: getVehicleLoading, isSuccess: getVehicleSuccess, } ] = useGetVehicleByResellerNewMutation();


  const getFilterStatusVehicle = async (commonPayload) => {
      const filterVehicle = await getVehicleByResellerNew(commonPayload);
      if(filterVehicle.data !== null && filterVehicle.data !== undefined){
        if(filterVehicle.data.vehicles.length > 0){
          dispatch(dropVehicles(filterVehicle.data));
        }else{
          dispatch(dropVehicles(null));
        }
      }else{
        dispatch(dropVehicles(null));
      }
  }

  useEffect(() => {
    dispatch(isLoadingState(getVehicleLoading))
  },[getVehicleLoading])

  // get show vehicle based on vehicle status
  const [vehicleStatus, setVehicleStatus] = useState('All');
  const showVehicleStatus = async (status) => {
    setVehicleStatus(status);
    reCenter();
    let commonPayload = {
      resellerId: userData.resellerId,
      regionId: 0,
      dealerId: 0,
      customerId: 0,
      orgId: userData.resellerId,
      checkboxStatus: false,
      page: 0,
      size: 1000,
      region: 0,
      vehModel: "All",
      searchText: "",
      zoneId: 0,
      status:status
    };
    if(userData.userOrgType === 2){
      commonPayload.orgId = userData.orgId;
      if(treeViewRoll.role === 'region'){
        commonPayload.dealerId = 0;
        commonPayload.customerId = 0;
        commonPayload.regionId = treeViewRoll.id;
      }else if(treeViewRoll.role === 'dealer'){
        commonPayload.dealerId = treeViewRoll.id;
        commonPayload.customerId = 0;
        commonPayload.regionId = 0;
      }else if(treeViewRoll.role === 'customer'){
        commonPayload.dealerId = 0;
        commonPayload.customerId = treeViewRoll.id;
        commonPayload.regionId = 0;
      }
      getFilterStatusVehicle(commonPayload);
    }else if(userOrgType === 5){
      commonPayload.orgId = userData.orgId;
      if(treeViewRoll.role === 'customer'){
        commonPayload.dealerId = userData.orgId;
        commonPayload.customerId = treeViewRoll.id;
        commonPayload.regionId = 0;
      }
      getFilterStatusVehicle(commonPayload);
    }else if(userOrgType === 3){
      commonPayload.customerId = treeViewRoll.id !== '' ? treeViewRoll.id : userData.orgId ;
      getFilterStatusVehicle(commonPayload);
    }
  }  
  
  return (
  //vehicleTrails === null &&  userData.resellerId !== 110224 &&
    <VehicleStatusBar sx={{right:show ? '0px' : '-70px'}}>
        <Paper elevation={0} className='vehicle-status-root' sx={{position:'relative'}}>
          
              {
                userData.resellerId === 110224 &&  <Tabs value={status} onChange={handleStatusChange}>
                <Tab label={''} value={'none'} sx={{display:'none'}}/>
                <Tab label={'All'} value={''}  icon={
                <Badge
                  badgeContent={data?.data?.totalCount}
                  color='success'
                  variant='square'
                  max={999999}
                  showZero={true}
                >
                  <Avatar variant="square" 
                    id={data?.data?.totalCount}
                    src={

                      vehicle === 2 ? (imgData?.all !=="-" && imgData?.all !== null  ? `https://${imgData?.all}` : AllScooters) :
                      vehicle === 3 ? (imgData?.all !=="-" && imgData?.all !== null  ? `https://${imgData?.all}` : AllAutos) :
                        vehicle === 4 ? (imgData?.all !=="-" && imgData?.all !== null ? `https://${imgData?.all}` : AllTrucks) :
                          vehicle === 5 ? (imgData?.all  !=="-" && imgData?.all !== null ? `https://${imgData?.all}` : MovingCar) :
                            vehicle === 6 ? (imgData?.all !=="-" && imgData?.all !== null  ? `https://${imgData?.all}` : MovingScvm) :
                              vehicle === 7 ? (imgData?.all !=="-" && imgData?.all !== null  ? `https://${imgData?.all}` : MovingTractorm) :
                              vehicle === 8 ? (imgData?.all !=="-" && imgData?.all !== null  ? `https://${imgData?.all}` : AllRickshaw) :
                              AllScooters
                    }/>
                </Badge>}/>

                <Tab label={'Moving'} value={'Moving'} data-count={data?.data?.totalCount}  icon={<Badge
                    badgeContent={data?.data?.moveCount}
                    color='success'
                    variant='square'
                    max={999999}
                    showZero={true}
                  >
              <Avatar variant="square"
                src={
                  vehicle === 2 ? (imgData?.moving !=="-" && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingBike) :
                    vehicle === 3 ? (imgData?.moving !=="-" && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingAuto) :
                      vehicle === 4 ? (imgData?.moving !=="-" && imgData?.moving !== null ? `https://${imgData?.moving}` : MovingTruck) :
                        vehicle === 5 ? (imgData?.moving  !=="-" && imgData?.moving !== null ? `https://${imgData?.moving}` : MovingCar) :
                          vehicle === 6 ? (imgData?.moving !=="-" && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingScvm) :
                            vehicle === 7 ? (imgData?.moving !=="-" && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingTractorm) :
                            vehicle === 8 ? (imgData?.moving !=="-" && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingRick) :
                              ''
                }
              />

            </Badge>} />

                <Tab label={'Stop'}  data-count={data?.data?.totalCount} 
                icon={<Badge
                  badgeContent={data?.data?.stopCount}
                  color='error'
                  max={999999}
                  showZero={true}
                >
                <Avatar variant="square"
                src={
                  vehicle === 2 ? (imgData?.stop !=="-" && imgData?.stop !== null ? `https://${imgData?.stop}` : StopBike) :
                    vehicle === 3 ? (imgData?.stop !=="-" && imgData?.stop !== null   ? `https://${imgData?.stop}` : StopAuto) :
                      vehicle === 4 ? (imgData?.stop !=="-" && imgData?.stop !== null   ? `https://${imgData?.stop}` : StopTruck) :
                        vehicle === 5 ? (imgData?.stop !=="-" && imgData?.stop !== null   ? `https://${imgData?.stop}` : StopCar) :
                          vehicle === 6 ? (imgData?.stop !=="-" && imgData?.stop !== null   ? `https://${imgData?.stop}` : StopScvm) :
                            vehicle === 7 ? (imgData?.stop !=="-" && imgData?.stop !== null   ? `https://${imgData?.stop}` : StopTractorm) :
                            vehicle === 8 ? (imgData?.stop !=="-" && imgData?.stop !== null   ? `https://${imgData?.stop}` : StopRick) :
                              ''
                }
              />
                </Badge>}
                value={'Stop'}/>

              <Tab label={'Charging'} data-count={data?.data?.totalCount} 
                icon={<Badge
                  badgeContent={data?.data?.chargeCount}
                  color='warning'
                  max={999999}
                  showZero={true}
                >
                  <Avatar variant="square"
                src={
                  vehicle === 2 ? (imgData?.charging !=="-" && imgData?.charging !== null ? `https://${imgData?.charging}` : ChargingBike) :
                    vehicle === 3 ? (imgData?.charging !=="-" && imgData?.charging !== null  ? `https://${imgData?.charging}` : ChargingAuto) :
                      vehicle === 4 ? (imgData?.charging !=="-" && imgData?.charging !== null ? `https://${imgData?.charging}` : ChargingTruck) :
                        vehicle === 5 ? (imgData?.charging !=="-" && imgData?.charging !== null ? `https://${imgData?.charging}` : ChargingCar) :
                          vehicle === 6 ? (imgData?.charging !=="-" && imgData?.charging !== null  ? `https://${imgData?.charging}` : ChargingScvm) :
                            vehicle === 7 ? (imgData?.charging !=="-" && imgData?.charging !== null ? `https://${imgData?.charging}` : ChargingTractorm) :
                            vehicle === 8 ? (imgData?.charging !=="-" && imgData?.charging !== null ? `https://${imgData?.charging}` : ChargingRick) :
                              ''
                }
              />
                </Badge>}
                 value={'Charging'}/>

                <Tab label={'Offline'} data-count={data?.data?.totalCount} 
                icon={<Badge
                  badgeContent={data?.data?.offlineCount}
                  color='default'
                  max={999999}
                  showZero={true}
                  sx={{'& .MuiBadge-badge':{background:theme.palette.grey[600], color:theme.palette.primary.contrastText}}}
                >
                  <Avatar variant="square"
                src={
                  vehicle === 2 ? (imgData?.offline !=="-" && imgData?.offline !== null ? `https://${imgData?.offline}` : OfflineBike) :
                    vehicle === 3 ? (imgData?.offline !=="-" && imgData?.offline !== null  ? `https://${imgData?.offline}` : OfflineAuto) :
                      vehicle === 4 ? (imgData?.offline !=="-" && imgData?.offline !== null ? `https://${imgData?.offline}` : OfflineTruck) :
                        vehicle === 5 ? (imgData?.offline !=="-" && imgData?.offline !== null  ? `https://${imgData?.offline}` : OfflineCar) :
                          vehicle === 6 ? (imgData?.offline !=="-" && imgData?.offline !== null ? `https://${imgData?.offline}` : OfflineScvm) :
                            vehicle === 7 ? (imgData?.offline !=="-" && imgData?.offline !== null ? `https://${imgData?.offline}` : OfflineTractorm) :
                            vehicle === 8 ? (imgData?.offline !=="-" && imgData?.offline !== null ? `https://${imgData?.offline}` : OfflineRick) :
                              ''
                }
              />
                </Badge>}
                 value={'Offline'}/>

                <Tab label={'Breakdown'} data-count={data?.data?.totalCount} 
                icon={<Badge
                  badgeContent={data?.data?.breakdownCount}
                  color='info'
                  max={999999}
                  showZero={true}
                  sx={{'& .MuiBadge-badge':{background:'#928d4d !important', color:theme.palette.primary.contrastText}}}
                >
                  <Avatar variant="square"
                    src={
                      vehicle === 2 ? (imgData?.breakdown !=="-" && imgData?.breakdown !== null  ? `https://${imgData?.breakdown}` : OfflineBike) :
                        vehicle === 3 ? (imgData?.breakdown !=="-"  && imgData?.breakdown !== null  ? `https://${imgData?.breakdown}` : OfflineAuto) :
                          vehicle === 4 ? (imgData?.breakdown !=="-"   && imgData?.breakdown !== null  ? `https://${imgData?.breakdown}` : BreakdownTruck) :
                            vehicle === 5 ? (imgData?.breakdown !=="-"  && imgData?.breakdown !== null  ? `https://${imgData?.breakdown}` : OfflineCar) :
                              ''
                    }
                  />
                </Badge>}
                 value={'Breakdown'}/>
                
                <Tab label={'Accident'} data-count={data?.data?.totalCount} 
                icon={<Badge
                  badgeContent={data?.data?.accidentCount}
                  color='info'
                  max={999999}
                  showZero={true}
                >
                   <Avatar variant="square"
                    src={
                      vehicle === 2 ? (imgData?.accident !=="-" && imgData?.accident !== null  ? `https://${imgData?.accident}` : OfflineBike) :
                        vehicle === 3 ? (imgData?.accident !=="-" && imgData?.accident !== null  ? `https://${imgData?.accident}` : OfflineAuto) :
                          vehicle === 4 ? (imgData?.accident !=="-" && imgData?.accident !== null  ? `https://${imgData?.accident}` : AccidentTruck) :
                            vehicle === 5 ? (imgData?.accident !=="-" && imgData?.accident !== null  ? `https://${imgData?.accident}` : OfflineCar) :
                              ''
                    }
                  />
                </Badge>}
                 value={'Accident'}/>
              </Tabs>
              }
              


              {
                  userData.resellerId !== 110224 && vehicleTrails === null &&
              <Tabs value={vehicleStatus}>
                  <Tab label={'All'} value={'All'} sx={{overflow:'visible'}} onClick={() => showVehicleStatus('All')} icon={ <Badge
                        badgeContent={data?.data?.totalCount}
                        color='info'
                        variant='square'
                        max={999999}
                        showZero={true}
                        
                      >
                      <Avatar variant="square"
                        src={
                          vehicle === 2 ? (imgData?.all !=="-" && imgData?.all !== null  ? `https://${imgData?.all}` : AllScooters) :
                      vehicle === 3 ? (imgData?.all !=="-" && imgData?.all !== null  ? `https://${imgData?.all}` : AllAutos) :
                        vehicle === 4 ? (imgData?.all !=="-" && imgData?.all !== null ? `https://${imgData?.all}` : AllTrucks) :
                          vehicle === 5 ? (imgData?.all  !=="-" && imgData?.all !== null ? `https://${imgData?.all}` : MovingCar) :
                            vehicle === 6 ? (imgData?.all !=="-" && imgData?.all !== null  ? `https://${imgData?.all}` : MovingScvm) :
                              vehicle === 7 ? (imgData?.all !=="-" && imgData?.all !== null  ? `https://${imgData?.all}` : MovingTractorm) :
                              vehicle === 8 ? (imgData?.all !=="-" && imgData?.all !== null  ? `https://${imgData?.all}` : AllRickshaw) :
                              vehicle === 9 ? (imgData?.all !=="-" && imgData?.all !== null  ? `https://${imgData?.all}` : AllBuses) :
                              AllScooters
                        }
                      />
                    </Badge>
                  } />


                <Tab label={'Moving'} value={'Moving'} sx={{overflow:'visible'}} onClick={() => showVehicleStatus('Moving')} icon={ <Badge
                      badgeContent={data?.data?.moveCount}
                      color='success'
                      variant='square'
                      max={999999}
                      showZero={true}
                      
                    >
                      <Avatar variant="square"
                        src={
                          vehicle === 2 ? ((imgData?.moving !=="-") && imgData?.moving !== null ? `https://${imgData?.moving}` : MovingBike) :
                            vehicle === 3 ? ((imgData?.moving !=="-") && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingAuto) :
                              vehicle === 4 ? ((imgData?.moving !=="-") && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingTruck) :
                                vehicle === 5 ? ((imgData?.moving !=="-") && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingCar) :
                                  vehicle === 6 ? ((imgData?.moving !=="-") && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingScvm) :
                                    vehicle === 7 ? ((imgData?.moving !=="-") && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingTractorm) :
                                    vehicle === 8 ? ((imgData?.moving !=="-") && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingRick) :
                                    vehicle === 9 ? ((imgData?.moving !=="-") && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingMapBus) :
                                      MovingBike
                        }
                      />
                    </Badge>
                  } />

                  <Tab label={'Stop'} value={'Stop'} sx={{overflow:'visible'}} onClick={() => showVehicleStatus('Stop')} icon={ <Badge
                      badgeContent={data?.data?.stopCount}
                      color='error'
                      max={999999}
                      showZero={true}
                      
                    >
                  <Avatar variant="square"
                    src={
                      vehicle === 2 ? ((imgData?.stop !=="-") && imgData?.stop !== null? `https://${imgData?.stop}` : StopBike) :
                        vehicle === 3 ? ((imgData?.stop !=="-") && imgData?.stop !== null ? `https://${imgData?.stop}` : StopAuto) :
                          vehicle === 4 ? ((imgData?.stop !=="-") && imgData?.stop !== null  ? `https://${imgData?.stop}` : StopTruck) :
                            vehicle === 5 ? ((imgData?.stop !=="-") && imgData?.stop !== null  ? `https://${imgData?.stop}` : StopCar) :
                              vehicle === 6 ? ((imgData?.stop !=="-") && imgData?.stop !== null  ? `https://${imgData?.stop}` : StopScvm) :
                                vehicle === 7 ? ((imgData?.stop !=="-") && imgData?.stop !== null  ? `https://${imgData?.stop}` : StopTractorm) :
                                vehicle === 8 ? ((imgData?.stop !=="-") && imgData?.stop !== null  ? `https://${imgData?.stop}` : StopRick) :
                                vehicle === 9 ? ((imgData?.stop !=="-") && imgData?.stop !== null  ? `https://${imgData?.stop}` : StopMapBus) :
                                StopBike
                    }
                  />
                    </Badge>
                  } />

                  <Tab label={'Charging'} value={'Charging'} sx={{overflow:'visible'}} onClick={() => showVehicleStatus('Charging')} icon={ <Badge
                      badgeContent={data?.data?.chargeCount}
                      color='warning'
                      max={999999}
                      showZero={true}
                      
                    >
                  <Avatar variant="square"
                    src={
                      vehicle === 2 ? (imgData?.charging !=="-" && imgData?.charging !== null ? `https://${imgData?.charging}` : ChargingBike) :
                        vehicle === 3 ? (imgData?.charging !=="-" && imgData?.charging !== null ? `https://${imgData?.charging}` : ChargingAuto) :
                          vehicle === 4 ? (imgData?.charging !=="-" && imgData?.charging !== null  ? `https://${imgData?.charging}` : ChargingTruck) :
                            vehicle === 5 ? (imgData?.charging !=="-" && imgData?.charging !== null  ? `https://${imgData?.charging}` : ChargingCar) :
                              vehicle === 6 ? (imgData?.charging !=="-" && imgData?.charging !== null  ? `https://${imgData?.charging}` : ChargingScvm) :
                                vehicle === 7 ? (imgData?.charging !=="-" && imgData?.charging !== null  ? `https://${imgData?.charging}` : ChargingTractorm) :
                                vehicle === 8 ? (imgData?.charging !=="-" && imgData?.charging !== null  ? `https://${imgData?.charging}` : ChargingRick) :
                                vehicle === 9 ? (imgData?.charging !=="-" && imgData?.charging !== null  ? `https://${imgData?.charging}` : ChargingMapBus) :
                                ChargingBike
                    }
                  />
                    </Badge>
                  } />


                  <Tab label={'Offline'} value={'Offline'} sx={{overflow:'visible'}} onClick={() => showVehicleStatus('Offline')} icon={ 
                  <Badge
                      badgeContent={data?.data?.offlineCount}
                      color='info'
                      max={999999}
                      showZero={true}
                      
                      sx={{'& .MuiBadge-badge':{background:theme.palette.grey[600], color:theme.palette.primary.contrastText}}}
                    >
                  <Avatar variant="square"
                    src={
                      vehicle === 2 ? (imgData?.offline !=="-" && imgData?.offline !== null ? `https://${imgData?.offline}` : OfflineBike) :
                        vehicle === 3 ? (imgData?.offline !=="-"  && imgData?.offline !== null  ? `https://${imgData?.offline}` : OfflineAuto) :
                          vehicle === 4 ? (imgData?.offline !=="-" && imgData?.offline !== null  ? `https://${imgData?.offline}` : OfflineTruck) :
                            vehicle === 5 ? (imgData?.offline !=="-" && imgData?.offline !== null  ? `https://${imgData?.offline}` : OfflineCar) :
                              vehicle === 6 ? (imgData?.offline !=="-" && imgData?.offline !== null  ? `https://${imgData?.offline}` : OfflineScvm) :
                                vehicle === 7 ? (imgData?.offline !=="-" && imgData?.offline !== null  ? `https://${imgData?.offline}` : OfflineTractorm) :
                                vehicle === 8 ? (imgData?.offline !=="-" && imgData?.offline !== null  ? `https://${imgData?.offline}` : OfflineRick) :
                                vehicle === 9 ? (imgData?.offline !=="-" && imgData?.offline !== null  ? `https://${imgData?.offline}` : OfflineMapBus) :
                                OfflineBike
                    }
                  />
                    </Badge>
                  } />


              </Tabs>
              }
            
        </Paper>
    </VehicleStatusBar>
  )
}

export default CountBar
