import { Box, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { StyledPaperShadow } from '../../theme/StyledEle';
import { Forgotpassword, Loginform } from './Form';
import LoginBG from '../../assets/images/Lgoin-bg.jpg'
function LoginLatest() {
    const theme = useTheme();

    //Forgot Password
    const [showForgot, setShowForgot] = useState(false);

    const HandleForgotView = () => {
     setShowForgot(!showForgot);
    };
  return (
    <Box
        sx={{
            //background:theme.palette.background.default,
            background:`url(${LoginBG})`,
            height:'100vh',
            width:'100%',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            flexDirection:'column'
        }}
    >
        <StyledPaperShadow
            sx={{
                width:{lg:'550px'},
                display:'block',
                p:3
            }}
        >
            <Typography variant='subtitle1' gutterBottom>Welcome Back!</Typography>
            <Box sx={{my:4}}/>
            <Box>
                {showForgot ? (
                <Forgotpassword showForgot={HandleForgotView} />
                ) : (
                <Loginform showForgot={HandleForgotView} />
                )}
            </Box>
        </StyledPaperShadow>
        <Typography variant="caption" sx={{ mt: 3 }}>
         Copyrights @ 2023, All rights reserved nesh.live
        </Typography>
    </Box>
  )
}

export default LoginLatest